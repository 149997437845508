import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { getUserCustomSpells, deleteCustomSpell, getUserSpellbooks, updateUserSpellbooks } from '../firebase/firestoreOperations';
import { useFeatureFlags } from '../FeatureFlagsContext';
import spellsData from '../data/spells.json';
import '../styles/pages/SpellDetailPage.css';
import SpellContent from '../components/SpellContent';
import SpellNotes from '../components/SpellNotes';
import SpellRating from '../components/SpellRating';
import SpellComments from '../components/SpellComments';
import CustomSpellForm from '../components/CustomSpellForm';
import AddToSpellbookModal from '../components/AddToSpellbookModal';
import { NotificationContext } from '../App';
import ConfirmationPopup from '../components/ConfirmationPopup';
import { MetricIcon, ActiveMetricIcon } from '../data/Icons';
import PageHead from '../components/PageHead';

function SpellDetailPage({ convertToMetric, setConvertToMetric }) {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const { enableRatings, enableComments } = useFeatureFlags();
  const [customSpells, setCustomSpells] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const decodedSpellName = decodeURIComponent(id);
  const navigate = useNavigate();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [spellbooks, setSpellbooks] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addNotification } = useContext(NotificationContext);

  const builtInSpell = spellsData.find(s => s.name === decodedSpellName);

  useEffect(() => {
    const loadCustomSpells = async () => {
      if (!builtInSpell && currentUser) {
        setIsLoading(true);
        const userCustomSpells = await getUserCustomSpells(currentUser.uid);
        setCustomSpells(userCustomSpells);
        setIsLoading(false);
      }
    };
    loadCustomSpells();
  }, [currentUser, builtInSpell]);

  useEffect(() => {
    const loadSpellbooks = async () => {
      if (currentUser) {
        const userSpellbooks = await getUserSpellbooks(currentUser.uid);
        setSpellbooks(userSpellbooks);
      } else {
        const guestSpellbooks = JSON.parse(localStorage.getItem('guestSpellbooks')) || { 'My Spellbook': [] };
        setSpellbooks(guestSpellbooks);
      }
    };
    loadSpellbooks();
  }, [currentUser]);

  const spell = builtInSpell || Object.values(customSpells).find(s => s.name === decodedSpellName);

  // Generate SEO metadata
  const generateSeoData = () => {
    if (!spell) {
      // Return default SEO data for "Spell Not Found" page
      return {
        title: "Spell Not Found",
        description: `The spell "${decodedSpellName}" could not be found in the TableMancer database.`,
        keywords: ['D&D spell', 'TableMancer', 'spell not found', 'D&D 5e'],
        canonical: `https://tablemancer.com/spell/${encodeURIComponent(decodedSpellName)}`,
        type: 'website'
      };
    }
    
    // Create a brief description of the spell for SEO
    let description = `${spell.name} - ${spell.level === 'cantrip' ? 'Cantrip' : `Level ${spell.level} spell`} from the ${spell.school} school`;
    
    if (spell.shortDescription) {
      description += `. ${spell.shortDescription}`;
    }
    
    // If there's a full description, add a portion of it (truncated)
    if (spell.description) {
      const shortExcerpt = spell.description.split('.')[0];
      if (shortExcerpt && shortExcerpt.length < 100) {
        description += `. ${shortExcerpt}.`;
      }
    }
    
    // Include casting time, range, and duration in description
    if (spell.casting_time && spell.range && spell.duration) {
      description += ` Cast as a ${spell.casting_time.toLowerCase()} with range ${spell.range.toLowerCase()}, duration ${spell.duration.toLowerCase()}.`;
    }
    
    // Classes that can cast this spell
    let classText = '';
    if (spell.classes && spell.classes.length > 0) {
      classText = `Available to ${spell.classes.slice(0, 3).join(', ')}${spell.classes.length > 3 ? ' and more' : ''}.`;
      description += ` ${classText}`;
    }
    
    // Generate keywords
    const keywords = [
      'D&D spell',
      'DnD 5e',
      spell.name,
      `${spell.school} spell`,
      `level ${spell.level} spell`,
      'Dungeons and Dragons',
      'D&D spellcasting',
      `${spell.level === 'cantrip' ? 'cantrip' : `level ${spell.level}`}`,
      ...(spell.classes || []),
      'TableMancer',
      'spell details'
    ];
    
    // Build canonical URL
    const baseUrl = 'https://tablemancer.com';
    const canonical = `${baseUrl}/spell/${encodeURIComponent(spell.name)}`;
    
    return {
      title: `${spell.name} - D&D 5e Spell`,
      description: description,
      keywords: keywords,
      canonical: canonical,
      type: 'article'
    };
  };

  // Get SEO data - always get this first before any conditionals
  const seoData = generateSeoData();

  if (isLoading) {
    return (
      <div className="spell-detail-page">
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Loading spell details...</p>
        </div>
      </div>
    );
  }

  if (!spell) {
    return (
      <div className="spell-detail-page">
        <PageHead 
          title={seoData.title}
          description={seoData.description}
          keywords={seoData.keywords}
          canonical={seoData.canonical}
          type={seoData.type}
        />
        <div className="spell-not-found">
          <h2>Spell Not Found</h2>
          <p>The spell "{decodedSpellName}" could not be found.</p>
          <Link to="/spells" className="back-link">
            <span style={{ marginRight: '8px' }}>←</span>
            Back to Spell List
          </Link>
        </div>
      </div>
    );
  }

  const getLevelDisplay = () => {
    if (spell.level === 'cantrip') {
      const capitalizedSchool = spell.school.charAt(0).toUpperCase() + spell.school.slice(1);
      return `Cantrip, ${capitalizedSchool}`;
    }
    return `Level ${spell.level}, ${spell.school}`;
  };

  const handleConvertUnitsChange = () => {
    setConvertToMetric(!convertToMetric);
  };

  const handleEditSpell = () => {
    setShowEditForm(true);
  };

  const handleDeleteSpell = async () => {
    try {
      await deleteCustomSpell(currentUser.uid, spell.id);
      navigate('/spells');
    } catch (error) {
      console.error('Error deleting spell:', error);
    }
  };

  const handleSpellUpdated = (updatedSpell) => {
    setCustomSpells(prev => ({
      ...prev,
      [updatedSpell.id]: updatedSpell
    }));
    setShowEditForm(false);
  };

  const handleAddToSpellbook = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = async (spellbookName) => {
    try {
      const existingSpells = spellbooks[spellbookName] || [];
      if (existingSpells.includes(spell.id)) {
        addNotification(`${spell.name} is already in ${spellbookName}`, 'warning');
        return;
      }

      const updatedSpellbooks = {
        ...spellbooks,
        [spellbookName]: [...existingSpells, spell.id]
      };

      if (currentUser) {
        await updateUserSpellbooks(currentUser.uid, updatedSpellbooks);
      } else {
        localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
      }

      setSpellbooks(updatedSpellbooks);
      addNotification(`${spell.name} has been added to ${spellbookName}`, 'success');
    } catch (error) {
      console.error('Error adding spell to spellbook:', error);
      addNotification('Failed to add spell to spellbook', 'error');
    }
  };

  const getContainingSpellbooks = () => {
    return Object.entries(spellbooks)
      .filter(([_, spellIds]) => spellIds.includes(spell.id))
      .map(([name]) => name);
  };

  if (showEditForm) {
    return (
      <CustomSpellForm
        onSpellAdded={handleSpellUpdated}
        onClose={() => setShowEditForm(false)}
        initialData={spell}
        isEditing={true}
      />
    );
  }

  return (
    <div className="spell-detail-page">
      <PageHead
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        canonical={seoData.canonical}
        type={seoData.type}
      />
      
      <div className={`spell-detail-header ${spell.isCustom ? 'custom-spell-header' : ''}`}>
        <div className="spell-header-content">
          <h1>{spell.name}</h1>
          <p>{getLevelDisplay()}</p>
        </div>
        {enableRatings && <SpellRating spellId={spell.id} />}
      </div>

      <div className="spell-detail-content">
        <SpellContent 
          spell={spell} 
          convertToMetric={convertToMetric} 
          hideHeader={true} 
          metricToggleButton={
            <button 
              className={`metric-toggle-button ${convertToMetric ? 'active' : ''}`}
              onClick={handleConvertUnitsChange}
              title={`${convertToMetric ? 'Disable' : 'Enable'} Metric Units`}
            >
              {convertToMetric ? <ActiveMetricIcon /> : <MetricIcon />}
            </button>
          }
        />
        
        <div className="spell-detail-actions">
          <button 
            className="add-to-spellbook-button"
            onClick={handleAddToSpellbook}
          >
            Add to Spellbook
          </button>

          {getContainingSpellbooks().length > 0 && (
            <div className="containing-spellbooks">
              <h3>Found in Spellbooks:</h3>
              <ul>
                {getContainingSpellbooks().map(book => (
                  <li key={book}>{book}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <SpellNotes spellId={spell.id} />
        
        {enableComments && <SpellComments spellId={spell.id} spellName={spell.name} />}

        {spell.isCustom && currentUser && spell.createdBy === currentUser.uid && (
          <div className="spell-actions">
            <button 
              className="edit-spell-button"
              onClick={handleEditSpell}
            >
              Edit Spell
            </button>
            <button 
              className="delete-spell-button"
              onClick={() => setShowDeleteConfirm(true)}
            >
              Delete Spell
            </button>
          </div>
        )}

        <Link to="/spells" className="back-link">
          <span style={{ marginRight: '8px' }}>←</span>
          Back to Spell List
        </Link>
      </div>

      <ConfirmationPopup
        isOpen={showDeleteConfirm}
        message={`Are you sure you want to delete "${spell.name}"? This action cannot be undone.`}
        onConfirm={handleDeleteSpell}
        onCancel={() => setShowDeleteConfirm(false)}
      />

      <AddToSpellbookModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        spellbooks={spellbooks}
        selectedSpells={[spell.name]}
      />
    </div>
  );
}

export default SpellDetailPage;
