import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, deleteField } from 'firebase/firestore';
import { logger } from './utils';
import { isUserAdmin } from '../../utils/setupAdmin';
import { getUserLimits } from './admin';
import { getUserCustomSpells } from './spells';
import { debounce } from '../../utils/debounce';
import spellsData from '../../data/spells.json';

/**
 * Get user's spellbooks from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing spellbooks
 */
export const getUserSpellbooks = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserSpellbooks');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));

    if (!userDoc.exists()) {
      const defaultSpellbooks = { 'My Spellbook': [] };
      await setDoc(doc(db, 'users', userId), {
        spellbooks: defaultSpellbooks
      });
      return defaultSpellbooks;
    }

    return userDoc.data().spellbooks || { 'My Spellbook': [] };
  } catch (error) {
    console.error('Error getting spellbooks:', error);
    return { 'My Spellbook': [] };
  }
};

/**
 * Update user's spellbooks in Firestore
 * @param {string} userId - The user's ID
 * @param {Object} spellbooks - Object containing spellbooks
 * @returns {Promise<void>}
 */
export const updateUserSpellbooks = async (userId, spellbooks) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserSpellbooks');
      return;
    }

    const userRef = doc(db, 'users', userId);

    // First check if the document exists
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      // If document doesn't exist, create it
      await setDoc(userRef, { spellbooks });
    } else {
      // If it exists, update it
      await updateDoc(userRef, { spellbooks });
    }
  } catch (error) {
    console.error('Error updating spellbooks:', error);
    throw error;
  }
};

/**
 * Add a spell to a spellbook
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {string} spellName - The name of the spell to add
 * @returns {Promise<Object>} - The spell that was added
 */
export const addSpellToSpellbook = async (userId, spellbookName, spellName) => {
  try {
    // First try to find the spell in built-in spells
    let spell = spellsData.find(s => s.name === spellName);

    // If not found, check custom spells
    if (!spell) {
      const userCustomSpells = await getUserCustomSpells(userId);
      spell = Object.values(userCustomSpells).find(s => s.name === spellName);
    }

    if (!spell) {
      throw new Error('Spell not found');
    }

    const userRef = doc(db, 'users', userId);

    // If it's a custom spell, ensure it exists in customSpells first
    if (spell.isCustom) {
      await setDoc(userRef, {
        customSpells: {
          [spell.id]: spell
        }
      }, { merge: true });
    }

    // Get the current spellbook contents to ensure we don't lose any spells
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const spellbooks = userDoc.data().spellbooks || {};
      const currentSpellbookSpells = spellbooks[spellbookName] || [];

      // Only update if the spell isn't already in the spellbook
      if (!currentSpellbookSpells.includes(spell.id)) {
        // Create a new array with all existing spells plus the new one
        const updatedSpellbookSpells = [...currentSpellbookSpells, spell.id];

        // Update the spellbook with the complete list of spells
        // Don't use arrayUnion as it can be unreliable in some cases
        await updateDoc(userRef, {
          [`spellbooks.${spellbookName}`]: updatedSpellbookSpells
        });

        logger(`Successfully added spell ${spell.name} to spellbook ${spellbookName}. New count: ${updatedSpellbookSpells.length}`);
      } else {
        logger(`Spell ${spell.name} already exists in spellbook ${spellbookName}`);
      }
    } else {
      // If the user document doesn't exist yet, create it with the spellbook
      await setDoc(userRef, {
        spellbooks: {
          [spellbookName]: [spell.id]
        }
      }, { merge: true });

      logger(`Created new spellbook ${spellbookName} with spell ${spell.name}`);
    }

    return spell;
  } catch (error) {
    console.error('Error adding spell to spellbook:', error);
    throw error;
  }
};

/**
 * Remove a spell from a spellbook
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {Object} spell - The spell to remove
 * @returns {Promise<void>}
 */
export const removeSpellFromSpellbook = async (userId, spellbookName, spell) => {
  try {
    if (!userId || !spellbookName || !spell) {
      console.warn('Missing required parameters in removeSpellFromSpellbook');
      return;
    }

    const userRef = doc(db, 'users', userId);

    // Use arrayRemove to remove the spell ID from the spellbook array
    await updateDoc(userRef, {
      [`spellbooks.${spellbookName}`]: arrayRemove(spell.id)
    });
  } catch (error) {
    console.error('Error removing spell from spellbook:', error);
    throw error;
  }
};

/**
 * Check if the user has reached their spellbook limit
 * @param {string} userId - The user's ID
 * @returns {Promise<boolean>} - True if the user can create more spellbooks
 */
export const checkSpellbookLimit = async (userId) => {
  try {
    // Check if user is an admin - admins bypass limits
    const isAdmin = await isUserAdmin(userId);
    
    // Admin users bypass limits check
    if (isAdmin) {
      return true;
    }
    
    // Get user limits
    const userLimits = await getUserLimits();
    
    // Get current user spellbooks
    const userDoc = await getDoc(doc(db, 'users', userId));
    
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const spellbooks = userData.spellbooks || {};
      
      // Check if user has reached the spellbook limit
      if (Object.keys(spellbooks).length >= userLimits.maxSpellbooks) {
        throw new Error(`You have reached the maximum limit of ${userLimits.maxSpellbooks} spellbooks. Please delete an existing spellbook to continue.`);
      }
    }
    
    return true;
  } catch (error) {
    console.error('Error checking spellbook limit:', error);
    throw error;
  }
};

/**
 * Create a new spellbook
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the new spellbook
 * @returns {Promise<void>}
 */
export const createSpellbook = async (userId, spellbookName) => {
  try {
    // Check if user can create another spellbook
    await checkSpellbookLimit(userId);
    
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      [`spellbooks.${spellbookName}`]: []
    });
  } catch (error) {
    console.error('Error creating spellbook:', error);
    throw error;
  }
};

/**
 * Remove a spellbook
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook to remove
 * @returns {Promise<void>}
 */
export const removeSpellbook = async (userId, spellbookName) => {
  try {
    if (!userId || !spellbookName) {
      console.warn('Missing required parameters in removeSpellbook');
      return;
    }

    const userRef = doc(db, 'users', userId);

    // Get the current user data
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      logger('User document does not exist');
      return;
    }

    const userData = userDoc.data();
    const updatedSpellbooks = { ...userData.spellbooks };

    // Delete the spellbook
    delete updatedSpellbooks[spellbookName];

    // Update prepared spells if they exist
    const updatedPreparedSpells = { ...userData.preparedSpells };
    delete updatedPreparedSpells[spellbookName];

    // Update spell slots if they exist
    const updatedSpellSlots = { ...userData.spellSlots };
    delete updatedSpellSlots[spellbookName];

    // Update spell points if they exist
    const updatedSpellPoints = { ...userData.spellPoints };
    delete updatedSpellPoints[spellbookName];

    // Update character levels if they exist
    const updatedCharacterLevels = { ...userData.characterLevelsByBook };
    delete updatedCharacterLevels[spellbookName];

    // Update user data without the deleted spellbook and related data
    await updateDoc(userRef, {
      spellbooks: updatedSpellbooks,
      preparedSpells: updatedPreparedSpells,
      spellSlots: updatedSpellSlots,
      spellPoints: updatedSpellPoints,
      characterLevelsByBook: updatedCharacterLevels
    });
  } catch (error) {
    console.error('Error removing spellbook:', error);
    throw error;
  }
};

/**
 * Get the user's active spellbook
 * @param {string} userId - The user's ID
 * @returns {Promise<string>} - The name of the active spellbook
 */
export const getUserActiveSpellbook = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserActiveSpellbook');
      return 'My Spellbook';
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() ? (userDoc.data().activeSpellbook || 'My Spellbook') : 'My Spellbook';
  } catch (error) {
    console.error('Error getting active spellbook:', error);
    return 'My Spellbook';
  }
};

/**
 * Update the user's active spellbook
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the active spellbook
 * @returns {Promise<void>}
 */
export const updateUserActiveSpellbook = async (userId, spellbookName) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserActiveSpellbook');
      return;
    }

    await updateDoc(doc(db, 'users', userId), {
      activeSpellbook: spellbookName
    });
  } catch (error) {
    console.error('Error updating active spellbook:', error);
    throw error;
  }
};

/**
 * Create a spellbook with a set of spells
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the new spellbook
 * @param {Array} spells - Array of spell IDs to add to the spellbook
 * @returns {Promise<void>}
 */
export const createSpellbookWithSpells = async (userId, spellbookName, spells) => {
  try {
    // Check if user can create another spellbook
    await checkSpellbookLimit(userId);
    
    const userRef = doc(db, 'users', userId);
    
    // Ensure spells is an array of spell IDs
    const spellIds = Array.isArray(spells) ? spells.map(spell => typeof spell === 'string' ? spell : spell.id) : [];
    
    await updateDoc(userRef, {
      [`spellbooks.${spellbookName}`]: spellIds
    });
  } catch (error) {
    console.error('Error creating spellbook with spells:', error);
    throw error;
  }
};

/**
 * Get the user's spellbook order
 * @param {string} userId - The user's ID
 * @returns {Promise<Array>} - Array of spellbook names in order
 */
export const getUserSpellbookOrder = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserSpellbookOrder');
      return [];
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() ? (userDoc.data().spellbookOrder || []) : [];
  } catch (error) {
    console.error('Error getting spellbook order:', error);
    return [];
  }
};

/**
 * Update the user's spellbook order
 * @param {string} userId - The user's ID
 * @param {Array} order - Array of spellbook names in order
 * @returns {Promise<void>}
 */
export const updateUserSpellbookOrder = async (userId, order) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserSpellbookOrder');
      return;
    }

    await updateDoc(doc(db, 'users', userId), {
      spellbookOrder: order
    });
  } catch (error) {
    console.error('Error updating spellbook order:', error);
    throw error;
  }
};

/**
 * Debounced version of updateUserSpellbooks that waits 1000ms before saving
 * @param {string} userId - The user's ID
 * @param {Object} spellbooks - The spellbooks to save
 * @returns {Promise<void>}
 */
export const debouncedUpdateUserSpellbooks = debounce(async (userId, spellbooks) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to debouncedUpdateUserSpellbooks');
      return;
    }

    logger('Saving spellbooks after debounce delay');
    const userRef = doc(db, 'users', userId);

    // First check if the document exists
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      // If document doesn't exist, create it
      await setDoc(userRef, { spellbooks });
    } else {
      // If it exists, update it
      await updateDoc(userRef, { spellbooks });
    }
  } catch (error) {
    console.error('Error updating spellbooks:', error);
    throw error;
  }
}, 1000); 