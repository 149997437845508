import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, deleteField } from 'firebase/firestore';
import { logger } from './utils';
import { isUserAdmin } from '../../utils/setupAdmin';
import { getUserLimits } from './admin';

/**
 * Get user's presets from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing presets
 */
export const getUserPresets = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserPresets');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() ? (userDoc.data().presets || {}) : {};
  } catch (error) {
    console.error('Error getting presets:', error);
    return {};
  }
};

/**
 * Update user's presets in Firestore
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {Object} presets - The presets to update
 * @returns {Promise<void>}
 */
export const updateUserPresets = async (userId, spellbookName, presets) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserPresets');
      return;
    }

    // Check if user is an admin - admins bypass limits
    const isAdmin = await isUserAdmin(userId);
    
    // Get current presets
    const userDoc = await getDoc(doc(db, 'users', userId));
    const currentPresets = userDoc.exists() ? (userDoc.data().presets || {}) : {};
    
    // Get user limits
    const userLimits = await getUserLimits();
    
    // Count presets for this spellbook
    const spellbookPresets = currentPresets[spellbookName] || {};
    
    // Check if new presets would exceed limit (but only for non-admins)
    if (!isAdmin && 
        Object.keys(presets).length > Object.keys(spellbookPresets).length && 
        Object.keys(presets).length > userLimits.maxPresets) {
      throw new Error(`You have reached the maximum limit of ${userLimits.maxPresets} presets per spellbook. Please delete an existing preset to continue.`);
    }
    
    // Update presets
    await setDoc(doc(db, 'users', userId), {
      presets: {
        [spellbookName]: presets
      }
    }, { merge: true });
  } catch (error) {
    console.error('Error updating presets:', error);
    throw error;
  }
};

/**
 * Remove a preset from a spellbook
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {string} presetName - The name of the preset to remove
 * @returns {Promise<void>}
 */
export const removePreset = async (userId, spellbookName, presetName) => {
  try {
    if (!userId || !spellbookName || !presetName) {
      console.warn('Missing required parameters in removePreset');
      return;
    }

    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      [`presets.${spellbookName}.${presetName}`]: deleteField()
    });
  } catch (error) {
    console.error('Error removing preset:', error);
    throw error;
  }
}; 