import React from 'react';
import '../styles/components/ActiveSpellbookHeader.css';

const ActiveSpellbookHeader = ({
  currentSpellbook,
  setCurrentSpellbook,
  spellbooks
}) => {
  return (
    <div className="active-spellbook-header">
      <h2>Active Spellbook:</h2>
      <select
        className="spellbook-selector"
        value={currentSpellbook}
        onChange={(e) => setCurrentSpellbook(e.target.value)}
      >
        {Object.keys(spellbooks).map(book => (
          <option key={book} value={book}>{book}</option>
        ))}
      </select>
    </div>
  );
};

export default ActiveSpellbookHeader; 