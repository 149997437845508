import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserLimits } from './firebase/firestoreOperations';

const UserLimitsContext = createContext(null);

export const useUserLimits = () => {
  const context = useContext(UserLimitsContext);
  if (!context) {
    throw new Error('useUserLimits must be used within a UserLimitsProvider');
  }
  return context;
};

export const UserLimitsProvider = ({ children }) => {
  const [userLimits, setUserLimits] = useState({
    maxSpellbooks: 3,
    maxCustomSpells: 5,
    maxPreparedPresets: 3,
    loading: true
  });

  useEffect(() => {
    const loadUserLimits = async () => {
      try {
        const limits = await getUserLimits();
        setUserLimits({
          ...limits,
          loading: false
        });
      } catch (error) {
        console.error('Error loading user limits:', error);
        setUserLimits(prev => ({
          ...prev,
          loading: false
        }));
      }
    };

    loadUserLimits();
  }, []);

  return (
    <UserLimitsContext.Provider value={{ ...userLimits, setUserLimits }}>
      {children}
    </UserLimitsContext.Provider>
  );
};

export default UserLimitsContext; 