// SmallCardsPDFGenerator.js - Small card size only
import { jsPDF } from 'jspdf';

// Card dimensions for small size (9 cards per page in a 3x3 grid)
const CARD_DIMENSIONS = {
  width: 66, // mm (increased from 63)
  height: 92, // mm (increased from 88)
  margin: 3
};

// Page margins and spacing
const MARGINS = {
  top: 14, // Increased from 8 to center vertically
  left: 14, // Increased from 8 to center horizontally
  bottom: 14, // Increased from 8 for symmetry
  right: 14, // Increased from 8 for symmetry
  between: 2 // Maintaining the 2mm gap between cards
};

// Cards per page in a 3x3 grid
const CARDS_PER_PAGE = 9;
const CARDS_PER_ROW = 3;

// Define header height
const headerHeight = 12; // mm - reduced from 15mm to 12mm for thinner header

// Create a global word index tracker to maintain state between function calls
let spellNextWordIndex = {};

// Keep track of cards per spell
let cardsPerSpell = {};

// Track current card position
let cardPosition = 0;

const capitalizeWords = (str) => {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase());
};

// Helper function to render a property with wrapping for long values
const renderPropertyWithWrapping = (doc, label, value, contentX, currentY, labelWidth, maxWidth) => {
  // Write the label in bold
  doc.setFont("helvetica", "bold");
  doc.text(label, contentX, currentY);

  // Write the value in normal font
  doc.setFont("helvetica", "normal");

  if (value) {
    // Calculate remaining width for value
    const remainingWidth = maxWidth - labelWidth;

    // Split value if it's too long for remaining space
    const valueLines = doc.splitTextToSize(value, remainingWidth);

    // Write first line after label
    doc.text(valueLines[0], contentX + labelWidth, currentY);

    // Write any additional lines from the start of the line
    for (let i = 1; i < valueLines.length; i++) {
      currentY += doc.getFontSize() * 0.35;
      doc.text(valueLines[i], contentX, currentY);
    }
  }

  return currentY + doc.getFontSize() * 0.35;
};

// Create and pre-render the card set
const createSpellCards = (doc, spell, cardWidth, cardHeight, headerHeight, cardMargin, fontSize, fontScale = 1.0) => {
  // Get description text
  const description = spell.description || '';

  // Set up margins and dimensions - ensure text fills to right edge
  const leftMargin = cardMargin * 0.9;  // Consistent left margin
  const rightMargin = cardMargin * 0.1;  // Almost no right margin (just enough to prevent edge clipping)
  const availableWidth = cardWidth - leftMargin - rightMargin;

  // Default line height
  const lineHeight = fontSize * 0.35;

  // Calculate available heights with EXACT measurements
  const availableHeightFirstCard = cardHeight - headerHeight - cardMargin;
  const linesPerFirstCard = Math.floor(availableHeightFirstCard / lineHeight);

  // Calculate space on continuation cards
  const contHeaderHeight = fontSize + 4; // Fixed header height
  const availableHeightCont = cardHeight - contHeaderHeight - cardMargin;
  const linesPerContCard = Math.floor(availableHeightCont / lineHeight);

  // Split text into words to count them
  const words = description.split(' ');

  // Find how many cards we need - start with just first card
  let totalCards = 1;

  // Mock document for testing text fitting
  const mockDoc = {
    getStringUnitWidth: (str) => doc.getStringUnitWidth(str),
    getFontSize: () => fontSize * fontScale,
    internal: { scaleFactor: doc.internal.scaleFactor },
    text: () => { } // Dummy function
  };

  // Do a test wrap to see how many words fit on first card
  const firstCardTest = wrapAndRenderText(
    mockDoc,
    description,
    0, 0, // Dummy positions
    availableWidth,
    lineHeight,
    linesPerFirstCard,
    0,
    true  // isDryRun = true - just test without rendering
  );

  // If we have more words, calculate continuation cards needed
  if (firstCardTest.hasMore) {
    // Calculate how many words fit on a continuation card
    const contCardTest = wrapAndRenderText(
      mockDoc,
      description,
      0, 0, // Dummy positions
      availableWidth,
      lineHeight,
      linesPerContCard,
      firstCardTest.wordsRendered,
      true  // isDryRun = true
    );

    const wordsPerContCard = contCardTest.wordsRendered - firstCardTest.wordsRendered;
    const wordsRemaining = words.length - firstCardTest.wordsRendered;

    // Calculate total cards needed based on actual words per card
    if (wordsRemaining > 0 && wordsPerContCard > 0) {
      totalCards = 1 + Math.ceil(wordsRemaining / wordsPerContCard);
    }
  }

  // Create the cards array
  const cards = [];

  // First card always gets the title and properties
  cards.push({
    title: spell.name,
    isFirstCard: true,
    spell: spell,
    description: description,
    pageInfo: totalCards > 1 ? `1/${totalCards}` : null,
    leftMargin: leftMargin,
    rightMargin: rightMargin,
    availableWidth: availableWidth,
    lineHeight: lineHeight,
    maxLines: linesPerFirstCard,
    wordIndex: 0, // Start from the beginning
    cardHeight: cardHeight,
    bottomMargin: cardMargin
  });

  // Create continuation cards if needed
  if (totalCards > 1) {
    // Start with the exact word count from the test run
    let wordOffset = firstCardTest.wordsRendered;

    for (let i = 2; i <= totalCards; i++) {
      // Only add a card if we have more words to render
      if (wordOffset < words.length) {
        cards.push({
          title: spell.name,
          isContinuation: true,
          description: description,
          pageInfo: `${i}/${totalCards}`,
          leftMargin: leftMargin,
          rightMargin: rightMargin,
          availableWidth: availableWidth,
          lineHeight: lineHeight,
          maxLines: linesPerContCard,
          wordIndex: wordOffset, // Words to skip
          cardHeight: cardHeight,
          headerHeight: contHeaderHeight,
          bottomMargin: cardMargin
        });

        // Test exactly how many words fit on this cont. card
        if (i < totalCards) {
          const contCardTest = wrapAndRenderText(
            mockDoc,
            description,
            0, 0, // Dummy positions
            availableWidth,
            lineHeight,
            linesPerContCard,
            wordOffset,
            true  // isDryRun = true
          );

          // Update offset for next card based on exact test
          wordOffset = contCardTest.wordsRendered;
        }
      }
    }
  }

  return cards;
};

// Improved direct text wrapping function with boundary checking and support for bold text
const wrapAndRenderText = (doc, text, x, y, maxWidth, lineHeight, maxLines = Infinity, wordsToSkip = 0, isDryRun = false) => {
  // Function to process a text segment (may contain bold markers)
  const processTextSegment = (segment, xPos, yPos, isDryRun) => {
    // Check for bold text markers
    if (segment.includes('**')) {
      const parts = segment.split('**');
      let currentX = xPos;

      parts.forEach((part, index) => {
        if (part.length === 0) return;

        // Toggle bold style for odd-indexed parts (inside ** **)
        const isBold = index % 2 === 1;
        doc.setFont("helvetica", isBold ? "bold" : "normal");

        if (!isDryRun) {
          doc.text(part, currentX, yPos);
        }

        // Move x position for next part
        currentX += doc.getStringUnitWidth(part) * doc.getFontSize() / doc.internal.scaleFactor;
      });

      // Reset to normal font after processing
      doc.setFont("helvetica", "normal");
      return true;
    }
    return false;
  };

  // Split the text into words
  const words = text.split(' ');
  let currentLine = '';
  let currentWidth = 0;
  let currentY = y;
  let linesRendered = 0;
  let wordsRendered = wordsToSkip;
  const fontSize = doc.getFontSize();

  // Check if we're starting in the middle of a bold section (for continuation cards)
  let isBoldOpen = false;

  // Count the number of '**' before our starting word
  if (wordsToSkip > 0) {
    const textBeforeSkip = words.slice(0, wordsToSkip).join(' ');
    const boldMarkerCount = (textBeforeSkip.match(/\*\*/g) || []).length;
    // If there's an odd number of bold markers, we're starting inside a bold section
    isBoldOpen = boldMarkerCount % 2 === 1;

    // If we're starting in a bold section, we need to add the opening marker
    if (isBoldOpen && wordsToSkip < words.length) {
      // Check if we're starting with "At Higher Levels:" or any text that should be bold
      const nextWordIndex = Math.min(wordsToSkip, words.length - 1);

      // Look ahead to see if the continuation has a common phrase that should be bold
      // This handles cases like "At Higher Levels:" being split across cards
      const nextWords = words.slice(nextWordIndex, nextWordIndex + 5).join(' ');
      if (nextWords.includes("Higher Levels:") || nextWords.includes("At Higher Levels:")) {
        // Add opening bold marker if we detect a header phrase at the start of continuation
        currentLine = '**';
      } else if (isBoldOpen) {
        // Otherwise, just respect the ongoing bold formatting
        currentLine = '**';
      }
    }
  }

  // Skip words if requested (for continuation cards)
  let i = wordsToSkip > 0 ? Math.min(wordsToSkip, words.length - 1) : 0;

  // Store initial font style - always use "normal" as default
  const originalFontStyle = "normal";

  // Now actually render starting from our skip point
  for (; i < words.length && linesRendered < maxLines; i++) {
    const word = words[i];
    if (!word) continue; // Skip empty words

    // Calculate the width of this word plus a space
    const wordWidth = doc.getStringUnitWidth(word + ' ') * fontSize / doc.internal.scaleFactor;

    // Check if adding this word would exceed the available width
    if (currentWidth + wordWidth > maxWidth) {
      // Render the current line if not empty
      if (currentLine) {
        if (!isDryRun) {
          const containsBold = processTextSegment(currentLine, x, currentY, isDryRun);
          if (!containsBold) {
            doc.text(currentLine, x, currentY);
          }
        }

        currentY += lineHeight;
        linesRendered++;

        if (linesRendered >= maxLines) {
          // We hit the line limit - don't count this word as rendered
          break;
        }

        currentLine = word + ' ';
        currentWidth = wordWidth;
      } else {
        // This word alone is too long, force break it
        if (!isDryRun) {
          const containsBold = processTextSegment(word, x, currentY, isDryRun);
          if (!containsBold) {
            doc.text(word, x, currentY);
          }
        }
        currentY += lineHeight;
        linesRendered++;

        if (linesRendered >= maxLines) {
          // We hit the line limit - count this word as rendered
          wordsRendered = i + 1;
          break;
        }

        currentLine = '';
        currentWidth = 0;
      }
    } else {
      // Add the word to the current line
      currentLine += word + ' ';
      currentWidth += wordWidth;
    }

    // Handle line breaks in the original text
    if (word.includes('\n')) {
      // Render the current line up to this point
      if (currentLine) {
        if (!isDryRun) {
          const containsBold = processTextSegment(currentLine.replace(word + ' ', word.split('\n')[0]), x, currentY, isDryRun);
          if (!containsBold) {
            doc.text(currentLine.replace(word + ' ', word.split('\n')[0]), x, currentY);
          }
        }

        currentY += lineHeight;
        linesRendered++;

        if (linesRendered >= maxLines) {
          wordsRendered = i + 1;
          break;
        }
      }

      // Start a new line with anything after the last newline
      const parts = word.split('\n');
      if (parts.length > 1) {
        currentLine = parts[parts.length - 1] + ' ';
        currentWidth = doc.getStringUnitWidth(currentLine) * fontSize / doc.internal.scaleFactor;

        // If there are middle parts, add them as separate lines
        for (let j = 1; j < parts.length - 1 && linesRendered < maxLines; j++) {
          if (!isDryRun) {
            const containsBold = processTextSegment(parts[j], x, currentY, isDryRun);
            if (!containsBold) {
              doc.text(parts[j], x, currentY);
            }
          }

          currentY += lineHeight;
          linesRendered++;

          if (linesRendered >= maxLines) {
            wordsRendered = i + 1;
            break;
          }
        }
      } else {
        currentLine = '';
        currentWidth = 0;
      }
    }

    // Update the count of words successfully rendered
    wordsRendered = i + 1;
  }

  // Render the final line if there's anything left
  if (currentLine.trim() && linesRendered < maxLines && !isDryRun) {
    const containsBold = processTextSegment(currentLine.trim(), x, currentY, isDryRun);
    if (!containsBold) {
      doc.text(currentLine.trim(), x, currentY);
    }

    currentY += lineHeight;
    linesRendered++;
  }

  // Restore original font settings
  doc.setFont("helvetica", originalFontStyle);

  // Return information about what was rendered
  return {
    endY: currentY,
    linesRendered,
    hasMore: wordsRendered < words.length,
    wordsRendered: wordsRendered, // Return the exact position we ended at
    totalWords: words.length
  };
};

// Main PDF generation function for small cards
export const generateSmallCardsPDF = (doc, {
  spells,
  layout,
  convertIfNeeded,
  showOnlySRDDescriptions
}) => {
  // Start with a fresh PDF if not provided
  if (!doc) {
    doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm'
    });
  }

  // Set up canvas
  doc.setFillColor(255, 255, 255);
  doc.setDrawColor(0);
  doc.setTextColor(0);
  doc.setLineWidth(0.3); // Set consistent line width for all borders

  // Define school colors - more vibrant and modern to match large cards
  const schoolColors = {
    'abjuration': [111, 188, 255],   // Brighter blue
    'necromancy': [88, 219, 88],     // Vibrant green
    'evocation': [255, 70, 70],      // More intense red (decreased green/blue components)
    'divination': [180, 180, 210],   // Soft lavender gray
    'conjuration': [255, 255, 120],  // Bright yellow
    'enchantment': [255, 120, 220],  // Vibrant pink
    'transmutation': [255, 140, 0],  // Pure vivid orange
    // Default for any missing school
    'default': [240, 240, 240] // light gray
  };

  // Helper function to get school color
  const getSchoolColor = (school) => {
    // If coloredHeaders is false, return default gray color
    if (layout.coloredHeaders === false) {
      return [240, 240, 240]; // light gray for all schools
    }

    const normalizedSchool = school ? school.toLowerCase() : 'default';
    return schoolColors[normalizedSchool] || schoolColors.default;
  };

  // Reset tracking variables at the start of generation
  spellNextWordIndex = {};
  cardsPerSpell = {};
  cardPosition = 0;

  // Get page dimensions
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Calculate layout for 9 cards per page (3x3 grid)
  const cardsPerRow = 3;
  const cardsPerColumn = 3;
  const cardsPerPage = cardsPerRow * cardsPerColumn;

  let allCards = [];

  // Helper function to add a new page if needed
  const addPageIfNeeded = () => {
    if (cardPosition >= CARDS_PER_PAGE) {
      doc.addPage();
      cardPosition = 0;
    }
  };

  // Helper function to get the current card position
  const getCardPosition = () => {
    const row = Math.floor(cardPosition / CARDS_PER_ROW);
    const col = cardPosition % CARDS_PER_ROW;

    // Get current page dimensions
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Calculate the total width and height of the card grid
    const gridWidth = CARDS_PER_ROW * CARD_DIMENSIONS.width + (CARDS_PER_ROW - 1) * MARGINS.between;
    const gridHeight = Math.ceil(CARDS_PER_PAGE / CARDS_PER_ROW) * CARD_DIMENSIONS.height +
      (Math.ceil(CARDS_PER_PAGE / CARDS_PER_ROW) - 1) * MARGINS.between;

    // Calculate the starting position to center the grid on the page
    const startX = (pageWidth - gridWidth) / 2;
    const startY = (pageHeight - gridHeight) / 2;

    // Calculate the position of this specific card in the centered grid
    const x = startX + (col * (CARD_DIMENSIONS.width + MARGINS.between));
    const y = startY + (row * (CARD_DIMENSIONS.height + MARGINS.between));

    return { x, y };
  };

  // Calculate how many cards each spell will need
  const calculateTotalCardsPerSpell = () => {
    spells.forEach(spell => {
      // Check if description should be shown based on SRD status
      const shouldShowDescription = !showOnlySRDDescriptions || spell.srd || spell.isCustom;
      
      // Use card_friendly_description if available
      let description = spell.card_friendly_description || spell.description || '';

      // Override description if restricted by SRD settings
      if (!shouldShowDescription) {
        description = "Spell description limited by content license\n\nThis spell's details are not part of the System Reference Document (SRD) and can't be displayed. Only the basic spell information is shown.";
      } else {
        // Add "At Higher Levels" text if available
        if (spell.higher_levels && spell.level > 0) {
          description += `\n\n**At Higher Levels:** ${spell.higher_levels}`;
        }

        // Add "Cantrip Upgrade" text if available
        if (spell.cantrip_upgrade && spell.level === 0) {
          description += `\n\n**Cantrip Upgrade:** ${spell.cantrip_upgrade}`;
        }
      }

      // Skip if no description
      if (!description || description.trim() === '') {
        cardsPerSpell[spell.name] = { totalCards: 1 };
        return;
      }

      // Create a mock document for text calculations
      const mockDoc = {
        getStringUnitWidth: (text) => doc.getStringUnitWidth(text),
        getFontSize: () => layout.fontSize - 3, // Use smaller font for calculations
        internal: { scaleFactor: doc.internal.scaleFactor },
        text: () => { },
        setFont: () => { }
      };

      // Convert description to words
      const words = description.split(' ');
      if (words.length === 0) {
        cardsPerSpell[spell.name] = { totalCards: 1 };
        return;
      }

      // Add a safety factor to account for potential calculation discrepancies
      const safetyFactor = 0.9; // Use only 90% of available height for calculations

      // Calculate space for first card (with header and properties)
      const firstCardContentY = headerHeight + 4; // Updated to match the new spacing in render function
      const availableFirstCardHeight = (CARD_DIMENSIONS.height - firstCardContentY - CARD_DIMENSIONS.margin * 2) * safetyFactor;
      const lineHeight = (layout.fontSize - 3) * 0.32; // Updated to match new line height
      const availableWidth = CARD_DIMENSIONS.width - (CARD_DIMENSIONS.margin * 1.7);
      const maxLinesFirstCard = Math.floor(availableFirstCardHeight / lineHeight);

      // Do a first card test run
      const firstCardResult = wrapAndRenderText(
        mockDoc,
        description,
        0, 0,
        availableWidth,
        lineHeight,
        maxLinesFirstCard,
        0,
        true // dry run
      );

      // If all text fits on the first card
      if (!firstCardResult.hasMore) {
        cardsPerSpell[spell.name] = { totalCards: 1 };
        return;
      }

      // Calculate space for continuation cards
      const contCardHeaderHeight = layout.fontSize + 2; // Reduced from +4 to +2
      const contCardContentY = contCardHeaderHeight + 4; // Increased from +3 to +4 to match rendering
      const availableContCardHeight = (CARD_DIMENSIONS.height - contCardContentY - CARD_DIMENSIONS.margin * 2) * safetyFactor;
      const maxLinesContCard = Math.floor(availableContCardHeight / lineHeight);

      // Process continuation cards
      let currentWordIndex = firstCardResult.wordsRendered;
      let totalCards = 1; // Start with one card (the first)

      // Keep checking continuation cards until all content fits
      while (currentWordIndex < words.length) {
        const contCardResult = wrapAndRenderText(
          mockDoc,
          description,
          0, 0,
          availableWidth,
          lineHeight,
          maxLinesContCard,
          currentWordIndex,
          true // dry run
        );

        totalCards++;

        // If no more progress or all words rendered, break
        if (contCardResult.wordsRendered <= currentWordIndex) {
          // We couldn't fit any more words, so force one more card to be safe
          totalCards++;
          break;
        } else if (!contCardResult.hasMore) {
          // All remaining text fits on this card
          break;
        }

        currentWordIndex = contCardResult.wordsRendered;

        // Failsafe - don't allow infinite loops or excessive cards
        if (totalCards > 20) break;
      }

      cardsPerSpell[spell.name] = { totalCards };
    });
  };

  // Now that calculateTotalCardsPerSpell is defined, we can call it
  calculateTotalCardsPerSpell();

  // First, prepare all cards including pagination for long descriptions
  spells.forEach((spell) => {
    // Check if description should be shown based on SRD status
    const shouldShowDescription = !showOnlySRDDescriptions || spell.srd || spell.isCustom;
    
    // Use card_friendly_description if available, otherwise use regular description
    let description = spell.card_friendly_description || spell.description || '';

    // Override description if restricted by SRD settings
    if (!shouldShowDescription) {
      description = "Spell description limited by content license\n\nThis spell's details are not part of the System Reference Document (SRD) and can't be displayed. Only the basic spell information is shown.";
    } else {
      // Add "At Higher Levels" text for leveled spells
      if (spell.higher_levels && spell.level > 0) {
        description += `\n\n**At Higher Levels:** ${spell.higher_levels}`;
      }

      // Add "Cantrip Upgrade" text for cantrips
      if (spell.cantrip_upgrade && spell.level === 0) {
        description += `\n\n**Cantrip Upgrade:** ${spell.cantrip_upgrade}`;
      }
    }

    // Skip empty descriptions
    if (!description || description.trim() === '') {
      return;
    }

    // Get the pre-calculated card count
    const totalCards = cardsPerSpell[spell.name] ? cardsPerSpell[spell.name].totalCards : 1;

    // Split description into words for tracking progress
    const words = description.split(' ');
    let currentWordIndex = 0;

    // Process all cards for this spell
    for (let cardNumber = 1; cardNumber <= totalCards; cardNumber++) {
      // Skip if we've rendered all words
      if (currentWordIndex >= words.length) break;

      // Ensure we have space for this card
      addPageIfNeeded();

      // Get card position
      const { x, y } = getCardPosition();

      // Draw card background (white)
      doc.setFillColor(255, 255, 255);
      doc.rect(x, y, CARD_DIMENSIONS.width, CARD_DIMENSIONS.height, 'F');

      // Setup content margins
      const contentX = x + CARD_DIMENSIONS.margin;
      const leftMargin = CARD_DIMENSIONS.margin * 0.7;
      const rightMargin = CARD_DIMENSIONS.margin * 1.0; // Increased for better text readability

      // Draw card title area with school-colored background
      const schoolColor = getSchoolColor(spell.school);
      doc.setFillColor(schoolColor[0], schoolColor[1], schoolColor[2]);

      // Different header heights for first vs continuation cards
      const isFirstCard = cardNumber === 1;
      const cardHeaderHeight = isFirstCard ? headerHeight : (layout.fontSize + 2); // Reduced from +4 to +2
      doc.rect(x, y, CARD_DIMENSIONS.width, cardHeaderHeight, 'F');

      // Draw the header content
      const contentY = y + 3;

      // Draw title - moved up higher in the header with larger font
      doc.setFont("helvetica", "bold");
      doc.setFontSize(layout.fontSize * 1.1); // Increased from 1.0 to 1.1 for more prominence
      const titleY = contentY + 3; // Reduced from 4 to 3 to move text higher
      doc.text(spell.name, contentX, titleY);

      // Add source information for first card only in the header
      if (isFirstCard && spell.sources) {
        // Format sources to include page numbers: "PHB24:p39, XGE:p42"
        const sourceStr = Array.isArray(spell.sources) ?
          spell.sources.map(src => {
            if (typeof src === 'string') return src;
            // Replace PHB2024 with PHB24 and include page number
            const book = src.book === 'PHB2024' ? 'PHB24' : src.book;
            return src.page ? `${book}:p${src.page}` : book;
          }).join(', ') :
          (typeof spell.sources === 'string' ? spell.sources : '');

        if (sourceStr) {
          doc.setFont("helvetica", "italic");
          doc.setFontSize(layout.fontSize * 0.5); // Very small font for source
          const sourceWidth = doc.getStringUnitWidth(sourceStr) * (layout.fontSize * 0.5) / doc.internal.scaleFactor;
          // Position source at bottom right of the header
          doc.text(sourceStr, x + CARD_DIMENSIONS.width - sourceWidth - CARD_DIMENSIONS.margin, y + headerHeight - 1);
        }
      }

      // DON'T show pagination yet - we'll add it after rendering if needed
      // First render the content to see if pagination is actually needed

      let currentY = y;

      // First card gets level, school, and properties
      if (isFirstCard) {
        // Calculate the height of title
        const nameWidth = CARD_DIMENSIONS.width - (2 * CARD_DIMENSIONS.margin);
        const titleLines = doc.splitTextToSize(spell.name, nameWidth);
        const nameHeight = titleLines.length * (layout.fontSize * 1.1 * 0.4); // Updated multiplier to match new title font size

        // Draw level and school text
        doc.setFont("helvetica", "italic");
        doc.setFontSize(layout.fontSize - 3); // Reduced from -2 to -3
        const levelText = spell.level === 0 ?
          `${capitalizeWords(spell.school)} Cantrip` :
          `Level ${spell.level} ${capitalizeWords(spell.school)}`;
        const levelY = titleY + nameHeight * 0.65; // Adjusted from 0.7 to 0.65 to maintain proper spacing
        doc.text(levelText, contentX, levelY);

        // Start content after the header with more space to prevent touching the header
        currentY = y + headerHeight + 4; // Increased from +1 to +4 to add more space after header

        // Draw properties
        doc.setFont("helvetica", "normal");
        doc.setFontSize(layout.fontSize - 3); // Reduced from -2 to -3

        // Get components string and material component details
        const componentsStr = Object.entries(spell.components || {})
          .filter(([key, value]) => value && ['verbal', 'somatic', 'material'].includes(key))
          .map(([key]) => key[0].toUpperCase())
          .join(', ');

        const materialDetails = spell.components && spell.components.materials_needed &&
          spell.components.materials_needed.length > 0 ?
          `(${Array.isArray(spell.components.materials_needed) ?
            spell.components.materials_needed[0] :
            spell.components.materials_needed})` : '';

        // Property rendering - use smaller line spacing for small cards
        const smallFontSize = layout.fontSize - 3; // Reduced from -2 to -3
        const lineHeight = smallFontSize * 0.32; // Slightly reduced line height (from 0.35 to 0.32)
        const availablePropertiesWidth = CARD_DIMENSIONS.width - leftMargin - rightMargin;
        const labelSpacing = 1.2; // Reduced from 1.5 to 1.2

        // Casting Time
        currentY = renderPropertyWithWrapping(
          doc,
          "Casting Time:",
          spell.casting_time,
          contentX,
          currentY,
          doc.getStringUnitWidth("Casting Time:") * smallFontSize / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );

        // Range
        currentY = renderPropertyWithWrapping(
          doc,
          "Range:",
          convertIfNeeded(spell.range),
          contentX,
          currentY,
          doc.getStringUnitWidth("Range:") * smallFontSize / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );

        // Components
        currentY = renderPropertyWithWrapping(
          doc,
          "Components:",
          `${componentsStr} ${materialDetails}`,
          contentX,
          currentY,
          doc.getStringUnitWidth("Components:") * smallFontSize / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );

        // Duration
        currentY = renderPropertyWithWrapping(
          doc,
          "Duration:",
          spell.duration,
          contentX,
          currentY,
          doc.getStringUnitWidth("Duration:") * smallFontSize / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );

        // Ritual (if applicable)
        if (spell.ritual) {
          doc.setFont("helvetica", "bold");
          doc.setFontSize(layout.fontSize - 2);
          doc.text("Ritual", contentX, currentY);
          currentY += lineHeight;
        }

        // Add divider line - moved up by 1mm
        currentY -= 1; // Move line up a bit
        doc.setDrawColor(180, 180, 180);
        doc.setLineWidth(0.1);
        doc.line(contentX, currentY, x + CARD_DIMENSIONS.width - CARD_DIMENSIONS.margin, currentY);
        currentY += 3; // Increased from 2mm to 3mm for more space after the line
      } else {
        // For continuation cards, just a small header
        // Start content after header with more space to prevent text touching the header
        currentY = y + cardHeaderHeight + 4; // Increased from +1 to +4 to match first card spacing

        // Add "Continued" indicator
        doc.setFont("helvetica", "italic");
        doc.setFontSize(layout.fontSize - 3); // Reduced from -2 to -3
        doc.text("(Continued)", contentX, currentY);
        currentY += (layout.fontSize - 3) * 0.5; // Updated to match new font size
      }

      // Draw description text
      doc.setFont("helvetica", "normal");
      doc.setFontSize(layout.fontSize - 3); // Reduced from -2 to -3

      // Calculate available space for text
      const maxY = y + CARD_DIMENSIONS.height - CARD_DIMENSIONS.margin;
      const availableHeight = maxY - currentY;
      const descriptionLineHeight = (layout.fontSize - 3) * 0.32; // Updated line height to match properties
      const maxLines = Math.floor(availableHeight / descriptionLineHeight);
      const availableWidth = CARD_DIMENSIONS.width - leftMargin - rightMargin;

      // Render text and get updated word index
      const renderResult = wrapAndRenderText(
        doc,
        description,
        contentX,
        currentY,
        availableWidth,
        descriptionLineHeight,
        maxLines,
        currentWordIndex
      );

      // Update word index for next card
      currentWordIndex = renderResult.wordsRendered;

      // Now we can determine if we really need pagination
      // For first cards, check if there's more content
      if (isFirstCard) {
        if (renderResult.hasMore) {
          // We need multiple cards, so add pagination
          // Re-calculate the total number of cards based on actual rendering
          const actualTotalCards = Math.ceil(words.length / Math.max(1, renderResult.wordsRendered));
          const totalCardsToShow = Math.max(totalCards, actualTotalCards);

          if (totalCardsToShow > 1) {
            const pageInfo = `1/${totalCardsToShow}`;
            const pageInfoWidth = doc.getStringUnitWidth(pageInfo) * (layout.fontSize * 0.7) / doc.internal.scaleFactor;
            doc.setFont("helvetica", "bold");
            doc.setFontSize(layout.fontSize * 0.7);
            doc.text(pageInfo, x + CARD_DIMENSIONS.width - pageInfoWidth - CARD_DIMENSIONS.margin, y + 4); // Moved up from y+6 to y+4

            // Update the stored total cards value
            cardsPerSpell[spell.name] = { totalCards: totalCardsToShow };
          }
        }
        // For single cards, don't show pagination at all
      } else {
        // For continuation cards, always add pagination
        const pageInfo = `${cardNumber}/${totalCards}`;
        const pageInfoWidth = doc.getStringUnitWidth(pageInfo) * (layout.fontSize * 0.7) / doc.internal.scaleFactor;
        doc.setFont("helvetica", "bold");
        doc.setFontSize(layout.fontSize * 0.7);
        doc.text(pageInfo, x + CARD_DIMENSIONS.width - pageInfoWidth - CARD_DIMENSIONS.margin, y + 4); // Moved up from y+6 to y+4
      }

      // Add tiny footer with website
      doc.setFont("helvetica", "normal");
      doc.setFontSize(4); // Very small font for small cards (reduced from 5 to 4)
      doc.setTextColor(150, 150, 150); // Light gray text
      const footerText = "made with www.tablemancer.com";
      const footerWidth = doc.getStringUnitWidth(footerText) * 4 / doc.internal.scaleFactor; // Updated from 5 to 4
      const footerX = x + (CARD_DIMENSIONS.width - footerWidth) / 2; // Center the text
      const footerY = y + CARD_DIMENSIONS.height - 1; // 1mm from bottom (reduced from 1.5mm)
      doc.text(footerText, footerX, footerY);
      doc.setTextColor(0, 0, 0); // Reset text color to black

      // Draw card border
      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.rect(x, y, CARD_DIMENSIONS.width, CARD_DIMENSIONS.height, 'S');

      // Move to next card position
      cardPosition++;

      // If all text has been rendered but we were planning more cards,
      // stop rendering more cards for this spell
      if (!renderResult.hasMore) {
        break; // No need to render more cards
      }
    }
  });

  return doc;
}; 