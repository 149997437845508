import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Footer.css';
import { FacebookIcon, RedditIcon, BlueskyIcon, EmailIcon } from '../data/Icons';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>D&D Spell Manager</h4>
          <p>Manage your spells with ease</p>
        </div>
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/spells">Spells</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Connect With Us</h4>
          <div className="social-links">
            <a href="https://facebook.com/TableMancer" target="_blank" rel="noopener noreferrer" className="social-link">
              <FacebookIcon />
              <span>Facebook</span>
            </a>
            <a href="https://bsky.app/profile/tablemancer.bsky.social" target="_blank" rel="noopener noreferrer" className="social-link">
              <BlueskyIcon />
              <span>Bluesky</span>
            </a>
            <a href="https://www.reddit.com/r/TableMancer" target="_blank" rel="noopener noreferrer" className="social-link">
              <RedditIcon />
              <span>Reddit</span>
            </a>
            <Link to="/contact" className="social-link">
              <EmailIcon />
              <span>Contact Us</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 D&D Spell Manager. All rights reserved.</p>
        <p className="wizards-disclaimer">Dungeons & Dragons, D&D, and all related content and their logos are trademarks of Wizards of the Coast LLC in the United States and other countries. ©2024 Wizards of the Coast LLC. TableMancer is unofficial Fan Content permitted under the Fan Content Policy. Not approved/endorsed by Wizards of the Coast. Portions of the materials used are property of Wizards of the Coast. For more information about Wizards of the Coast or any of Wizards' trademarks or other intellectual property, please visit their website at <a href="https://www.wizards.com" target="_blank" rel="noopener noreferrer">www.wizards.com</a>.</p>
      </div>
    </footer>
  );
}

export default Footer;