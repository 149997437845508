import React, { useEffect, useRef, useState } from 'react';
import '../styles/components/SpellInfoBox.css';
import SpellContent from './SpellContent';
import { auth } from '../firebase/firebase';
import { getUserSpellNotes } from '../firebase/firestoreOperations';
import { useClickOutside } from '../hooks/useClickOutside';
import { CloseIcon, MetricIcon, ActiveMetricIcon } from '../data/Icons';

const SpellInfoBox = ({ spell, onClose, convertToMetric, onToggleMetric }) => {
  const [notes, setNotes] = useState('');
  const boxRef = useRef(null);
  const modalRef = useRef();

  useClickOutside(modalRef, onClose);

  useEffect(() => {
    const loadNotes = async () => {
      if (auth.currentUser && spell) {
        const userNotes = await getUserSpellNotes(auth.currentUser.uid, spell.id);
        setNotes(userNotes);
      }
    };
    loadNotes();
  }, [spell]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const getLevelDisplay = () => {
    if (!spell) return '';
    if (spell.level === 'cantrip') {
      const capitalizedSchool = spell.school.charAt(0).toUpperCase() + spell.school.slice(1);
      return `Cantrip, ${capitalizedSchool}`;
    }
    return `Level ${spell.level}, ${spell.school}`;
  };

  return (
    <div className="spellinfo-overlay">
      <div className="spell-info-box" ref={modalRef}>
        <div className={`spell-info-header ${spell?.isCustom ? 'custom-spell-header' : ''}`}>
          <h2>{spell?.name}</h2>
          <p>{getLevelDisplay()}</p>
          <button 
            type="button"
            className="close-button"
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="spell-info-content">
          <SpellContent 
            spell={spell} 
            convertToMetric={convertToMetric} 
            hideHeader={true}
            metricToggleButton={
              <button 
                className={`metric-toggle-button ${convertToMetric ? 'active' : ''}`}
                onClick={onToggleMetric}
                title={`${convertToMetric ? 'Disable' : 'Enable'} Metric Units`}
              >
                {convertToMetric ? <ActiveMetricIcon /> : <MetricIcon />}
              </button>
            }
          />
          
          {notes && (
            <div className="spell-info-box__notes">
              <h3>Personal Notes</h3>
              <div className="spell-info-box__notes-content">
                {notes}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpellInfoBox;
