import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../AuthContext';
import { getSpellRatings, getUserSpellRating, addSpellRating, removeSpellRating } from '../firebase/firestoreOperations';
import { useFeatureFlags } from '../FeatureFlagsContext';
import '../styles/components/SpellRating.css';
import d20Icon from '../assets/dice/d20.svg';

const D20Icon = ({ filled, onClick, size = 24, className = '' }) => {
  // For half-filled, we need to render two images side by side
  if (className === 'half-filled') {
    return (
      <div
        className={`d20-icon ${className}`}
        onClick={onClick}
        style={{ width: size, height: size, position: 'relative' }}
      >
        {/* Left half (glowing) */}
        <div className="d20-left-half">
          <img src={d20Icon} alt="d20" width={size} height={size} className="glowing" />
        </div>
        {/* Right half (non-glowing) */}
        <div className="d20-right-half">
          <img src={d20Icon} alt="d20" width={size} height={size} />
        </div>
      </div>
    );
  }

  // Regular filled or unfilled icon
  return (
    <div
      className={`d20-icon ${filled ? 'filled' : ''} ${className}`}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <img src={d20Icon} alt="d20" width={size} height={size} />
    </div>
  );
};

// Component to display read-only dice for community rating
const CommunityRating = ({ rating, size = 24, onClick }) => {
  // Convert rating to nearest half-die
  const fullDice = Math.floor(rating);
  const hasHalfDie = rating % 1 >= 0.5;

  return (
    <div className="community-dice" onClick={onClick}>
      {[1, 2, 3, 4, 5].map((die) => {
        // Determine fill state: 0 = empty, 1 = half, 2 = full
        let fillState = 0;
        if (die <= fullDice) {
          fillState = 2; // full
        } else if (die === fullDice + 1 && hasHalfDie) {
          fillState = 1; // half
        }

        return (
          <div key={die} className="community-die-container" style={{ width: size, height: size }}>
            <D20Icon
              filled={fillState > 0}
              size={size}
              className={fillState === 1 ? 'half-filled' : ''}
            />
          </div>
        );
      })}
    </div>
  );
};

const RatingDropdown = ({ isOpen, onClose, userRating, onRatingChange, onRatingRemove, count }) => {
  if (!isOpen) return null;

  return (
    <div className="rating-dropdown">
      <div className="rating-dropdown-header">
        <h3>Rate This Spell</h3>
      </div>
      <div className="rating-dropdown-dice">
        {[1, 2, 3, 4, 5].map((rating) => (
          <D20Icon
            key={rating}
            filled={rating <= userRating}
            onClick={() => {
              // If clicking on the first die and user already has this rating, remove it
              if (rating === 1 && userRating === 1) {
                onRatingRemove();
              } else {
                onRatingChange(rating);
              }
            }}
            size={28}
          />
        ))}
      </div>
      <div className="rating-dropdown-info">
        <span className="rating-count">Based on {count} {count === 1 ? 'rating' : 'ratings'}</span>
        {userRating > 0 && (
          <div className="user-rating-info">
            Your rating: {userRating} <span className="remove-rating" onClick={onRatingRemove}>(Remove)</span>
          </div>
        )}
      </div>
    </div>
  );
};

const SpellRating = ({ spellId }) => {
  const { currentUser } = useAuth();
  const { enableRatings } = useFeatureFlags();
  const [averageRating, setAverageRating] = useState(0);
  const [userRating, setUserRating] = useState(0);
  const [count, setCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const headerRef = useRef(null);
  const [shouldRender, setShouldRender] = useState(true);

  // Load ratings
  useEffect(() => {
    // Skip loading if ratings are disabled
    if (!enableRatings) {
      setShouldRender(false);
      return;
    }

    setShouldRender(true);
    const loadRatings = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Get all ratings for the spell
        const ratingsData = await getSpellRatings(spellId);
        setAverageRating(ratingsData.average || 0);
        setCount(ratingsData.count || 0);
        
        // If user is logged in, get their rating
        if (currentUser) {
          const userRatingData = await getUserSpellRating(currentUser.uid, spellId);
          setUserRating(userRatingData || 0);
        }
      } catch (error) {
        console.error('Error loading ratings:', error);
        setError('Failed to load ratings');
      } finally {
        setLoading(false);
      }
    };

    loadRatings();
  }, [spellId, currentUser, enableRatings]);

  // Handle click outside
  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        headerRef.current &&
        !headerRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // If ratings are disabled, don't render anything
  if (!shouldRender) {
    return null;
  }

  const handleRatingClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleRatingChange = async (rating) => {
    if (!currentUser) {
      alert('Please log in to rate spells');
      setIsDropdownOpen(false);
      return;
    }

    try {
      setError(null);
      // Optimistically update UI
      setUserRating(rating);

      // Update in database
      const success = await addSpellRating(currentUser.uid, spellId, rating);

      if (success) {
        // Refresh ratings
        const updatedRatings = await getSpellRatings(spellId);
        setAverageRating(updatedRatings.average || 0);
        setCount(updatedRatings.count || 0);
      } else {
        // Revert on failure
        const userRatingValue = await getUserSpellRating(currentUser.uid, spellId);
        setUserRating(userRatingValue || 0);
      }
    } catch (error) {
      console.error('Error updating rating:', error);
      setError('Unable to save your rating');
    }
  };

  const handleRatingRemove = async () => {
    if (!currentUser) {
      return;
    }

    try {
      setError(null);
      // Optimistically update UI
      setUserRating(0);

      // Remove from database
      const success = await removeSpellRating(currentUser.uid, spellId);

      if (success) {
        // Refresh ratings
        const updatedRatings = await getSpellRatings(spellId);
        setAverageRating(updatedRatings.average || 0);
        setCount(updatedRatings.count || 0);
      } else {
        // Revert on failure
        const userRatingValue = await getUserSpellRating(currentUser.uid, spellId);
        setUserRating(userRatingValue || 0);
      }
    } catch (error) {
      console.error('Error removing rating:', error);
      setError('Unable to remove your rating');
    }
  };

  if (loading) {
    return <div className="spell-rating-header loading">Loading...</div>;
  }

  // Format the average rating to show one decimal place if it's not a whole number
  const formattedRating = averageRating % 1 === 0
    ? averageRating.toString()
    : averageRating.toFixed(1);

  return (
    <div className="rating-container">
      <div
        ref={headerRef}
        className="spell-rating-header"
        onClick={handleRatingClick}
      >
        <CommunityRating rating={averageRating} size={22} />
        <div className="rating-text">
          <span className="rating-value">{formattedRating}</span>
          <span className="rating-count">({count})</span>
        </div>
      </div>

      <div ref={dropdownRef} className="dropdown-container">
        <RatingDropdown
          isOpen={isDropdownOpen}
          onClose={() => setIsDropdownOpen(false)}
          userRating={userRating}
          onRatingChange={handleRatingChange}
          onRatingRemove={handleRatingRemove}
          count={count}
        />
      </div>

      {error && <div className="rating-error">{error}</div>}
    </div>
  );
};

export default SpellRating; 