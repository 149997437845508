import React from 'react';
import '../styles/components/SpellCounter.css';

const SpellCounter = ({ count, totalCount }) => {
  return (
    <div className="spell-counter">
      <span className="spell-count">{count}</span>
      <span className="spell-count-separator">/</span>
      <span className="spell-count total-count">{totalCount}</span>
      <span className="desktop-only"> Spells</span>
    </div>
  );
};

export default SpellCounter;