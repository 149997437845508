import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/components/TabBar.css';

function TabBar() {
  return (
    <nav className="tab-navigation">
      <NavLink to="/spells" className="tab">
        <span>SPELL LIST</span>
      </NavLink>
      <NavLink to="/spellbooks" className="tab">
        <span>SPELLBOOKS</span>
      </NavLink>
      <NavLink to="/prepared-spells" className="tab">
        <span>PREPARED SPELLS</span>
      </NavLink>
    </nav>
  );
}

export default TabBar;
