import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import useSpellFilters from '../hooks/useSpellFilters';
import useSortSpells from '../hooks/useSortSpells';
import useSearchSpells from '../hooks/useSearchSpells';
import useCheckboxSelection from '../hooks/useCheckboxSelection';
import useUnitConversion from '../hooks/useUnitConversion';
import { AllSpellsIcon, BackToTopIcon, NewInfoIcon, CastSpellIcon } from '../data/Icons';
import '../styles/components/PreparedSpells.css';
import SpellInfoBox from './SpellInfoBox';
import { NotificationContext } from '../App';
import { useAuth } from '../AuthContext';
import {
  getUserPresets,
  updateUserPresets,
  updateUserPreparedSpells,
  getUserSettings,
  updateUserSettings,
  subscribeToCustomSpells,
  refreshCustomSpells,
  getUserData
} from '../firebase/firestoreOperations';
import PreparedSpellsPresets from './PreparedSpellsPresets';
import spellsData from '../data/spells.json';
import SpellResourceManager from './SpellResourceManager';
import ConfirmationPopup from './ConfirmationPopup';
import { debounce } from 'lodash';
import SpellTable from './SpellTable';
import HowToUseBox from './HowToUseBox';
import SpellFilters from './SpellFilters';
import SpellCardGenerator from './SpellCardGenerator';
import SpellTableControls from './SpellTableControls';
import CustomSpellForm from './CustomSpellForm';
import ActiveSpellbookHeader from './ActiveSpellbookHeader';
import AdvancedFilterTags from './AdvancedFilterTags';

const SimpleConfirmationPopup = ({ isOpen, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;
  
  return (
    <div className="simple-confirmation-overlay" onClick={onCancel}>
      <div className="simple-confirmation-popup" onClick={(e) => e.stopPropagation()}>
        <div className="simple-confirmation-header">
          <h2>Cast Spell</h2>
          <button 
            className="simple-close-button"
            onClick={onCancel}
            aria-label="Close"
          >
            <svg viewBox="0 0 24 24" width="24" height="24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
        </div>
        <div className="simple-confirmation-content">
          <p>{message}</p>
        </div>
        <div className="simple-confirmation-actions">
          <button className="simple-confirm-button" onClick={onConfirm}>Cast</button>
          <button className="simple-cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

function PreparedSpells({
  filters,
  setFilters,
  selectedSpells,
  setSelectedSpells,
  searchTerm,
  setSearchTerm,
  convertToMetric,
  setConvertToMetric,
  spellbooks,
  showRitualSpells,
  setShowRitualSpells,
  showShortDescriptions,
  setShowShortDescriptions,
  showSchoolTags,
  setShowSchoolTags,
  spellSlots,
  setSpellSlots,
  currentSpellbook,
  setCurrentSpellbook,
  preparedSpellsByBook,
  updatePreparedSpells,
  spellSlotsByBook,
  updateSpellSlots,
  sortConfig,
  setSortConfig,
  pagination,
  setPagination,
  showSpellSlotsEverywhere,
  setShowSpellSlotsEverywhere,
  spellPointsByBook,
  updateSpellPoints,
  characterLevel,
  setCharacterLevel,
  highLevelSpellUses = {},
  setHighLevelSpellUses,
  allowMultipleHighLevelCasts = false,
  setAllowMultipleHighLevelCasts,
  useSpellPoints,
  setUseSpellPoints
}) {
  const { addNotification } = useContext(NotificationContext);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedSpell, setSelectedSpell] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const { currentUser } = useAuth();
  const [presets, setPresets] = useState({});
  const [currentPreset, setCurrentPreset] = useState(null);
  const [confirmationPopup, setConfirmationPopup] = useState({ isOpen: false, message: '', onConfirm: null });
  const [visibleColumns, setVisibleColumns] = useState(10);
  const [showCardGenerator, setShowCardGenerator] = useState(false);
  const [showCustomSpellForm, setShowCustomSpellForm] = useState(false);
  const [resetColumnsFunc, setResetColumnsFunc] = useState(() => () => { });
  const [customSpells, setCustomSpells] = useState({});
  const [simpleConfirmationPopup, setSimpleConfirmationPopup] = useState({ 
    isOpen: false, 
    message: '', 
    onConfirm: null, 
    onCancel: null 
  });

  const updateVisibleColumns = useCallback(debounce(() => {
    const width = window.innerWidth;
    let count = 10;

    if (width <= 600) count = 3;
    else if (width <= 700) count = 4;
    else if (width <= 800) count = 5;
    else if (width <= 900) count = 6;
    else if (width <= 1000) count = 7;
    else if (width <= 1100) count = 8;
    else if (width <= 1200) count = 9;

    setVisibleColumns(count);
  }, 200), []);

  useEffect(() => {
    updateVisibleColumns();
    window.addEventListener('resize', updateVisibleColumns);
    return () => {
      window.removeEventListener('resize', updateVisibleColumns);
      updateVisibleColumns.cancel();
    };
  }, [updateVisibleColumns]);

  const allSpells = useMemo(() => {
    if (!spellbooks[currentSpellbook]) return [];

    // Create a Map to store unique spells
    const uniqueSpells = new Map();

    // Get prepared spells for current spellbook
    const currentBookPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];

    // Get spells from the current spellbook
    const spellbookSpells = Array.isArray(spellbooks[currentSpellbook])
      ? spellbooks[currentSpellbook].map(spellId => {
        // Check if it's a built-in spell
        const builtInSpell = spellsData.find(s => s.id === spellId);
        if (builtInSpell) return builtInSpell;

        // If not built-in, check if it's a custom spell
        if (spellId.startsWith('custom-')) {
          const customSpell = Object.values(customSpells).find(s => s.id === spellId);
          if (customSpell) return { ...customSpell, isCustom: true };
        }
        return null;
      }).filter(Boolean)
      : [];

    // Add all spellbook spells to the Map
    spellbookSpells.forEach(spell => {
      uniqueSpells.set(spell.name, spell);
    });

    // Add any prepared spells that might not be in the spellbook
    if (Array.isArray(currentBookPreparedSpells)) {
      currentBookPreparedSpells.forEach(spell => {
        uniqueSpells.set(spell.name, spell);
      });
    }

    return Array.from(uniqueSpells.values());
  }, [spellbooks, currentSpellbook, preparedSpellsByBook, customSpells]);

  const {
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters
  } = useSpellFilters(allSpells, filters, setFilters);

  const { sortedSpells, requestSort } = useSortSpells(filteredSpells, sortConfig, setSortConfig);
  const searchedSpells = useSearchSpells(sortedSpells, searchTerm);
  const { toggleItem, selectAll, areAllSelected, clearSelection } = useCheckboxSelection(searchedSpells, selectedSpells, setSelectedSpells);
  const { toggleConversion, convertIfNeeded } = useUnitConversion(convertToMetric, setConvertToMetric);

  const spellsToDisplay = useMemo(() => {
    const currentBookPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    const preparedSpellsSet = new Set(
      Array.isArray(currentBookPreparedSpells)
        ? currentBookPreparedSpells.map(spell => spell.name)
        : []
    );

    // Filter the sorted and searched spells for prepared spells
    const preparedSpells = searchedSpells.filter(spell =>
      preparedSpellsSet.has(spell.name)
    ).map(spell => ({
      ...spell,
      isPrepared: true
    }));

    if (showRitualSpells) {
      // Get ritual spells that aren't prepared from the sorted and searched spells
      // Include both regular and custom ritual spells
      const ritualSpells = searchedSpells.filter(spell =>
        spell.ritual && !preparedSpellsSet.has(spell.name)
      ).map(spell => ({
        ...spell,
        isPrepared: false,
        isCustom: spell.isCustom || false // Preserve the isCustom flag
      }));

      if (ritualSpells.length > 0) {
        return [
          ...preparedSpells,
          { id: 'separator', isSeparator: true },
          ...ritualSpells
        ];
      }
    }

    return preparedSpells;
  }, [showRitualSpells, preparedSpellsByBook, currentSpellbook, searchedSpells]);

  const renderIcons = (spell) => {
    if (spell.isSeparator) return null;
    const isPrepared = isSpellPrepared(spell.name);

    return (
      <div className="spell-icons" data-spell-name={spell.name}>
        {spell.level > 0 && isPrepared && (
          <span
            className="cast-spell-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleCastSpell(spell);
            }}
            title="Cast Spell"
          >
            <CastSpellIcon />
          </span>
        )}
        <span
          className="info-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleSpellInfoClick(spell);
          }}
          title="Spell Info"
        >
          <NewInfoIcon />
        </span>
        <span
          className={`prepare-spell-icon ${isPrepared ? 'prepared' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            isPrepared ? handleUnprepareSpell(spell.name) : handlePrepareSpell(spell);
          }}
          title={isPrepared ? "Unprepare Spell" : "Prepare Spell"}
        >
          <AllSpellsIcon />
        </span>
      </div>
    );
  };

  const handleUnprepareSelectedSpells = () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const spellsToUnprepare = spellsToDisplay
      .filter(spell => !spell.isSeparator && selectedSpellsList.includes(spell.name) && spell.isPrepared);

    if (spellsToUnprepare.length > 0) {
      const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
      const newPreparedSpells = currentPreparedSpells.filter(spell =>
        !selectedSpellsList.includes(spell.name)
      );

      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      clearSelection();
      addNotification(`${spellsToUnprepare.length} spell${spellsToUnprepare.length > 1 ? 's' : ''} unprepared`, 'info');
    }
  };

  const handlePrepareSelectedSpells = () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const spellsToPrepare = spellsToDisplay
      .filter(spell => !spell.isSeparator && selectedSpellsList.includes(spell.name) && !spell.isPrepared);

    if (spellsToPrepare.length > 0) {
      // Ensure we have the current prepared spells array
      const currentPreparedSpells = Array.isArray(preparedSpellsByBook[currentSpellbook])
        ? preparedSpellsByBook[currentSpellbook]
        : [];

      const newPreparedSpells = [...currentPreparedSpells, ...spellsToPrepare];

      // Update local state first
      updatePreparedSpells(currentSpellbook, newPreparedSpells);

      // Then update remote storage
      if (currentUser) {
        try {
          updateUserPreparedSpells(currentUser.uid, newPreparedSpells)
            .catch(error => {
              console.error('Error updating prepared spells:', error);
              addNotification('Failed to save prepared spells', 'error');
            });
        } catch (error) {
          console.error('Error updating prepared spells:', error);
          addNotification('Failed to save prepared spells', 'error');
        }
      } else {
        try {
          localStorage.setItem('guestPreparedSpells',
            JSON.stringify(newPreparedSpells.map(spell => spell.id))
          );
        } catch (error) {
          console.error('Error saving to localStorage:', error);
          addNotification('Failed to save prepared spells', 'error');
        }
      }

      clearSelection();
      addNotification(`${spellsToPrepare.length} spell${spellsToPrepare.length > 1 ? 's' : ''} prepared`, 'success');
    }
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowBackToTop(scrollTop > 300);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    localStorage.setItem('showShortDescriptions', JSON.stringify(showShortDescriptions));
    setExpandedRows({}); // Reset expanded rows when showShortDescriptions changes
  }, [showShortDescriptions]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleSpellInfoClick = (spell) => {
    setSelectedSpell(spell);
  };

  // Add this function:
  const handleConvertToMetricChange = (event) => {
    setConvertToMetric(event.target.checked);
  };

  useEffect(() => {
    if (currentUser) {
      const fetchUserPresets = async () => {
        const userPresets = await getUserPresets(currentUser.uid);
        setPresets(userPresets);
      };
      fetchUserPresets();
    } else {
      // Load presets from localStorage for non-logged-in users
      const guestPresets = JSON.parse(localStorage.getItem('guestPresets')) || {};
      setPresets(guestPresets);
    }
  }, [currentUser]);

  const savePreset = async (presetName) => {
    try {
      // Get the current prepared spells for this spellbook
      const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
      
      // Validate that we have prepared spells to save
      if (!currentPreparedSpells.length) {
        addNotification('Cannot save an empty preset. Please prepare some spells first.', 'error');
        return;
      }
      
      // Extract just the IDs from the prepared spells to store
      const preparedSpellIds = currentPreparedSpells.map(spell => spell.id).filter(id => id);
      
      // Save current prepared spells as a preset
      const currentPresets = presets[currentSpellbook] || {};
      const updatedPresets = {
        ...currentPresets,
        [presetName]: preparedSpellIds
      };
      
      await updateUserPresets(currentUser.uid, currentSpellbook, updatedPresets);
      
      setPresets(prev => ({
        ...prev,
        [currentSpellbook]: updatedPresets
      }));
      
      setCurrentPreset(presetName);
      addNotification(`Preset "${presetName}" saved successfully with ${preparedSpellIds.length} spells`, 'success');
    } catch (error) {
      // Show a user-friendly notification instead of crashing
      addNotification(error.message, 'error');
      console.error('Error saving preset:', error);
    }
  };

  const loadPreset = useCallback((spellbookName, presetName) => {
    if (presets[spellbookName]?.[presetName]) {
      const spellObjects = presets[spellbookName][presetName]
        .map(id => {
          // First check if it's a built-in spell
          const builtInSpell = spellsData.find(spell => spell.id === id);
          if (builtInSpell) return builtInSpell;
          
          // Check if id is valid (not null, undefined, and is a string) before using startsWith
          if (id && typeof id === 'string' && id.startsWith('custom-')) {
            // Try to find it in customSpells
            const customSpell = Object.values(customSpells).find(spell => spell.id === id);
            if (customSpell) return { ...customSpell, isCustom: true };
          }
          
          return null;
        })
        .filter(Boolean);
      
      updatePreparedSpells(currentSpellbook, spellObjects);
      setCurrentPreset(presetName);
      addNotification(`Preset "${presetName}" loaded from ${spellbookName}`, 'info');
    }
  }, [presets, currentSpellbook, updatePreparedSpells, addNotification, customSpells]);

  const deletePreset = useCallback((spellbookName, presetName) => {
    const newPresets = {
      ...presets,
      [spellbookName]: { ...(presets[spellbookName] || {}) }
    };
    delete newPresets[spellbookName][presetName];
    setPresets(newPresets);
    if (currentUser) {
      updateUserPresets(currentUser.uid, spellbookName, newPresets[spellbookName]);
    } else {
      localStorage.setItem('guestPresets', JSON.stringify(newPresets));
    }
    addNotification(`Preset "${presetName}" deleted from ${spellbookName}`, 'info');
  }, [presets, currentUser, addNotification]);

  const findNextAvailableSlot = (startLevel) => {
    for (let i = startLevel; i < currentSpellSlots.length; i++) {
      if (currentSpellSlots[i] && currentSpellSlots[i].used < currentSpellSlots[i].max) {
        return i;
      }
    }
    return -1;
  };

  const handleCastSpell = (spell) => {
    // Get the current settings from local storage or default to spell slots
    const useSpellPoints = localStorage.getItem('useSpellPoints') === 'true';

    if (useSpellPoints) {
      // Handle spell points casting
      const spellLevel = parseInt(spell.level);
      const SPELL_POINT_COSTS = {
        1: 2,
        2: 3,
        3: 5,
        4: 6,
        5: 7,
        6: 9,
        7: 10,
        8: 11,
        9: 13
      };
      const cost = SPELL_POINT_COSTS[spellLevel];

      // Check if it's a high-level spell (6th or higher)
      if (spellLevel >= 6) {
        // Get current high-level uses from spellPoints object
        const currentHighLevelUses = spellPointsByBook[currentSpellbook]?.highLevelUses || {};

        // Check if we can cast this high-level spell
        if (!allowMultipleHighLevelCasts && (currentHighLevelUses[spellLevel] || 0) >= 1) {
          addNotification(`You've already cast a ${spellLevel}th level spell today.`, 'error');
          return;
        }
      }

      // Check if enough points are available
      if (spellPointsByBook[currentSpellbook].total - spellPointsByBook[currentSpellbook].used >= cost) {
        // Find and animate the icon
        const iconContainer = document.querySelector(`[data-spell-name="${spell.name}"] .cast-spell-icon`);
        if (iconContainer) {
          iconContainer.classList.add('casting');
          setTimeout(() => iconContainer.classList.remove('casting'), 800);
        }

        // Prepare the updated spell points object
        const updatedSpellPoints = {
          ...spellPointsByBook[currentSpellbook],
          used: spellPointsByBook[currentSpellbook].used + cost
        };

        // Update high level spell uses for 6th level and higher
        if (spellLevel >= 6) {
          const currentHighLevelUses = spellPointsByBook[currentSpellbook]?.highLevelUses || {};
          updatedSpellPoints.highLevelUses = {
            ...currentHighLevelUses,
            [spellLevel]: (currentHighLevelUses[spellLevel] || 0) + 1
          };
        }

        // Update spell points with all changes
        updateSpellPoints(currentSpellbook, updatedSpellPoints);

        // Show success notification
        addNotification(`You cast ${spell.name} using ${cost} spell points.`, 'success');
      } else {
        // Show error if not enough points
        addNotification(`Not enough spell points to cast ${spell.name}.`, 'error');
      }
    } else {
      // Original spell slot casting logic
      const spellLevel = parseInt(spell.level) - 1;
      if (currentSpellSlots[spellLevel] && currentSpellSlots[spellLevel].used < currentSpellSlots[spellLevel].max) {
        // Find and animate the icon
        const iconContainer = document.querySelector(`[data-spell-name="${spell.name}"] .cast-spell-icon`);
        if (iconContainer) {
          iconContainer.classList.add('casting');
          setTimeout(() => iconContainer.classList.remove('casting'), 800);
        }

        const newSlots = [...currentSpellSlots];
        newSlots[spellLevel] = {
          ...newSlots[spellLevel],
          used: newSlots[spellLevel].used + 1
        };
        updateSpellSlots(currentSpellbook, newSlots);
        addNotification(`${spell.name} cast successfully!`, 'success');
      } else {
        // Try to find a higher level slot
        const nextAvailableSlotIndex = findNextAvailableSlot(spellLevel);
        if (nextAvailableSlotIndex !== -1) {
          // Show the custom confirmation popup
          console.log(`Found higher level slot: ${nextAvailableSlotIndex + 1} for spell: ${spell.name}`);
          
          // Use our new simple confirmation popup
          setSimpleConfirmationPopup({
            isOpen: true,
            message: `No more level ${spellLevel + 1} spell slots available. Do you want to cast ${spell.name} using a level ${nextAvailableSlotIndex + 1} spell slot instead?`,
            onConfirm: () => {
              const iconContainer = document.querySelector(`[data-spell-name="${spell.name}"] .cast-spell-icon`);
              if (iconContainer) {
                iconContainer.classList.add('casting');
                setTimeout(() => iconContainer.classList.remove('casting'), 800);
              }

              const newSlots = [...currentSpellSlots];
              newSlots[nextAvailableSlotIndex] = {
                ...newSlots[nextAvailableSlotIndex],
                used: newSlots[nextAvailableSlotIndex].used + 1
              };
              updateSpellSlots(currentSpellbook, newSlots);
              addNotification(`${spell.name} cast successfully at level ${nextAvailableSlotIndex + 1}!`, 'success');
              setSimpleConfirmationPopup({ isOpen: false, message: '', onConfirm: null, onCancel: null });
            },
            onCancel: () => {
              setSimpleConfirmationPopup({ isOpen: false, message: '', onConfirm: null, onCancel: null });
            }
          });
        } else {
          addNotification(`No available spell slots to cast ${spell.name}.`, 'error');
        }
      }
    }
  };

  const isAnySpellSelected = useMemo(() => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    if (selectedSpellsList.length === 0) return false;

    const hasPreparableSpells = spellsToDisplay.some(spell =>
      !spell.isSeparator &&
      selectedSpells[spell.name] &&
      !spell.isPrepared
    );

    const hasUnpreparableSpells = spellsToDisplay.some(spell =>
      !spell.isSeparator &&
      selectedSpells[spell.name] &&
      spell.isPrepared
    );

    return { hasPreparableSpells, hasUnpreparableSpells };
  }, [selectedSpells, spellsToDisplay]);

  const handleUnprepareSpell = (spellName) => {
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    const newPreparedSpells = currentPreparedSpells.filter(spell => spell.name !== spellName);
    updatePreparedSpells(currentSpellbook, newPreparedSpells);
    addNotification(`${spellName} has been unprepared from ${currentSpellbook}`, 'info');
  };

  const isSpellPrepared = useCallback((spellName) => {
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    return Array.isArray(currentPreparedSpells) &&
      currentPreparedSpells.some(s => s.name === spellName);
  }, [preparedSpellsByBook, currentSpellbook]);

  const handlePrepareSpell = (spell) => {
    // Ensure currentSpellbook exists in preparedSpellsByBook
    const currentPreparedSpells = Array.isArray(preparedSpellsByBook[currentSpellbook])
      ? preparedSpellsByBook[currentSpellbook]
      : [];

    if (isSpellPrepared(spell.name)) {
      // Unprepare the spell
      const newPreparedSpells = currentPreparedSpells.filter(s => s.name !== spell.name);
      updatePreparedSpells(currentSpellbook, newPreparedSpells);

      // Update remote storage for unpreparing
      if (currentUser) {
        updateUserPreparedSpells(currentUser.uid, currentSpellbook, newPreparedSpells)
          .catch(error => {
            console.error('Error updating prepared spells:', error);
            addNotification('Failed to save changes', 'error');
          });
      } else {
        localStorage.setItem('guestPreparedSpells', JSON.stringify(newPreparedSpells.map(s => s.id)));
      }

      addNotification(`${spell.name} has been unprepared from ${currentSpellbook}`, 'info');
    } else {
      // Prepare the spell
      const spellToPrepare = {
        ...spell,
        isPrepared: true
      };

      const newPreparedSpells = [...currentPreparedSpells, spellToPrepare];

      // Update local state first
      updatePreparedSpells(currentSpellbook, newPreparedSpells);

      // Then update remote storage
      if (currentUser) {
        updateUserPreparedSpells(currentUser.uid, currentSpellbook, newPreparedSpells)
          .catch(error => {
            console.error('Error updating prepared spells:', error);
            addNotification('Failed to save changes', 'error');
          });
      } else {
        try {
          localStorage.setItem('guestPreparedSpells', JSON.stringify(newPreparedSpells.map(s => s.id)));
        } catch (error) {
          console.error('Error saving to localStorage:', error);
          addNotification('Failed to save changes', 'error');
          return; // Exit early if save fails
        }
      }

      addNotification(`${spell.name} has been prepared in ${currentSpellbook}`, 'success');
    }
  };

  // Get the current spellbook's spell slots or create default ones
  const currentSpellSlots = useMemo(() => {
    console.log('Getting current spell slots for spellbook:', currentSpellbook);
    console.log('Current spellSlotsByBook state:', spellSlotsByBook);
    
    if (!spellSlotsByBook || typeof spellSlotsByBook !== 'object') {
      console.warn('spellSlotsByBook is not a valid object:', spellSlotsByBook);
      return Array(9).fill().map(() => ({ max: 0, used: 0 }));
    }
    
    const slots = spellSlotsByBook[currentSpellbook];
    
    if (!slots) {
      console.log('No slots found for current spellbook, using defaults');
      return Array(9).fill().map(() => ({ max: 0, used: 0 }));
    }
    
    if (!Array.isArray(slots)) {
      console.warn('Slots for current spellbook are not an array:', slots);
      return Array(9).fill().map(() => ({ max: 0, used: 0 }));
    }
    
    // Ensure we have exactly 9 slots with the correct format
    const validatedSlots = Array(9).fill().map((_, i) => {
      if (i < slots.length && slots[i] && typeof slots[i] === 'object') {
        return {
          max: Number(slots[i].max) || 0,
          used: Number(slots[i].used) || 0
        };
      }
      return { max: 0, used: 0 };
    });
    
    console.log('Validated slots for current spellbook:', validatedSlots);
    return validatedSlots;
  }, [spellSlotsByBook, currentSpellbook]);

  // Add this useEffect to load the ritual spells setting
  useEffect(() => {
    const loadRitualSpellsSetting = async () => {
      if (currentUser) {
        const settings = await getUserSettings(currentUser.uid);
        setShowRitualSpells(settings.showRitualSpells ?? true); // Default to true if not set
      } else {
        // For non-logged in users, use localStorage
        const savedSetting = localStorage.getItem('showRitualSpells');
        setShowRitualSpells(savedSetting === null ? true : savedSetting === 'true');
      }
    };

    loadRitualSpellsSetting();
  }, [currentUser, setShowRitualSpells]);

  // Update this function to not depend on an event object
  const handleRitualSpellsChange = async () => {
    const newValue = !showRitualSpells;
    setShowRitualSpells(newValue);

    if (currentUser) {
      // Update Firebase
      await updateUserSettings(currentUser.uid, {
        showRitualSpells: newValue
      });
    } else {
      // Update localStorage for non-logged in users
      localStorage.setItem('showRitualSpells', newValue);
    }
  };

  const handleToggleSchoolTags = async () => {
    const newValue = !showSchoolTags;
    setShowSchoolTags(newValue);

    if (currentUser) {
      await updateUserSettings(currentUser.uid, {
        showSchoolTags: newValue
      });
    } else {
      localStorage.setItem('showSchoolTags', JSON.stringify(newValue));
    }
  };

  const getTotalSpellCount = () => {
    const currentBookPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    const preparedCount = Array.isArray(currentBookPreparedSpells)
      ? currentBookPreparedSpells.length
      : 0;

    if (showRitualSpells) {
      // Include both prepared spells and ritual spells in total
      const ritualSpells = allSpells.filter(spell =>
        spell.ritual && !currentBookPreparedSpells.some(ps => ps.name === spell.name)
      );
      return preparedCount + ritualSpells.length;
    }

    // Only count prepared spells if ritual spells are not shown
    return preparedCount;
  };

  const getSelectedSpells = () => {
    const selectedSpellNames = Object.keys(selectedSpells).filter(name => selectedSpells[name]);
    return spellsToDisplay
      .filter(spell => !spell.isSeparator && selectedSpellNames.includes(spell.name))
      .map(spell => ({
        ...spell,
        // Remove the isPrepared property as it's not needed for the card generator
        isPrepared: undefined
      }));
  };

  const handleResetColumns = useCallback((resetFunc) => {
    setResetColumnsFunc(() => resetFunc);
  }, []);

  const handleCustomSpellAdded = (newSpell) => {
    setCustomSpells(prev => ({
      ...prev,
      [newSpell.id]: newSpell
    }));
    addNotification(`Custom spell "${newSpell.name}" created successfully`, 'success');
  };

  // Add useEffect to fetch custom spells
  useEffect(() => {
    let isMounted = true;

    const fetchCustomSpells = async () => {
      if (currentUser && isMounted) {
        try {
          const newCustomSpells = await refreshCustomSpells(currentUser.uid);

          // Only update if the custom spells have actually changed
          // This prevents unnecessary re-renders
          const currentIds = Object.keys(customSpells).sort().join(',');
          const newIds = Object.keys(newCustomSpells).sort().join(',');

          if (currentIds !== newIds) {
            setCustomSpells(newCustomSpells);
          } else {
            // Check if any spell properties have changed
            let hasChanges = false;
            Object.keys(newCustomSpells).some(id => {
              if (JSON.stringify(newCustomSpells[id]) !== JSON.stringify(customSpells[id])) {
                hasChanges = true;
                return true;
              }
              return false;
            });

            if (hasChanges) {
              setCustomSpells(newCustomSpells);
            }
          }
        } catch (error) {
          console.error('Error fetching custom spells:', error);
        }
      }
    };

    // Initial fetch when component mounts
    fetchCustomSpells();

    // We're removing the interval to prevent duplicate database reads
    // The SpellList component will handle the periodic refresh

    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  // Add a function to manually refresh custom spells
  const handleRefreshCustomSpells = useCallback(async () => {
    if (currentUser) {
      try {
        const newCustomSpells = await refreshCustomSpells(currentUser.uid);
        setCustomSpells(newCustomSpells);
      } catch (error) {
        console.error('Error manually refreshing custom spells:', error);
      }
    }
  }, [currentUser]);

  // Fetch user's spell notes and add them to the spell objects
  useEffect(() => {
    const fetchSpellNotes = async () => {
      if (currentUser) {
        try {
          const userData = await getUserData(currentUser.uid);
          const userNotes = userData?.spellNotes || {};

          // Create a map of spells with notes
          const spellsWithNotes = {};
          Object.keys(userNotes).forEach(spellId => {
            if (userNotes[spellId] && userNotes[spellId].trim() !== '') {
              spellsWithNotes[spellId] = true;
            }
          });

          // Update the existing spells directly
          let allSpellsUpdated = false;
          if (allSpells.length > 0) {
            allSpells.forEach(spell => {
              if (!spell.isSeparator) {
                const hasNotes = spellsWithNotes[spell.id] || false;
                if (spell.notes !== hasNotes) {
                  spell.notes = hasNotes;
                  allSpellsUpdated = true;
                }
              }
            });
          }

          // Also update the customSpells object to ensure custom spells have the notes property
          const updatedCustomSpells = {};
          let customSpellsUpdated = false;

          Object.entries(customSpells).forEach(([id, spell]) => {
            const hasNotes = spellsWithNotes[id] || false;
            if (spell.notes !== hasNotes) {
              customSpellsUpdated = true;
              updatedCustomSpells[id] = {
                ...spell,
                notes: hasNotes
              };
            } else {
              updatedCustomSpells[id] = spell;
            }
          });

          // Only update states if there were actual changes
          if (allSpellsUpdated) {
            // Force a re-render by updating a different state
            setExpandedRows(prev => ({ ...prev }));
          }

          if (customSpellsUpdated) {
            setCustomSpells(updatedCustomSpells);
          }
        } catch (error) {
          console.error('Error fetching spell notes:', error);
        }
      }
    };

    fetchSpellNotes();
  }, [currentUser, allSpells]); // Remove customSpells from dependencies

  // Prepare the spells for display
  const preparedSpells = useMemo(() => {
    // ... existing code ...
  }, [spellsToDisplay]);

  // Add this function near the other handler functions
  const showNotification = (message, type = "info") => {
    addNotification({
      message,
      type,
      duration: 3000
    });
  };

  return (
    <div className="prepared-spells spell-list">
      <HowToUseBox pageType="preparedSpells" />

      {/* Always show SpellResourceManager on PreparedSpells */}
      <SpellResourceManager
        spellSlots={currentSpellSlots}
        setSpellSlots={(newSlots) => updateSpellSlots(currentSpellbook, newSlots)}
        spellPoints={spellPointsByBook[currentSpellbook] || { total: 0, used: 0 }}
        setSpellPoints={(newPoints) => updateSpellPoints(currentSpellbook, newPoints)}
        spellbookName={currentSpellbook}
        showSpellSlotsEverywhere={showSpellSlotsEverywhere}
        setShowSpellSlotsEverywhere={setShowSpellSlotsEverywhere}
        characterLevel={characterLevel}
        setCharacterLevel={setCharacterLevel}
        highLevelSpellUses={highLevelSpellUses}
        setHighLevelSpellUses={setHighLevelSpellUses}
        allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
        setAllowMultipleHighLevelCasts={setAllowMultipleHighLevelCasts}
        useSpellPoints={useSpellPoints}
        setUseSpellPoints={setUseSpellPoints}
      />
      <ActiveSpellbookHeader
        currentSpellbook={currentSpellbook}
        setCurrentSpellbook={setCurrentSpellbook}
        spellbooks={spellbooks}
        useSpellPoints={useSpellPoints}
        setUseSpellPoints={setUseSpellPoints}
      />

      <SpellFilters
        filters={filters}
        setFilters={setFilters}
        filterOptions={{
          classes: uniqueClasses,
          levels: uniqueLevels,
          schools: uniqueSchools,
          components: componentOptions,
          sources: uniqueSources
        }}
      />

      <AdvancedFilterTags
        filters={filters}
        setFilters={setFilters}
      />

      <SpellTableControls
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        totalSpellCount={getTotalSpellCount()}
        currentSpellCount={spellsToDisplay.filter(spell => !spell.isSeparator).length}
        onShowCustomSpellForm={() => setShowCustomSpellForm(true)}
        onResetColumns={resetColumnsFunc}
        convertToMetric={convertToMetric}
        onToggleMetric={() => setConvertToMetric(!convertToMetric)}
        showShortDescriptions={showShortDescriptions}
        onToggleShortDescriptions={() => setShowShortDescriptions(!showShortDescriptions)}
        showRitualSpells={showRitualSpells}
        onToggleRitualSpells={handleRitualSpellsChange}
        showSchoolTags={showSchoolTags}
        onToggleSchoolTags={handleToggleSchoolTags}
        filters={filters}
        setFilters={setFilters}
        spells={spellsToDisplay}
        spellbooks={spellbooks}
      />

      <SpellTable
        spells={spellsToDisplay}
        selectedSpells={selectedSpells}
        toggleItem={toggleItem}
        selectAll={selectAll}
        areAllSelected={areAllSelected}
        showShortDescriptions={showShortDescriptions}
        convertToMetric={convertToMetric}
        showSchoolTags={showSchoolTags}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        visibleColumns={visibleColumns}
        renderIcons={renderIcons}
        emptyMessage="No spells are currently prepared."
        showRitualStyling={showRitualSpells}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        pagination={pagination}
        setPagination={setPagination}
        tabName="preparedSpells"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        totalSpellCount={getTotalSpellCount()}
        onResetColumns={handleResetColumns}
        useSpellPoints={useSpellPoints}
        spellPoints={spellPointsByBook[currentSpellbook] || { total: 0, used: 0 }}
        setSpellPoints={(newPoints) => updateSpellPoints(currentSpellbook, newPoints)}
        allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
        spellPointsByBook={spellPointsByBook}
        currentSpellbook={currentSpellbook}
        updateSpellPoints={updateSpellPoints}
        currentSpellSlots={currentSpellSlots}
        updateSpellSlots={updateSpellSlots}
        showNotification={showNotification}
      />

      {(isAnySpellSelected.hasPreparableSpells || isAnySpellSelected.hasUnpreparableSpells) && (
        <div className="floating-buttons">
          <button
            className="floating-close-button"
            onClick={() => {
              clearSelection();
              setSelectedSpells({});
            }}
            title="Clear Selection"
          >
            ×
          </button>
          {isAnySpellSelected.hasPreparableSpells && (
            <button
              onClick={handlePrepareSelectedSpells}
              className="floating-button prepare-button"
            >
              Prepare Selected Spells
            </button>
          )}
          {isAnySpellSelected.hasUnpreparableSpells && (
            <button
              onClick={handleUnprepareSelectedSpells}
              className="floating-button unprepare-button"
            >
              Unprepare Selected Spells
            </button>
          )}
          <button
            onClick={() => setShowCardGenerator(true)}
            className="floating-button generate-cards-button"
          >
            Generate Spell Cards
          </button>
        </div>
      )}

      {showCardGenerator && (
        <div className="spellcard-overlay">
          <SpellCardGenerator
            spells={getSelectedSpells()}
            onClose={() => setShowCardGenerator(false)}
          />
        </div>
      )}

      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <BackToTopIcon />
        </button>
      )}

      {selectedSpell && (
        <SpellInfoBox
          spell={selectedSpell}
          onClose={() => setSelectedSpell(null)}
          convertToMetric={convertToMetric}
        />
      )}

      <PreparedSpellsPresets
        presets={presets}
        setPresets={setPresets}
        onSavePreset={savePreset}
        onLoadPreset={loadPreset}
        onDeletePreset={deletePreset}
        availableSpells={allSpells}
        currentPreset={currentPreset}
        setCurrentPreset={setCurrentPreset}
        preparedSpells={preparedSpellsByBook[currentSpellbook]}
        currentSpellbook={currentSpellbook}
        currentUser={currentUser}
        addNotification={addNotification}
      />

      {confirmationPopup.isOpen && (
        <ConfirmationPopup
          message={confirmationPopup.message}
          onConfirm={() => {
            if (confirmationPopup.onConfirm) confirmationPopup.onConfirm();
            setConfirmationPopup({ isOpen: false, message: '', onConfirm: null });
          }}
          onCancel={() => setConfirmationPopup({ isOpen: false, message: '', onConfirm: null })}
        />
      )}

      {showCustomSpellForm && (
        <CustomSpellForm
          onSpellAdded={handleCustomSpellAdded}
          onClose={() => setShowCustomSpellForm(false)}
        />
      )}

      <SimpleConfirmationPopup
        isOpen={simpleConfirmationPopup.isOpen}
        message={simpleConfirmationPopup.message}
        onConfirm={simpleConfirmationPopup.onConfirm}
        onCancel={simpleConfirmationPopup.onCancel}
      />
    </div>
  );
}

export default PreparedSpells;
