// Helper functions
const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const matchesComponentFilter = (spellComponents, filterValue) => {
  const { verbal, somatic, material } = spellComponents;
  const materialsStr = spellComponents.materials_needed?.join(' ') || '';
  const hasCost = materialsStr.includes(' GP') || materialsStr.includes(' SP');

  switch (filterValue) {
    case 'Verbal Component': return verbal;
    case 'Somatic Component': return somatic;
    case 'Material Component': return material;
    case 'Costly Material': return material && hasCost;
    case 'Only Verbal': return verbal && !somatic && !material;
    case 'Only Somatic': return !verbal && somatic && !material;
    default: return true;
  }
};

// Main filter function
export const filterSpells = (spells, filters, spellbooks = {}) => {
  // Debug the inputs
  console.log("Filter inputs:", {
    filtersKeys: Object.keys(filters),
    spellbookFilter: filters.spellbook,
    excludeSpellbookFilter: filters.exclude_spellbook,
    spellbooksKeys: Object.keys(spellbooks),
    spellbooksExample: spellbooks['My Spellbook'] ? `My Spellbook has ${spellbooks['My Spellbook'].length} spells` : 'No My Spellbook'
  });

  return spells.filter(spell => {
    // First check all regular filters (inclusive)
    for (const [field, values] of Object.entries(filters)) {
      // Skip exclude filters and empty filters
      if (field.startsWith('exclude_') || !values.length) continue;

      if (field === 'components') {
        const spellComponents = spell.components || {};
        if (!values.every(v => matchesComponentFilter(spellComponents, v))) return false;
      }
      else if (field === 'class') {
        if (!Array.isArray(spell.classes)) return false;
        if (!values.some(v =>
          spell.classes.some(c =>
            capitalizeFirstLetter(c) === v ||
            capitalizeFirstLetter(c.name || c) === v
          )
        )) return false;
      }
      else if (field === 'level') {
        const spellLevel = spell.level === 'cantrip' ? 'Cantrip' : spell.level?.toString();
        if (!values.includes(spellLevel)) return false;
      }
      else if (field === 'school') {
        if (!values.includes(capitalizeFirstLetter(spell[field]))) return false;
      }
      else if (field === 'source') {
        // Check if any of the selected source filters match the spell
        if (!values.some(value => {
          if (value === 'Custom Spells') {
            return spell.id?.startsWith('custom-');
          } else if (value === '5.0 Free Rules') {
            return spell.srd;
          } else {
            return spell.sources?.some(source => source.book === value);
          }
        })) return false;
      }
      else if (field === 'concentration') {
        const isConcentration = spell.duration?.toLowerCase().includes('concentration');
        if (!values.includes(isConcentration ? 'Yes' : 'No')) return false;
      }
      else if (field === 'ritual') {
        const isRitual = spell.ritual === true;
        if (!values.includes(isRitual ? 'Yes' : 'No')) return false;
      }
      else if (field === 'duration') {
        // Check if any of the selected duration filters match the spell
        if (!values.some(value => {
          // Normalize the spell duration for comparison
          const spellDuration = spell.duration?.toLowerCase() || '';
          const filterValue = value.toLowerCase();

          return spellDuration === filterValue ||
            spellDuration.includes(filterValue);
        })) return false;
      }
      else if (field === 'range') {
        // Check if any of the selected range filters match the spell
        if (!values.some(value => {
          return spell.range === value;
        })) return false;
      }
      else if (field === 'notes') {
        // Check if the spell has notes when the notes filter is active
        if (values.includes('Yes') && spell.notes === false) return false;

        // If it's a custom spell, it should only pass the filter if it actually has notes
        if (values.includes('Yes') && spell.id?.startsWith('custom-') && spell.notes !== true) return false;
      }
      else if (field === 'spellbook') {
        console.log(`Checking spell ${spell.name} (${spell.id}) for spellbook filter`, values);

        // Check if the spell is in any of the selected spellbooks
        let found = false;

        // Loop through each selected spellbook name
        for (const bookName of values) {
          console.log(`Looking in spellbook: ${bookName}`);

          // Get the spells in this spellbook
          const spellsInThisBook = spellbooks[bookName];

          if (!spellsInThisBook) {
            console.log(`Spellbook ${bookName} not found or empty`);
            continue;
          }

          console.log(`Spellbook ${bookName} has ${spellsInThisBook.length} spells`);

          // Check if this spellbook contains the current spell's ID
          if (spellsInThisBook.includes(spell.id)) {
            console.log(`Found spell ${spell.id} in spellbook ${bookName}`);
            found = true;
            break;
          }
        }

        // If the spell was not found in any of the selected spellbooks, filter it out
        if (!found) {
          console.log(`Filtering out spell ${spell.id} - not in any selected spellbook`);
          return false;
        }
      }
      else if (!values.includes(spell[field])) return false;
    }

    // Then check all exclude filters
    for (const [field, values] of Object.entries(filters)) {
      // Skip non-exclude filters and empty filters
      if (!field.startsWith('exclude_') || !values.length) continue;

      const actualField = field.replace('exclude_', '');

      if (actualField === 'components') {
        const spellComponents = spell.components || {};
        if (values.some(v => matchesComponentFilter(spellComponents, v))) return false;
      }
      else if (actualField === 'class') {
        if (Array.isArray(spell.classes) && values.some(v =>
          spell.classes.some(c =>
            capitalizeFirstLetter(c) === v ||
            capitalizeFirstLetter(c.name || c) === v
          )
        )) return false;
      }
      else if (actualField === 'level') {
        const spellLevel = spell.level === 'cantrip' ? 'Cantrip' : spell.level?.toString();
        if (values.includes(spellLevel)) return false;
      }
      else if (actualField === 'school') {
        if (values.includes(capitalizeFirstLetter(spell[actualField]))) return false;
      }
      else if (actualField === 'source') {
        // Check if any of the excluded source filters match the spell
        if (values.some(value => {
          if (value === 'Custom Spells') {
            return spell.id?.startsWith('custom-');
          } else if (value === '5.0 Free Rules') {
            return spell.srd;
          } else {
            return spell.sources?.some(source => source.book === value);
          }
        })) return false;
      }
      else if (actualField === 'concentration') {
        const isConcentration = spell.duration?.toLowerCase().includes('concentration');
        if (values.includes(isConcentration ? 'Yes' : 'No')) return false;
      }
      else if (actualField === 'ritual') {
        const isRitual = spell.ritual === true;
        if (values.includes(isRitual ? 'Yes' : 'No')) return false;
      }
      else if (actualField === 'duration') {
        // Check if any of the excluded duration filters match the spell
        if (values.some(value => {
          // Normalize the spell duration for comparison
          const spellDuration = spell.duration?.toLowerCase() || '';
          const filterValue = value.toLowerCase();

          return spellDuration === filterValue ||
            spellDuration.includes(filterValue);
        })) return false;
      }
      else if (actualField === 'range') {
        // Check if any of the excluded range filters match the spell
        if (values.some(value => {
          return spell.range === value;
        })) return false;
      }
      else if (actualField === 'notes') {
        // Check if the spell has notes when the exclude notes filter is active
        if (values.includes('Yes') && spell.notes !== false) return false;
      }
      else if (actualField === 'spellbook') {
        console.log(`Checking spell ${spell.name} (${spell.id}) for exclude_spellbook filter`, values);

        // Loop through each excluded spellbook name
        for (const bookName of values) {
          console.log(`Looking in excluded spellbook: ${bookName}`);

          // Get the spells in this spellbook
          const spellsInThisBook = spellbooks[bookName];

          if (!spellsInThisBook) {
            console.log(`Excluded spellbook ${bookName} not found or empty`);
            continue;
          }

          console.log(`Excluded spellbook ${bookName} has ${spellsInThisBook.length} spells`);

          // Check if this spellbook contains the current spell's ID
          if (spellsInThisBook.includes(spell.id)) {
            console.log(`Found spell ${spell.id} in excluded spellbook ${bookName}, filtering out`);
            return false;
          }
        }
      }
      else if (values.includes(spell[actualField])) return false;
    }

    return true;
  });
};

// Utility functions
export const getUniqueValues = (spells, field) => {
  const values = new Set();

  spells.forEach(spell => {
    if (field === 'components') {
      const components = [];
      if (spell.components?.verbal) components.push('Verbal Component');
      if (spell.components?.somatic) components.push('Somatic Component');
      if (spell.components?.material) components.push('Material Component');
      if (spell.components?.verbal && !spell.components?.somatic && !spell.components?.material) {
        components.push('Only Verbal');
      }
      if (!spell.components?.verbal && spell.components?.somatic && !spell.components?.material) {
        components.push('Only Somatic');
      }
      components.forEach(c => values.add(c));
    }
    else if (field === 'classes') {
      if (Array.isArray(spell.classes)) {
        spell.classes.forEach(className => {
          const name = typeof className === 'string' ? className : className.name;
          if (name) {
            values.add(capitalizeFirstLetter(name));
          }
        });
      }
    }
    else if (field === 'school') {
      if (typeof spell[field] === 'string') {
        values.add(capitalizeFirstLetter(spell[field]));
      }
    }
    else if (field === 'level') {
      if (spell[field] === 'cantrip') {
        values.add('Cantrip');
      } else if (spell[field] !== undefined && spell[field] !== null) {
        values.add(spell[field].toString());
      }
    }
    else if (field === 'source') {
      // Add Custom Spells source if the spell is custom
      if (spell.id?.startsWith('custom-')) {
        values.add('Custom Spells');
      }
      // Add regular sources
      if (spell.sources) {
        spell.sources.forEach(source => {
          if (source.book) {
            values.add(source.book);
          }
        });
      }
    }
    else if (spell[field]) {
      values.add(spell[field]);
    }
  });

  // Special sorting for levels
  if (field === 'level') {
    return Array.from(values).sort((a, b) => {
      if (a === 'Cantrip') return -1;
      if (b === 'Cantrip') return 1;
      return parseInt(a) - parseInt(b);
    });
  }

  return Array.from(values).sort();
};

export const getComponentOptions = () => {
  return [
    'Verbal Component',
    'Somatic Component',
    'Material Component',
    'Costly Material',
    'Only Verbal',
    'Only Somatic'
  ];
};

export const getActiveFilters = (filters) => {
  const active = {};
  Object.entries(filters).forEach(([key, values]) => {
    if (values.length > 0) {
      // For exclude filters, store them with the original filter name but mark them as exclude
      if (key.startsWith('exclude_')) {
        const baseKey = key.replace('exclude_', '');
        if (!active[baseKey]) {
          active[baseKey] = [];
        }
        // Add each value with an exclude flag
        values.forEach(value => {
          active[baseKey].push({ value, exclude: true });
        });
      } else {
        if (!active[key]) {
          active[key] = [];
        }
        // Add each value as a regular object
        values.forEach(value => {
          active[key].push({ value, exclude: false });
        });
      }
    }
  });
  return active;
};

export const addFilter = (filters, name, value) => {
  const newFilters = { ...filters };
  if (!newFilters[name].includes(value)) {
    newFilters[name] = [...newFilters[name], value];
  }
  return newFilters;
};

export const removeFilter = (filters, name, value) => {
  const newFilters = { ...filters };
  newFilters[name] = newFilters[name].filter(v => v !== value);
  return newFilters;
};

export const clearFilters = () => ({
  class: [],
  level: [],
  school: [],
  components: [],
  concentration: [],
  ritual: [],
  source: [],
  duration: [],
  range: [],
  notes: [],
  spellbook: []
});

export const prepareFilterOptions = (spells, spellbooks = {}) => {
  // Debug the spellbooks object
  console.log("Preparing filter options with spellbooks:", {
    type: typeof spellbooks,
    isNull: spellbooks === null,
    isObject: typeof spellbooks === 'object',
    keys: spellbooks ? Object.keys(spellbooks) : [],
    example: spellbooks && spellbooks['My Spellbook'] ?
      `My Spellbook has ${spellbooks['My Spellbook'].length} spells` :
      'No My Spellbook found'
  });

  // Get spellbook names safely
  let spellbookNames = [];
  if (spellbooks && typeof spellbooks === 'object') {
    spellbookNames = Object.keys(spellbooks);
    console.log("Extracted spellbook names:", spellbookNames);
  }

  return {
    class: getUniqueValues(spells, 'classes').map(capitalizeFirstLetter),
    level: getUniqueValues(spells, 'level'),
    school: getUniqueValues(spells, 'school').map(capitalizeFirstLetter),
    components: getComponentOptions(),
    concentration: ['Yes', 'No'],
    ritual: ['Yes', 'No'],
    source: [...new Set([
      ...getUniqueValues(spells, 'source'),
      '5.0 Free Rules',
      "Custom Spells"
    ])].sort(),
    spellbook: spellbookNames,
    notes: ['Yes']
  };
};

export const getSourceAbbreviation = (source) => {
  const abbreviations = {
    '5.0 Free Rules': 'SRD',
    "Custom Spells": 'Custom'
  };
  return abbreviations[source] || source;
};

export const getSourceDisplayName = (source) => {
  const displayNames = {
    'PHB2024': "2024 Player's Handbook",
    'EE': "Elemental Evil Player's Companion",
    'TCE': "Tasha's Cauldron of Everything",
    'XGE': "Xanathar's Guide to Everything",
    'SCAG': "Sword Coast Adventurer's Guide",
    '5.0 Free Rules': '5e Free Rules (SRD)',
  };
  return displayNames[source] || source;
};

export const getSpellLevel = (level) => level === 0 ? 'Cantrip' : level.toString();

export const getComponents = (spell) => {
  const components = [];
  if (spell.components.verbal) components.push('V');
  if (spell.components.somatic) components.push('S');
  if (spell.components.material) components.push('M');
  return components.join(', ');
};