import React, { useState } from 'react';
import '../styles/pages/ContactPage.css';
import { FacebookIcon, RedditIcon, BlueskyIcon, EmailIcon } from '../data/Icons';

function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
    
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('access_key', 'a0a25cba-eb18-457e-9e5a-85a9f8814590');
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('subject', formData.subject);
      // Add honeypot field to prevent spam
      formDataToSend.append('botcheck', '');

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formDataToSend
      });

      const result = await response.json();
      console.log('Form submission result:', result);

      if (result.success) {
        setStatus('sent');
        setFormData({ name: '', email: '', subject: '', message: '' });
        setTimeout(() => setStatus(''), 5000);
      } else {
        console.error('Form submission failed:', result);
        setStatus('error');
        setTimeout(() => setStatus(''), 5000);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus('error');
      setTimeout(() => setStatus(''), 5000);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p className="contact-intro">Feel free to reach out to us with any questions, feedback, or concerns about D&D Spell Manager.</p>
          
          <div className="contact-features">
            <div className="contact-feature">
              <div className="feature-icon">
                <i className="fas fa-question-circle"></i>
              </div>
              <div className="feature-text">
                <h3>Questions</h3>
                <p>Have a question about how to use our app?</p>
              </div>
            </div>
            
            <div className="contact-feature">
              <div className="feature-icon">
                <i className="fas fa-lightbulb"></i>
              </div>
              <div className="feature-text">
                <h3>Suggestions</h3>
                <p>Share your ideas for new features or improvements.</p>
              </div>
            </div>
            
            <div className="contact-feature">
              <div className="feature-icon">
                <i className="fas fa-bug"></i>
              </div>
              <div className="feature-text">
                <h3>Bug Reports</h3>
                <p>Found an issue? Let us know and we'll fix it.</p>
              </div>
            </div>
          </div>
          
          <div className="social-connect">
            <h3>Connect With Us</h3>
            <div className="social-icons">
              <a href="https://facebook.com/TableMancer" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
              <a href="https://bsky.app/profile/tablemancer.bsky.social" target="_blank" rel="noopener noreferrer">
                <BlueskyIcon />
              </a>
              <a href="https://www.reddit.com/r/TableMancer" target="_blank" rel="noopener noreferrer">
                <RedditIcon />
              </a>
            </div>
          </div>
        </div>
        
        <div className="form-container">
          <h2>Send a Message</h2>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Your name"
                  className="form-input"
                  style={{ backgroundColor: '#ffffff', color: '#333333' }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Your email address"
                  className="form-input"
                  style={{ backgroundColor: '#ffffff', color: '#333333' }}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder="What is this regarding?"
                className="form-input"
                style={{ backgroundColor: '#ffffff', color: '#333333' }}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
                placeholder="Your message here..."
                className="form-textarea"
                style={{ backgroundColor: '#ffffff', color: '#333333' }}
              ></textarea>
            </div>
            
            {/* Honeypot field to prevent spam */}
            <input type="checkbox" name="botcheck" style={{ display: 'none' }} />
            
            <button 
              type="submit" 
              className={`submit-button ${status === 'sending' ? 'sending' : ''}`}
              disabled={status === 'sending'}
            >
              {status === 'sending' ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Sending...
                </>
              ) : (
                <>
                  <i className="fas fa-paper-plane"></i> Send Message
                </>
              )}
            </button>
            
            {status === 'sent' && (
              <div className="success-message">
                <i className="fas fa-check-circle"></i> Message sent successfully! We'll get back to you soon.
              </div>
            )}
            
            {status === 'error' && (
              <div className="error-message">
                <i className="fas fa-exclamation-circle"></i> Something went wrong. Please try again later.
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage; 