import React, { useState, useEffect, useMemo, useCallback, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import SpellFilters from './SpellFilters';
import useSpellFilters from '../hooks/useSpellFilters';
import useSortSpells from '../hooks/useSortSpells';
import useSearchSpells from '../hooks/useSearchSpells';
import useCheckboxSelection from '../hooks/useCheckboxSelection';
import useUnitConversion from '../hooks/useUnitConversion';
import { formatUnits } from '../utils/formatUnits';
import { convertUnits } from '../utils/converUnits';
import '../styles/components/SpellBook.css';
import { BookIcon, TrashcanIcon, RemoveSpellIcon, AllSpellsIcon, BackToTopIcon, NewInfoIcon } from '../data/Icons';
import ConfirmationPopup from './ConfirmationPopup';
import NotificationPopup from './NotificationPopup';
import SpellInfoBox from './SpellInfoBox';
import SpellCounter from './SpellCounter';
import { NotificationContext } from '../App';
import { useAuth } from '../AuthContext';
import {
  updateUserSpellbooks,
  removeSpellbook,
  createSpellbook as createFirestoreSpellbook,
  getUserSpellbooks,
  getUserSettings,
  updateUserPreparedSpells,
  getUserSpellbookOrder,
  updateUserSpellbookOrder,
  getAllSpells,
  getSpellsByIds,
  subscribeToCustomSpells,
  refreshCustomSpells,
  removeSpellFromSpellbook,
  createSpellbookWithSpells,
  getUserData,
  updateUserSettings
} from '../firebase/firestoreOperations';
import spellsData from '../data/spells.json';
import { debounce } from 'lodash';
import SpellTable from './SpellTable';
import SpellbookGenerator from './SpellbookGenerator';
import HowToUseBox from './HowToUseBox';
import SpellResourceManager from './SpellResourceManager';
import SpellCardGenerator from './SpellCardGenerator';
import SpellTableControls from './SpellTableControls';
import CustomSpellForm from './CustomSpellForm';
import ActiveSpellbookHeader from './ActiveSpellbookHeader';
import AdvancedFilterTags from './AdvancedFilterTags';

// Utility function for conditional logging
const logger = (message, ...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(message, ...args);
  }
};

const SpellBook = forwardRef(({
  filters,
  setFilters,
  selectedSpells,
  setSelectedSpells,
  searchTerm,
  setSearchTerm,
  convertToMetric,
  setConvertToMetric,
  spellbooks,
  setSpellbooks,
  addToSpellbook,
  createSpellbook,
  preparedSpells,
  setPreparedSpells,
  showShortDescriptions,
  setShowShortDescriptions,
  showRitualSpells,
  setShowRitualSpells,
  currentSpellbook,
  setCurrentSpellbook,
  preparedSpellsByBook,
  updatePreparedSpells,
  sortConfig,
  setSortConfig,
  pagination,
  setPagination,
  showSpellSlotsEverywhere,
  spellSlotsByBook,
  updateSpellSlots,
  setShowSpellSlotsEverywhere,
  spellPointsByBook,
  updateSpellPoints,
  characterLevel,
  setCharacterLevel,
  highLevelSpellUses,
  setHighLevelSpellUses,
  allowMultipleHighLevelCasts,
  setAllowMultipleHighLevelCasts,
  useSpellPoints,
  setUseSpellPoints,
  characterLevelsByBook,
  handleSetCharacterLevel,
  showSchoolTags,
  setShowSchoolTags
}, ref) => {
  const [newSpellbookName, setNewSpellbookName] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [spellbookToDelete, setSpellbookToDelete] = useState(null);
  const [spellbookOrder, setSpellbookOrder] = useState(() => {
    return JSON.parse(localStorage.getItem('spellbookOrder')) || ['My Spellbook'];
  });
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedSpell, setSelectedSpell] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const { addNotification } = useContext(NotificationContext);
  const { currentUser } = useAuth();
  const [showLoginWarning, setShowLoginWarning] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(10);
  const [showGenerator, setShowGenerator] = useState(false);
  const [availableSpells, setAvailableSpells] = useState([]);
  const [spellbookSpells, setSpellbookSpells] = useState([]);
  const [customSpells, setCustomSpells] = useState({});
  const [showCardGenerator, setShowCardGenerator] = useState(false);
  const [showCustomSpellForm, setShowCustomSpellForm] = useState(false);
  const [resetColumnsFunc, setResetColumnsFunc] = useState(() => () => { });

  useEffect(() => {
    const loadSpellbookOrder = async () => {
      if (currentUser) {
        const order = await getUserSpellbookOrder(currentUser.uid);
        setSpellbookOrder(order);
      }
    };
    loadSpellbookOrder();
  }, [currentUser]);

  const updateVisibleColumns = useCallback(debounce(() => {
    const width = window.innerWidth;
    let count = 10;

    if (width <= 600) count = 3;
    else if (width <= 700) count = 4;
    else if (width <= 800) count = 5;
    else if (width <= 900) count = 6;
    else if (width <= 1000) count = 7;
    else if (width <= 1100) count = 8;
    else if (width <= 1200) count = 9;

    setVisibleColumns(count);
  }, 200), []);

  useEffect(() => {
    updateVisibleColumns();
    window.addEventListener('resize', updateVisibleColumns);
    return () => {
      window.removeEventListener('resize', updateVisibleColumns);
      updateVisibleColumns.cancel();
    };
  }, [updateVisibleColumns]);

  useEffect(() => {
    if (Object.keys(spellbooks).length === 0) {
      setSpellbooks(prevSpellbooks => ({
        'My Spellbook': [],
        ...prevSpellbooks
      }));
    }

    setSpellbookOrder(prevOrder => {
      const allSpellbooks = Object.keys(spellbooks);
      const newOrder = [...prevOrder];

      allSpellbooks.forEach(book => {
        if (!newOrder.includes(book)) {
          newOrder.push(book);
        }
      });

      // Remove any spellbooks that no longer exist
      const finalOrder = newOrder.filter(book => allSpellbooks.includes(book));

      // Save the order to localStorage
      localStorage.setItem('spellbookOrder', JSON.stringify(finalOrder));

      return finalOrder;
    });

    if (!currentSpellbook || !spellbooks[currentSpellbook]) {
      const firstAvailableSpellbook = Object.keys(spellbooks)[0];
      if (firstAvailableSpellbook) {
        setCurrentSpellbook(firstAvailableSpellbook);
        localStorage.setItem('lastActiveSpellbook', firstAvailableSpellbook);
      }
    }

    // Show login warning for non-logged-in users
    if (!currentUser) {
      setShowLoginWarning(true);
    }
  }, [spellbooks, currentSpellbook, setSpellbooks, currentUser]);

  const {
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters
  } = useSpellFilters(spellbookSpells, filters, setFilters);

  const { sortedSpells } = useSortSpells(filteredSpells || [], sortConfig, setSortConfig);
  const searchedSpells = useSearchSpells(sortedSpells || [], searchTerm);
  const { toggleItem, selectAll, areAllSelected, clearSelection } = useCheckboxSelection(searchedSpells, selectedSpells, setSelectedSpells);
  const { toggleConversion, convertIfNeeded } = useUnitConversion(convertToMetric, setConvertToMetric);

  const isAnySpellSelected = Object.values(selectedSpells).some(value => value);

  const handleCreateSpellbook = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      addNotification('Please log in to create new spellbooks', 'warning');
      return;
    }

    // Case-insensitive check for existing spellbook names
    const existingNames = Object.keys(spellbooks).map(name => name.toLowerCase());
    if (currentUser && newSpellbookName && !existingNames.includes(newSpellbookName.toLowerCase()) && newSpellbookName.length <= 25) {
      try {
        // First check if we can create the spellbook in Firestore
        // This will throw an error if the limit is reached
        await createFirestoreSpellbook(currentUser.uid, newSpellbookName);

        // If we get here, the Firestore operation succeeded, so update local state

        // Update the spellbooks state explicitly
        setSpellbooks(prevSpellbooks => ({
          ...prevSpellbooks,
          [newSpellbookName]: []
        }));

        // Update the spellbook order
        const newOrder = [...spellbookOrder, newSpellbookName];
        setSpellbookOrder(newOrder);

        if (currentUser) {
          await updateUserSpellbookOrder(currentUser.uid, newOrder);
        } else {
          localStorage.setItem('spellbookOrder', JSON.stringify(newOrder));
        }

        setNewSpellbookName('');
        setCurrentSpellbook(newSpellbookName);

        // Add a success notification since createSpellbook in firestoreOperations.js doesn't add one
        addNotification(`Spellbook "${newSpellbookName}" created successfully`, 'success');
      } catch (error) {
        // Display a user-friendly notification for any errors
        addNotification(error.message, 'error');
        console.error('Error creating spellbook:', error);
      }
    } else if (existingNames.includes(newSpellbookName.toLowerCase())) {
      addNotification('A spellbook with this name already exists', 'error');
    } else if (newSpellbookName.length > 25) {
      addNotification('Spellbook name must be 25 characters or less', 'error');
    }
  };

  const handleRemoveSpellbook = (name) => {
    if (Object.keys(spellbooks).length > 1) {
      setSpellbookToDelete(name);
      setIsConfirmationOpen(true);
    } else {
      addNotification("Cannot delete the last spellbook", "error");
    }
  };

  const confirmRemoveSpellbook = async () => {
    if (spellbookToDelete && Object.keys(spellbooks).length > 1) {
      try {
        if (currentUser) {
          // Remove spellbook and all related data from database
          await removeSpellbook(currentUser.uid, spellbookToDelete);

          // Update local state
          const updatedSpellbooks = { ...spellbooks };
          delete updatedSpellbooks[spellbookToDelete];
          setSpellbooks(updatedSpellbooks);

          // Update spellbook order
          const newOrder = spellbookOrder.filter(book => book !== spellbookToDelete);
          setSpellbookOrder(newOrder);
          await updateUserSpellbookOrder(currentUser.uid, newOrder);

          // Switch to another spellbook if needed
          if (currentSpellbook === spellbookToDelete) {
            const newCurrentSpellbook = Object.keys(updatedSpellbooks)[0];
            setCurrentSpellbook(newCurrentSpellbook);
            localStorage.setItem('lastActiveSpellbook', newCurrentSpellbook);
          }

          addNotification(`Spellbook "${spellbookToDelete}" deleted`, 'success');
        } else {
          // Handle deletion for non-logged-in users
          setSpellbooks(prevSpellbooks => {
            const updatedSpellbooks = { ...prevSpellbooks };
            delete updatedSpellbooks[spellbookToDelete];
            localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
            return updatedSpellbooks;
          });
        }
      } catch (error) {
        console.error('Error removing spellbook:', error);
        addNotification('Failed to delete spellbook', 'error');
      }
    } else {
      addNotification("Cannot delete the last spellbook", "error");
    }
    setIsConfirmationOpen(false);
    setSpellbookToDelete(null);
  };

  const cancelRemoveSpellbook = () => {
    setIsConfirmationOpen(false);
    setSpellbookToDelete(null);
  };

  const getSpellLevel = (level) => {
    return level === 0 ? 'Cantrip' : level.toString();
  };

  const getComponents = (spell) => {
    const components = [];
    if (spell.components.verbal) components.push('V');
    if (spell.components.somatic) components.push('S');
    if (spell.components.material) components.push('M');
    return components.join(', ');
  };

  const formatTableValue = (value) => {
    if (!value) return '';

    if (typeof value === 'string') {
      if (value.startsWith('1 reaction')) {
        return '1 reaction';
      }
      if (value.startsWith('Concentration,')) {
        return value.replace('Concentration, ', '');
      }
      if (value.includes('emanation')) {
        return value.replace(/(\d+)-?foot emanation/, '$1ft emanation')
          .replace(/(\d+)-?ft emanation/, '$1ft emanation');
      }
    }
    return formatUnits(convertIfNeeded(value));
  };

  // Add this new function to handle short description conversion
  const formatShortDescription = (description) => {
    return convertToMetric ? convertUnits(description) : description;
  };

  const handleAddToSpellbook = (spellName, selectedSpellbook) => {
    if (selectedSpellbook) {
      const spellToAdd = spellsData.find(spell => spell.name === spellName);
      if (spellToAdd) {
        setSpellbooks(prevSpellbooks => {
          const updatedSpellbooks = {
            ...prevSpellbooks,
            [selectedSpellbook]: [...(prevSpellbooks[selectedSpellbook] || []), spellToAdd]
          };

          if (currentUser) {
            updateUserSpellbooks(currentUser.uid, updatedSpellbooks);
          } else {
            localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
          }

          return updatedSpellbooks;
        });

        addNotification(`${spellName} has been added to ${selectedSpellbook}`, 'success');
      }
    }
  };

  const isSpellPrepared = useCallback((spellName) => {
    const currentBookPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    return currentBookPreparedSpells.some(s => s.name === spellName);
  }, [preparedSpellsByBook, currentSpellbook]);

  const handlePrepareSpell = (spell) => {
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];

    if (isSpellPrepared(spell.name)) {
      // Unprepare the spell
      const newPreparedSpells = currentPreparedSpells.filter(s => s.name !== spell.name);
      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      addNotification(`${spell.name} has been unprepared from ${currentSpellbook}`, 'info');
    } else {
      // Prepare the spell
      const newPreparedSpells = [...currentPreparedSpells, spell];
      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      addNotification(`${spell.name} has been prepared in ${currentSpellbook}`, 'success');
    }
  };

  const handleSpellbookClick = (book) => {
    setCurrentSpellbook(book);
    localStorage.setItem('lastActiveSpellbook', book);
  };

  const handlePrepareSelectedSpells = () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const spellsToPrepare = spellbookSpells.filter(spell => selectedSpellsList.includes(spell.name));

    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    const newPreparedSpells = [...currentPreparedSpells];

    let addedCount = 0;
    let alreadyPreparedCount = 0;

    spellsToPrepare.forEach(spell => {
      if (!currentPreparedSpells.some(ps => ps.name === spell.name)) {
        newPreparedSpells.push(spell);
        addedCount++;
      } else {
        alreadyPreparedCount++;
      }
    });

    updatePreparedSpells(currentSpellbook, newPreparedSpells);
    clearSelection();
    setSelectedSpells({});

    // Show appropriate notification
    if (addedCount > 0 && alreadyPreparedCount > 0) {
      addNotification(
        `${addedCount} spell${addedCount !== 1 ? 's' : ''} prepared, ${alreadyPreparedCount} already prepared`,
        'info'
      );
    } else if (addedCount > 0) {
      addNotification(
        `${addedCount} spell${addedCount !== 1 ? 's' : ''} prepared`,
        'success'
      );
    } else if (alreadyPreparedCount > 0) {
      addNotification(
        `${alreadyPreparedCount} spell${alreadyPreparedCount !== 1 ? 's were' : ' was'} already prepared`,
        'warning'
      );
    }
  };

  const handleRemoveSelectedSpells = async () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const spellsToRemove = spellbookSpells.filter(spell => selectedSpellsList.includes(spell.name));

    try {
      const updatedSpellbooks = {
        ...spellbooks,
        [currentSpellbook]: spellbooks[currentSpellbook].filter(id =>
          !spellsToRemove.some(spell => spell.id === id)
        )
      };

      setSpellbooks(updatedSpellbooks);

      if (currentUser) {
        // Remove each spell from Firestore
        for (const spell of spellsToRemove) {
          await removeSpellFromSpellbook(currentUser.uid, currentSpellbook, spell);
        }
      } else {
        localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
      }

      clearSelection();
      addNotification(`${spellsToRemove.length} spell${spellsToRemove.length > 1 ? 's' : ''} removed from ${currentSpellbook}`, 'success');
    } catch (error) {
      console.error('Error removing spells:', error);
      addNotification('Failed to remove some spells', 'error');
    }
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowBackToTop(scrollTop > 300);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleRemoveSpellFromBook = useCallback(async (spellName) => {
    try {
      // Find the spell object in the current spellbook
      const spellToRemove = spellbookSpells.find(spell => spell.name === spellName);
      if (!spellToRemove) {
        console.error('Spell not found:', spellName);
        return;
      }

      // Update local state first for immediate UI feedback
      const updatedSpellbooks = {
        ...spellbooks,
        [currentSpellbook]: spellbooks[currentSpellbook].filter(id => id !== spellToRemove.id)
      };

      setSpellbooks(updatedSpellbooks);

      if (currentUser) {
        // Remove from Firestore
        await removeSpellFromSpellbook(currentUser.uid, currentSpellbook, spellToRemove);
      } else {
        localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
      }

      addNotification(`${spellName} removed from ${currentSpellbook}`, 'success');
    } catch (error) {
      console.error('Error removing spell from spellbook:', error);
      addNotification(`Failed to remove ${spellName} from ${currentSpellbook}`, 'error');
    }
  }, [currentUser, currentSpellbook, spellbooks, spellbookSpells, addNotification]);

  const handleSpellInfoClick = (spell) => {
    setSelectedSpell(spell);
  };

  useEffect(() => {
    setExpandedRows({});
  }, [showShortDescriptions]);

  const toggleSpellDescription = useCallback((spellName, event) => {
    // Prevent toggling if the click was on a checkbox or icon
    if (event.target.type === 'checkbox' || event.target.closest('.info-icon, .remove-spell-icon, .prepare-spell-icon')) {
      return;
    }
    setExpandedRows(prev => ({
      ...prev,
      [spellName]: !prev[spellName]
    }));
  }, []);

  const isRowExpanded = useCallback((spellName) => {
    return showShortDescriptions || expandedRows[spellName];
  }, [showShortDescriptions, expandedRows]);

  // Add this function:
  const handleConvertToMetricChange = (event) => {
    setConvertToMetric(event.target.checked);
  };

  const handleToggleSchoolTags = async () => {
    const newValue = !showSchoolTags;
    setShowSchoolTags(newValue);

    if (currentUser) {
      await updateUserSettings(currentUser.uid, {
        showSchoolTags: newValue
      });
    } else {
      localStorage.setItem('showSchoolTags', JSON.stringify(newValue));
    }
  };

  // Add a new function to handle removing spells for non-logged-in users
  const handleRemoveSpellFromBookNonLogged = (spellName) => {
    setSpellbooks(prevSpellbooks => {
      const updatedSpellbooks = {
        ...prevSpellbooks,
        'My Spellbook': prevSpellbooks['My Spellbook'].filter(spell => spell.name !== spellName)
      };
      localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
      return updatedSpellbooks;
    });
    addNotification(`${spellName} removed from My Spellbook`, 'success');
  };

  const renderFilterGroup = (name, options, label) => {
    const activeFilters = getActiveFilters(filters);
    return (
      <div className="filter-group">
        <select name={name} value="" onChange={handleFilterChange}>
          <option value="">{label}</option>
          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <div className="filter-tags">
          {activeFilters[name] && activeFilters[name].map(value => (
            <span
              key={value}
              className="filter-tag"
              onClick={() => handleRemoveFilter(name, value)}
            >
              {value}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveFilter(name, value);
                }}
              >
                &times;
              </button>
            </span>
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        const userSpellbooks = await getUserSpellbooks(currentUser.uid);
        setSpellbooks(userSpellbooks);

        const userSettings = await getUserSettings(currentUser.uid);
        setShowShortDescriptions(userSettings.showShortDescriptions || false);
        setShowRitualSpells(userSettings.showRitualSpells || false);
        setConvertToMetric(userSettings.convertToMetric || false);
      };
      fetchUserData();
    } else {
      // Load spellbooks from localStorage for non-logged-in users
      const guestSpellbooks = JSON.parse(localStorage.getItem('guestSpellbooks')) || { 'My Spellbook': [] };
      setSpellbooks(guestSpellbooks);
    }
  }, [currentUser]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderIcons = (spell) => {
    return (
      <>
        <span
          className="info-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleSpellInfoClick(spell);
          }}
          title="Spell Info"
        >
          <NewInfoIcon />
        </span>
        <span
          className="remove-spell-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveSpellFromBook(spell.name);
          }}
          title="Remove from Spellbook"
        >
          <RemoveSpellIcon />
        </span>
        <span
          className={`prepare-spell-icon ${isSpellPrepared(spell.name) ? 'prepared' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            handlePrepareSpell(spell);
          }}
          title={isSpellPrepared(spell.name) ? "Unprepare Spell" : "Prepare Spell"}
        >
          <AllSpellsIcon />
        </span>
      </>
    );
  };

  useEffect(() => {
    if (!spellbooks[currentSpellbook]) {
      const defaultSpellbook = Object.keys(spellbooks)[0] || 'My Spellbook';
      setCurrentSpellbook(defaultSpellbook);
      localStorage.setItem('lastActiveSpellbook', defaultSpellbook);
    }
  }, [spellbooks, currentSpellbook]);

  const handleDragStart = (e, book) => {
    e.dataTransfer.setData('text/plain', book);
    e.currentTarget.classList.add('dragging');
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove('dragging');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    const dragTab = document.querySelector('.dragging');
    if (!dragTab) return;

    const tabsList = dragTab.parentNode;
    const siblings = [...tabsList.querySelectorAll('.spellbook-item:not(.dragging)')];

    const afterElement = siblings.reduce((closest, sibling) => {
      const box = sibling.getBoundingClientRect();
      const offset = e.clientX - box.left - box.width / 2;

      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: sibling };
      } else {
        return closest;
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element;

    if (afterElement) {
      tabsList.insertBefore(dragTab, afterElement);
    } else {
      tabsList.appendChild(dragTab);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const draggedBook = e.dataTransfer.getData('text/plain');
    const dropZone = e.currentTarget.closest('.spellbook-item');
    const tabsList = dropZone.parentNode;
    const newOrder = [...tabsList.querySelectorAll('.spellbook-item')].map(item =>
      item.getAttribute('data-book')
    );

    setSpellbookOrder(newOrder);

    if (currentUser) {
      await updateUserSpellbookOrder(currentUser.uid, newOrder);
    } else {
      localStorage.setItem('spellbookOrder', JSON.stringify(newOrder));
    }
  };

  const handleGenerateSpellbook = async (name, spells) => {
    if (!currentUser) {
      addNotification('Please log in to generate spellbooks', 'warning');
      return;
    }

    // Check if the spellbook name already exists
    const existingNames = Object.keys(spellbooks).map(bookName => bookName.toLowerCase());
    if (existingNames.includes(name.toLowerCase())) {
      addNotification('A spellbook with this name already exists', 'error');
      return;
    }

    try {
      // Create the spellbook with spells - this will throw an error if the limit is reached
      await createSpellbookWithSpells(currentUser.uid, name, spells);

      // Only update local state if the Firestore operation succeeded
      setSpellbooks(prev => ({
        ...prev,
        [name]: spells.map(spell => spell.id)
      }));

      // Update spellbook order
      const newOrder = [...spellbookOrder, name];
      setSpellbookOrder(newOrder);
      await updateUserSpellbookOrder(currentUser.uid, newOrder);

      // Set the new spellbook as current
      setCurrentSpellbook(name);

      // Show success notification
      addNotification(`Spellbook "${name}" created successfully with ${spells.length} spells`, 'success');

      // Close the generator
      setShowGenerator(false);
    } catch (error) {
      console.error('Error generating spellbook:', error);
      addNotification(error.message, 'error');
    }
  };

  useEffect(() => {
    const loadSpells = async () => {
      if (currentUser) {
        const allSpells = await getAllSpells(currentUser.uid);
        setAvailableSpells(allSpells);
      } else {
        setAvailableSpells([...spellsData]);
      }
    };
    loadSpells();
  }, [currentUser]);

  // Fetch user's spell notes and add them to the spell objects
  useEffect(() => {
    const fetchSpellNotes = async () => {
      if (currentUser) {
        try {
          const userData = await getUserData(currentUser.uid);
          const userNotes = userData?.spellNotes || {};

          // Create a map of spells with notes
          const spellsWithNotes = {};
          Object.keys(userNotes).forEach(spellId => {
            if (userNotes[spellId] && userNotes[spellId].trim() !== '') {
              spellsWithNotes[spellId] = true;
            }
          });

          // Update the existing spells directly without creating new arrays
          if (availableSpells.length > 0) {
            availableSpells.forEach(spell => {
              spell.notes = spellsWithNotes[spell.id] || false;
            });
          }

          if (spellbookSpells.length > 0) {
            spellbookSpells.forEach(spell => {
              spell.notes = spellsWithNotes[spell.id] || false;
            });
          }

          // Force a re-render by updating a different state
          setExpandedRows(prev => ({ ...prev }));

          // Also update the customSpells object to ensure custom spells have the notes property
          const updatedCustomSpells = {};
          let hasChanges = false;

          Object.entries(customSpells).forEach(([id, spell]) => {
            const hasNotes = spellsWithNotes[id] || false;
            if (spell.notes !== hasNotes) {
              hasChanges = true;
              updatedCustomSpells[id] = {
                ...spell,
                notes: hasNotes
              };
            } else {
              updatedCustomSpells[id] = spell;
            }
          });

          // Only update customSpells if there are actual changes
          if (hasChanges) {
            setCustomSpells(updatedCustomSpells);
          }
        } catch (error) {
          console.error('Error fetching spell notes:', error);
        }
      }
    };

    fetchSpellNotes();
  }, [currentUser, availableSpells, spellbookSpells]);

  useEffect(() => {
    let isMounted = true;

    const fetchCustomSpells = async () => {
      if (currentUser && isMounted) {
        try {
          const newCustomSpells = await refreshCustomSpells(currentUser.uid);

          // Only update if the custom spells have actually changed
          // This prevents unnecessary re-renders
          const currentIds = Object.keys(customSpells).sort().join(',');
          const newIds = Object.keys(newCustomSpells).sort().join(',');

          if (currentIds !== newIds) {
            setCustomSpells(newCustomSpells);
          } else {
            // Check if any spell properties have changed
            let hasChanges = false;
            Object.keys(newCustomSpells).some(id => {
              if (JSON.stringify(newCustomSpells[id]) !== JSON.stringify(customSpells[id])) {
                hasChanges = true;
                return true;
              }
              return false;
            });

            if (hasChanges) {
              setCustomSpells(newCustomSpells);
            }
          }
        } catch (error) {
          console.error('Error fetching custom spells:', error);
        }
      }
    };

    // Initial fetch when component mounts
    fetchCustomSpells();

    // We're removing the interval to prevent duplicate database reads
    // The SpellList component will handle the periodic refresh

    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  // Add a function to manually refresh the spellbook contents
  const refreshSpellbookContents = useCallback(async () => {
    if (!currentSpellbook) return;

    try {
      logger(`Starting manual refresh for ${currentSpellbook}`);
      const spellIds = spellbooks[currentSpellbook] || [];

      // Log the spellIds to help with debugging
      logger(`Spellbook ${currentSpellbook} contains ${spellIds.length} spells:`, spellIds);

      // Get the spells from the IDs
      const spells = await getSpellsByIds(currentUser?.uid, spellIds);

      // Log the retrieved spells to help with debugging
      logger(`Retrieved ${spells.length} spells for ${currentSpellbook}:`,
        spells.map(s => ({ id: s.id, name: s.name })));

      // Only update if we actually got spells back and the number matches
      if (spells.length > 0) {
        setSpellbookSpells(spells);
        logger(`Successfully refreshed spellbook contents for ${currentSpellbook}`);
      } else if (spellIds.length > 0 && spells.length === 0) {
        // This is a potential error condition - we had IDs but couldn't retrieve any spells
        console.warn(`Warning: Could not retrieve any spells for ${spellIds.length} spell IDs in ${currentSpellbook}`);
      }
    } catch (error) {
      console.error('Error manually refreshing spellbook contents:', error);
    }
  }, [currentSpellbook, spellbooks, currentUser]);

  // Update spellbookSpells when customSpells or spellbooks change
  useEffect(() => {
    const loadSpellbookSpells = async () => {
      if (!currentSpellbook) return;

      // Only refresh custom spells if we haven't done so recently
      // This avoids excessive API calls and re-renders
      const spellIds = spellbooks[currentSpellbook] || [];

      try {
        const spells = await getSpellsByIds(currentUser?.uid, spellIds);

        // Only update state if the spells have actually changed
        // This prevents unnecessary re-renders
        if (JSON.stringify(spells.map(s => s.id)) !== JSON.stringify(spellbookSpells.map(s => s.id))) {
          setSpellbookSpells(spells);
        }
      } catch (error) {
        console.error('Error loading spellbook spells:', error);
      }
    };

    loadSpellbookSpells();
  }, [currentSpellbook, spellbooks, currentUser]);

  const getSelectedSpells = () => {
    const selectedSpellNames = Object.keys(selectedSpells).filter(name => selectedSpells[name]);
    return spellbookSpells.filter(spell => selectedSpellNames.includes(spell.name));
  };

  const handleResetColumns = useCallback((resetFunc) => {
    setResetColumnsFunc(() => resetFunc);
  }, []);

  // Expose the refreshSpellbookContents function via ref
  useImperativeHandle(ref, () => ({
    refreshSpellbookContents
  }));

  return (
    <div className="spell-list">
      <HowToUseBox pageType="spellBook" />
      {showSpellSlotsEverywhere && spellSlotsByBook && currentSpellbook && (
        <>
          <SpellResourceManager
            spellSlots={spellSlotsByBook[currentSpellbook] || Array(9).fill().map(() => ({ max: 0, used: 0 }))}
            setSpellSlots={(newSlots) => updateSpellSlots(currentSpellbook, newSlots)}
            spellPoints={spellPointsByBook[currentSpellbook] || { total: 0, used: 0, highLevelUses: {} }}
            setSpellPoints={(newPoints) => updateSpellPoints(currentSpellbook, newPoints)}
            spellbookName={currentSpellbook}
            showSpellSlotsEverywhere={showSpellSlotsEverywhere}
            setShowSpellSlotsEverywhere={setShowSpellSlotsEverywhere}
            characterLevel={characterLevelsByBook?.[currentSpellbook] || 0}
            setCharacterLevel={handleSetCharacterLevel}
            highLevelSpellUses={highLevelSpellUses}
            setHighLevelSpellUses={setHighLevelSpellUses}
            allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
            setAllowMultipleHighLevelCasts={setAllowMultipleHighLevelCasts}
            useSpellPoints={useSpellPoints}
            setUseSpellPoints={setUseSpellPoints}
          />
          <ActiveSpellbookHeader
            currentSpellbook={currentSpellbook}
            setCurrentSpellbook={setCurrentSpellbook}
            spellbooks={spellbooks}
            useSpellPoints={useSpellPoints}
            setUseSpellPoints={setUseSpellPoints}
          />
        </>
      )}

      {!currentUser && (
        <div className="login-warning">
          <p>You are not logged in. Your spells in "My Spellbook" will not be saved when you leave the page. Please log in to save your spells and create multiple spellbooks.</p>
        </div>
      )}

      <div className="spellbook-controls-container">
        <div className="spellbook-controls-box">
          <h2>Create New Spellbook:</h2>
          <div className="spellbook-input-group">
            <input
              type="text"
              value={newSpellbookName}
              onChange={(e) => setNewSpellbookName(e.target.value)}
              placeholder="Enter spellbook name"
              className="spellbook-name-input"
              maxLength={25}
            />
            <button
              onClick={handleCreateSpellbook}
              disabled={!currentUser}
              className="create-spellbook-button"
            >
              Create
            </button>
          </div>
        </div>

        <div className="spellbook-controls-box">
          <h2>Generate Spellbook:</h2>
          <button
            onClick={() => setShowGenerator(true)}
            disabled={!currentUser}
            className="generator-button"
          >
            Open Spellbook Generator
          </button>
        </div>
      </div>

      <div className="spellbook-management">
        <div className="spellbook-list">
          {spellbookOrder.map((book) => (
            <div
              key={book}
              className={`spellbook-item ${currentSpellbook === book ? 'active' : ''}`}
              draggable="true"
              data-book={book}
              onDragStart={(e) => handleDragStart(e, book)}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <button
                className={`spellbook-select ${currentSpellbook === book ? 'active' : ''}`}
                onClick={() => handleSpellbookClick(book)}
              >
                <span className={`book-icon ${currentSpellbook === book ? 'active' : ''}`}>
                  <BookIcon />
                </span>
                {book}
                <span className="spell-count">({spellbooks[book] ? spellbooks[book].length : 0})</span>
              </button>
              <button
                className="spellbook-remove"
                onClick={() => handleRemoveSpellbook(book)}
                title={`Delete ${book}`}
              >
                <TrashcanIcon title="" aria-hidden="true" />
              </button>
            </div>
          ))}
        </div>
      </div>

      <SpellFilters
        filters={filters}
        setFilters={setFilters}
        filterOptions={{
          classes: uniqueClasses,
          levels: uniqueLevels,
          schools: uniqueSchools,
          components: componentOptions,
          sources: uniqueSources
        }}
      />

      <div className="checkbox-container">
      </div>

      <AdvancedFilterTags
        filters={filters}
        setFilters={setFilters}
      />

      <SpellTableControls
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        totalSpellCount={spellbookSpells.length}
        currentSpellCount={searchedSpells.length}
        onShowCustomSpellForm={() => setShowCustomSpellForm(true)}
        onResetColumns={resetColumnsFunc}
        convertToMetric={convertToMetric}
        onToggleMetric={() => setConvertToMetric(!convertToMetric)}
        showShortDescriptions={showShortDescriptions}
        onToggleShortDescriptions={() => setShowShortDescriptions(!showShortDescriptions)}
        showSchoolTags={showSchoolTags}
        onToggleSchoolTags={handleToggleSchoolTags}
        filters={filters}
        setFilters={setFilters}
        spells={spellbookSpells}
        spellbooks={spellbooks}
      />

      <SpellTable
        spells={Array.isArray(searchedSpells) ? searchedSpells : []}
        selectedSpells={selectedSpells}
        toggleItem={toggleItem}
        selectAll={selectAll}
        areAllSelected={areAllSelected}
        showShortDescriptions={showShortDescriptions}
        convertToMetric={convertToMetric}
        showSchoolTags={showSchoolTags}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        visibleColumns={visibleColumns}
        renderIcons={renderIcons}
        emptyMessage="No spells in this spellbook."
        className="spellbook-table"
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        pagination={pagination}
        setPagination={setPagination}
        tabName="spellBook"
        totalSpellCount={spellbookSpells.length}
        onResetColumns={handleResetColumns}
      />

      {isAnySpellSelected && (
        <div className="floating-buttons">
          <button
            className="floating-close-button"
            onClick={() => {
              clearSelection();
              setSelectedSpells({});
            }}
            title="Clear Selection"
          >
            ×
          </button>
          <button
            onClick={handlePrepareSelectedSpells}
            className="floating-button prepare-button"
          >
            Prepare Selected Spells
          </button>
          <button
            onClick={handleRemoveSelectedSpells}
            className="floating-button remove-button"
          >
            Remove Selected from Spellbook
          </button>
          <button
            onClick={() => setShowCardGenerator(true)}
            className="floating-button generate-cards-button"
          >
            Generate Spell Cards
          </button>
        </div>
      )}

      <ConfirmationPopup
        isOpen={isConfirmationOpen}
        message={`Are you sure you want to delete the spellbook "${spellbookToDelete}"? This action cannot be undone.`}
        onConfirm={confirmRemoveSpellbook}
        onCancel={cancelRemoveSpellbook}
      />

      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <BackToTopIcon />
        </button>
      )}

      {selectedSpell && (
        <div className="spellinfo-overlay">
          <SpellInfoBox
            spell={selectedSpell}
            onClose={() => setSelectedSpell(null)}
            convertToMetric={convertToMetric}
            onToggleMetric={() => setConvertToMetric(!convertToMetric)}
          />
        </div>
      )}

      {showGenerator && (
        <>
          <div className="spellbook-overlay" onClick={() => setShowGenerator(false)}></div>
          <div className="generator-modal">
            <SpellbookGenerator
              onGenerate={handleGenerateSpellbook}
              onClose={() => setShowGenerator(false)}
              spellbooks={spellbooks}
            />
          </div>
        </>
      )}

      {showCardGenerator && (
        <>
          <div className="spellcard-overlay"></div>
          <SpellCardGenerator
            spells={getSelectedSpells()}
            onClose={() => setShowCardGenerator(false)}
          />
        </>
      )}

      {showCustomSpellForm && (
        <CustomSpellForm
          onSpellAdded={(newSpell) => {
            // Add your custom spell handling logic here
          }}
          onClose={() => setShowCustomSpellForm(false)}
        />
      )}
    </div>
  );
});

export default SpellBook;
