import React from 'react';
import '../styles/pages/HowToPage.css';
import { Link } from 'react-router-dom';
import PageHead from '../components/PageHead';

const HowToPage = () => {
  return (
    <div className="how-to-container">
      <PageHead
        title="D&D 5e Spell Guides & Tutorials | How To Use Spells in D&D"
        description="Learn how spells work in D&D 5e with our comprehensive guide. Understand spell slots, prepared spells, spellbooks, cantrips and more for all spellcasting classes."
        keywords={['D&D spells guide', 'how spells work in D&D', '5e spell slots explained', 'prepared spells D&D', 'wizard spellbooks', 'cantrips guide', 'D&D 5e spellcasting']}
        canonical="https://tablemancer.com/how-to"
      />

      <section className="how-to-header">
        <h1>How To Use TableMancer & D&D 5e Spells</h1>
        <div className="divider">
          <span className="divider-dice d4"></span>
          <span className="divider-dice d6"></span>
          <span className="divider-dice d8"></span>
          <span className="divider-dice d10"></span>
          <span className="divider-dice d12"></span>
          <span className="divider-dice d20"></span>
        </div>
        <p className="header-intro">
          Welcome to our comprehensive guides for TableMancer and D&D 5e spellcasting. Whether you're a newcomer to D&D or 
          an experienced player looking to optimize your spellcasting, our guides will help you master the arcane arts.
        </p>
      </section>

      <section className="guide-section" id="how-spells-work">
        <h2>How Spells Work in D&D 5e: A Beginner's Guide</h2>
        
        <div className="guide-intro">
          <p>
            If you're playing a spellcaster for the first time, managing spells may seem daunting. 
            This guide breaks down everything you need to know about D&D 5e spellcasting in simple terms.
          </p>
        </div>
        
        <div className="guide-content">
          <h3>Understanding D&D 5e Spells</h3>
          <p>
            Dungeons & Dragons 5th Edition features hundreds of spells, each with unique properties and effects. 
            Most spells are found in the Player's Handbook (PHB) or the Systems Reference Document (SRD), with additional spells 
            available in expansion books like Xanathar's Guide to Everything (XGE) and Tasha's Cauldron of Everything (TCE).
          </p>
          
          <h3>Spellcasting Classes</h3>
          <p>
            Different classes have access to different spell lists. Each spell description indicates which classes can cast it, 
            and most spells are available to multiple classes. With the exception of Wizards, all spellcasting classes automatically 
            gain access to their entire class spell list as they level up, provided they meet the level requirement for casting that spell.
          </p>
          <p>
            For example, a level 5 Druid can cast any Druid spell of level 3 or lower. The Player's Handbook contains tables for each 
            class showing exactly what level of spells a character can cast at each character level.
          </p>
          
          <h3>Wizard Spellbooks</h3>
          <p>
            Unlike other spellcasters, Wizards don't automatically gain access to all Wizard spells. Instead, they must collect spells and 
            record them in their spellbook. Wizards can acquire new spells by:
          </p>
          <ul>
            <li>Finding spell scrolls or spellbooks as treasure or loot</li>
            <li>Purchasing scrolls from magic shops or other wizards</li>
            <li>Learning new spells when they level up (2 new spells per level)</li>
          </ul>
          <p>
            While this might seem like a disadvantage, Wizards compensate by having access to the largest and arguably most powerful 
            spell list in the game. A Wizard's spellbook becomes a valuable resource that grows throughout their adventures.
          </p>
          
          <h3>Prepared Spells System</h3>
          <p>
            Once a spellcaster knows their spells, they must prepare which ones they'll be able to cast each day. After completing a long rest, 
            spellcasters select a subset of spells from their known or available spells to prepare for the day.
          </p>
          <p>
            The number of spells you can prepare depends on your class, level, and spellcasting ability modifier (typically Intelligence for Wizards, 
            Wisdom for Clerics and Druids, and Charisma for Bards, Sorcerers, and Warlocks). The PHB specifies these numbers for each class, and 
            certain subclasses or feats may modify them.
          </p>
          <p>
            <strong>Important:</strong> If a spell isn't prepared, you cannot cast it until you prepare it after your next long rest.
          </p>
          
          <h3>Spell Slots Explained</h3>
          <p>
            Spell slots represent the magical energy available to a spellcaster for casting their prepared spells. After a long rest, 
            casters begin with a full complement of spell slots as specified in their class table in the PHB.
          </p>
          <p>
            One spell slot can be used to cast one spell of that slot's level (or lower, if casting a lower-level spell in a higher-level slot). 
            For example, a level 7 Wizard typically has:
          </p>
          <ul>
            <li>4 × 1st-level spell slots</li>
            <li>3 × 2nd-level spell slots</li>
            <li>3 × 3rd-level spell slots</li>
            <li>1 × 4th-level spell slot</li>
          </ul>
          <p>
            This means they can cast four 1st-level spells, three 2nd-level spells, three 3rd-level spells, and one 4th-level spell per day. 
            Once a spell slot is used, it remains expended until the caster completes a long rest (with some exceptions for certain classes 
            like Warlocks who recover spell slots on short rests).
          </p>
          
          <h3>Cantrips: Unlimited Magic</h3>
          <p>
            Cantrips are level 0 spells that don't consume spell slots. Each spellcasting class has access to a limited number of cantrips 
            (specified in the PHB). Key features of cantrips include:
          </p>
          <ul>
            <li>They don't need to be prepared</li>
            <li>They can be cast unlimited times per day</li>
            <li>They're generally less powerful than leveled spells</li>
            <li>Most cantrips scale in power as you gain levels</li>
          </ul>
          <p>
            Cantrips provide spellcasters with reliable magical options even when they've used all their spell slots. Common examples 
            include the Wizard's Fire Bolt, the Cleric's Sacred Flame, and the Warlock's Eldritch Blast.
          </p>
          
          <h3>Managing Spells with TableMancer</h3>
          <p>
            TableMancer simplifies spell management in D&D 5e with these features:
          </p>
          <ul>
            <li>Browse the complete <Link to="/spells">D&D 5e spell list</Link> with advanced filtering</li>
            <li>Create <Link to="/spellbooks">custom spellbooks</Link> for different characters</li>
            <li>Track <Link to="/prepared-spells">prepared spells and spell slots</Link> during gameplay</li>
            <li>Generate printable spell cards for your character</li>
          </ul>
        </div>
      </section>

      {/* Comprehensive TableMancer Guide */}
      <div className="guide-section">
        <h2>Complete TableMancer User Guide</h2>
        
        <div className="guide-intro">
          <p>This guide will walk you through all of TableMancer's features, from navigating the interface to advanced spell management techniques.</p>
        </div>
        
        <div className="guide-content">
          <h3>Getting Started with TableMancer</h3>
          <p>TableMancer is designed to make spell management easy and efficient for D&D 5e players. Here's how to get started:</p>
          
          <h4>Creating an Account</h4>
          <p>While TableMancer can be used without an account, creating one allows you to save your spellbooks, prepared spells, and custom content across sessions and devices:</p>
          <ul>
            <li>Click the "Login" button in the top right corner</li>
            <li>Choose "Need an account? Sign Up" on the login page</li>
            <li>Enter your email address and create a username and password</li>
            <li>Verify your email address by clicking the link sent to your inbox</li>
          </ul>
          
          <h4>Navigating the Interface</h4>
          <p>The TableMancer spell management system has three main sections, accessible via the tab bar:</p>
          <ul>
            <li><strong>Spell List</strong>: Browse, search, and filter all D&D 5e spells</li>
            <li><strong>Spellbooks</strong>: Create and manage your character's spellbooks</li>
            <li><strong>Prepared Spells</strong>: Track which spells are prepared and manage spell slots</li>
          </ul>
          
          <h3>Using the Spell List</h3>
          <p>The Spell List is your comprehensive reference for all D&D 5e spells.</p>
          
          <h4>Finding Spells</h4>
          <p>There are several ways to find the spells you're looking for:</p>
          <ul>
            <li><strong>Search Box</strong>: Type a spell name or keyword to find matching spells</li>
            <li><strong>Filters</strong>: Use the filter panel to narrow results by level, school, class, casting time, and more</li>
            <li><strong>Sorting</strong>: Click column headers to sort by name, level, school, etc.</li>
            <li><strong>Pagination</strong>: Navigate through multiple pages of results using the controls at the bottom</li>
          </ul>
          
          <h4>Viewing Spell Details</h4>
          <p>To view the complete details of a spell:</p>
          <ul>
            <li>Click on the spell's name for the full description page or the info icon in its row to quickly see the spell's description</li>
            <li>Click inside the spell's row to expand it and see a short description of the spell</li>
            <li>Use the "Expand All" button to show short descriptions for all visible spells at once</li>
            <li>Open the full description page of a spell to add notes.</li>
          </ul>
          
          <h4>Adding Spells to Spellbooks</h4>
          <p>To add spells to your spellbooks:</p>
          <ul>
            <li>Click the Add to Spellbook icon next to a spell and select the spellbook you want to add it to</li>
            <li>Alternatively, use the checkboxes to select multiple spells, then click "Add Selected to Spellbook"</li>
            <li>The dialog will notify you if any selections are duplicates already in your spellbook</li>
          </ul>
          
          <h4>Creating Custom Spells</h4>
          <p>TableMancer allows you to create your own custom spells for homebrew content:</p>
          <ul>
            <li>Click the "Create Spell" button at the top of the Spell List</li>
            <li>Fill in the spell details in the form that appears and click "Save" to add your custom spell to your collection</li>
            <li>Custom spells can be used just like official spells but are only available to your account</li>
            <li>By opening the custom spell's full descriptionpage, you can edit or delete it</li>
          </ul>
          
          <h3>Managing Spellbooks</h3>
          <p>The Spellbooks section allows you to organize spells for different characters or scenarios.</p>
          
          <h4>Creating Spellbooks</h4>
          <p>To create a new spellbook:</p>
          <ul>
            <li>On the Spellbooks tab, click the "Create Spellbook" button</li>
            <li>Enter a name for your spellbook (e.g., "Gandalf's Grimoire" or "Level 5 Wizard")</li>
            <li>Your new spellbook will appear as a tab at the top of the Spellbooks page</li>
            <li>Add spells to the spellbook as described above</li>
          </ul>
          
          <h4>Using the Spellbook Generator</h4>
          <p>For a quick start, you can generate a themed spellbook:</p>
          <ul>
            <li>Click "Generate Spellbook"</li>
            <li>Select a class, level, and optional subclass</li>
            <li>Click "Generate" to create a spellbook with an appropriate selection of spells</li>
            <li>The number of spells generated by default is according to the official rules. To change this number, check the Manual distribution checkbox and enter the number of spells you want to generate per spell level.</li>
            <li>You can customize this generated spellbook afterward by adding or removing spells</li>
          </ul>
          
          <h4>Managing Spellbook Content</h4>
          <p>Within each spellbook, you can:</p>
          <ul>
            <li><strong>Remove spells</strong>: Click the red X icon next to a spell or use checkboxes to remove multiple spells</li>
            <li><strong>remove spellbooks</strong>: Click the trashcan next to the tab of the spellbook you want to delete. This action cannot be undone, so be certain before confirming</li>
            <li><strong>Filter and search</strong>: Use the search box and filters just like in the main Spell List</li>
            <li><strong>Prepare spells</strong>: Click the pentagram icon next to a spell to mark a spell as prepared (it will appear in the Prepared Spells section)</li>
          </ul>
          
          <h3>Working with Prepared Spells</h3>
          <p>The Prepared Spells section helps you track which spells you have prepared for the adventuring day and manage spell slots.</p>
          
          <h4>Preparing and Unpreparing Spells</h4>
          <ul>
            <li>From the Spellbooks page, click the pentagram icon next to a spell to prepare or unprepare it</li>
            <li>From the Prepared Spells page, click the pentagram icon to unprepare a spell</li>
            <li>The number of spells you've prepared is displayed at the top of the Prepared Spells page</li>
          </ul>
          
          <h4>Managing Spell Slots</h4>
          <p>The Spell Slot Tracker at the top of the Prepared Spells page allows you to:</p>
          <ul>
            <li>Fill in the number of spell slots you have available for each spell level</li>
            <li>Mark slots as used when you cast spells (manually or by clicking the magic wand icon next to the spell name)</li>
            <li>Reset all spell slots with the "Reset All" button (after a long rest)</li>
            <li>Switch between spell slot tracking and spell points tracking (for variant rules)</li>
            <li>Click the pin icon to show the spell slot manager also on the Spell List and Spellbooks pages</li>
          </ul>
          
          <h4>Casting Spells</h4>
          <p>To track spell usage during play:</p>
          <ul>
            <li>Click the magic wand icon next to a prepared spell</li>
            <li>A spell slot of the appropriate level will be automatically marked as used</li>
            <li>If no slot of the required level is available, you'll be prompted to upcast using a higher-level slot</li>
            <li>Cantrips don't use spell slots and don't have the magic wand icon</li>
          </ul>
          
          <h4>Creating and Using Presets</h4>
          <p>Spell presets let you save different configurations of prepared spells:</p>
          <ul>
            <li>Prepare the spells you want to include in the preset</li>
            <li>Click "Save Preset" in the Presets section</li>
            <li>Enter a name for the preset (e.g., "Forest survival" or "City Adventures")</li>
            <li>To load a preset, select it from the dropdown and click "Load"</li>
          </ul>
          
          <h3>Creating Printable Spell Cards</h3>
          <p>TableMancer can generate printable spell cards for easy reference during games.</p>
          
          <h4>Generating Spell Cards</h4>
          <p>To create printable spell cards:</p>
          <ul>
            <li>On one of the pages with the spell table, select which spells to include (from a specific spellbook, prepared spells, or manually selected)</li>
            <li>Pick a layout. 'Full (detailed)' for complete spell descriptions, 'Cards' for spell cards that can be printed and cut out, and 'Quick Reference Sheet' for an information dense page with short descriptions</li>
            <li>For 'Cards' layout, pick a card size (small, medium, or large)</li>
            <li>Adjust other settings to your liking, and click "Generate PDF" to instantly receive your printable spell cards</li>
          <p>Note: Because the spell cards are dynamically generated, it's possible that some combinations of settings might not look optimal. We continue to try to improve the Spell Card Generator</p>
          </ul>
         
          <h3>Tips</h3>
          <ul>
            <li>If you prefer Metric units, you can click the Convert to Meric button above the spell table or in the spell description page.</li>
            <li>Spellbooks are not only for Wizards. Any spellcasting class can use spellbooks to manage their spells. Spellbooks can also be used as a personal collection of spells you're interested in (a wish list, for example).</li>
            <li>For the maniacs who prefer it over physical dice, we also have a digital dice roller. Enjoy!</li>
          </ul>
        
          <h3>Troubleshooting</h3>
          <p>If you encounter any issues while using TableMancer:</p>
          <ul>
            <li>Try refreshing your browser page</li>
            <li>Clear your browser cache and cookies</li>
            <li>Make sure you're using a modern browser (Chrome, Firefox, Safari, or Edge)</li>
            <li>Check our FAQ page for answers to common questions</li>
            <li>Contact us through the Contact page if problems persist</li>
          </ul>
        </div>
      </div>

      {/* More Guides Coming Soon Section */}
      <section className="coming-soon-section">
        <div className="coming-soon-content">
          <h2>More Guides Coming Soon</h2>
          <span className="coming-soon-badge">Coming Soon</span>
          
          <p className="coming-soon-message">
            We're currently working on additional guides to help you make the most of TableMancer and master D&D 5e spellcasting:
          </p>
          <ul className="upcoming-guides">
            <li>Character Building for Spellcasters</li>
            <li>Advanced Spellcasting Tactics</li>
            <li>Homebrewing Balanced Spells</li>
            <li>TableMancer Pro Tips</li>
          </ul>
          
          <div className="divider">
            <span className="divider-dice d4"></span>
            <span className="divider-dice d6"></span>
            <span className="divider-dice d8"></span>
            <span className="divider-dice d10"></span>
            <span className="divider-dice d12"></span>
            <span className="divider-dice d20"></span>
          </div>
          
          <div className="help-message">
            Have questions? Visit our <Link to="/faq" className="help-link">FAQ page</Link> or <Link to="/contact" className="help-link">contact us</Link> for assistance.
          </div>
          
          <div className="action-buttons">
            <Link to="/spells" className="action-button primary">Explore Spells</Link>
            <Link to="/spellbooks" className="action-button secondary">Create a Spellbook</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowToPage; 