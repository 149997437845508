// Add helper function to get source string
const getSourceString = (spell) => {
  // Check if it's a custom spell (created by user)
  if (spell.id && spell.id.startsWith('custom-')) {
    return 'custom';
  }

  if (!spell.sources) {
    // If no sources field exists, try to determine from other data
    if (spell.tags && spell.tags.includes('custom')) {
      return 'custom';
    }
    return 'PHB'; // Default to PHB if no other source info available
  }
  
  // Format sources more compactly: "PHB24:p239, XGE:p50"
  return spell.sources.map(source => {
    if (typeof source === 'string') return source;
    // Replace PHB2024 with PHB24
    const book = source.book === 'PHB2024' ? 'PHB24' : source.book;
    return `${book}:p${source.page}`;
  }).join(', ');
};

export const generateQuickLookupPDF = (doc, {
  spells,
  layout,
  margin = 10,
  convertIfNeeded,
  renderHeader
}) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  
  // Add header and get starting Y position
  let currentY = renderHeader(doc, pageWidth, margin);

  // Set up dimensions
  const tableWidth = pageWidth - (2 * margin);
  const levelWidth = tableWidth * 0.03;
  const nameWidth = tableWidth * 0.17;
  const propsWidth = tableWidth * 0.80;

  const rowHeight = layout.fontSize * 0.4;
  const cellPadding = 1;

  let currentPage = 1;

  spells.forEach((spell) => {
    // First row: Level, Name, and Properties
    // Level - ensure consistent font size
    doc.setFont("helvetica", "normal");
    doc.setFontSize(layout.fontSize - 1);
    doc.text(spell.level === 0 ? "0" : spell.level.toString(), margin + cellPadding, currentY + rowHeight/2);
    
    // Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(layout.fontSize - 1);
    
    // Draw spell name
    doc.text(spell.name, margin + levelWidth + cellPadding, currentY + rowHeight/2);
    
    // If spell is ritual, draw circled R after the name
    if (spell.ritual) {
      const circleRadius = (layout.fontSize - 1) * 0.15;
      const nameWidth = doc.getStringUnitWidth(spell.name) * (layout.fontSize - 1) / doc.internal.scaleFactor;
      const circleX = margin + levelWidth + cellPadding + nameWidth + circleRadius + 1;
      const circleY = currentY + rowHeight/2 - circleRadius/1.2;
      
      // Draw circle
      doc.setDrawColor(0);
      doc.circle(circleX, circleY, circleRadius);
      
      // Draw R - adjusted positioning
      doc.setFont("helvetica", "normal");
      doc.setFontSize(layout.fontSize - 3);
      doc.text("R", circleX - circleRadius/1.5, circleY + circleRadius/1.5);

      // If spell also requires concentration, draw C right after R
      if (spell.concentration) {
        const cCircleX = circleX + circleRadius * 2.5;
        doc.circle(cCircleX, circleY, circleRadius);
        doc.text("C", cCircleX - circleRadius/1.5, circleY + circleRadius/1.5);
      }
    } else if (spell.concentration) {
      // If only concentration (no ritual), draw C in the same position as R would be
      const circleRadius = (layout.fontSize - 1) * 0.15;
      const nameWidth = doc.getStringUnitWidth(spell.name) * (layout.fontSize - 1) / doc.internal.scaleFactor;
      const circleX = margin + levelWidth + cellPadding + nameWidth + circleRadius + 1;
      const circleY = currentY + rowHeight/2 - circleRadius/1.2;
      
      // Draw circle
      doc.setDrawColor(0);
      doc.circle(circleX, circleY, circleRadius);
      
      // Draw C - adjusted positioning
      doc.setFont("helvetica", "normal");
      doc.setFontSize(layout.fontSize - 3);
      doc.text("C", circleX - circleRadius/1.5, circleY + circleRadius/1.5);
    }
    
    // Set italic style for properties
    doc.setFont("helvetica", "italic");
    doc.setFontSize(layout.fontSize - 1);

    // School
    doc.text(
      (spell.school || "").slice(0, 3).toUpperCase(), 
      margin + levelWidth + nameWidth + (tableWidth * 0.12), 
      currentY + rowHeight/2
    );
    
    // Components
    doc.text(
      Object.entries(spell.components || {})
        .filter(([key, value]) => value && ['verbal', 'somatic', 'material'].includes(key))
        .map(([key]) => key[0].toUpperCase())
        .join(','), 
      margin + levelWidth + nameWidth + (tableWidth * 0.19), 
      currentY + rowHeight/2
    );

    // Casting Time
    doc.text(
      (spell.casting_time || "").toString().split(' which')[0],
      margin + levelWidth + nameWidth + (tableWidth * 0.26),
      currentY + rowHeight/2
    );

    // Range
    doc.text(
      convertIfNeeded((spell.range || "").toString()),
      margin + levelWidth + nameWidth + (tableWidth * 0.39),
      currentY + rowHeight/2
    );

    // Duration
    doc.text(
      (spell.duration || "").toString().replace(/^Concentration, /, ''),
      margin + levelWidth + nameWidth + (tableWidth * 0.59),
      currentY + rowHeight/2
    );

    // Reset to normal style for description
    doc.setFont("helvetica", "normal");

    // Second row: Description and Source
    const description = spell.shortDescription || spell.description.split('.')[0];
    const sourceString = getSourceString(spell);
    
    // Calculate the width available for description (leaving space for source)
    const sourceWidth = doc.getStringUnitWidth(sourceString) * (layout.fontSize - 1) / doc.internal.scaleFactor;
    // Increase padding between description and source for better readability
    const descriptionWidth = tableWidth - (2 * cellPadding) - sourceWidth - 15; // 15mm padding
    
    const descLines = doc.splitTextToSize(description, descriptionWidth);
    
    // Draw description (left-aligned)
    descLines.forEach((line, i) => {
      doc.text(line, margin + cellPadding, currentY + rowHeight * 1.4 + (i * rowHeight * 0.8));
    });

    // Draw source (right-aligned) with smaller font
    doc.setFont("helvetica", "italic");
    doc.setFontSize(layout.fontSize - 2); // Slightly smaller font for source
    doc.text(
      sourceString,
      pageWidth - margin - cellPadding,
      currentY + rowHeight * 1.4,
      { align: 'right' }
    );

    // Draw separator line
    doc.setDrawColor(200);
    doc.setLineWidth(0.1);
    doc.line(
      margin,
      currentY + (rowHeight * (descLines.length + 0.8)),
      pageWidth - margin,
      currentY + (rowHeight * (descLines.length + 0.8))
    );
    doc.setDrawColor(0);
    
    // Move to next spell with reduced spacing
    currentY += (rowHeight * (descLines.length + 1.2)) + (rowHeight * 0.1);

    if (currentY + (rowHeight * 2) > pageHeight - margin) {
      doc.addPage();
      currentPage++;
      currentY = margin;
    }
  });

  return currentPage;
}; 