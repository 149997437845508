import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import '../styles/pages/HomePage.css';
import { 
  BookIcon, 
  AllSpellsIcon, 
  PrepareSpellIcon, 
  CreateSpellIcon, 
  SearchIcon,
  MaterialIcon,
  SpellLibraryIcon,
  SpellbooksIcon,
  NotesIcon,
  PrintableIcon,
  SpellTrackingIcon
} from '../data/Icons';

function HomePage() {
  // Get current user
  const { currentUser } = useAuth();
  
  // State for testimonial carousel
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  
  // Testimonial data
  const testimonials = [
    { name: "Bigby", quote: "I give this site a big thumbs up. It's like having an extra hand doing all the heavy lifting for me." },
    { name: "Tasha", quote: "I bubbled over with joy when using TableMancer. I couldn't stop smiling!" },
    { name: "Evard", quote: "At first, I wasn't convinced, but now? I'm completely entangled in its brilliance." },
    { name: "Otiluke", quote: "A solid, well-rounded, container of knowledge and possibilities!." },
    { name: "Mordenkainen", quote: "Magnificent! What a luxury to have this at my disposal." },
    { name: "G. the Grey", quote: "You shall not pass... up the opportunity to use this spell manager!" }
  ];
  
  // Auto-rotate testimonials
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 6000);
    
    return () => clearInterval(interval);
  }, [testimonials.length]);
  
  // Handle manual navigation
  const goToTestimonial = (index) => {
    setActiveTestimonial(index);
  };
  
  return (
    <div className="home-page">
      <header className="hero">
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="title-glow">TableMancer</h1>
            <p>A D&D Spell Manager</p>
            <p className="hero-subtext">Conjure, organize, and master your magical arsenal</p>
            <div className="hero-buttons">
              <Link to="/spells" className="button primary">
                Explore Spells
              </Link>
              <Link to={currentUser ? "/spells" : "/login"} className="button secondary">
                {currentUser ? "My Spells" : "Get Started"}
              </Link>
            </div>
          </div>
          
          {/* Testimonial Carousel */}
          <div className="testimonial-carousel">
            <div className="testimonial-slide">
              <div className="testimonial-quote">
                <p>"{testimonials[activeTestimonial].quote}"</p>
              </div>
              <div className="testimonial-attribution">
                <span className="testimonial-name">— {testimonials[activeTestimonial].name}</span>
              </div>
            </div>
            
            <div className="testimonial-dots">
              {testimonials.map((_, index) => (
                <button 
                  key={index} 
                  className={`testimonial-dot ${index === activeTestimonial ? 'active' : ''}`} 
                  onClick={() => goToTestimonial(index)}
                  aria-label={`Testimonial ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </header>

      <div className="home-content">
        <section className="home-intro">
          <div className="intro-heading">
            <div className="divider-line"></div>
            <h2>Master Your Arcane Arts</h2>
            <div className="divider-line"></div>
          </div>
          <p>Streamline your D&D 5e spellcasting experience with our comprehensive toolkit designed for players and DMs alike.</p>
        </section>

        <section className="feature-cards">
          <div className="feature-card">
            <div className="feature-card-icon">
              <SpellLibraryIcon />
            </div>
            <h3>Spell Library</h3>
            <p>Access hundreds of spells from official D&D 5e sourcebooks with detailed information at your fingertips.</p>
            <Link to="/spells" className="card-link">Browse Spells</Link>
          </div>
          
          <div className="feature-card">
            <div className="feature-card-icon">
              <SpellbooksIcon />
            </div>
            <h3>Spellbooks</h3>
            <p>Create and manage multiple spellbooks for different characters, keeping all your magical knowledge organized.</p>
            <Link to="/spellbooks" className="card-link">Manage Spellbooks</Link>
          </div>
          
          <div className="feature-card">
            <div className="feature-card-icon">
              <AllSpellsIcon />
            </div>
            <h3>Spell Preparation</h3>
            <p>Efficiently prepare daily spells and track spell slots during gameplay with our intuitive system.</p>
            <Link to="/prepared-spells" className="card-link">Prepare Spells</Link>
          </div>
          
          <div className="feature-card">
            <div className="feature-card-icon">
              <CreateSpellIcon />
            </div>
            <h3>Custom Spells</h3>
            <p>Create your own custom spells for homebrew content or unique character abilities.</p>
            <Link to="/spells" className="card-link">Create Spells</Link>
          </div>
        </section>

        <section className="spell-showcase">
          <div className="showcase-content">
            <div className="showcase-text">
              <h2>Powerful Tools for Spellcasters</h2>
              <ul className="showcase-features">
                <li>
                  <span className="feature-icon"><SearchIcon /></span>
                  <span>Advanced filtering and search capabilities</span>
                </li>
                <li>
                  <span className="feature-icon"><NotesIcon /></span>
                  <span>Add personal notes to any spell for quick reference</span>
                </li>
                <li>
                  <span className="feature-icon"><SpellbooksIcon /></span>
                  <span>Multiple spellbooks in one place</span>
                </li>
                <li>
                  <span className="feature-icon"><SpellTrackingIcon /></span>
                  <span>Spell slot tracking and management</span>
                </li>
                <li>
                  <span className="feature-icon"><PrintableIcon /></span>
                  <span>Turn spells into printable spellcards and sheets</span>
                </li>
              </ul>
              <Link to="/spells" className="button showcase-button">Discover More</Link>
            </div>
            <div className="showcase-image">
              <img src={require('../assets/images/cardstack.webp')} alt="Spell Card Stack" className="cardstack-image" />
            </div>
          </div>
        </section>

        <section className="cta-section">
          <div className="cta-content">
            <h2>Ready to Enhance Your Game?</h2>
            <p>Join thousands of players who have streamlined their spellcasting experience</p>
            <div className="cta-buttons">
              <Link to={currentUser ? "/spells" : "/login"} className="button primary">
                {currentUser ? "Go to My Spells" : "Create Free Account"}
              </Link>
              <Link to="/spells" className="button secondary">Explore Spell List</Link>
            </div>
          </div>
        </section>

        <section className="workflow-section">
          <h2>How It Works</h2>
          <div className="workflow-steps">
            <div className="workflow-step">
              <div className="step-number">1</div>
              <h3>Browse Spells</h3>
              <p>Search and filter through our comprehensive database of D&D 5e spells.</p>
            </div>
            <div className="workflow-step">
              <div className="step-number">2</div>
              <h3>Create Spellbooks</h3>
              <p>Organize spells into character-specific spellbooks for easy reference.</p>
            </div>
            <div className="workflow-step">
              <div className="step-number">3</div>
              <h3>Prepare Daily Spells</h3>
              <p>Quickly select which spells to prepare for your next adventure.</p>
            </div>
            <div className="workflow-step">
              <div className="step-number">4</div>
              <h3>Track During Play</h3>
              <p>Manage spell slots and prepared spells throughout your gameplay session.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default HomePage;

