import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import PasswordChangeForm from '../components/PasswordChangeForm';
import EmailChangeForm from '../components/EmailChangeForm';
import { clearUserAccount } from '../firebase/firestoreOperations';
import { addUserAsAdmin } from '../utils/setupAdmin';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import '../styles/pages/AccountPage.css';

// Get admin setup code from environment variables
// Falls back to a default code if environment variable is not set
const ADMIN_SETUP_CODE = process.env.REACT_APP_ADMIN_SETUP_CODE || "spellmanager2024";

function AccountPage() {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('account');
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminUserId, setAdminUserId] = useState('');
  const [adminActionMessage, setAdminActionMessage] = useState('');
  const [adminActionError, setAdminActionError] = useState('');
  const [noAdminsExist, setNoAdminsExist] = useState(false);
  const [setupAdminLoading, setSetupAdminLoading] = useState(false);
  const [firebaseStatus, setFirebaseStatus] = useState({ checking: true, accessible: false, error: null });
  const [adminSetupCode, setAdminSetupCode] = useState('');
  const [showAdminSetup, setShowAdminSetup] = useState(false);

  // Check if current user is an admin and if any admins exist
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        setIsAdmin(false);
        return;
      }

      try {
        const userDoc = await getDocs(collection(db, 'admins'));
        const adminIds = userDoc.docs.map(doc => doc.id);

        // Check if there are no admins in the system
        if (adminIds.length === 0) {
          setNoAdminsExist(true);
        } else {
          setNoAdminsExist(false);
        }

        setIsAdmin(adminIds.includes(currentUser.uid));
      } catch (err) {
        console.error('Error checking admin status:', err);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  // Check Firebase accessibility
  useEffect(() => {
    const checkFirebaseAccess = async () => {
      try {
        setFirebaseStatus({ checking: true, accessible: false, error: null });

        // Try to access the test_access collection that's allowed in security rules
        const testCollection = collection(db, 'test_access');
        await getDocs(testCollection);

        // If we get here, Firebase is accessible
        setFirebaseStatus({ checking: false, accessible: true, error: null });
        console.log('Firebase is accessible');
      } catch (error) {
        console.error('Firebase access check failed:', error);
        setFirebaseStatus({ checking: false, accessible: false, error });
      }
    };

    if (currentUser) {
      checkFirebaseAccess();
    }
  }, [currentUser]);

  if (!currentUser) {
    return (
      <div className="account-page">
        <div className="account-container not-logged-in">
          <h2>Account Access Denied</h2>
          <p>Please log in to view your account details.</p>
        </div>
      </div>
    );
  }

  // Format the creation date
  const creationDate = new Date(currentUser.metadata.creationTime);
  const formattedDate = creationDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const handleClearAccount = async () => {
    try {
      await clearUserAccount(currentUser.uid);
      setShowClearConfirmation(false);
      alert('Your account data has been cleared successfully.');
    } catch (error) {
      console.error('Error clearing account:', error);
      alert('An error occurred while clearing your account. Please try again.');
    }
  };

  const handleAddAdmin = async (e) => {
    e.preventDefault();
    setAdminActionMessage('');
    setAdminActionError('');

    if (!adminUserId.trim()) {
      setAdminActionError('Please enter a user ID');
      return;
    }

    try {
      await addUserAsAdmin(adminUserId.trim());
      setAdminActionMessage(`User ${adminUserId} has been added as an admin`);
      setAdminUserId('');
    } catch (error) {
      console.error('Error adding admin:', error);
      setAdminActionError('Failed to add admin. Please try again.');
    }
  };

  const handleSetupFirstAdmin = async (e) => {
    e.preventDefault();

    if (adminSetupCode !== ADMIN_SETUP_CODE) {
      alert('Invalid admin setup code. Please enter the correct code to become an admin.');
      return;
    }

    setSetupAdminLoading(true);
    try {
      console.log('Starting admin setup process...');
      console.log('Current user ID:', currentUser.uid);

      await addUserAsAdmin(currentUser.uid);
      setIsAdmin(true);
      setNoAdminsExist(false);
      alert('You are now the first admin of the system! The page will refresh to show admin features.');
      window.location.reload();
    } catch (error) {
      console.error('Error setting up first admin:', error);

      // Provide more detailed error information
      let errorMessage = 'Failed to set up admin. ';

      if (error.code === 'permission-denied') {
        errorMessage += 'You do not have permission to access the Firestore database. Please check your Firebase security rules.';
      } else if (error.code === 'unavailable') {
        errorMessage += 'The Firebase service is currently unavailable. Please check your internet connection and try again.';
      } else if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += 'Please try again.';
      }

      alert(errorMessage);
    } finally {
      setSetupAdminLoading(false);
    }
  };

  return (
    <div className="account-page">
      <div className="account-container">
        <div className="account-sidebar">
          <button
            className={`sidebar-button ${activeTab === 'account' ? 'active' : ''}`}
            onClick={() => setActiveTab('account')}
          >
            Account
          </button>
          <button
            className={`sidebar-button ${activeTab === 'changeEmail' ? 'active' : ''}`}
            onClick={() => setActiveTab('changeEmail')}
          >
            Change Email
          </button>
          <button
            className={`sidebar-button ${activeTab === 'changePassword' ? 'active' : ''}`}
            onClick={() => setActiveTab('changePassword')}
          >
            Change Password
          </button>
          <button
            className={`sidebar-button ${activeTab === 'clearAccount' ? 'active' : ''}`}
            onClick={() => setActiveTab('clearAccount')}
          >
            Clear Account
          </button>
          {isAdmin && (
            <button
              className={`sidebar-button admin-button ${activeTab === 'adminControls' ? 'active' : ''}`}
              onClick={() => setActiveTab('adminControls')}
            >
              Admin Controls
            </button>
          )}
        </div>
        <div className="account-content">
          {activeTab === 'account' && (
            <>
              <h2>Account Details</h2>
              <div className="account-info">
                <div className="info-item">
                  <span className="info-label">Email:</span>
                  <span className="info-value">{currentUser.email}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">Username:</span>
                  <span className="info-value">{currentUser.displayName || 'Not set'}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">Member since:</span>
                  <span className="info-value">{formattedDate}</span>
                </div>
                {isAdmin && (
                  <div className="info-item">
                    <span className="info-label">Role:</span>
                    <span className="info-value admin-badge">Admin</span>
                  </div>
                )}
              </div>

              {/* Setup First Admin button - only shows if no admins exist yet */}
              {noAdminsExist && !isAdmin && (
                <div className="setup-admin-section">
                  <h3>Setup First Admin</h3>
                  <p>There are no administrators in the system yet. To become an admin, you need the admin setup code.</p>

                  {!showAdminSetup ? (
                    <button
                      className="admin-button"
                      onClick={() => setShowAdminSetup(true)}
                    >
                      Setup Admin Access
                    </button>
                  ) : (
                    <form onSubmit={handleSetupFirstAdmin} className="admin-form">
                      <div className="form-group">
                        <label htmlFor="adminSetupCode">Admin Setup Code:</label>
                        <input
                          type="password"
                          id="adminSetupCode"
                          value={adminSetupCode}
                          onChange={(e) => setAdminSetupCode(e.target.value)}
                          placeholder="Enter admin setup code"
                          className="admin-input"
                          required
                        />
                      </div>
                      <div className="form-actions">
                        <button
                          type="submit"
                          className="admin-button"
                          disabled={setupAdminLoading}
                        >
                          {setupAdminLoading ? 'Setting up...' : 'Become Admin'}
                        </button>
                        <button
                          type="button"
                          className="cancel-button"
                          onClick={() => setShowAdminSetup(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              )}

              {/* Firebase connection error display */}
              {!firebaseStatus.checking && !firebaseStatus.accessible && (
                <div className="firebase-error">
                  <p className="admin-error">
                    Firebase connection error: {firebaseStatus.error?.message || 'Unknown error'}
                  </p>
                  <p>Please check your Firebase configuration and make sure you have the correct permissions.</p>
                  <div className="error-help">
                    <p><strong>Troubleshooting steps:</strong></p>
                    <p>1. Make sure you're logged in with a valid account</p>
                    <p>2. Check that your Firebase project is properly configured</p>
                    <p>3. Verify that your Firestore security rules allow access to the necessary collections</p>
                    <p>4. Try refreshing the page and attempting again</p>
                    {firebaseStatus.error?.code && (
                      <p>Error code: <span className="error-code">{firebaseStatus.error.code}</span></p>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {activeTab === 'changeEmail' && <EmailChangeForm />}
          {activeTab === 'changePassword' && <PasswordChangeForm />}
          {activeTab === 'clearAccount' && (
            <div className="clear-account-section">
              <h2>Clear Account Data</h2>
              <p>This action will remove all your spellbooks, prepared spells, presets, spell notes, spell slots, spell points, custom spells, and settings. This cannot be undone.</p>
              {!showClearConfirmation ? (
                <button className="danger-button" onClick={() => setShowClearConfirmation(true)}>
                  Clear Account Data
                </button>
              ) : (
                <div className="confirmation-dialog">
                  <p>Are you sure you want to clear all your account data?</p>
                  <button className="danger-button" onClick={handleClearAccount}>
                    Yes, Clear My Data
                  </button>
                  <button className="cancel-button" onClick={() => setShowClearConfirmation(false)}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          )}
          {activeTab === 'adminControls' && isAdmin && (
            <div className="admin-controls-section">
              <h2>Admin Controls</h2>
              <div className="admin-actions">
                <div className="admin-action">
                  <h3>Add Admin User</h3>
                  <p>Enter the Firebase User ID of the user you want to make an admin.</p>
                  <form onSubmit={handleAddAdmin} className="admin-form">
                    <input
                      type="text"
                      value={adminUserId}
                      onChange={(e) => setAdminUserId(e.target.value)}
                      placeholder="User ID"
                      className="admin-input"
                    />
                    <button type="submit" className="admin-button">Add Admin</button>
                  </form>
                  {adminActionMessage && <p className="admin-success">{adminActionMessage}</p>}
                  {adminActionError && <p className="admin-error">{adminActionError}</p>}
                </div>
                <div className="admin-action">
                  <h3>Admin Dashboard</h3>
                  <p>View and manage all users in the system.</p>
                  <a href="/admin" className="admin-link">Go to Admin Dashboard</a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AccountPage;
