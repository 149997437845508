import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { getSpellComments, addSpellComment, deleteSpellComment, likeSpellComment } from '../firebase/firestoreOperations';
import { isUserAdmin } from '../utils/setupAdmin';
import { useFeatureFlags } from '../FeatureFlagsContext';
import '../styles/components/SpellComments.css';

const SpellComments = ({ spellId, spellName }) => {
  const { currentUser } = useAuth();
  const { enableComments } = useFeatureFlags();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);
  
  // Check if user is admin
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const adminStatus = await isUserAdmin(currentUser.uid);
        setIsAdmin(adminStatus);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  // Load comments
  useEffect(() => {
    // Skip loading if comments are disabled
    if (!enableComments) {
      setShouldRender(false);
      return;
    }

    setShouldRender(true);
    const loadComments = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const commentsData = await getSpellComments(spellId);
        setComments(commentsData);
      } catch (error) {
        console.error('Error loading comments:', error);
        setError('Unable to load comments');
      } finally {
        setIsLoading(false);
      }
    };

    loadComments();
  }, [spellId, enableComments]);

  // If comments are disabled, don't render anything
  if (!shouldRender) {
    return null;
  }

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('Please log in to add a comment');
      return;
    }

    if (!newComment.trim()) {
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const userName = currentUser.displayName || 'User';
      const success = await addSpellComment(currentUser.uid, spellId, newComment, userName);
      
      if (success) {
        // Refresh comments
        const updatedComments = await getSpellComments(spellId);
        setComments(updatedComments);
        setNewComment('');
      } else {
        setError('Failed to add comment');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      setError('An error occurred while adding your comment');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (!currentUser) return;

    try {
      setError(null);
      const success = await deleteSpellComment(currentUser.uid, spellId, commentId);
      
      if (success) {
        // Update local state
        setComments(comments.filter(comment => comment.id !== commentId));
      } else {
        setError('Failed to delete comment');
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError('An error occurred while deleting the comment');
    }
  };

  const handleLikeComment = async (commentId) => {
    if (!currentUser) {
      alert('Please log in to like comments');
      return;
    }

    try {
      setError(null);
      const success = await likeSpellComment(currentUser.uid, spellId, commentId);
      
      if (success) {
        // Refresh comments
        const updatedComments = await getSpellComments(spellId);
        setComments(updatedComments);
      } else {
        setError('Failed to update like');
      }
    } catch (error) {
      console.error('Error liking comment:', error);
      setError('An error occurred while updating the like');
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="spell-comments">
      <h3 className="comments-title">Comments for {spellName}</h3>
      
      {currentUser && (
        <form className="comment-form" onSubmit={handleSubmitComment}>
          <textarea
            className="comment-input"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            rows={3}
            disabled={isSubmitting}
          />
          <button 
            type="submit" 
            className="comment-submit-btn"
            disabled={isSubmitting || !newComment.trim()}
          >
            {isSubmitting ? 'Posting...' : 'Post Comment'}
          </button>
        </form>
      )}
      
      {!currentUser && (
        <div className="login-prompt">
          Please log in to comment
        </div>
      )}
      
      {error && <div className="comments-error">{error}</div>}
      
      <div className="comments-list">
        {isLoading ? (
          <div className="comments-loading">Loading comments...</div>
        ) : comments.length === 0 ? (
          <div className="no-comments">No comments yet. Be the first to comment!</div>
        ) : (
          comments.map(comment => (
            <div key={comment.id} className="comment-item">
              <div className="comment-header">
                <span className="comment-author">{comment.userName}</span>
                <span className="comment-date">{formatDate(comment.timestamp)}</span>
              </div>
              <div className="comment-text">{comment.text}</div>
              <div className="comment-actions">
                <button 
                  className={`like-button ${currentUser && comment.likedBy?.includes(currentUser.uid) ? 'liked' : ''}`}
                  onClick={() => handleLikeComment(comment.id)}
                  disabled={!currentUser}
                >
                  <span className="like-icon">👍</span>
                  <span className="like-count">{comment.likes || 0}</span>
                </button>
                
                {currentUser && (currentUser.uid === comment.userId || isAdmin) && (
                  <button 
                    className="delete-button"
                    onClick={() => handleDeleteComment(comment.id)}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SpellComments; 