import React from 'react';
import '../styles/components/AdvancedFilterTags.css';
import { getActiveFilters, getSourceAbbreviation } from '../utils/spellFilters';

function AdvancedFilterTags({ filters, setFilters }) {
  // Extract all active filters
  const activeFilters = getActiveFilters(filters);
  
  // Check if there are any filters selected
  const hasFilters = Object.values(activeFilters).some(filterArray => filterArray.length > 0);

  // If no filters are selected, don't render anything
  if (!hasFilters) {
    return null;
  }

  // Handle removing a filter
  const handleRemoveFilter = (filterName, value, isExclude) => {
    const newFilters = { ...filters };
    const key = isExclude ? `exclude_${filterName}` : filterName;
    newFilters[key] = filters[key].filter(v => v !== value);
    setFilters(newFilters);
  };

  // Handle clearing all filters
  const handleClearAllFilters = () => {
    const emptyFilters = {};
    Object.keys(filters).forEach(key => {
      emptyFilters[key] = [];
    });
    setFilters(emptyFilters);
  };

  // Render filter tag with appropriate display value
  const renderFilterTag = (name, value) => {
    if (name === 'source') {
      return getSourceAbbreviation(value);
    }
    return value;
  };

  // Get filter type display name
  const getFilterTypeName = (filterType) => {
    const displayNames = {
      'class': 'Class',
      'level': 'Level',
      'school': 'School',
      'components': 'Components',
      'concentration': 'Concentration',
      'ritual': 'Ritual',
      'source': 'Source',
      'duration': 'Duration',
      'range': 'Range'
    };
    return displayNames[filterType] || filterType.charAt(0).toUpperCase() + filterType.slice(1);
  };

  return (
    <div className="all-filters-tags-container">
      <div className="filters-tags-header">
        <span>Active Filters:</span>
        {hasFilters && (
          <button 
            className="clear-filters-button"
            onClick={handleClearAllFilters}
            title="Clear all filters"
          >
            Clear All
          </button>
        )}
      </div>
      <div className="filters-tags-content">
        {Object.entries(activeFilters).map(([filterType, values]) => {
          if (values.length === 0) return null;
          
          return (
            <div key={filterType} className="filter-tag-group">
              <span className="filter-type-label">{getFilterTypeName(filterType)}:</span>
              <div className="filter-tag-stack">
                {values.map(item => (
                  <div 
                    key={`${filterType}-${item.value}-${item.exclude ? 'exclude' : 'include'}`} 
                    className={`filter-tag ${item.exclude ? 'exclude' : 'include'}`}
                  >
                    <span className="filter-value">{renderFilterTag(filterType, item.value)}</span>
                    <button 
                      className="remove-filter"
                      onClick={() => handleRemoveFilter(filterType, item.value, item.exclude)}
                      title={`Remove ${item.exclude ? 'NOT ' : ''}${item.value} filter`}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AdvancedFilterTags; 