import { useMemo } from 'react';
import { sortSpells, updateSortConfig } from '../utils/sortSpells';

const useSortSpells = (spells, sortConfig, setSortConfig) => {
  const defaultConfig = { key: 'name', direction: 'asc' };
  
  // Ensure spells is always an array
  const spellsArray = Array.isArray(spells) ? spells : [];

  const sortedSpells = useMemo(() => {
    return sortSpells(spellsArray, sortConfig || defaultConfig);
  }, [spellsArray, sortConfig]);

  const requestSort = (key) => {
    const newSortConfig = updateSortConfig(sortConfig || defaultConfig, key);
    setSortConfig(newSortConfig);
  };

  return { sortedSpells, requestSort, sortConfig: sortConfig || defaultConfig };
};

export default useSortSpells;