import React, { useState, useEffect } from 'react';
import SpellSlotManager from './SpellSlotManager';
import SpellPointsManager from './SpellPointsManager';
import '../styles/components/SpellResourceManager.css';
import { useAuth } from '../AuthContext';
import { getUserSettings, updateUserSettings } from '../firebase/firestoreOperations';

const SpellResourceManager = ({
  spellSlots,
  setSpellSlots,
  spellPoints = { total: 0, used: 0, highLevelUses: {} },
  setSpellPoints,
  spellbookName = '',
  showSpellSlotsEverywhere,
  setShowSpellSlotsEverywhere,
  characterLevel = 0,
  setCharacterLevel,
  highLevelSpellUses = {},
  setHighLevelSpellUses,
  allowMultipleHighLevelCasts = false,
  setAllowMultipleHighLevelCasts,
  useSpellPoints,
  setUseSpellPoints
}) => {
  const { currentUser } = useAuth();

  if (!spellbookName) {
    return null;
  }

  return (
    <div className="spell-resource-manager">
      {useSpellPoints ? (
        <SpellPointsManager
          spellPoints={spellPoints}
          setSpellPoints={setSpellPoints}
          spellbookName={spellbookName}
          showSpellSlotsEverywhere={showSpellSlotsEverywhere}
          setShowSpellSlotsEverywhere={setShowSpellSlotsEverywhere}
          characterLevel={characterLevel}
          setCharacterLevel={setCharacterLevel}
          allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
          setAllowMultipleHighLevelCasts={setAllowMultipleHighLevelCasts}
          useSpellPoints={useSpellPoints}
          setUseSpellPoints={setUseSpellPoints}
        />
      ) : (
        <SpellSlotManager
          spellSlots={spellSlots}
          setSpellSlots={setSpellSlots}
          spellbookName={spellbookName}
          showSpellSlotsEverywhere={showSpellSlotsEverywhere}
          setShowSpellSlotsEverywhere={setShowSpellSlotsEverywhere}
          useSpellPoints={useSpellPoints}
          setUseSpellPoints={setUseSpellPoints}
        />
      )}
    </div>
  );
};

export default SpellResourceManager; 