import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth } from './firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { isUserVerified } from './firebase/authFunctions';
import { debouncedUpdateUserLastVisit } from './firebase/firestoreOperations';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const verifiedUser = user && isUserVerified() ? user : null;
      setCurrentUser(verifiedUser);
      
      // Update last visit timestamp when user logs in (debounced)
      if (verifiedUser) {
        debouncedUpdateUserLastVisit(verifiedUser.uid);
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
