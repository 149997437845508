import React from 'react';
import { getSourceDisplayName } from '../utils/spellFilters';

function SpellFilters({ filters, setFilters, filterOptions }) {
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (value) {
      // Only add the filter if it's not already selected
      if (!filters[name] || !filters[name].includes(value)) {
        const newFilters = { ...filters };
        newFilters[name] = [...(filters[name] || []), value];
        setFilters(newFilters);
      }
    }
  };

  const renderFilterGroup = (name, options, label) => {
    return (
      <div className="filter-group" key={name}>
        <select name={name} value="" onChange={handleFilterChange}>
          <option value="">{label}</option>
          {options.map(option => (
            <option key={option} value={option}>
              {name === 'source' ? getSourceDisplayName(option) : option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className="filters">
      {renderFilterGroup('class', filterOptions.classes, 'Class')}
      {renderFilterGroup('level', filterOptions.levels, 'Level')}
      {renderFilterGroup('school', filterOptions.schools, 'School')}
      {renderFilterGroup('components', filterOptions.components, 'Components')}
      {renderFilterGroup('concentration', ['Yes', 'No'], 'Concentration')}
      {renderFilterGroup('ritual', ['Yes', 'No'], 'Ritual')}
      {renderFilterGroup('source', filterOptions.sources, 'Source')}
    </div>
  );
}

export default SpellFilters;