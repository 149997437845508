import { db } from '../firebase/firebase';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';

/**
 * Utility function to add a user to the admins collection
 * This should be run manually in the browser console or as a one-time setup script
 * 
 * @param {string} userId - The Firebase Auth user ID to add as an admin
 * @returns {Promise<void>}
 * 
 * Example usage:
 * 1. Import the function in your component or page:
 *    import { addUserAsAdmin } from '../utils/setupAdmin';
 * 
 * 2. Call the function with the user ID:
 *    addUserAsAdmin('user123').then(() => console.log('Admin added successfully'));
 */
export const addUserAsAdmin = async (userId) => {
  if (!userId) {
    console.error('No user ID provided to addUserAsAdmin');
    throw new Error('User ID is required');
  }

  try {
    console.log(`Attempting to add user ${userId} as admin...`);
    
    // First check if the user exists in the users collection
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      console.error(`User ${userId} does not exist in the database`);
      throw new Error(`User ID ${userId} does not exist. Please check the ID and try again.`);
    }
    
    // Then check if the user is already an admin
    const adminDocRef = doc(db, 'admins', userId);
    const adminDoc = await getDoc(adminDocRef);
    
    if (adminDoc.exists()) {
      console.log(`User ${userId} is already an admin`);
      return; // User is already an admin, no need to add again
    }
    
    // Add the user to the admins collection with their ID as the document ID
    await setDoc(adminDocRef, {
      role: 'admin',
      addedAt: new Date(),
      username: userDoc.data().username || 'Unknown',
      email: userDoc.data().email || 'Unknown'
    });
    
    console.log(`User ${userId} has been successfully added as an admin`);
  } catch (error) {
    console.error('Error adding admin:', error);
    // Add more detailed error information
    if (error.code) {
      console.error('Error code:', error.code);
    }
    if (error.message) {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

/**
 * Utility function to check if a user is an admin
 * 
 * @param {string} userId - The Firebase Auth user ID to check
 * @returns {Promise<boolean>} - True if the user is an admin, false otherwise
 */
export const isUserAdmin = async (userId) => {
  if (!userId) {
    return false;
  }

  try {
    const adminDocRef = doc(db, 'admins', userId);
    const adminDoc = await getDoc(adminDocRef);
    return adminDoc.exists();
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

/**
 * Utility function to remove a user from the admins collection
 * 
 * @param {string} userId - The Firebase Auth user ID to remove from admin role
 * @returns {Promise<void>}
 */
export const removeUserAsAdmin = async (userId) => {
  if (!userId) {
    console.error('No user ID provided to removeUserAsAdmin');
    throw new Error('User ID is required');
  }

  try {
    console.log(`Attempting to remove user ${userId} from admin role...`);
    
    // Check if the user is an admin
    const adminDocRef = doc(db, 'admins', userId);
    const adminDoc = await getDoc(adminDocRef);
    
    if (!adminDoc.exists()) {
      console.log(`User ${userId} is not an admin`);
      return; // User is not an admin, no need to remove
    }
    
    // Remove the user from the admins collection
    await deleteDoc(adminDocRef);
    
    console.log(`User ${userId} has been successfully removed from admin role`);
  } catch (error) {
    console.error('Error removing admin:', error);
    if (error.code) {
      console.error('Error code:', error.code);
    }
    if (error.message) {
      console.error('Error message:', error.message);
    }
    throw error;
  }
}; 