import { capitalizeWords } from '../../utils/textUtils';
import autoTable from 'jspdf-autotable';

// Import getSourceString from QuickLookupPDFGenerator
const getSourceString = (spell) => {
  // Check if it's a custom spell (created by user)
  if (spell.id && spell.id.startsWith('custom-')) {
    return 'custom';
  }

  if (!spell.sources) {
    // If no sources field exists, try to determine from other data
    if (spell.tags && spell.tags.includes('custom')) {
      return 'custom';
    }
    return 'PHB'; // Default to PHB if no other source info available
  }

  // Format sources more compactly: "PHB24:p239, XGE:p50"
  return spell.sources.map(source => {
    if (typeof source === 'string') return source;
    // Replace PHB2024 with PHB24
    const book = source.book === 'PHB2024' ? 'PHB24' : source.book;
    return `${book}:p${source.page}`;
  }).join(', ');
};

// Update the helper function to handle both bold and italic
const processStyledText = (doc, text, xPos, yPos, width, lineHeight) => {
  // First split by bold markers
  const boldParts = text.split('**');
  let currentX = xPos;

  boldParts.forEach((boldPart, boldIndex) => {
    if (boldPart === '') return; // Skip empty parts

    // Determine if this section should be bold
    const isBold = boldIndex % 2 === 1;

    // Split this part by italic markers
    const italicParts = boldPart.split('_');

    italicParts.forEach((part, italicIndex) => {
      if (part === '') return; // Skip empty parts

      // Determine if this section should be italic
      const isItalic = italicIndex % 2 === 1;

      // Set font style based on bold and italic flags
      let fontStyle = 'normal';
      if (isBold && isItalic) fontStyle = 'bolditalic';
      else if (isBold) fontStyle = 'bold';
      else if (isItalic) fontStyle = 'italic';

      // Handle spaces at the beginning and end of styled text
      const leadingSpace = part.match(/^\s+/)?.[0] || '';
      const trailingSpace = part.match(/\s+$/)?.[0] || '';
      const trimmedPart = part.trim();

      if (leadingSpace) {
        doc.setFont("helvetica", "normal");
        doc.text(leadingSpace, currentX, yPos);
        currentX += doc.getStringUnitWidth(leadingSpace) * doc.getFontSize() / doc.internal.scaleFactor;
      }

      if (trimmedPart) {
        doc.setFont("helvetica", fontStyle);
        doc.text(trimmedPart, currentX, yPos);
        currentX += doc.getStringUnitWidth(trimmedPart) * doc.getFontSize() / doc.internal.scaleFactor;
      }

      if (trailingSpace) {
        doc.setFont("helvetica", "normal");
        doc.text(trailingSpace, currentX, yPos);
        currentX += doc.getStringUnitWidth(trailingSpace) * doc.getFontSize() / doc.internal.scaleFactor;
      }
    });
  });

  // Reset font to normal
  doc.setFont("helvetica", "normal");
};

// Add this helper function to measure styled text width
const measureStyledText = (doc, text) => {
  let totalWidth = 0;
  const boldParts = text.split('**');

  boldParts.forEach((boldPart, boldIndex) => {
    const isBold = boldIndex % 2 === 1;
    const italicParts = boldPart.split('_');

    italicParts.forEach((part, italicIndex) => {
      const isItalic = italicIndex % 2 === 1;
      let fontStyle = 'normal';
      if (isBold && isItalic) fontStyle = 'bolditalic';
      else if (isBold) fontStyle = 'bold';
      else if (isItalic) fontStyle = 'italic';

      doc.setFont("helvetica", fontStyle);
      totalWidth += doc.getStringUnitWidth(part) * doc.getFontSize() / doc.internal.scaleFactor;
    });
  });

  doc.setFont("helvetica", "normal");
  return totalWidth;
};

// Add this helper function to estimate table height
const estimateTableHeight = (doc, headers, body, fontSize, cellPadding) => {
  const lineHeight = fontSize * 1.2;
  let totalHeight = 0;

  // Header height
  headers.forEach(header => {
    const lines = header.content.split('\n').length;
    totalHeight += lines * lineHeight + (cellPadding * 2);
  });

  // Body height
  body.forEach(row => {
    let maxLines = 1;
    row.forEach(cell => {
      const lines = cell.content.split('\n').length;
      maxLines = Math.max(maxLines, lines);
    });
    totalHeight += maxLines * lineHeight + (cellPadding * 2);
  });

  return totalHeight;
};

// Update the function signature to include margin parameters
const processTable = (doc, tableText, xPos, yPos, width, lineHeight, margins) => {
  // Parse table content
  const rows = tableText
    .replace(/<\/?table>/g, '') // Remove table tags
    .split('\n')
    .filter(row => row.trim()); // Remove empty lines

  // Find the separator row to distinguish headers
  const separatorIndex = rows.findIndex(row => row.includes('---'));

  // Parse headers and data, ensuring we keep complete cell contents together
  const headers = rows[0]
    .split('|')
    .filter(cell => cell.trim())
    .map(cell => ({
      content: cell.trim(),
      styles: { fontStyle: 'bold' }
    }));

  // Process the body rows, keeping cell contents intact
  const body = [];
  let currentRow = [];

  for (let i = separatorIndex + 1; i < rows.length; i++) {
    const row = rows[i].trim();

    // If this is a new row (starts with |)
    if (row.startsWith('|')) {
      if (currentRow.length > 0) {
        body.push(currentRow);
        currentRow = [];
      }

      // Split the row into cells
      const cells = row.split('|')
        .filter(cell => cell.trim())
        .map(cell => {
          const content = cell.trim();
          const styles = {};

          if (content.includes('**')) {
            styles.fontStyle = 'bold';
          }
          if (content.includes('_')) {
            styles.fontStyle = styles.fontStyle === 'bold' ? 'bolditalic' : 'italic';
          }

          return {
            content: content.replace(/[\*_]/g, ''),
            styles
          };
        });

      currentRow = cells;
    } else {
      // This is a continuation of the previous cell's content
      if (currentRow.length > 0) {
        const lastCell = currentRow[currentRow.length - 1];
        lastCell.content += '\n' + row.trim();
      }
    }
  }

  // Add the last row if it exists
  if (currentRow.length > 0) {
    body.push(currentRow);
  }

  // Calculate optimal column widths based on content
  const calculateColumnWidths = (headers, body, totalWidth) => {
    const columnCount = headers.length;

    // Special handling for common 2-column tables (like Stage + Condition)
    if (columnCount === 2 && headers[0].content.toLowerCase().includes('stage')) {
      // Fixed ratio for Stage/Condition tables
      return [
        totalWidth * 0.25,  // Stage column - 25%
        totalWidth * 0.75   // Condition column - 75%
      ];
    }

    // For other tables, calculate based on content
    const contentLengths = new Array(columnCount).fill(0);

    // Check header lengths
    headers.forEach((header, index) => {
      contentLengths[index] = Math.max(
        contentLengths[index],
        doc.getStringUnitWidth(header.content) * doc.getFontSize()
      );
    });

    // Check all cell contents
    body.forEach(row => {
      row.forEach((cell, index) => {
        const cellWidth = doc.getStringUnitWidth(cell.content) * doc.getFontSize();
        contentLengths[index] = Math.max(contentLengths[index], cellWidth);
      });
    });

    // Calculate proportional widths
    const totalContentLength = contentLengths.reduce((sum, length) => sum + length, 0);

    // First pass: calculate desired widths
    let widths = contentLengths.map(length => {
      const proportion = length / totalContentLength;
      return totalWidth * proportion;
    });

    // Ensure minimum width and adjust if total exceeds available width
    const minWidth = totalWidth * 0.15; // Minimum 15% of total width
    let totalUsedWidth = 0;

    widths = widths.map(width => {
      const adjustedWidth = Math.max(width, minWidth);
      totalUsedWidth += adjustedWidth;
      return adjustedWidth;
    });

    // If total width exceeds available width, scale down proportionally
    if (totalUsedWidth > totalWidth) {
      const scale = totalWidth / totalUsedWidth;
      widths = widths.map(width => width * scale);
    }

    return widths;
  };

  // Get optimized column widths
  const columnWidths = calculateColumnWidths(headers, body, width);

  // Configure and draw the table
  autoTable(doc, {
    startY: yPos,
    head: [headers],
    body: body,
    styles: {
      fontSize: doc.getFontSize(),
      cellPadding: 2,
      lineWidth: 0.1,
      lineColor: [180, 180, 180],
      textColor: [0, 0, 0],
      overflow: 'linebreak',
      cellWidth: 'wrap',
      minCellHeight: 3,
      halign: 'left',
      valign: 'top',
      fillStyle: 'F' // Ensure solid fill
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontStyle: 'bold',
      overflow: 'linebreak'
    },
    // Use calculated column widths
    columnStyles: Object.fromEntries(
      columnWidths.map((width, index) => [index, {
        cellWidth: width,
        overflow: 'linebreak',
        minCellWidth: width // Ensure minimum width is respected
      }])
    ),
    margin: { left: xPos },
    tableWidth: width,
    pageBreak: 'avoid',
    useCss: false,
    tableLineWidth: 0.1,
    showHead: 'firstPage',
    showFoot: 'never',
    didDrawCell: (data) => {
      // Ensure proper cell height for multiline content
      const cell = data.cell;
      if (cell.raw && typeof cell.raw === 'string') {
        const lines = cell.raw.split('\n');
        if (lines.length > 1) {
          cell.styles.minCellHeight = lines.length * (doc.getFontSize() * 1.2);
        }
      }
    },
    willDrawCell: (data) => {
      // Prevent cells from being drawn if they would go beyond our column bounds
      const cell = data.cell;
      if (data.cursor.y + cell.height > doc.internal.pageSize.getHeight() - (margins.bottom + margins.safety)) {
        return false;
      }
      return true;
    }
  });

  // Get the final height of what was actually drawn
  const finalY = doc.previousAutoTable.finalY;
  return finalY ? finalY - yPos + lineHeight : 0;
};

export const generateDetailedPDF = (doc, {
  spells,
  layout,
  margin = 10,
  convertIfNeeded,
  renderHeader,
  showOnlySRDDescriptions
}) => {
  // Force layout to use maximum of 2 columns
  if (layout.columns > 2) {
    layout.columns = 2;
  }

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Add header and get starting Y position for first page only
  const headerY = renderHeader(doc, pageWidth, margin);

  // Adjust margins and spacing
  const bottomMargin = margin * 1.3;
  const safetyMargin = layout.fontSize * 0.8;
  const padding = 2;  // Remove conditional padding
  const lineSpacing = layout.fontSize * 0.4;  // Remove conditional line spacing

  const totalGapWidth = margin * (layout.columns - 1);
  const columnWidth = (pageWidth - (2 * margin) - totalGapWidth) / layout.columns;

  let currentPage = 1;
  let currentColumn = 0;
  let currentY = headerY;  // Start first page at header position

  // Helper functions to update position state
  const setCurrentColumn = (value) => {
    currentColumn = value;
  };

  const setCurrentY = (value) => {
    currentY = value;
  };

  spells.forEach((spell) => {
    // Calculate total height needed for name and properties
    const nameWidth = columnWidth - (2 * padding);
    const nameLines = doc.getStringUnitWidth(spell.name) * (layout.fontSize + 4) > nameWidth ?
      doc.splitTextToSize(spell.name, nameWidth) : [spell.name];

    // Update the properties array to include label and value pairs
    const properties = [
      { label: 'Casting Time: ', value: spell.casting_time },
      { label: 'Range: ', value: convertIfNeeded(spell.range) },
      {
        label: 'Components: ',
        value: Object.entries(spell.components || {})
          .filter(([key, value]) => value && ['verbal', 'somatic', 'material'].includes(key))
          .map(([key]) => key[0].toUpperCase())
          .join(', ') +
          (spell.components.material && spell.components.materials_needed ?
            ` (${spell.components.materials_needed.join(', ')})` : '')
      },
      { label: 'Duration: ', value: spell.duration },
      spell.ritual ? { label: 'Ritual', value: '' } : null
    ].filter(Boolean);

    // Calculate height needed for properties
    const propertyWidth = columnWidth - (2 * padding);
    let totalPropertyLines = 0;
    properties.forEach(prop => {
      const fullText = prop.label + prop.value;
      totalPropertyLines += doc.splitTextToSize(fullText, propertyWidth).length;
    });

    // Calculate total height needed
    const nameHeight = nameLines.length * layout.fontSize * 0.5;
    const propertiesHeight = totalPropertyLines * lineSpacing;
    const totalHeightNeeded = nameHeight + propertiesHeight + (layout.fontSize * 0.8);

    // Check if we need to move to next column before starting the spell
    if (currentY + totalHeightNeeded > pageHeight - (bottomMargin + safetyMargin)) {
      currentColumn++;
      currentY = currentPage === 1 ? headerY : margin;

      if (currentColumn >= layout.columns) {
        doc.addPage();
        currentPage++;
        currentColumn = 0;
        currentY = margin;
      }
    }

    // Now proceed with rendering the spell
    const xPosition = margin + (currentColumn * (columnWidth + margin));

    // Write spell name with adjusted position
    doc.setFont("helvetica", "bold");
    doc.setFontSize(layout.fontSize + 4);
    const nameY = currentY + (layout.fontSize * 0.8);

    // Write the name lines
    nameLines.forEach((line, i) => {
      doc.text(line, xPosition + padding, nameY + (i * layout.fontSize * 0.4));
    });

    // Add subtitle line in italics
    doc.setFont("helvetica", "italic");
    doc.setFontSize(layout.fontSize);
    const subtitle = spell.level === 0
      ? `${capitalizeWords(spell.school)} Cantrip (${(spell.classes || []).map(capitalizeWords).join(', ')})`
      : `Level ${spell.level} ${capitalizeWords(spell.school)} (${(spell.classes || []).map(capitalizeWords).join(', ')})`;

    const subtitleY = nameY + ((nameLines.length) * layout.fontSize * 0.4);
    const subtitleLines = doc.splitTextToSize(subtitle, columnWidth - (2 * padding));
    subtitleLines.forEach((line, i) => {
      doc.text(line, xPosition + padding, subtitleY + (i * layout.fontSize * 0.4));
    });

    // Start properties immediately after subtitle
    let propertyY = subtitleY + (subtitleLines.length * layout.fontSize * 0.5);

    // Write properties with fixed spacing
    doc.setFont("helvetica", "normal");
    doc.setFontSize(layout.fontSize);

    properties.forEach((prop) => {
      // Write the label in bold
      doc.setFont("helvetica", "bold");
      doc.text(prop.label, xPosition + padding, propertyY);

      // Write the value in normal font
      doc.setFont("helvetica", "normal");
      const labelWidth = (doc.getStringUnitWidth(prop.label) * doc.getFontSize() / doc.internal.scaleFactor) + 1;

      if (prop.value) {
        // Calculate remaining width for value
        const remainingWidth = propertyWidth - labelWidth;
        // Split value if it's too long for remaining space
        const valueLines = doc.splitTextToSize(prop.value, remainingWidth); // Use remainingWidth instead of propertyWidth

        // Write first line after label
        doc.text(valueLines[0], xPosition + padding + labelWidth, propertyY);

        // Write any additional lines from the start of the line
        for (let i = 1; i < valueLines.length; i++) {
          propertyY += lineSpacing;
          doc.text(valueLines[i], xPosition + padding, propertyY);
        }
      }

      propertyY += lineSpacing; // Only add line spacing once per property
    });

    // Calculate description layout
    doc.setFontSize(layout.fontSize);
    const descriptionWidth = columnWidth - (2 * padding);

    // Check if description should be shown based on SRD status
    const shouldShowDescription = !showOnlySRDDescriptions || spell.srd || spell.isCustom;

    // Use card_friendly_description if available, otherwise use regular description
    let fullDescription = spell.card_friendly_description || spell.description;

    // Override description if restricted by SRD settings
    if (!shouldShowDescription) {
      fullDescription = "Spell description limited by content license\n\nThis spell's details are not part of the System Reference Document (SRD) and can't be displayed. Only the basic spell information is shown.";
    } else {
      // Combine main description with upgrade text if available (only if showing full description)
      if (spell.level === 0 && spell.cantrip_upgrade) {
        fullDescription += '\n\nAt Higher Levels: ' + spell.cantrip_upgrade;
      }
      else if (spell.level > 0 && spell.higher_levels) {
        fullDescription += '\n\nAt Higher Levels: ' + spell.higher_levels;
      }
    }

    const description = doc.splitTextToSize(
      convertIfNeeded(fullDescription, spell),
      descriptionWidth
    );

    let descriptionY = propertyY + (layout.fontSize * 0.3);
    let currentLine = 0;
    const lineHeight = layout.fontSize * 0.35;

    const minLinesBeforeBreak = 3;

    while (currentLine < description.length) {
      const spaceRemaining = pageHeight - (bottomMargin + safetyMargin) - descriptionY;
      const canFitMinLines = spaceRemaining >= (lineHeight * minLinesBeforeBreak);

      if (descriptionY + lineHeight > pageHeight - (bottomMargin + safetyMargin) && !canFitMinLines) {
        currentColumn++;
        descriptionY = currentPage === 1 ? headerY + (layout.fontSize * 0.5) : margin + (layout.fontSize * 0.5);

        if (currentColumn >= layout.columns) {
          doc.addPage();
          currentPage++;
          currentColumn = 0;
          descriptionY = margin + (layout.fontSize * 0.5);
          currentY = margin;
        }
      }

      let xPos = margin + (currentColumn * (columnWidth + margin)) + padding;

      // Check if this line starts a table
      if (description[currentLine].includes('<table>')) {
        // Collect all table lines
        let tableText = '';
        let tableLines = 0;
        while (currentLine + tableLines < description.length &&
          !description[currentLine + tableLines].includes('</table>')) {
          tableText += description[currentLine + tableLines] + '\n';
          tableLines++;
        }
        tableText += description[currentLine + tableLines];

        // Parse table content for height estimation
        const rows = tableText
          .replace(/<\/?table>/g, '')
          .split('\n')
          .filter(row => row.trim());

        const separatorIndex = rows.findIndex(row => row.includes('---'));
        const headers = rows[0]
          .split('|')
          .filter(cell => cell.trim())
          .map(cell => ({ content: cell.trim() }));

        const body = rows.slice(separatorIndex + 1)
          .map(row => row.split('|')
            .filter(cell => cell.trim())
            .map(cell => ({ content: cell.trim() })));

        // Calculate minimum space needed for table
        const minTableHeight = (layout.fontSize * 1.5) + // header
          (layout.fontSize * 1.2 * 2); // minimum 2 rows

        // Check if we have enough space for at least the header and 2 rows
        if (pageHeight - descriptionY - bottomMargin - safetyMargin < minTableHeight) {
          // Move to next column only if we don't have minimum space
          currentColumn++;
          if (currentColumn >= layout.columns) {
            doc.addPage();
            currentPage++;
            currentColumn = 0;
            descriptionY = margin + (layout.fontSize * 0.5);
          } else {
            descriptionY = currentPage === 1 ? headerY + (layout.fontSize * 0.5) : margin + (layout.fontSize * 0.5);
          }
          xPos = margin + (currentColumn * (columnWidth + margin)) + padding;
        }

        // Draw table in current position
        const tableHeight = processTable(doc, tableText, xPos, descriptionY, descriptionWidth, lineHeight, {
          bottom: bottomMargin,
          safety: safetyMargin
        });

        // Update position after table
        if (tableHeight > 0) {
          descriptionY += tableHeight;
        }

        currentLine += tableLines + 1;
      }
      // Handle regular text (existing code)
      else if (description[currentLine].includes('**') || description[currentLine].includes('_')) {
        processStyledText(doc, description[currentLine], xPos, descriptionY, descriptionWidth, lineHeight);
        descriptionY += lineHeight;
        currentLine++;
      } else {
        doc.text(description[currentLine], xPos, descriptionY);
        descriptionY += lineHeight;
        currentLine++;
      }
    }

    // Add source after description
    const sourceString = getSourceString(spell);
    doc.setFont("helvetica", "italic");
    doc.setFontSize(layout.fontSize - 1);

    // Add some space between description and source
    descriptionY += lineHeight * 0.5;

    // Check if we need to move to next column for the source
    if (descriptionY + lineHeight > pageHeight - (bottomMargin + safetyMargin)) {
      currentColumn++;
      descriptionY = currentPage === 1 ? headerY + (layout.fontSize * 0.5) : margin + (layout.fontSize * 0.5);

      if (currentColumn >= layout.columns) {
        doc.addPage();
        currentPage++;
        currentColumn = 0;
        descriptionY = margin + (layout.fontSize * 0.5);
      }
    }

    const xPos = margin + (currentColumn * (columnWidth + margin)) + padding;
    doc.text(`Source: ${sourceString}`, xPos, descriptionY);

    // Update currentY to include the source line
    currentY = descriptionY + lineHeight;

    if (currentY + lineHeight > pageHeight - (bottomMargin + safetyMargin)) {
      currentColumn++;
      currentY = currentPage === 1 ? headerY : margin;

      if (currentColumn >= layout.columns) {
        doc.addPage();
        currentPage++;
        currentColumn = 0;
        currentY = margin;
      }
    }
  });

  return currentPage;
}; 