import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/HowToUseBox.css';
import { 
  NewInfoIcon, 
  AddToSpellbookIcon, 
  RemoveSpellIcon, 
  AllSpellsIcon, 
  TrashcanIcon,
  CastSpellIcon 
} from '../data/Icons';

// Custom icon components to ensure correct positioning and colors
const InlineInfoIcon = () => {
  return (
    <span className="manual-info-icon">
      <NewInfoIcon />
    </span>
  );
};

const InlineRemoveIcon = () => {
  return (
    <span className="manual-remove-icon">
      <RemoveSpellIcon />
    </span>
  );
};

const InlineBookIcon = () => {
  return (
    <span className="manual-book-icon">
      <AllSpellsIcon />
    </span>
  );
};

const InlineTrashIcon = () => {
  return (
    <span className="manual-trash-icon">
      <TrashcanIcon />
    </span>
  );
};

const InlineCastIcon = () => {
  return (
    <span className="manual-cast-icon">
      <CastSpellIcon />
    </span>
  );
};

function HowToUseBox({ pageType }) {
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef(null);

  const getInstructions = () => {
    switch (pageType) {
      case 'spellList':
        return {
          title: "How to Use the Spell List",
          instructions: [
            "Browse spells and view their properties in the table",
            "Use filters and search to find specific spells",
            <span key="info-instruction" className="instruction-with-icon">
              Click spell names or the <InlineInfoIcon /> icon to view details
            </span>,
            "Click a row to show description or use 'Expand all'",
            <>Use the <span className="icon-inline add-icon"><AddToSpellbookIcon /></span> icon or checkboxes to add spells to spellbooks</>,
            "Click the Create Spell button to add a new homebrew spell"
          ]
        };
      case 'spellBook':
        return {
          title: "How to Use Spellbooks",
          instructions: [
            "Create spellbooks with the 'Create' button",
            "Switch between spellbooks using the tabs",
            "Add spells from the Spell List page",
            <span key="remove-instruction" className="instruction-with-icon">
              Use the <InlineRemoveIcon /> icon or checkboxes to remove spells
            </span>,
            <span key="prepare-instruction" className="instruction-with-icon">
              Use the <InlineBookIcon /> icon to prepare spells for casting
            </span>,
            <span key="delete-instruction" className="instruction-with-icon">
              Delete spellbooks with the <InlineTrashIcon /> icon
            </span>,
            "Try the Spellbook Generator for themed collections"
          ]
        };
      case 'preparedSpells':
        return {
          title: "How to Use Prepared Spells",
          instructions: [
            "Select your active spellbook from the dropdown",
            "View your prepared spells in the table",
            <span key="cast-instruction" className="instruction-with-icon">
              Cast spells with the <InlineCastIcon /> icon to track spell slots
            </span>,
            <span key="unprepare-instruction" className="instruction-with-icon">
              Unprepare spells with the <InlineBookIcon /> icon
            </span>,
            "Track spell slots with the slot tracker",
            "Use 'Reset All' after a long rest",
            "Create presets for different situations"
          ]
        };
      default:
        return {
          title: "How to Use",
          instructions: ["No specific instructions available for this page."]
        };
    }
  };

  const { title, instructions } = getInstructions();
  
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`how-to-use-box ${isOpen ? 'active' : ''}`} key={`how-to-use-${pageType}`} ref={boxRef}>
      <div className="how-to-use-question" onClick={toggleOpen}>
        <h3>{title}</h3>
        <span className="how-to-use-icon"></span>
      </div>
      <div className="how-to-use-answer">
        <ul>
          {instructions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
        {isOpen && (
          <div className="how-to-more-link">
            <Link to="/how-to">View detailed instructions</Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default HowToUseBox; 