import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { logger } from './utils';
import { isUserAdmin } from '../../utils/setupAdmin';

/**
 * Get feature flags from Firestore
 * @returns {Promise<Object>} - Object containing feature flags
 */
export const getFeatureFlags = async () => {
  try {
    const featureFlagsDoc = await getDoc(doc(db, 'settings', 'featureFlags'));
    
    if (!featureFlagsDoc.exists()) {
      // Create default feature flags if they don't exist
      const defaultFlags = {
        enableCustomSpells: true,
        enableSpellRatings: true,
        enableSpellComments: true,
        enableDiceRoller: true,
        enableSpellSearch: true,
        enableSpellbookGenerator: true,
        enablePrintableCards: true,
        enableSpellPoints: true,
        enableThemeSwitching: true,
        enableSSO: false,
        debug_mode: false
      };
      
      await setDoc(doc(db, 'settings', 'featureFlags'), defaultFlags);
      return defaultFlags;
    }
    
    return featureFlagsDoc.data();
  } catch (error) {
    console.error('Error getting feature flags:', error);
    
    // Return default flags in case of error
    return {
      enableCustomSpells: true,
      enableSpellRatings: true,
      enableSpellComments: true,
      enableDiceRoller: true,
      enableSpellSearch: true,
      enableSpellbookGenerator: true,
      enablePrintableCards: true,
      enableSpellPoints: true,
      enableThemeSwitching: true,
      enableSSO: false,
      debug_mode: false
    };
  }
};

/**
 * Update feature flags in Firestore
 * @param {string} userId - The user's ID (must be admin)
 * @param {Object} flagUpdates - Object containing flag updates
 * @returns {Promise<Object>} - The updated feature flags
 */
export const updateFeatureFlags = async (userId, flagUpdates) => {
  try {
    // Verify user is an admin
    const admin = await isUserAdmin(userId);
    if (!admin) {
      throw new Error('Unauthorized: Only admins can update feature flags');
    }
    
    // Get current flags
    const currentFlags = await getFeatureFlags();
    
    // Merge updates with current flags
    const updatedFlags = {
      ...currentFlags,
      ...flagUpdates
    };
    
    // Update flags in Firestore
    await setDoc(doc(db, 'settings', 'featureFlags'), updatedFlags);
    
    return updatedFlags;
  } catch (error) {
    console.error('Error updating feature flags:', error);
    throw error;
  }
};

/**
 * Get user limits from Firestore
 * @returns {Promise<Object>} - Object containing user limits
 */
export const getUserLimits = async () => {
  try {
    const userLimitsDoc = await getDoc(doc(db, 'settings', 'userLimits'));
    
    if (!userLimitsDoc.exists()) {
      // Create default user limits if they don't exist
      const defaultLimits = {
        maxSpellbooks: 3,
        maxCustomSpells: 5,
        maxPresets: 3
      };
      
      await setDoc(doc(db, 'settings', 'userLimits'), defaultLimits);
      return defaultLimits;
    }
    
    return userLimitsDoc.data();
  } catch (error) {
    console.error('Error getting user limits:', error);
    
    // Return default limits in case of error
    return {
      maxSpellbooks: 3,
      maxCustomSpells: 5,
      maxPresets: 3
    };
  }
};

/**
 * Update user limits in Firestore
 * @param {string} userId - The user's ID (must be admin)
 * @param {Object} limitUpdates - Object containing limit updates
 * @returns {Promise<Object>} - The updated user limits
 */
export const updateUserLimits = async (userId, limitUpdates) => {
  try {
    // Verify user is an admin
    const admin = await isUserAdmin(userId);
    if (!admin) {
      throw new Error('Unauthorized: Only admins can update user limits');
    }
    
    // Get current limits
    const currentLimits = await getUserLimits();
    
    // Merge updates with current limits
    const updatedLimits = {
      ...currentLimits,
      ...limitUpdates
    };
    
    // Update limits in Firestore
    await setDoc(doc(db, 'settings', 'userLimits'), updatedLimits);
    
    return updatedLimits;
  } catch (error) {
    console.error('Error updating user limits:', error);
    throw error;
  }
}; 