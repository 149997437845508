import React, { useState, useRef, useEffect } from 'react';
import SearchBar from './SearchBar';
import SpellCounter from './SpellCounter';
import AdvancedFilteringModal from './AdvancedFilteringModal';
import '../styles/components/SpellTableControls.css';
import { 
  CreateSpellIcon, 
  MetricIcon, 
  ShortDescriptionIcon, 
  RitualSpellsIcon, 
  AdvancedFiltersIcon,
  MoreOptionsIcon,
  SchoolTagsIcon
} from '../data/Icons';

const SpellTableControls = ({ 
  searchTerm, 
  setSearchTerm,
  totalSpellCount,
  currentSpellCount,
  onShowCustomSpellForm,
  convertToMetric,
  onToggleMetric,
  showShortDescriptions,
  onToggleShortDescriptions,
  showRitualSpells,
  onToggleRitualSpells,
  showSchoolTags,
  onToggleSchoolTags,
  filters,
  setFilters,
  spells,
  spellbooks
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const dropdownRef = useRef(null);
  const actualTotalCount = totalSpellCount;

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAdvancedFiltersClick = () => {
    setShowAdvancedFilters(true);
  };

  const handleCloseAdvancedFilters = () => {
    setShowAdvancedFilters(false);
  };

  return (
    <div className="table-controls">
      <div className="table-controls-left">
        <div className="search-counter-group">
          <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
          <SpellCounter count={currentSpellCount} totalCount={actualTotalCount} />
        </div>
        <div className="toggle-buttons-group desktop-only">
          <button 
            className={`metric-toggle-button ${convertToMetric ? 'active' : ''}`}
            onClick={onToggleMetric}
            title={convertToMetric ? 'Use default units' : 'Convert units to Metric'}
          >
            <MetricIcon />
          </button>
          <button 
            className={`school-tags-button ${showSchoolTags ? 'active' : ''}`}
            onClick={onToggleSchoolTags}
            title={showSchoolTags ? 'Disable colored badges' : 'Colored school badges'}
          >
            <SchoolTagsIcon />
          </button>
          <button 
            className={`short-description-button ${showShortDescriptions ? 'active' : ''}`}
            onClick={onToggleShortDescriptions}
            title={showShortDescriptions ? 'Collapse all spell rows' : 'Expand all spell rows'}
          >
            <ShortDescriptionIcon />
          </button>
          {onToggleRitualSpells && (
            <button 
              className={`ritual-spells-button ${showRitualSpells ? 'active' : ''}`}
              onClick={onToggleRitualSpells}
              title={`${showRitualSpells ? 'Hide' : 'Show'} Ritual Spells`}
            >
              <RitualSpellsIcon />
            </button>
          )}
        </div>
      </div>
      <div className="table-controls-right">
        <div className="desktop-only">
          <button 
            className="advanced-filters-button"
            onClick={handleAdvancedFiltersClick}
            title="Advanced Filters"
          >
            <AdvancedFiltersIcon />
          </button>
          <button 
            className="create-custom-spell-button"
            onClick={onShowCustomSpellForm}
            title="Create Custom Spell"
          >
            <span className="button-text">Create Spell</span>
            <CreateSpellIcon />
          </button>
        </div>
        <div className="more-options-container mobile-only" ref={dropdownRef}>
          <button 
            className="more-options-button"
            onClick={() => setShowDropdown(!showDropdown)}
            title="More Options"
          >
            <MoreOptionsIcon />
          </button>
          {showDropdown && (
            <div className="more-options-dropdown">
              <div className="dropdown-option" onClick={() => {
                onToggleMetric();
                setShowDropdown(false);
              }}>
                <MetricIcon />
                <span>{convertToMetric ? 'Use default units' : 'Convert units to Metric'}</span>
              </div>
              <div className="dropdown-option" onClick={() => {
                onToggleShortDescriptions();
                setShowDropdown(false);
              }}>
                <ShortDescriptionIcon />
                <span>{showShortDescriptions ? 'Collapse all spell rows' : 'Expand all spell rows'}</span>
              </div>
              {onToggleRitualSpells && (
                <div className="dropdown-option" onClick={() => {
                  onToggleRitualSpells();
                  setShowDropdown(false);
                }}>
                  <RitualSpellsIcon />
                  <span>{showRitualSpells ? 'Hide' : 'Show'} Ritual Spells</span>
                </div>
              )}
              <div className="dropdown-option" onClick={() => {
                onToggleSchoolTags();
                setShowDropdown(false);
              }}>
                <SchoolTagsIcon />
                <span>{showSchoolTags ? 'Disable colored badges' : 'Color coded school badges'}</span>
              </div>
              <div className="dropdown-divider"></div>
              <div className="dropdown-option" onClick={() => {
                setShowDropdown(false);
                handleAdvancedFiltersClick();
              }}>
                <AdvancedFiltersIcon />
                <span>Advanced Filters</span>
              </div>
              <div className="dropdown-option" onClick={() => {
                onShowCustomSpellForm();
                setShowDropdown(false);
              }}>
                <CreateSpellIcon />
                <span>Create Spell</span>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Advanced Filtering Modal */}
      <AdvancedFilteringModal 
        isOpen={showAdvancedFilters} 
        onClose={handleCloseAdvancedFilters}
        filters={filters}
        setFilters={setFilters}
        spells={spells}
        spellbooks={spellbooks}
      />
    </div>
  );
};

export default SpellTableControls; 