import React, { useEffect } from 'react';

/**
 * Component to dynamically update page metadata
 */
const PageHead = ({ 
  title, 
  description, 
  canonical, 
  image, 
  type = 'website',
  keywords = [] 
}) => {
  useEffect(() => {
    // Update document title
    document.title = title ? `${title} | TableMancer` : 'TableMancer - D&D 5e Spell Manager';

    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description || 'TableMancer - Your comprehensive D&D 5e spell companion. Organize, filter, and manage your D&D spells with ease.');
    }

    // Update canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', canonical || window.location.href);

    // Update Open Graph and Twitter tags
    updateMetaTag('og:title', title ? `${title} | TableMancer` : 'TableMancer - D&D 5e Spell Manager');
    updateMetaTag('og:description', description);
    updateMetaTag('og:url', canonical || window.location.href);
    updateMetaTag('og:type', type);
    updateMetaTag('og:image', image || `${window.location.origin}/og-image.jpg`);
    updateMetaTag('twitter:card', 'summary_large_image');

    // Update keywords
    let keywordsString = keywords.join(', ');
    if (keywordsString) {
      updateMetaTag('keywords', keywordsString);
    }

    return () => {
      // No cleanup needed as we're just updating existing tags
    };
  }, [title, description, canonical, image, type, keywords]);

  // Helper function to update or create meta tags
  const updateMetaTag = (name, content) => {
    if (!content) return;

    let metaTag;
    if (name.startsWith('og:')) {
      metaTag = document.querySelector(`meta[property="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('property', name);
        document.head.appendChild(metaTag);
      }
    } else {
      metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        document.head.appendChild(metaTag);
      }
    }
    metaTag.setAttribute('content', content);
  };

  // This component doesn't render anything visible
  return null;
};

export default PageHead; 