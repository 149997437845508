import React, { useState, useEffect } from 'react';
import { auth } from '../firebase/firebase';
import { getUserSpellNotes, updateSpellNote, debouncedUpdateSpellNote } from '../firebase/firestoreOperations';
import '../styles/components/SpellNotes.css';

function SpellNotes({ spellId }) {
  const [notes, setNotes] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [tempNotes, setTempNotes] = useState('');
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    const loadNotes = async () => {
      if (auth.currentUser) {
        const userNotes = await getUserSpellNotes(auth.currentUser.uid, spellId);
        setNotes(userNotes || '');
        setTempNotes(userNotes || '');
      }
    };
    loadNotes();
  }, [spellId]);

  const handleEdit = () => {
    setIsEditing(true);
    setTempNotes(notes);
  };

  const handleSave = async () => {
    if (!auth.currentUser) {
      alert('Please log in to save notes');
      return;
    }

    setSaving(true);
    try {
      await debouncedUpdateSpellNote(auth.currentUser.uid, spellId, tempNotes);
      setNotes(tempNotes);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving note:', error);
      alert('Failed to save note');
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setTempNotes(notes);
  };

  if (!auth.currentUser) {
    return (
      <div className="spell-notes">
        <h3>Personal Notes</h3>
        <p className="spell-notes__login-message">Please log in to add notes</p>
      </div>
    );
  }

  return (
    <div className="spell-notes">
      <div className="spell-notes__header">
        <h3>Personal Notes</h3>
        {!isEditing ? (
          <button onClick={handleEdit} className="spell-notes__button">
            Edit
          </button>
        ) : (
          <div className="spell-notes__button-group">
            <button 
              onClick={handleSave} 
              className="spell-notes__button spell-notes__button--save"
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            <button 
              onClick={handleCancel} 
              className="spell-notes__button spell-notes__button--cancel"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      
      {isEditing ? (
        <textarea
          value={tempNotes}
          onChange={(e) => setTempNotes(e.target.value)}
          placeholder="Add your personal notes about this spell here..."
          className="spell-notes__textarea"
        />
      ) : (
        <div className="spell-notes__content">
          {notes ? notes : 'No notes added yet.'}
        </div>
      )}
    </div>
  );
}

export default SpellNotes; 