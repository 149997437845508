import React from 'react';
import '../styles/pages/PrintablesPage.css';
import hpXpPdf from '../assets/printables/hp-xp.pdf';

function PrintablesPage() {
  return (
    <div className="printables-page">
      <div className="printables-header">
        <h1>Printable Resources</h1>
        <p>Download and print these resources to enhance your tabletop gaming experience.</p>
      </div>
      
      <div className="printables-container">
        <div className="printable-card">
          <div className="printable-info">
            <h2>HP & XP Tracker</h2>
            <p>Two printable sheets for tracking your character's hit points and experience points during gameplay.</p>
            
            <div className="printing-instructions">
              <h3>Printing Instructions</h3>
              <p>These sheets look best in A5 (half A4) size. For optimal results, print them in A4 in landscape orientation, set to 2 pages per sheet.</p>
            </div>
            
            <div className="printable-actions">
              <a href={hpXpPdf} download="hp-xp-tracker.pdf" className="download-button">
                Download PDF
              </a>
            </div>
          </div>
          <div className="printable-preview">
            <div className="pdf-preview">
              <embed src={hpXpPdf} type="application/pdf" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
      
      <div className="printables-footer">
        <p>More printable resources coming soon! Have suggestions? <a href="/contact">Contact us</a>.</p>
      </div>
    </div>
  );
}

export default PrintablesPage; 