/**
 * Filters spells based on a search term
 * @param {Array} spells - Array of spell objects
 * @param {string} searchTerm - Term to search for in spell names
 * @returns {Array} - Filtered array of spells
 */
export const searchSpells = (spells, searchTerm) => {
  if (!searchTerm) return spells;
  
  const spellsArray = Array.isArray(spells) ? spells : [];
  
  return spellsArray.filter(spell =>
    spell.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
}; 