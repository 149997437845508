import React from 'react';
import '../styles/pages/AboutPage.css';

function AboutPage() {
  return (
    <div className="about-container">
      <section className="about-section">
        <h2>About TableMancer</h2>
        <p>TableMancer was born from a simple need: to make spell management in Dungeons & Dragons more efficient and enjoyable. As players ourselves, we wanted a tool that would eliminate the hassle of tracking spells on paper character sheets or through cumbersome apps.</p>
      </section>

      <section className="about-section">
        <h2>Our Mission</h2>
        <p>We believe that the best gaming experiences come when players can focus on storytelling and strategy rather than administration. TableMancer aims to be the most intuitive spell management tool available, helping spellcasters spend less time flipping through rulebooks and more time casting epic spells.</p>
      </section>

      <section className="about-section">
        <h2>Core Features</h2>
        <div className="feature-grid">
          <div className="feature-card">
            <h3>Comprehensive Spell Database</h3>
            <p>Access the complete collection of D&D 5e spells, updated for the latest 2024 edition, with powerful search and filtering capabilities.</p>
          </div>
          <div className="feature-card">
            <h3>Customizable Spellbooks</h3>
            <p>Create and organize spellbooks for different characters, campaigns, or scenarios. Keep your magical arsenal perfectly organized.</p>
          </div>
          <div className="feature-card">
            <h3>Resource Management</h3>
            <p>Easily track spell slots, prepare daily spells, and manage your magical resources during gameplay with intuitive controls.</p>
          </div>
          <div className="feature-card">
            <h3>Custom Spell Creation</h3>
            <p>Design your own homebrew spells with our intuitive editor. Add custom descriptions, properties, and effects to expand your magical repertoire.</p>
          </div>
          <div className="feature-card">
            <h3>Printable Spell Cards</h3>
            <p>Generate beautifully formatted spell cards for your spellbooks. Print them for easy reference during gameplay or to share with your party.</p>
          </div>
          <div className="feature-card">
            <h3>Cloud Synchronization</h3>
            <p>Access your spellbooks from any device with your account. Your magical knowledge travels with you wherever you go.</p>
          </div>
        </div>
      </section>

      <section className="about-section">
        <h2>Our Commitment</h2>
        <p>As we continue to develop TableMancer, we're committed to:</p>
        <ul>
          <li>Maintaining an accurate, up-to-date spell database</li>
          <li>Developing new features based on community feedback</li>
          <li>Creating an accessible tool for players of all experience levels</li>
          <li>Respecting the balance between free features and sustainable development</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>Coming Soon</h2>
        <p>We're actively working on several exciting enhancements:</p>
        <ul>
          <li>Mobile applications for iOS and Android</li>
          <li>Community features for sharing spellbooks and custom content</li>
          <li>Integration with popular character sheet platforms</li>
          <li>Advanced dice rolling and damage calculation tools</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>The Team</h2>
        <p>TableMancer is developed by a small team of developers who are also avid D&D players. We built this tool because we wanted it for our own games, and we're excited to share it with the wider D&D community.</p>
      </section>

      <section className="about-section">
        <h2>Get in Touch</h2>
        <p>We value your feedback and suggestions. If you've encountered an issue, have an idea for a new feature, or just want to say hello, please visit our <a href="/contact" className="about-link">Contact page</a>.</p>
      </section>
    </div>
  );
}

export default AboutPage; 