import React, { useState, useEffect } from 'react';
import '../styles/components/UserTable.css';

const UserTable = ({ 
  users, 
  expandedUserId, 
  toggleUserExpand, 
  handleDeleteUser, 
  initiateAdminAction, 
  currentUser, 
  adminUsers,
  searchTerm 
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: 'username',
    direction: 'ascending'
  });
  const [sortedUsers, setSortedUsers] = useState([]);

  useEffect(() => {
    // First filter users based on search term if provided
    let filteredUsers = [...users];
    if (searchTerm && searchTerm.trim() !== '') {
      const term = searchTerm.toLowerCase().trim();
      filteredUsers = users.filter(user => {
        const username = (user.username || '').toLowerCase();
        const email = (user.email || '').toLowerCase();
        const userId = user.id.toLowerCase();
        
        return username.includes(term) || email.includes(term) || userId.includes(term);
      });
    }
    
    // Then sort the filtered users
    let sortableUsers = [...filteredUsers];
    if (sortConfig.key) {
      sortableUsers.sort((a, b) => {
        // Handle different data types for sorting
        let aValue, bValue;
        
        switch(sortConfig.key) {
          case 'username':
          case 'email':
            aValue = (a[sortConfig.key] || '').toLowerCase();
            bValue = (b[sortConfig.key] || '').toLowerCase();
            break;
          case 'createdAt':
          case 'lastVisit':
            // Convert timestamps to dates for comparison
            aValue = a[sortConfig.key] ? new Date(
              a[sortConfig.key].seconds ? a[sortConfig.key].seconds * 1000 : a[sortConfig.key]
            ).getTime() : 0;
            bValue = b[sortConfig.key] ? new Date(
              b[sortConfig.key].seconds ? b[sortConfig.key].seconds * 1000 : b[sortConfig.key]
            ).getTime() : 0;
            break;
          case 'spellbooks':
            aValue = a.spellbooks ? Object.keys(a.spellbooks).length : 0;
            bValue = b.spellbooks ? Object.keys(b.spellbooks).length : 0;
            break;
          case 'customSpells':
            aValue = a.customSpells ? Object.keys(a.customSpells).length : 0;
            bValue = b.customSpells ? Object.keys(b.customSpells).length : 0;
            break;
          case 'isAdmin':
            aValue = a.isAdmin ? 1 : 0;
            bValue = b.isAdmin ? 1 : 0;
            break;
          default:
            aValue = a[sortConfig.key];
            bValue = b[sortConfig.key];
        }
        
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    setSortedUsers(sortableUsers);
  }, [users, sortConfig, searchTerm]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return (
        <span className={`sort-indicator ${sortConfig.direction}`}>
          {sortConfig.direction === 'ascending' ? '▲' : '▼'}
        </span>
      );
    }
    return null;
  };

  const formatDate = (timestamp, includeTime = false) => {
    if (!timestamp) return includeTime ? 'Never' : 'N/A';
    
    const date = new Date(
      timestamp.seconds ? timestamp.seconds * 1000 : timestamp
    );
    
    return includeTime ? date.toLocaleString() : date.toLocaleDateString();
  };

  return (
    <div className="users-table-container">
      <table className="users-table">
        <thead>
          <tr>
            <th onClick={() => requestSort('username')} className={sortConfig.key === 'username' ? 'active-sort' : ''}>
              Username {getSortIndicator('username')}
            </th>
            <th onClick={() => requestSort('email')} className={sortConfig.key === 'email' ? 'active-sort' : ''}>
              Email {getSortIndicator('email')}
            </th>
            <th onClick={() => requestSort('createdAt')} className={sortConfig.key === 'createdAt' ? 'active-sort' : ''}>
              Joined {getSortIndicator('createdAt')}
            </th>
            <th onClick={() => requestSort('lastVisit')} className={sortConfig.key === 'lastVisit' ? 'active-sort' : ''}>
              Last Visit {getSortIndicator('lastVisit')}
            </th>
            <th onClick={() => requestSort('spellbooks')} className={sortConfig.key === 'spellbooks' ? 'active-sort' : ''}>
              Spellbooks {getSortIndicator('spellbooks')}
            </th>
            <th onClick={() => requestSort('customSpells')} className={sortConfig.key === 'customSpells' ? 'active-sort' : ''}>
              Custom Spells {getSortIndicator('customSpells')}
            </th>
            <th onClick={() => requestSort('isAdmin')} className={sortConfig.key === 'isAdmin' ? 'active-sort' : ''}>
              Admin {getSortIndicator('isAdmin')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.length > 0 ? (
            sortedUsers.map(user => (
              <React.Fragment key={user.id}>
                <tr 
                  className={expandedUserId === user.id ? 'expanded' : ''}
                  onClick={() => toggleUserExpand(user.id)}
                >
                  <td>{user.username || 'N/A'}</td>
                  <td>{user.email || 'N/A'}</td>
                  <td>{formatDate(user.createdAt)}</td>
                  <td>{formatDate(user.lastVisit, true)}</td>
                  <td>{user.spellbooks ? Object.keys(user.spellbooks).length : 0}</td>
                  <td>{user.customSpells ? Object.keys(user.customSpells).length : 0}</td>
                  <td>
                    <span className={`admin-status ${user.isAdmin ? 'is-admin' : 'not-admin'}`}>
                      {user.isAdmin ? 'Yes' : 'No'}
                    </span>
                  </td>
                </tr>
                {expandedUserId === user.id && (
                  <tr className="details-row">
                    <td colSpan="7">
                      <div className="user-details-expanded">
                        <div className="user-details-grid">
                          <div className="user-info">
                            <h3>User Details</h3>
                            <div className="user-info-grid">
                              <div className="user-info-column">
                                <p><strong>Username:</strong> {user.username || 'N/A'}</p>
                                <p><strong>Email:</strong> {user.email || 'N/A'}</p>
                                <p><strong>User ID:</strong> <span className="user-id">{user.id}</span></p>
                              </div>
                              <div className="user-info-column">
                                <p><strong>Created:</strong> {formatDate(user.createdAt, true)}</p>
                                <p><strong>Last Visit:</strong> {formatDate(user.lastVisit, true)}</p>
                                <p><strong>Admin Status:</strong> {user.isAdmin ? 'Administrator' : 'Regular User'}</p>
                              </div>
                            </div>
                            
                            <div className="user-actions">
                              <h4>User Actions</h4>
                              <div className="action-buttons">
                                {!user.isAdmin ? (
                                  <button 
                                    className="admin-button grant-admin compact"
                                    onClick={(e) => { e.stopPropagation(); initiateAdminAction(user.id, 'add'); }}
                                    title="Grant admin privileges"
                                  >
                                    Make Admin
                                  </button>
                                ) : (
                                  <button 
                                    className="admin-button revoke-admin compact"
                                    onClick={(e) => { e.stopPropagation(); initiateAdminAction(user.id, 'remove'); }}
                                    title="Revoke admin privileges"
                                    disabled={user.id === currentUser.uid || adminUsers.length <= 1}
                                  >
                                    Remove Admin
                                  </button>
                                )}
                                <button 
                                  className="delete-button compact"
                                  onClick={(e) => { e.stopPropagation(); handleDeleteUser(user.id, e); }}
                                  title="Delete user"
                                >
                                  Delete User
                                </button>
                              </div>
                            </div>
                          </div>
                          
                          <div className="user-collections">
                            <div className="user-spellbooks">
                              <h4>Spellbooks</h4>
                              {user.spellbooks && Object.keys(user.spellbooks).length > 0 ? (
                                <ul>
                                  {Object.entries(user.spellbooks).map(([name, spells]) => (
                                    <li key={name}>
                                      <strong>{name}</strong> - {Array.isArray(spells) ? spells.length : 0} spells
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No spellbooks found</p>
                              )}
                            </div>
                            
                            <div className="user-custom-spells">
                              <h4>Custom Spells</h4>
                              {user.customSpells && Object.keys(user.customSpells).length > 0 ? (
                                <ul>
                                  {Object.values(user.customSpells).map(spell => (
                                    <li key={spell.id}>
                                      <strong>{spell.name}</strong> - Level {spell.level} {spell.school}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No custom spells found</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="no-results">
                No users found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable; 