import React, { useState, createContext, useCallback, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import HomePage from './pages/HomePage';
import SpellDetailPage from './pages/SpellDetailPage';
import SpellList from './components/SpellList';
import SpellBook from './components/SpellBook';
import PreparedSpells from './components/PreparedSpells';
import NavBar from './components/NavBar';
import TabBar from './components/TabBar';
import Footer from './components/Footer';
import { clearFilters } from './utils/spellFilters';
import spellsData from './data/spells.json';
import './App.css';
import { BookIcon, PrepareSpellIcon, AllSpellsIcon } from './data/Icons';
import NotificationPopup from './components/NotificationPopup';
import Login from './components/Login';
import { AuthProvider } from './AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { DiceProvider } from './contexts/DiceContext';
import { FeatureFlagsProvider } from './FeatureFlagsContext';
import { UserLimitsProvider } from './UserLimitsContext';
import AccountPage from './pages/AccountPage';
import AdminDashboard from './pages/AdminDashboard';
import ForgotPassword from './components/ForgotPassword';
import {
  getUserSpellbooks,
  updateUserSpellbooks,
  debouncedUpdateUserSpellbooks,
  getUserSettings,
  updateUserSettings,
  debouncedUpdateUserSettings,
  getUserPreparedSpells,
  updateUserPreparedSpells,
  getUserSpellSlots,
  updateUserSpellSlots,
  getUserActiveSpellbook,
  updateUserActiveSpellbook,
  getUserCustomSpells,
  getUserSpellPoints,
  updateUserSpellPoints,
  getUserCharacterLevels,
  updateUserCharacterLevel,
  getUserData,
  createSpellbook as createSpellbookInFirestore
} from './firebase/firestoreOperations';
import HowToPage from './pages/HowToPage';
import FAQPage from './pages/FAQPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import PrintablesPage from './pages/PrintablesPage';
import SpellResourceManager from './components/SpellResourceManager';
import PageHero from './components/PageHero';
import SpellTableControls from './components/SpellTableControls';
import ProtectedRoute from './components/ProtectedRoute';
import PageHead from './components/PageHead';

export const NotificationContext = createContext();

// Utility function for conditional logging
const logger = (message, ...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(message, ...args);
  }
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

function AppContent() {
  const [filters, setFilters] = useState(clearFilters());
  const [selectedSpells, setSelectedSpells] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [convertToMetric, setConvertToMetric] = useState(false);
  const [spellbooks, setSpellbooks] = useState({ 'My Spellbook': [] });
  const [preparedSpellsByBook, setPreparedSpellsByBook] = useState({});
  const [showRitualSpells, setShowRitualSpells] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showShortDescriptions, setShowShortDescriptions] = useState(false);
  const [spellSlotsByBook, setSpellSlotsByBook] = useState({});
  const [currentSpellbook, setCurrentSpellbook] = useState('My Spellbook');
  const [spellListFilters, setSpellListFilters] = useState(clearFilters());
  const [spellbookFilters, setSpellbookFilters] = useState(clearFilters());
  const [preparedSpellsFilters, setPreparedSpellsFilters] = useState(clearFilters());
  const [spellListSortConfig, setSpellListSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [spellbookSortConfig, setSpellbookSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [preparedSpellsSortConfig, setPreparedSpellsSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [spellListPagination, setSpellListPagination] = useState({ itemsPerPage: 25, currentPage: 1 });
  const [spellbookPagination, setSpellbookPagination] = useState({ itemsPerPage: 25, currentPage: 1 });
  const [preparedSpellsPagination, setPreparedSpellsPagination] = useState({ itemsPerPage: 25, currentPage: 1 });
  const [showSpellSlotsEverywhere, setShowSpellSlotsEverywhere] = useState(
    JSON.parse(localStorage.getItem('showSpellSlotsEverywhere') || 'false')
  );
  const [spellPointsByBook, setSpellPointsByBook] = useState({});
  const [useSpellPoints, setUseSpellPoints] = useState(
    JSON.parse(localStorage.getItem('useSpellPoints') || 'false')
  );
  const [allowMultipleHighLevelCasts, setAllowMultipleHighLevelCasts] = useState(
    JSON.parse(localStorage.getItem('allowMultipleHighLevelCasts') || 'false')
  );
  const [characterLevelsByBook, setCharacterLevelsByBook] = useState({});
  const [highLevelSpellUses, setHighLevelSpellUses] = useState({});
  const [showSchoolTags, setShowSchoolTags] = useState(false);

  const { currentUser } = useAuth();
  const location = useLocation();

  const isLoginPage = location.pathname === '/login' || location.pathname === '/forgot-password';
  const isHomePage = location.pathname === '/';
  const isAccountPage = location.pathname === '/account';
  const isContactPage = location.pathname === '/contact';
  const isPrintablesPage = location.pathname === '/tools/printables';
  const isHowToPage = location.pathname.includes('/how-to');
  const isFAQPage = location.pathname.includes('/faq');
  const isAboutPage = location.pathname.includes('/about');
  const isAdminPage = location.pathname.includes('/admin');

  // Condition for TabBar only
  const shouldShowTabBar = !isLoginPage && !isHomePage && !isAccountPage &&
    !isHowToPage && !isFAQPage && !isAboutPage &&
    !isContactPage && !isPrintablesPage && !isAdminPage;

  // Condition for PageHero - show on all pages except homepage and login
  const shouldShowPageHero = !isLoginPage && !isHomePage;

  // Add a ref for the SpellBook component
  const spellBookRef = useRef(null);

  const handleSpellbookChange = useCallback(async (newSpellbook) => {
    setCurrentSpellbook(newSpellbook);
    if (currentUser) {
      await updateUserActiveSpellbook(currentUser.uid, newSpellbook);
    } else {
      localStorage.setItem('lastActiveSpellbook', newSpellbook);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        // Get all user data in a single Firestore read
        const userData = await getUserData(currentUser.uid);

        if (!userData) {
          console.error('Failed to fetch user data');
          return;
        }

        console.log('Fetched user data:', userData);

        // Update state with the retrieved data
        setSpellbooks(userData.spellbooks);

        // Settings
        setShowShortDescriptions(userData.settings.showShortDescriptions || false);
        setShowRitualSpells(userData.settings.showRitualSpells || false);
        setConvertToMetric(userData.settings.convertToMetric || false);
        setShowSpellSlotsEverywhere(userData.settings.showSpellSlotsEverywhere || false);
        setUseSpellPoints(userData.settings.useSpellPoints || false);
        setAllowMultipleHighLevelCasts(userData.settings.allowMultipleHighLevelCasts || false);
        setShowSchoolTags(userData.settings.showSchoolTags ?? false);

        // CRITICAL FIX: Make sure we're handling prepared spells properly
        console.log('Setting prepared spells:', userData.preparedSpells);
        if (userData.preparedSpells) {
          setPreparedSpellsByBook(userData.preparedSpells);
        } else {
          setPreparedSpellsByBook({});
        }

        // CRITICAL FIX: Make sure we're handling spell slots properly
        // Use spellSlotsByBook instead of spellSlots to maintain compatibility
        console.log('Setting spell slots:', userData.spellSlotsByBook || userData.spellSlots);
        if (userData.spellSlotsByBook) {
          setSpellSlotsByBook(userData.spellSlotsByBook);
        } else if (userData.spellSlots) {
          // For backward compatibility - migrate from old format
          setSpellSlotsByBook(userData.spellSlots);
        } else {
          // Default empty spell slots structure
          const defaultSlots = {};
          setSpellSlotsByBook(defaultSlots);
        }

        // CRITICAL FIX: Make sure we're handling spell points properly
        console.log('Setting spell points:', userData.spellPoints);
        if (userData.spellPoints) {
          setSpellPointsByBook(userData.spellPoints);
        } else {
          // Default empty spell points structure
          const defaultPoints = {};
          setSpellPointsByBook(defaultPoints);
        }

        // Active spellbook
        setCurrentSpellbook(userData.activeSpellbook);

        // Character levels
        setCharacterLevelsByBook(userData.characterLevelsByBook);
        
        // CRITICAL FIX: Set spell notes
        if (userData.spellNotes) {
          console.log('Setting spell notes:', userData.spellNotes);
          // Store spell notes in a global variable for components to access
          window.spellNotes = userData.spellNotes;
        }
      };
      
      fetchUserData();
    } else {
      const guestSpellbooks = JSON.parse(localStorage.getItem('guestSpellbooks')) || { 'My Spellbook': [] };
      setSpellbooks(guestSpellbooks);

      const savedActiveSpellbook = localStorage.getItem('lastActiveSpellbook');
      if (savedActiveSpellbook && guestSpellbooks[savedActiveSpellbook]) {
        setCurrentSpellbook(savedActiveSpellbook);
      } else {
        setCurrentSpellbook('My Spellbook');
      }

      const guestPreparedSpells = JSON.parse(localStorage.getItem('guestPreparedSpellsByBook')) || {};
      setPreparedSpellsByBook(guestPreparedSpells);

      try {
        // CRITICAL FIX: Make sure we're loading spell slots correctly for non-logged-in users
        const savedSlots = localStorage.getItem('spellSlotsByBook');
        if (savedSlots) {
          const parsedSlots = JSON.parse(savedSlots);
          // Check if the parsed data has the expected structure
          if (typeof parsedSlots === 'object') {
            setSpellSlotsByBook(parsedSlots);
          } else {
            throw new Error('Invalid spell slots format');
          }
        } else {
          // Create a default structure for spell slots
          const defaultSlots = { 'My Spellbook': Array(9).fill().map(() => ({ max: 0, used: 0 })) };
          setSpellSlotsByBook(defaultSlots);
          localStorage.setItem('spellSlotsByBook', JSON.stringify(defaultSlots));
        }
      } catch (error) {
        console.error('Error loading spell slots:', error);
        const defaultSlots = { 'My Spellbook': Array(9).fill().map(() => ({ max: 0, used: 0 })) };
        setSpellSlotsByBook(defaultSlots);
        localStorage.setItem('spellSlotsByBook', JSON.stringify(defaultSlots));
      }

      try {
        // CRITICAL FIX: Make sure we're loading spell points correctly for non-logged-in users
        const savedSpellPoints = localStorage.getItem('spellPointsByBook');
        if (savedSpellPoints) {
          const parsedPoints = JSON.parse(savedSpellPoints);
          if (typeof parsedPoints === 'object') {
            setSpellPointsByBook(parsedPoints);
          } else {
            throw new Error('Invalid spell points format');
          }
        } else {
          const defaultPoints = { 'My Spellbook': { points: 0, used: 0, highLevelSpellUses: {} } };
          setSpellPointsByBook(defaultPoints);
          localStorage.setItem('spellPointsByBook', JSON.stringify(defaultPoints));
        }
      } catch (error) {
        console.error('Error loading spell points:', error);
        const defaultPoints = { 'My Spellbook': { points: 0, used: 0, highLevelSpellUses: {} } };
        setSpellPointsByBook(defaultPoints);
        localStorage.setItem('spellPointsByBook', JSON.stringify(defaultPoints));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem('showSpellSlotsEverywhere', JSON.stringify(showSpellSlotsEverywhere));
  }, [showSpellSlotsEverywhere]);

  useEffect(() => {
    localStorage.setItem('useSpellPoints', JSON.stringify(useSpellPoints));
  }, [useSpellPoints]);

  useEffect(() => {
    localStorage.setItem('allowMultipleHighLevelCasts', JSON.stringify(allowMultipleHighLevelCasts));
  }, [allowMultipleHighLevelCasts]);

  const addToSpellbook = useCallback(async (spellbookName, spellsToAdd, onSuccess) => {
    try {
      const updatedSpellbook = [...(spellbooks[spellbookName] || [])];
      const addedSpells = [];
      const duplicateSpells = [];

      // Use Promise.all to handle async operations
      await Promise.all(spellsToAdd.map(async (spellName) => {
        // First check built-in spells
        let spellObject = spellsData.find(spell => spell.name === spellName);

        // If not found in built-in spells and user is logged in, check custom spells
        if (!spellObject && currentUser) {
          const userCustomSpells = await getUserCustomSpells(currentUser.uid);
          spellObject = Object.values(userCustomSpells).find(spell => spell.name === spellName);
        }

        if (spellObject) {
          if (!updatedSpellbook.includes(spellObject.id)) {
            updatedSpellbook.push(spellObject.id);
            addedSpells.push(spellName);
          } else {
            duplicateSpells.push(spellName);
          }
        }
      }));

      const newSpellbooks = {
        ...spellbooks,
        [spellbookName]: updatedSpellbook
      };

      if (currentUser) {
        // For Firestore updates, use the non-debounced version for immediate effect
        // This ensures the database is updated right away
        await updateUserSpellbooks(currentUser.uid, newSpellbooks);

        // Still use the debounced version for any subsequent updates
        // that might happen in quick succession
        debouncedUpdateUserSpellbooks(currentUser.uid, newSpellbooks);
      } else {
        localStorage.setItem('guestSpellbooks', JSON.stringify(newSpellbooks));
      }

      // Update local state immediately
      setSpellbooks(newSpellbooks);

      // Always refresh the spellbook contents if we're viewing the updated spellbook
      if (spellBookRef.current && currentSpellbook === spellbookName) {
        logger(`Refreshing spellbook ${spellbookName} after adding ${addedSpells.length} spells`);
        // Force a small delay to ensure state updates have propagated
        setTimeout(() => {
          spellBookRef.current.refreshSpellbookContents();
        }, 50);
      }

      // Call the onSuccess callback if provided
      if (typeof onSuccess === 'function') {
        onSuccess({ added: addedSpells, duplicates: duplicateSpells });
      }

      return { added: addedSpells, duplicates: duplicateSpells };
    } catch (error) {
      console.error('Error adding spells to spellbook:', error);
      throw error;
    }
  }, [currentUser, spellbooks, setSpellbooks, debouncedUpdateUserSpellbooks, currentSpellbook]);

  const createSpellbook = async (name) => {
    try {
      if (currentUser && !spellbooks[name]) {
        await createSpellbookInFirestore(currentUser.uid, name);
        
        setSpellbooks(prevSpellbooks => {
          const newSpellbooks = {
            ...prevSpellbooks,
            [name]: []
          };
          // Use debounced version for better performance
          debouncedUpdateUserSpellbooks(currentUser.uid, newSpellbooks);
          return newSpellbooks;
        });
        
        addNotification(`Spellbook "${name}" created successfully`, 'success');
      }
    } catch (error) {
      // Display a user-friendly notification instead of throwing the error
      addNotification(error.message, 'error');
      console.error('Error creating spellbook:', error);
    }
  };

  const addNotification = useCallback((message, type = 'info', duration = 3000) => {
    setNotification({ message, type, duration });
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, notification.duration);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const updateUserSetting = useCallback(async (settingName, value) => {
    if (currentUser) {
      const updatedSettings = { [settingName]: value };
      debouncedUpdateUserSettings(currentUser.uid, updatedSettings);
    }
  }, [currentUser]);

  const updateShowShortDescriptions = useCallback((value) => {
    setShowShortDescriptions(value);
    updateUserSetting('showShortDescriptions', value);
  }, [updateUserSetting]);

  const updateShowRitualSpells = useCallback((value) => {
    setShowRitualSpells(value);
    updateUserSetting('showRitualSpells', value);
  }, [updateUserSetting]);

  const updateConvertToMetric = useCallback((value) => {
    setConvertToMetric(value);
    updateUserSetting('convertToMetric', value);
  }, [updateUserSetting]);

  const updateShowSpellSlotsEverywhere = useCallback((value) => {
    setShowSpellSlotsEverywhere(value);
    updateUserSetting('showSpellSlotsEverywhere', value);
  }, [updateUserSetting]);

  const updateShowSchoolTags = useCallback((value) => {
    setShowSchoolTags(value);
    updateUserSetting('showSchoolTags', value);
  }, [updateUserSetting]);

  const updatePreparedSpells = useCallback((spellbookName, newPreparedSpells) => {
    console.log(`Updating prepared spells for ${spellbookName}:`, newPreparedSpells);
    
    // Update local state
    setPreparedSpellsByBook(prev => {
      const updated = {
        ...prev,
        [spellbookName]: newPreparedSpells
      };
      console.log('New preparedSpellsByBook state:', updated);
      return updated;
    });

    // Save to database or localStorage
    if (currentUser) {
      console.log(`Saving prepared spells to database for user ${currentUser.uid}`);
      updateUserPreparedSpells(currentUser.uid, spellbookName, newPreparedSpells)
        .then(() => console.log('Successfully saved prepared spells to database'))
        .catch(err => console.error('Error saving prepared spells to database:', err));
    } else {
      // Get current prepared spells from localStorage
      const currentPreparedSpellsByBook = JSON.parse(localStorage.getItem('guestPreparedSpellsByBook') || '{}');
      
      // Update with new prepared spells for the current spellbook
      const updatedPreparedSpellsByBook = {
        ...currentPreparedSpellsByBook,
        [spellbookName]: newPreparedSpells
      };
      
      // Save back to localStorage
      console.log('Saving prepared spells to localStorage:', updatedPreparedSpellsByBook);
      localStorage.setItem('guestPreparedSpellsByBook', JSON.stringify(updatedPreparedSpellsByBook));
    }
  }, [currentUser]);

  const updateSpellSlots = useCallback(async (spellbookName, newSpellSlots) => {
    console.log(`Updating spell slots for ${spellbookName}:`, newSpellSlots);
    
    // Validate that new spell slots are in the proper format (array of objects with max and used properties)
    if (!Array.isArray(newSpellSlots)) {
      console.error('Invalid spell slots format: not an array', newSpellSlots);
      addNotification('Invalid spell slots format', 'error');
      return;
    }
    
    // Ensure each slot has the expected structure
    const validatedSlots = newSpellSlots.map(slot => ({
      max: Number(slot.max) || 0,
      used: Number(slot.used) || 0
    }));
    
    try {
      // Save to database or localStorage
      if (currentUser) {
        console.log(`Saving spell slots to database for user ${currentUser.uid}, spellbook ${spellbookName}:`, validatedSlots);
        
        // Use await here to catch errors immediately
        const savedSlots = await updateUserSpellSlots(currentUser.uid, spellbookName, validatedSlots);
        console.log('Successfully saved spell slots to database:', savedSlots);
        
        // Update local state with the validated slots that were actually saved
        setSpellSlotsByBook(prev => {
          const updated = {
            ...prev,
            [spellbookName]: savedSlots || validatedSlots
          };
          console.log('New spellSlotsByBook state:', updated);
          return updated;
        });
        
        // Show a success notification if desired
        // addNotification('Spell slots updated successfully', 'success');
      } else {
        // Get current spell slots from localStorage
        const currentSpellSlotsByBook = JSON.parse(localStorage.getItem('spellSlotsByBook') || '{}');
        
        // Update with new spell slots for the current spellbook
        const updatedSpellSlotsByBook = {
          ...currentSpellSlotsByBook,
          [spellbookName]: validatedSlots
        };
        
        // Save back to localStorage
        console.log('Saving spell slots to localStorage:', updatedSpellSlotsByBook);
        localStorage.setItem('spellSlotsByBook', JSON.stringify(updatedSpellSlotsByBook));
        
        // Update local state
        setSpellSlotsByBook(updatedSpellSlotsByBook);
      }
    } catch (error) {
      console.error('Error updating spell slots:', error);
      // Show a detailed error message
      addNotification(`Failed to save spell slots: ${error.message}`, 'error');
    }
  }, [currentUser, addNotification, updateUserSpellSlots]);

  const updateSpellPoints = useCallback(async (spellbookName, newSpellPoints) => {
    console.log(`Updating spell points for ${spellbookName}:`, newSpellPoints);
    
    // Update local state
    setSpellPointsByBook(prev => {
      const updated = {
        ...prev,
        [spellbookName]: newSpellPoints
      };
      console.log('New spellPointsByBook state:', updated);
      return updated;
    });

    // Save to database or localStorage
    if (currentUser) {
      console.log(`Saving spell points to database for user ${currentUser.uid}`);
      try {
        await updateUserSpellPoints(currentUser.uid, spellbookName, newSpellPoints);
        console.log('Successfully saved spell points to database');
      } catch (err) {
        console.error('Error saving spell points to database:', err);
      }
    } else {
      // Get current spell points from localStorage
      const currentSpellPointsByBook = JSON.parse(localStorage.getItem('spellPointsByBook') || '{}');
      
      // Update with new spell points for the current spellbook
      const updatedSpellPointsByBook = {
        ...currentSpellPointsByBook,
        [spellbookName]: newSpellPoints
      };
      
      // Save back to localStorage
      console.log('Saving spell points to localStorage:', updatedSpellPointsByBook);
      localStorage.setItem('spellPointsByBook', JSON.stringify(updatedSpellPointsByBook));
    }
  }, [currentUser]);

  const updateUseSpellPoints = useCallback((value) => {
    setUseSpellPoints(value);
    updateUserSetting('useSpellPoints', value);
  }, [updateUserSetting]);

  const updateAllowMultipleHighLevelCasts = useCallback((value) => {
    setAllowMultipleHighLevelCasts(value);
    updateUserSetting('allowMultipleHighLevelCasts', value);
  }, [updateUserSetting]);

  const handleSetCharacterLevel = (level, spellbookName) => {
    // Update local state
    setCharacterLevelsByBook(prev => ({
      ...prev,
      [spellbookName]: level
    }));

    // Update in Firebase
    if (currentUser) {
      updateUserCharacterLevel(currentUser.uid, spellbookName, level);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      setSpellListSortConfig({ key: 'name', direction: 'asc' });
      setSpellbookSortConfig({ key: 'name', direction: 'asc' });
      setPreparedSpellsSortConfig({ key: 'name', direction: 'asc' });
      setSpellListPagination({ itemsPerPage: 25, currentPage: 1 });
      setSpellbookPagination({ itemsPerPage: 25, currentPage: 1 });
      setPreparedSpellsPagination({ itemsPerPage: 25, currentPage: 1 });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname;
    let title = 'TableMancer';

    switch (path) {
      case '/spells':
        title = 'Spells | TableMancer';
        break;
      case '/spellbooks':
        title = 'Spellbooks | TableMancer';
        break;
      case '/prepared-spells':
        title = 'Prepared Spells | TableMancer';
        break;
      case '/how-to':
        title = 'How To | TableMancer';
        break;
      case '/faq':
        title = 'FAQ | TableMancer';
        break;
      case '/about':
        title = 'About | TableMancer';
        break;
      case '/contact':
        title = 'Contact | TableMancer';
        break;
      case '/login':
        title = 'Login | TableMancer';
        break;
      case '/forgot-password':
        title = 'Forgot Password | TableMancer';
        break;
      case '/account':
        title = 'Account | TableMancer';
        break;
      case '/tools/printables':
        title = 'Printables | TableMancer';
        break;
      default:
        title = 'TableMancer';
    }

    document.title = title;
  }, [location.pathname]);

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      <div className="App">
        <ScrollToTop />
        <NavBar />
        {shouldShowPageHero && <PageHero />}
        {shouldShowTabBar && <TabBar />}
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/spells" element={
              <div className="route-content">
                <PageHead
                  title="D&D 5e Spell List | Complete Searchable Spell Database"
                  description="Browse, filter and search all D&D 5e spells with TableMancer's comprehensive spell database. Sort by class, level, school and more."
                  keywords={['D&D spell list', 'DnD 5e', 'spell database', 'searchable spells', 'spell filter', 'D&D magic', 'TableMancer', 'spell finder']}
                  canonical="https://tablemancer.com/spells"
                />
                <SpellList
                  filters={spellListFilters}
                  setFilters={setSpellListFilters}
                  selectedSpells={selectedSpells}
                  setSelectedSpells={setSelectedSpells}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  convertToMetric={convertToMetric}
                  setConvertToMetric={updateConvertToMetric}
                  spellbooks={spellbooks}
                  addToSpellbook={addToSpellbook}
                  showShortDescriptions={showShortDescriptions}
                  setShowShortDescriptions={updateShowShortDescriptions}
                  showSchoolTags={showSchoolTags}
                  setShowSchoolTags={updateShowSchoolTags}
                  sortConfig={spellListSortConfig}
                  setSortConfig={setSpellListSortConfig}
                  pagination={spellListPagination}
                  setPagination={setSpellListPagination}
                  showSpellSlotsEverywhere={showSpellSlotsEverywhere}
                  currentSpellbook={currentSpellbook}
                  setCurrentSpellbook={handleSpellbookChange}
                  spellSlotsByBook={spellSlotsByBook}
                  updateSpellSlots={updateSpellSlots}
                  setShowSpellSlotsEverywhere={updateShowSpellSlotsEverywhere}
                  spellPointsByBook={spellPointsByBook}
                  updateSpellPoints={updateSpellPoints}
                  characterLevel={characterLevelsByBook[currentSpellbook] || 0}
                  setCharacterLevel={handleSetCharacterLevel}
                  highLevelSpellUses={highLevelSpellUses}
                  setHighLevelSpellUses={setHighLevelSpellUses}
                  allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
                  setAllowMultipleHighLevelCasts={updateAllowMultipleHighLevelCasts}
                  useSpellPoints={useSpellPoints}
                  setUseSpellPoints={updateUseSpellPoints}
                />
              </div>
            } />
            <Route path="/spellbooks" element={
              <div className="route-content">
                <PageHead
                  title="D&D 5e Spellbook Creator | Organize Your Wizard Spells"
                  description="Create custom spellbooks for your D&D 5e characters. Save and organize spells by class, level, and campaign with TableMancer's spellbook manager."
                  keywords={['D&D spellbook', 'DnD 5e', 'wizard spellbook', 'spellbook creator', 'spell organizer', 'D&D character spells', 'custom spellbook', 'digital spellbook']}
                  canonical="https://tablemancer.com/spellbooks"
                />
                <SpellBook
                  ref={spellBookRef}
                  filters={spellbookFilters}
                  setFilters={setSpellbookFilters}
                  selectedSpells={selectedSpells}
                  setSelectedSpells={setSelectedSpells}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  convertToMetric={convertToMetric}
                  setConvertToMetric={updateConvertToMetric}
                  spellbooks={spellbooks}
                  setSpellbooks={setSpellbooks}
                  addToSpellbook={addToSpellbook}
                  createSpellbook={createSpellbook}
                  preparedSpellsByBook={preparedSpellsByBook}
                  updatePreparedSpells={updatePreparedSpells}
                  currentSpellbook={currentSpellbook}
                  setCurrentSpellbook={handleSpellbookChange}
                  showShortDescriptions={showShortDescriptions}
                  setShowShortDescriptions={updateShowShortDescriptions}
                  showRitualSpells={showRitualSpells}
                  setShowRitualSpells={setShowRitualSpells}
                  sortConfig={spellbookSortConfig}
                  setSortConfig={setSpellbookSortConfig}
                  pagination={spellbookPagination}
                  setPagination={setSpellbookPagination}
                  showSpellSlotsEverywhere={showSpellSlotsEverywhere}
                  spellSlotsByBook={spellSlotsByBook}
                  updateSpellSlots={updateSpellSlots}
                  setShowSpellSlotsEverywhere={updateShowSpellSlotsEverywhere}
                  useSpellPoints={useSpellPoints}
                  setUseSpellPoints={updateUseSpellPoints}
                  spellPointsByBook={spellPointsByBook}
                  updateSpellPoints={updateSpellPoints}
                  allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
                  setAllowMultipleHighLevelCasts={updateAllowMultipleHighLevelCasts}
                  characterLevel={characterLevelsByBook[currentSpellbook] || 0}
                  setCharacterLevel={handleSetCharacterLevel}
                  showSchoolTags={showSchoolTags}
                  setShowSchoolTags={updateShowSchoolTags}
                />
              </div>
            } />
            <Route path="/prepared-spells" element={
              <div className="route-content">
                <PageHead
                  title="D&D 5e Spell Preparation Tracker | Manage Spell Slots"
                  description="Track prepared spells and spell slots for your D&D 5e spellcasters. Easily manage which spells are ready to cast during your campaign."
                  keywords={['D&D spell slots', 'DnD 5e', 'prepared spells', 'spell preparation', 'track spell slots', 'spell slot manager', 'spellcasting tracker', 'spell points']}
                  canonical="https://tablemancer.com/prepared-spells"
                />
                <PreparedSpells
                  filters={preparedSpellsFilters}
                  setFilters={setPreparedSpellsFilters}
                  selectedSpells={selectedSpells}
                  setSelectedSpells={setSelectedSpells}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  convertToMetric={convertToMetric}
                  setConvertToMetric={updateConvertToMetric}
                  preparedSpellsByBook={preparedSpellsByBook}
                  updatePreparedSpells={updatePreparedSpells}
                  spellbooks={spellbooks}
                  currentSpellbook={currentSpellbook}
                  setCurrentSpellbook={handleSpellbookChange}
                  showRitualSpells={showRitualSpells}
                  setShowRitualSpells={setShowRitualSpells}
                  showShortDescriptions={showShortDescriptions}
                  setShowShortDescriptions={updateShowShortDescriptions}
                  showSchoolTags={showSchoolTags}
                  setShowSchoolTags={updateShowSchoolTags}
                  spellSlotsByBook={spellSlotsByBook}
                  updateSpellSlots={updateSpellSlots}
                  sortConfig={preparedSpellsSortConfig}
                  setSortConfig={setPreparedSpellsSortConfig}
                  pagination={preparedSpellsPagination}
                  setPagination={setPreparedSpellsPagination}
                  showSpellSlotsEverywhere={showSpellSlotsEverywhere}
                  setShowSpellSlotsEverywhere={updateShowSpellSlotsEverywhere}
                  useSpellPoints={useSpellPoints}
                  setUseSpellPoints={updateUseSpellPoints}
                  spellPointsByBook={spellPointsByBook}
                  updateSpellPoints={updateSpellPoints}
                  allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
                  setAllowMultipleHighLevelCasts={updateAllowMultipleHighLevelCasts}
                  characterLevel={characterLevelsByBook[currentSpellbook] || 0}
                  setCharacterLevel={handleSetCharacterLevel}
                />
              </div>
            } />
            <Route path="/spell/:id" element={
              <SpellDetailPage
                convertToMetric={convertToMetric}
                setConvertToMetric={updateConvertToMetric}
                spellbooks={spellbooks}
                addToSpellbook={addToSpellbook}
              />
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Navigate to="/login" replace state={{ initialMode: "signup" }} />} />
            <Route path="/account" element={
              <ProtectedRoute>
                <AccountPage />
              </ProtectedRoute>
            } />
            <Route path="/how-to" element={
              <div className="route-content">
                <HowToPage />
              </div>
            } />
            <Route path="/faq" element={
              <div className="route-content">
                <FAQPage />
              </div>
            } />
            <Route path="/about" element={
              <div className="route-content">
                <AboutPage />
              </div>
            } />
            <Route path="/contact" element={
              <div className="route-content">
                <ContactPage />
              </div>
            } />
            <Route path="/tools/printables" element={
              <div className="route-content">
                <PrintablesPage />
              </div>
            } />
            <Route path="/admin" element={
              <ProtectedRoute requireAdmin={true}>
                <AdminDashboard />
              </ProtectedRoute>
            } />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </div>
        {notification && (
          <NotificationPopup
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification(null)}
          />
        )}
        <Footer />
      </div>
    </NotificationContext.Provider>
  );
}

function App() {
  const [notification, setNotification] = useState(null);

  const addNotification = useCallback((message, type = 'info', duration = 3000) => {
    setNotification({ message, type, duration });
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, notification.duration);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <AuthProvider>
      <ThemeProvider>
        <DiceProvider>
          <FeatureFlagsProvider>
            <UserLimitsProvider>
              <NotificationContext.Provider value={{ addNotification }}>
                <Router>
                  <ScrollToTop />
                  <div className="app-container">
                    <AppContent />
                  </div>
                  {notification && (
                    <NotificationPopup
                      message={notification.message}
                      type={notification.type}
                      onClose={() => setNotification(null)}
                    />
                  )}
                </Router>
              </NotificationContext.Provider>
            </UserLimitsProvider>
          </FeatureFlagsProvider>
        </DiceProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
