import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { db } from '../firebase/firebase';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  startsWith,
  getCountFromServer,
  Timestamp,
  getAggregateFromServer,
  count,
  sum
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { addUserAsAdmin, removeUserAsAdmin, isUserAdmin } from '../utils/setupAdmin';
import { getFeatureFlags, updateFeatureFlags, getUserLimits, updateUserLimits } from '../firebase/firestoreOperations';
import { useFeatureFlags } from '../FeatureFlagsContext';
import UserTable from '../components/UserTable';
import '../styles/pages/AdminDashboard.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, AreaChart, Area, BarChart, Bar, PieChart, Pie, Cell
} from 'recharts';

// Get admin setup code from environment variables
// Falls back to a default code if environment variable is not set
const ADMIN_SETUP_CODE = process.env.REACT_APP_ADMIN_SETUP_CODE || "spellmanager2024";

// Colors for charts
const COLORS = ['#3d5a80', '#ee6c4d', '#98c1d9', '#e0fbfc', '#293241', '#5d8aa8', '#f4a261'];

// Error handler for ResizeObserver errors
const suppressResizeObserverErrors = () => {
  const errorHandler = (event) => {
    if (
      event.message?.includes('ResizeObserver') || 
      event.message?.includes('ResizeObserver loop completed with undelivered notifications') ||
      event.error?.message?.includes('ResizeObserver') ||
      event.error?.message?.includes('ResizeObserver loop completed with undelivered notifications')
    ) {
      // Prevent the error from appearing in the console
      event.preventDefault();
      event.stopPropagation();
      return true;
    }
    // Let other errors propagate
    return false;
  };

  const rejectionHandler = (event) => {
    if (
      event.reason?.message?.includes('ResizeObserver') ||
      event.reason?.message?.includes('ResizeObserver loop completed with undelivered notifications')
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  // Add the event listeners
  window.addEventListener('error', errorHandler, true);
  window.addEventListener('unhandledrejection', rejectionHandler, true);

  // Return a cleanup function to remove the listeners when component unmounts
  return () => {
    window.removeEventListener('error', errorHandler, true);
    window.removeEventListener('unhandledrejection', rejectionHandler, true);
  };
};

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [userStats, setUserStats] = useState({});
  const [registrationStats, setRegistrationStats] = useState({
    today: 0,
    thisWeek: 0,
    thisMonth: 0,
    thisYear: 0
  });
  const [adminUsers, setAdminUsers] = useState([]);
  const [adminActionInProgress, setAdminActionInProgress] = useState(false);
  const [adminActionMessage, setAdminActionMessage] = useState('');
  const [adminActionError, setAdminActionError] = useState('');
  const [showAdminConfirmation, setShowAdminConfirmation] = useState(false);
  const [adminSetupCode, setAdminSetupCode] = useState('');
  const [pendingAdminAction, setPendingAdminAction] = useState(null);
  const [growthData, setGrowthData] = useState([]);
  const [cachedGrowthData, setCachedGrowthData] = useState({
    week: null,
    month: null, 
    year: null,
    lastUpdated: null
  });
  const [activeTimeRange, setActiveTimeRange] = useState('year');
  const [activeUsers, setActiveUsers] = useState(0);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({});
  const [featureFlagsLoading, setFeatureFlagsLoading] = useState(true);
  const [featureFlagsError, setFeatureFlagsError] = useState(null);
  const [featureFlagsUpdateStatus, setFeatureFlagsUpdateStatus] = useState({ success: false, message: '' });
  const [userLimits, setUserLimits] = useState({
    maxSpellbooks: 3,
    maxCustomSpells: 5,
    maxPreparedPresets: 3
  });
  const [userLimitsLoading, setUserLimitsLoading] = useState(true);
  const [userLimitsError, setUserLimitsError] = useState(null);
  const [userLimitsUpdateStatus, setUserLimitsUpdateStatus] = useState({ success: false, message: '' });
  const { setFeatureFlags: updateGlobalFeatureFlags } = useFeatureFlags();

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Define fetchDashboardStats outside of useEffect
  const fetchDashboardStats = async () => {
    try {
      setLoading(true);

      // Get a sample of users (limit to 100 for efficiency)
      // This gives us enough data for accurate statistics without excessive reads
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(query(usersCollection, limit(100)));
      const userDocs = userSnapshot.docs.map(doc => doc.data());

      // Calculate total users using count aggregation (single read)
      const countSnapshot = await getCountFromServer(usersCollection);
      const totalUsers = countSnapshot.data().count;

      // Calculate statistics from the sample data
      let totalSpellbooks = 0;
      let totalCustomSpells = 0;
      let usersWithCustomSpells = 0;
      let usersWithMultipleSpellbooks = 0;

      userDocs.forEach(user => {
        // Count spellbooks
        const spellbooksCount = user.spellbooks ? Object.keys(user.spellbooks).length : 0;
        totalSpellbooks += spellbooksCount;

        if (spellbooksCount > 1) {
          usersWithMultipleSpellbooks++;
        }

        // Count custom spells
        const customSpellsCount = user.customSpells ? Object.keys(user.customSpells).length : 0;
        totalCustomSpells += customSpellsCount;

        if (customSpellsCount > 0) {
          usersWithCustomSpells++;
        }
      });

      // Extrapolate to the full user base if we have a sample
      const sampleSize = userDocs.length;
      if (sampleSize > 0 && sampleSize < totalUsers) {
        const scaleFactor = totalUsers / sampleSize;
        totalSpellbooks = Math.round(totalSpellbooks * scaleFactor);
        totalCustomSpells = Math.round(totalCustomSpells * scaleFactor);
      }

      // Calculate percentages
      const percentUsersWithCustomSpells = sampleSize > 0
        ? Math.round((usersWithCustomSpells / sampleSize) * 100)
        : 0;

      const percentUsersWithMultipleSpellbooks = sampleSize > 0
        ? Math.round((usersWithMultipleSpellbooks / sampleSize) * 100)
        : 0;

      // Calculate averages
      const averageSpellbooksPerUser = sampleSize > 0
        ? (totalSpellbooks / totalUsers).toFixed(1)
        : '0.0';

      const averageCustomSpellsPerUser = sampleSize > 0
        ? (totalCustomSpells / totalUsers).toFixed(1)
        : '0.0';

      // Calculate registration statistics
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const oneWeekAgo = new Date(today);
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      const oneMonthAgo = new Date(today);
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      const oneYearAgo = new Date(today);
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

      // Convert JavaScript dates to Firestore timestamps
      const todayTimestamp = Timestamp.fromDate(today);
      const oneWeekAgoTimestamp = Timestamp.fromDate(oneWeekAgo);
      const oneMonthAgoTimestamp = Timestamp.fromDate(oneMonthAgo);
      const oneYearAgoTimestamp = Timestamp.fromDate(oneYearAgo);

      // Count users created in different time periods from our sample
      let todayCount = 0;
      let weekCount = 0;
      let monthCount = 0;
      let yearCount = 0;
      let activeUsersCount = 0;

      // Calculate active users (users who logged in within the last 30 days)
      const thirtyDaysAgo = new Date(now);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const thirtyDaysAgoTimestamp = Timestamp.fromDate(thirtyDaysAgo);

      userDocs.forEach(user => {
        const createdAt = user.createdAt;
        const lastVisit = user.lastVisit;

        // Skip users without createdAt timestamp
        if (!createdAt) return;

        // Convert to Date object if it's a Firestore timestamp
        const createdDate = createdAt.toDate ? createdAt.toDate() : new Date(createdAt);

        if (createdDate >= today) {
          todayCount++;
        }
        if (createdDate >= oneWeekAgo) {
          weekCount++;
        }
        if (createdDate >= oneMonthAgo) {
          monthCount++;
        }
        if (createdDate >= oneYearAgo) {
          yearCount++;
        }

        // Check if user is active (has logged in within the last 30 days)
        if (lastVisit) {
          const lastVisitDate = lastVisit.toDate ? lastVisit.toDate() : new Date(lastVisit);
          if (lastVisitDate >= thirtyDaysAgo) {
            activeUsersCount++;
          }
        }
      });

      // Extrapolate registration counts if we have a sample
      if (sampleSize > 0 && sampleSize < totalUsers) {
        const scaleFactor = totalUsers / sampleSize;
        todayCount = Math.round(todayCount * scaleFactor);
        weekCount = Math.round(weekCount * scaleFactor);
        monthCount = Math.round(monthCount * scaleFactor);
        yearCount = Math.round(yearCount * scaleFactor);
        activeUsersCount = Math.round(activeUsersCount * scaleFactor);
      }

      // Set the statistics
      setRegistrationStats({
        today: todayCount,
        thisWeek: weekCount,
        thisMonth: monthCount,
        thisYear: yearCount,
        lastUpdated: new Date().toLocaleString()
      });

      setActiveUsers(activeUsersCount);

      // Set user stats
      setUserStats({
        totalUsers,
        totalSpellbooks,
        totalCustomSpells,
        totalPreparedSpells: Math.round(totalUsers * 0.7), // Estimate based on typical usage
        averageSpellbooksPerUser,
        averageCustomSpellsPerUser,
        percentUsersWithCustomSpells,
        percentUsersWithMultipleSpellbooks,
        lastUpdated: new Date().toLocaleString()
      });

      // Remove the growth data generation from here, as it now has its own useEffect

      setInitialLoading(false);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching dashboard stats:', err);
      setError('Failed to load dashboard statistics. Please try again later.');
      setInitialLoading(false);
      setLoading(false);
    }
  };

  // Check if current user is an admin
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        navigate('/');
        return;
      }

      try {
        const userDoc = await getDocs(collection(db, 'admins'));
        const adminIds = userDoc.docs.map(doc => doc.id);

        if (!adminIds.includes(currentUser.uid)) {
          navigate('/');
        }

        // Store admin users for later use
        setAdminUsers(adminIds);
      } catch (err) {
        console.error('Error checking admin status:', err);
        navigate('/');
      }
    };
    
    // Check if user is actually an admin
    checkAdminStatus();
    
    // Fetch initial dashboard statistics
    fetchDashboardStats();
    
    // Load feature flags
    loadFeatureFlags();
    
    // Load user limits
    loadUserLimits();
  }, [currentUser, navigate]);

  // Add dedicated useEffect for ResizeObserver error suppression
  useEffect(() => {
    // Set up the error suppression and get the cleanup function
    const cleanup = suppressResizeObserverErrors();
    
    // Clean up when component unmounts
    return cleanup;
  }, []);

  // Fetch dashboard statistics using aggregation queries
  useEffect(() => {
    if (adminUsers.length > 0) {
      fetchDashboardStats();
    }
  }, [adminUsers, activeTimeRange]);

  // Moved the generateGrowthData function outside useEffect and wrapped in useCallback
  const generateGrowthData = React.useCallback(async () => {
    try {
      const timeRange = activeTimeRange;
      
      console.log('Generating growth data for', timeRange);
      
      // Check if we have cached data for this time range and if it's less than 1 hour old
      const cacheTTL = 60 * 60 * 1000; // 1 hour in milliseconds
      const currentTime = new Date().getTime();
      
      if (cachedGrowthData[timeRange] && 
          cachedGrowthData.lastUpdated && 
          currentTime - cachedGrowthData.lastUpdated < cacheTTL) {
        // Use cached data if available and fresh
        console.log('Using cached growth data for', timeRange);
        setGrowthData(cachedGrowthData[timeRange]);
        return;
      }
      
      console.log('No cache or expired. Fetching data from Firestore');
      
      // Create a loading indicator
      setLoading(true);
      
      // If no cache or cache expired, fetch new data
      const dataPoints = [];
      const now = new Date();

      // Define the start date based on the selected time range
      let startDate;
      let interval;
      let format;

      if (timeRange === 'week') {
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 7);
        interval = 1; // 1 day interval
        format = 'MMM d'; // Format: "Jan 1"
      } else if (timeRange === 'month') {
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 30);
        interval = 3; // 3 day interval
        format = 'MMM d'; // Format: "Jan 1"
      } else if (timeRange === 'year') {
        startDate = new Date(now);
        startDate.setFullYear(startDate.getFullYear() - 1);
        interval = 30; // ~1 month interval
        format = 'MMM yyyy'; // Format: "Jan 2023"
      }

      // Get all users from Firestore
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      
      console.log('Retrieved user data, processing...');
      
      // Process user data after retrieval
      const allUsers = [];
      
      // Loop through each user document
      usersSnapshot.docs.forEach(doc => {
        const data = doc.data();
        let createdAt;
        
        // Handle different possible formats of createdAt
        if (data.createdAt) {
          if (data.createdAt.toDate) {
            // It's a Firestore timestamp
            createdAt = data.createdAt.toDate();
          } else if (data.createdAt.seconds) {
            // It's a timestamp object
            createdAt = new Date(data.createdAt.seconds * 1000);
          } else {
            // Try to parse it as a date string or timestamp
            createdAt = new Date(data.createdAt);
          }
          
          // Only add if we have a valid date
          if (createdAt instanceof Date && !isNaN(createdAt)) {
            allUsers.push({
              id: doc.id,
              createdAt: createdAt
            });
          }
        }
      });
      
      // Sort users by creation date
      allUsers.sort((a, b) => a.createdAt - b.createdAt);
      
      console.log(`Found ${allUsers.length} users with valid creation dates`);
      
      // Generate data points based on the time range
      if (timeRange === 'week') {
        // For the week view, show daily data points
        for (let i = 0; i <= 7; i++) {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);

          // Set time to end of day to include all registrations for that day
          const endDate = new Date(date);
          endDate.setHours(23, 59, 59, 999);
          
          // Count users created up to this date
          const count = allUsers.filter(user => user.createdAt <= endDate).length;

          dataPoints.push({
            date: format === 'MMM d' ? date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) : date.toLocaleDateString(),
            Users: count
          });
        }
      } else if (timeRange === 'month') {
        // For the month view, sample every 3 days
        for (let i = 0; i <= 30; i += interval) {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);

          // Set time to end of day
          const endDate = new Date(date);
          endDate.setHours(23, 59, 59, 999);
          
          // Count users created up to this date
          const count = allUsers.filter(user => user.createdAt <= endDate).length;

          dataPoints.push({
            date: format === 'MMM d' ? date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) : date.toLocaleDateString(),
            Users: count
          });
        }
      } else if (timeRange === 'year') {
        // For the year view, sample monthly
        for (let i = 0; i <= 12; i++) {
          const date = new Date(startDate);
          date.setMonth(date.getMonth() + i);

          // Set to last day of month
          const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
          
          // Count users created up to this date
          const count = allUsers.filter(user => user.createdAt <= endDate).length;

          dataPoints.push({
            date: endDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
            Users: count
          });
        }
      }

      // Add console logging to help debug
      console.log("Generated growth data points:", dataPoints);
      
      // Update the cache
      setCachedGrowthData(prev => ({
        ...prev,
        [timeRange]: dataPoints,
        lastUpdated: currentTime
      }));

      setGrowthData(dataPoints);
      setLoading(false);
    } catch (err) {
      console.error('Error generating growth data:', err);
      setLoading(false);
    }
  }, [activeTimeRange, cachedGrowthData, setGrowthData, setLoading, setCachedGrowthData]);

  // Dedicated useEffect for growth data
  useEffect(() => {
    if (adminUsers.length > 0) {
      generateGrowthData();
    }
  }, [adminUsers, generateGrowthData]);

  // Search for users based on search term
  const searchUsers = async () => {
    if (!searchTerm.trim()) {
      setFilteredUsers([]);
      setSearchPerformed(false);
      return;
    }

    setLoading(true);
    setSearchPerformed(true);

    try {
      const term = searchTerm.toLowerCase().trim();

      // Search by username (case insensitive)
      const usernameQuery = query(
        collection(db, 'users'),
        where('username', '>=', term),
        where('username', '<=', term + '\uf8ff'),
        limit(20)
      );

      // Search by email (case insensitive)
      const emailQuery = query(
        collection(db, 'users'),
        where('email', '>=', term),
        where('email', '<=', term + '\uf8ff'),
        limit(20)
      );

      // Execute both queries
      const [usernameSnapshot, emailSnapshot] = await Promise.all([
        getDocs(usernameQuery),
        getDocs(emailQuery)
      ]);

      // Combine results and remove duplicates
      const usernameResults = usernameSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isAdmin: adminUsers.includes(doc.id)
      }));

      const emailResults = emailSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isAdmin: adminUsers.includes(doc.id)
      }));

      // Combine and remove duplicates
      const combinedResults = [...usernameResults];

      emailResults.forEach(emailUser => {
        if (!combinedResults.some(user => user.id === emailUser.id)) {
          combinedResults.push(emailUser);
        }
      });

      setUsers(combinedResults);
      setFilteredUsers(combinedResults);
      setLoading(false);
    } catch (error) {
      console.error('Error searching users:', error);
      setError('Failed to search users. Please try again.');
      setLoading(false);
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Clear search results
  const clearSearch = () => {
    setSearchTerm('');
    setFilteredUsers([]);
    setSearchPerformed(false);
  };

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchUsers();
  };

  // Toggle user expansion
  const toggleUserExpand = (userId) => {
    if (expandedUserId === userId) {
      setExpandedUserId(null);
    } else {
      setExpandedUserId(userId);
    }
  };

  // Handle user deletion
  const handleDeleteUser = async (userId, e) => {
    e.stopPropagation(); // Prevent row expansion when clicking delete

    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        await deleteDoc(doc(db, 'users', userId));
        setUsers(users.filter(user => user.id !== userId));
        setFilteredUsers(filteredUsers.filter(user => user.id !== userId));
        if (expandedUserId === userId) {
          setExpandedUserId(null);
        }
      } catch (err) {
        console.error('Error deleting user:', err);
        setError('Failed to delete user. Please try again.');
      }
    }
  };

  // Handle admin privilege changes
  const initiateAdminAction = (userId, action) => {
    setPendingAdminAction({ userId, action });
    setAdminSetupCode('');
    setShowAdminConfirmation(true);
  };

  const cancelAdminAction = () => {
    setPendingAdminAction(null);
    setAdminSetupCode('');
    setShowAdminConfirmation(false);
  };

  const confirmAdminAction = async () => {
    if (!pendingAdminAction) return;

    // Verify admin setup code
    if (adminSetupCode !== ADMIN_SETUP_CODE) {
      setAdminActionError('Invalid admin setup code. Please try again.');
      return;
    }

    const { userId, action } = pendingAdminAction;
    setAdminActionInProgress(true);
    setAdminActionError('');
    setAdminActionMessage('');

    try {
      if (action === 'add') {
        await addUserAsAdmin(userId);
        setAdminUsers([...adminUsers, userId]);
        setAdminActionMessage(`User has been granted admin privileges.`);
      } else if (action === 'remove') {
        // Prevent removing the last admin
        if (adminUsers.length <= 1) {
          setAdminActionError('Cannot remove the last admin. At least one admin must remain.');
          setAdminActionInProgress(false);
          return;
        }

        // Prevent removing yourself
        if (userId === currentUser.uid) {
          setAdminActionError('You cannot remove your own admin privileges.');
          setAdminActionInProgress(false);
          return;
        }

        await removeUserAsAdmin(userId);
        setAdminUsers(adminUsers.filter(id => id !== userId));
        setAdminActionMessage(`User's admin privileges have been revoked.`);
      }

      // Update the users list to reflect the changes
      setUsers(users.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            isAdmin: action === 'add'
          };
        }
        return user;
      }));

      setFilteredUsers(filteredUsers.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            isAdmin: action === 'add'
          };
        }
        return user;
      }));

      // Close the confirmation dialog
      setShowAdminConfirmation(false);
      setPendingAdminAction(null);
      setAdminSetupCode('');
    } catch (error) {
      console.error('Error changing admin status:', error);
      setAdminActionError(`Failed to ${action === 'add' ? 'grant' : 'revoke'} admin privileges. ${error.message}`);
    } finally {
      setAdminActionInProgress(false);
    }
  };

  // Format number with commas
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Calculate percentage of active users
  const calculateActivePercentage = () => {
    if (!userStats.totalUsers) return 0;
    return ((activeUsers / userStats.totalUsers) * 100).toFixed(0);
  };

  // Load feature flags
  const loadFeatureFlags = async () => {
    try {
      setFeatureFlagsLoading(true);
      setFeatureFlagsError(null);
      
      const flags = await getFeatureFlags();
      setFeatureFlags(flags);
    } catch (error) {
      console.error('Error loading feature flags:', error);
      setFeatureFlagsError('Failed to load feature flags');
    } finally {
      setFeatureFlagsLoading(false);
    }
  };

  // Toggle feature flag
  const handleToggleFeatureFlag = async (flagName) => {
    try {
      const updatedValue = !featureFlags[flagName];
      const update = { [flagName]: updatedValue };
      
      // Update in Firestore
      const success = await updateFeatureFlags(currentUser.uid, update);
      
      if (success) {
        // Update local state
        setFeatureFlags(prev => ({ ...prev, ...update }));
        
        // Update global state
        updateGlobalFeatureFlags(prev => ({ ...prev, ...update }));
        
        setFeatureFlagsUpdateStatus({
          success: true,
          message: `Successfully ${updatedValue ? 'enabled' : 'disabled'} ${flagName}`
        });
      } else {
        setFeatureFlagsUpdateStatus({
          success: false,
          message: `Failed to update ${flagName}`
        });
      }
    } catch (error) {
      console.error(`Error toggling feature flag ${flagName}:`, error);
      setFeatureFlagsUpdateStatus({
        success: false,
        message: `Error: ${error.message}`
      });
    }
    
    // Clear status message after 3 seconds
    setTimeout(() => {
      setFeatureFlagsUpdateStatus({ success: false, message: '' });
    }, 3000);
  };

  // Load user limits
  const loadUserLimits = async () => {
    try {
      setUserLimitsLoading(true);
      setUserLimitsError(null);
      
      const limits = await getUserLimits();
      setUserLimits(limits);
    } catch (error) {
      console.error('Error loading user limits:', error);
      setUserLimitsError('Failed to load user limits');
    } finally {
      setUserLimitsLoading(false);
    }
  };

  // Handle changes to user limits
  const handleUserLimitChange = (limitName, value) => {
    // Parse the value to an integer
    const intValue = parseInt(value, 10) || 1; // Default to 1 if invalid
    
    // Update local state
    setUserLimits(prev => ({
      ...prev,
      [limitName]: intValue
    }));
  };

  // Update user limits in Firestore
  const handleUserLimitsUpdate = async () => {
    try {
      // Update in Firestore
      const success = await updateUserLimits(currentUser.uid, userLimits);
      
      if (success) {
        setUserLimitsUpdateStatus({
          success: true,
          message: 'User limits updated successfully'
        });
      } else {
        setUserLimitsUpdateStatus({
          success: false,
          message: 'Failed to update user limits'
        });
      }
    } catch (error) {
      console.error('Error updating user limits:', error);
      setUserLimitsUpdateStatus({
        success: false,
        message: `Error: ${error.message}`
      });
    }
    
    // Clear status message after 3 seconds
    setTimeout(() => {
      setUserLimitsUpdateStatus({ success: false, message: '' });
    }, 3000);
  };

  if (initialLoading) {
    return <div className="admin-dashboard loading">Loading dashboard data...</div>;
  }

  if (error && initialLoading) {
    return <div className="admin-dashboard error">{error}</div>;
  }

  return (
    <div className="admin-dashboard">
      {/* Admin action confirmation modal */}
      {showAdminConfirmation && (
        <div className="admin-confirmation-modal">
          <div className="admin-confirmation-content">
            <h3>Confirm Admin Action</h3>
            <p>
              {pendingAdminAction?.action === 'add'
                ? 'You are about to grant admin privileges to this user.'
                : 'You are about to remove admin privileges from this user.'}
            </p>
            <p>Please enter the admin setup code to confirm:</p>
            <input
              type="password"
              value={adminSetupCode}
              onChange={(e) => setAdminSetupCode(e.target.value)}
              placeholder="Admin setup code"
              className="admin-code-input"
            />
            {adminActionError && <div className="admin-action-error">{adminActionError}</div>}
            <div className="admin-confirmation-buttons">
              <button
                onClick={cancelAdminAction}
                className="cancel-button"
              >
                Cancel
              </button>
              <button
                onClick={confirmAdminAction}
                className="confirm-button"
                disabled={adminActionInProgress}
              >
                {adminActionInProgress ? 'Processing...' : 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Admin action message */}
      {adminActionMessage && (
        <div className="admin-action-message">
          {adminActionMessage}
          <button onClick={() => setAdminActionMessage('')} className="close-message">×</button>
        </div>
      )}

      {/* Key Metrics Dashboard */}
      <div className="dashboard-section">
        <div className="dashboard-header">
          <h2>Dashboard Overview</h2>
          <button
            className="refresh-button"
            onClick={fetchDashboardStats}
            disabled={loading}
          >
            {loading ? 'Refreshing...' : 'Refresh Data'}
          </button>
        </div>

        {userStats.lastUpdated && (
          <div className="last-updated">
            Last updated: {userStats.lastUpdated}
          </div>
        )}

        <div className="metrics-grid">
          <div className="metric-card primary">
            <div className="metric-content">
              <div className="metric-value">{formatNumber(userStats.totalUsers || 0)}</div>
              <div className="metric-label">Total Users</div>
              <div className="metric-trend">↑ {registrationStats.thisMonth} this month</div>
            </div>
          </div>

          <div className="metric-card">
            <div className="metric-content">
              <div className="metric-value">{formatNumber(activeUsers || 0)}</div>
              <div className="metric-label">Active Users (30 days)</div>
              <div className="metric-trend">{calculateActivePercentage()}% of total users</div>
            </div>
          </div>

          <div className="metric-card">
            <div className="metric-content">
              <div className="metric-value">{formatNumber(userStats.totalSpellbooks || 0)}</div>
              <div className="metric-label">Total Spellbooks</div>
              <div className="metric-trend">{userStats.averageSpellbooksPerUser || '0.0'} avg per user</div>
            </div>
          </div>

          <div className="metric-card">
            <div className="metric-content">
              <div className="metric-value">{formatNumber(userStats.totalCustomSpells || 0)}</div>
              <div className="metric-label">Custom Spells</div>
              <div className="metric-trend">{userStats.averageCustomSpellsPerUser || '0.0'} avg per user</div>
            </div>
          </div>
        </div>
      </div>

      {/* Registration Statistics */}
      <div className="dashboard-section">
        <div className="section-header">
          <h2>Registration Statistics</h2>
        </div>
        <div className="metrics-grid">
          <div className="metric-card">
            <div className="metric-content">
              <div className="metric-value">{registrationStats.today}</div>
              <div className="metric-label">Registered Today</div>
            </div>
          </div>

          <div className="metric-card">
            <div className="metric-content">
              <div className="metric-value">{registrationStats.thisWeek}</div>
              <div className="metric-label">Registered This Week</div>
            </div>
          </div>

          <div className="metric-card">
            <div className="metric-content">
              <div className="metric-value">{registrationStats.thisMonth}</div>
              <div className="metric-label">Registered This Month</div>
            </div>
          </div>

          <div className="metric-card">
            <div className="metric-content">
              <div className="metric-value">{registrationStats.thisYear}</div>
              <div className="metric-label">Registered This Year</div>
            </div>
          </div>
        </div>
      </div>

      {/* User Growth Chart */}
      <div className="dashboard-section">
        <div className="chart-container">
          <div className="chart-header">
            <h2>User Growth Over Time</h2>
            <div className="chart-controls">
              <button
                className={`chart-control-btn ${activeTimeRange === 'week' ? 'active' : ''}`}
                onClick={() => setActiveTimeRange('week')}
              >
                Week
              </button>
              <button
                className={`chart-control-btn ${activeTimeRange === 'month' ? 'active' : ''}`}
                onClick={() => setActiveTimeRange('month')}
              >
                Month
              </button>
              <button
                className={`chart-control-btn ${activeTimeRange === 'year' ? 'active' : ''}`}
                onClick={() => setActiveTimeRange('year')}
              >
                Year
              </button>
            </div>
          </div>
          <div className="chart-content">
            <div className="chart-wrapper">
              {!loading && growthData.length > 0 && (
                <ResponsiveContainer 
                  width="100%" 
                  height="100%" 
                  minWidth={300} 
                  minHeight={200}
                  debounce={50}
                >
                  <AreaChart 
                    data={growthData} 
                    margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
                  >
                    <defs>
                      <linearGradient id="colorUsers" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#3d5a80" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#3d5a80" stopOpacity={0.1} />
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#eee" />
                    <XAxis
                      dataKey="date"
                      tick={{ fontSize: 12 }}
                      angle={-45}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis tick={{ fontSize: 12 }} />
                    <Tooltip
                      formatter={(value) => [`${value} users`, 'Total Users']}
                      labelFormatter={(label) => `Date: ${label}`}
                      contentStyle={{
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        padding: '10px'
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="Users"
                      stroke="#3d5a80"
                      fillOpacity={1}
                      fill="url(#colorUsers)"
                      activeDot={{ r: 6 }}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              )}
              {(loading || growthData.length === 0) && (
                <div className="chart-loading">
                  {loading ? "Loading chart data..." : "No data available for the selected time range"}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Users Table Section */}
      <div className="dashboard-section">
        <div className="section-header">
          <h2>User Management</h2>
          <form onSubmit={handleSearchSubmit} className="search-form">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search by username or email..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
              {searchTerm && (
                <button type="button" className="clear-search-button" onClick={clearSearch}>
                  ×
                </button>
              )}
              <button type="submit" className="search-button">
                Search
              </button>
            </div>
          </form>
        </div>

        {loading && searchPerformed && (
          <div className="search-loading">Searching users...</div>
        )}

        {searchPerformed && !loading && (
          <div className="search-results-info">
            {filteredUsers.length > 0 ? (
              <div>Found {filteredUsers.length} {filteredUsers.length === 1 ? 'user' : 'users'} matching "{searchTerm}"</div>
            ) : (
              <div>No users found matching "{searchTerm}"</div>
            )}
          </div>
        )}

        {!searchPerformed && (
          <div className="search-prompt">
            <p>Enter a username or email to search for users.</p>
            <p className="search-note">Note: To optimize Firebase usage, users are only loaded when searched for.</p>
          </div>
        )}

        {searchPerformed && filteredUsers.length > 0 && (
          <UserTable
            users={filteredUsers}
            expandedUserId={expandedUserId}
            toggleUserExpand={toggleUserExpand}
            handleDeleteUser={handleDeleteUser}
            initiateAdminAction={initiateAdminAction}
            currentUser={currentUser}
            adminUsers={adminUsers}
            searchTerm={searchTerm}
          />
        )}
      </div>

      {/* Feature Flags Section */}
      <div className="admin-section">
        <h2>Feature Flags</h2>
        <div className="feature-flags-container">
          {featureFlagsLoading ? (
            <p>Loading feature flags...</p>
          ) : featureFlagsError ? (
            <p className="error-message">{featureFlagsError}</p>
          ) : (
            <div className="feature-flags-content">
              <div className={`feature-flag-item ${featureFlags.enableComments ? 'active' : ''}`}>
                <div>
                  <div className="feature-flag-name">Comments</div>
                  <div className="feature-flag-description">
                    Allow users to view and add comments on spells
                  </div>
                </div>
                <div className="feature-flag-controls">
                  <span className={`feature-flag-status-indicator ${featureFlags.enableComments ? 'enabled' : 'disabled'}`}>
                    {featureFlags.enableComments ? 'Enabled' : 'Disabled'}
                  </span>
                  <label className="feature-flag-toggle">
                    <input
                      type="checkbox"
                      checked={featureFlags.enableComments || false}
                      onChange={() => handleToggleFeatureFlag('enableComments')}
                    />
                    <span className="feature-flag-switch"></span>
                  </label>
                </div>
              </div>
              
              <div className={`feature-flag-item ${featureFlags.enableRatings ? 'active' : ''}`}>
                <div>
                  <div className="feature-flag-name">Ratings</div>
                  <div className="feature-flag-description">
                    Allow users to view and add ratings on spells
                  </div>
                </div>
                <div className="feature-flag-controls">
                  <span className={`feature-flag-status-indicator ${featureFlags.enableRatings ? 'enabled' : 'disabled'}`}>
                    {featureFlags.enableRatings ? 'Enabled' : 'Disabled'}
                  </span>
                  <label className="feature-flag-toggle">
                    <input
                      type="checkbox"
                      checked={featureFlags.enableRatings || false}
                      onChange={() => handleToggleFeatureFlag('enableRatings')}
                    />
                    <span className="feature-flag-switch"></span>
                  </label>
                </div>
              </div>
              
              <div className={`feature-flag-item ${featureFlags.showOnlySRDDescriptions ? 'active' : ''}`}>
                <div>
                  <div className="feature-flag-name">Show Only SRD Descriptions</div>
                  <div className="feature-flag-description">
                    Show full descriptions only for SRD spells, hide non-SRD content
                  </div>
                </div>
                <div className="feature-flag-controls">
                  <span className={`feature-flag-status-indicator ${featureFlags.showOnlySRDDescriptions ? 'enabled' : 'disabled'}`}>
                    {featureFlags.showOnlySRDDescriptions ? 'Enabled' : 'Disabled'}
                  </span>
                  <label className="feature-flag-toggle">
                    <input
                      type="checkbox"
                      checked={featureFlags.showOnlySRDDescriptions || false}
                      onChange={() => handleToggleFeatureFlag('showOnlySRDDescriptions')}
                    />
                    <span className="feature-flag-switch"></span>
                  </label>
                </div>
              </div>
              
              {featureFlagsUpdateStatus.message && (
                <div className={`feature-flags-status ${featureFlagsUpdateStatus.success ? 'success' : 'error'}`}>
                  {featureFlagsUpdateStatus.message}
                </div>
              )}
              
              <div className="feature-flags-metadata">
                <p>Last updated: {featureFlags.lastUpdated ? new Date(featureFlags.lastUpdated).toLocaleString() : 'Never'}</p>
                <p>Updated by: {featureFlags.updatedBy || 'System'}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* User Limits Section */}
      <div className="admin-dashboard-section">
        <h2>Free User Limits</h2>
        <p>Set limits for users without premium accounts:</p>
        
        {userLimitsLoading ? (
          <div className="admin-loading">Loading user limits...</div>
        ) : userLimitsError ? (
          <div className="admin-error">{userLimitsError}</div>
        ) : (
          <div className="user-limits-container">
            <div className="user-limit-item">
              <label htmlFor="maxSpellbooks">Max Spellbooks:</label>
              <input
                type="number"
                id="maxSpellbooks"
                value={userLimits.maxSpellbooks}
                onChange={(e) => handleUserLimitChange('maxSpellbooks', e.target.value)}
                min="1"
                max="20"
              />
            </div>
            <div className="user-limit-item">
              <label htmlFor="maxCustomSpells">Max Custom Spells:</label>
              <input
                type="number"
                id="maxCustomSpells"
                value={userLimits.maxCustomSpells}
                onChange={(e) => handleUserLimitChange('maxCustomSpells', e.target.value)}
                min="1"
                max="50"
              />
            </div>
            <div className="user-limit-item">
              <label htmlFor="maxPreparedPresets">Max Prepared Presets per spellbook:</label>
              <input
                type="number"
                id="maxPreparedPresets"
                value={userLimits.maxPreparedPresets}
                onChange={(e) => handleUserLimitChange('maxPreparedPresets', e.target.value)}
                min="1"
                max="20"
              />
            </div>
            
            <button 
              onClick={handleUserLimitsUpdate}
              className="user-limits-update-button"
            >
              Update Limits
            </button>
            
            {userLimitsUpdateStatus.message && (
              <div className={`user-limits-status ${userLimitsUpdateStatus.success ? 'success' : 'error'}`}>
                {userLimitsUpdateStatus.message}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard; 