import React, { useState, useEffect } from 'react';
import '../styles/components/SpellSlotManager.css';
import { useAuth } from '../AuthContext';
import { getUserSpellSlots, updateUserSpellSlots } from '../firebase/firestoreOperations';
import { TentIcon, PinIcon } from '../data/Icons';

const SpellSlotManager = ({ 
  spellSlots, 
  setSpellSlots, 
  maxLevel = 9, 
  spellbookName,
  showSpellSlotsEverywhere,
  setShowSpellSlotsEverywhere,
  useSpellPoints,
  onSystemChange,
  setUseSpellPoints
}) => {
  const { currentUser } = useAuth();

  // Ensure spellSlots is an array
  const slots = Array.isArray(spellSlots) ? spellSlots : Array(9).fill().map(() => ({ max: 0, used: 0 }));

  const handleMaxChange = (level, value) => {
    const newValue = Math.max(0, Math.min(10, value));
    const newSlots = [...slots];
    newSlots[level] = { 
      ...newSlots[level], 
      max: newValue,
      used: Math.min(newSlots[level].used, newValue) // Ensure used slots don't exceed new max
    };
    setSpellSlots(newSlots);
  };

  const handleUseSlot = (level) => {
    if (slots[level].used < slots[level].max) {
      const newSlots = [...slots];
      newSlots[level] = { ...newSlots[level], used: newSlots[level].used + 1 };
      setSpellSlots(newSlots);
    }
  };

  const handleRegainSlot = (level) => {
    if (slots[level].used > 0) {
      const newSlots = [...slots];
      newSlots[level] = { ...newSlots[level], used: newSlots[level].used - 1 };
      setSpellSlots(newSlots);
    }
  };

  const handleResetSlot = (level) => {
    const newSlots = [...slots];
    newSlots[level] = { ...newSlots[level], used: 0 };
    setSpellSlots(newSlots);
  };

  const handleResetAll = () => {
    const newSlots = slots.map(slot => ({ ...slot, used: 0 }));
    setSpellSlots(newSlots);
  };

  return (
    <div className="spell-slot-manager">
      <div className="spell-slot-header">
        <h3>Spell Slots for: <span className="spellbook-name">{spellbookName}</span></h3>
        <div className="system-toggle-container">
          <div className="toggle-labels">
            <span className={!useSpellPoints ? 'active' : ''}>Spell Slots</span>
            <div className="toggle-switch" onClick={() => setUseSpellPoints(!useSpellPoints)}>
              <div className={`toggle-slider ${useSpellPoints ? 'points' : 'slots'}`}></div>
            </div>
            <span className={useSpellPoints ? 'active' : ''}>Spell Points</span>
          </div>
        </div>
      </div>
      <div className="spell-slot-container">
        {slots.map((slot, index) => (
          <div key={index} className={`spell-slot-level ${slot.max === 0 ? 'empty' : ''}`}>
            <h4>Level {index + 1}</h4>
            <div className="spell-slot-content">
              <div className="spell-slot-controls">
                <input
                  type="number"
                  min="0"
                  max="10"
                  value={slot.max}
                  onChange={(e) => handleMaxChange(index, parseInt(e.target.value) || 0)}
                />
                <div className="spell-slot-usage">
                  <span>{slot.used} / {slot.max}</span>
                  <button onClick={() => handleUseSlot(index)} disabled={slot.used >= slot.max}>
                    Use
                  </button>
                  <button onClick={() => handleRegainSlot(index)} disabled={slot.used <= 0}>
                    Regain
                  </button>
                  <button onClick={() => handleResetSlot(index)}>Reset</button>
                </div>
              </div>
              <div className="spell-slot-visual">
                {Array.from({ length: slot.max }).map((_, i) => (
                  <div key={i} className={`spell-slot-block ${i < slot.used ? 'used' : ''}`}></div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="spell-slot-footer">
        <button className="reset-all-button" onClick={handleResetAll}>
          <TentIcon /> Reset All
        </button>
        {setShowSpellSlotsEverywhere && (
          <button 
            className={`pin-button ${showSpellSlotsEverywhere ? 'pinned' : ''}`}
            onClick={(e) => setShowSpellSlotsEverywhere(!showSpellSlotsEverywhere)}
            title={showSpellSlotsEverywhere ? "Unpin from all tabs" : "Pin to all tabs"}
          >
            <PinIcon filled={showSpellSlotsEverywhere} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SpellSlotManager;
