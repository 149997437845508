import React from 'react';
import '../styles/components/SpellPointsManager.css';
import { TentIcon, PinIcon } from '../data/Icons';

const SPELL_POINT_COSTS = {
  1: 2,
  2: 3,
  3: 5,
  4: 6,
  5: 7,
  6: 9,
  7: 10,
  8: 11,
  9: 13
};

const SPELL_POINTS_BY_LEVEL = {
  1: 4,
  2: 6,
  3: 14,
  4: 17,
  5: 27,
  6: 32,
  7: 38,
  8: 44,
  9: 57,
  10: 64,
  11: 73,
  12: 73,
  13: 83,
  14: 83,
  15: 94,
  16: 94,
  17: 107,
  18: 114,
  19: 123,
  20: 133
};

const SPELLCASTING_CLASSES = {
  'Artificer': level => Math.ceil(level / 2),
  'Bard': level => level,
  'Cleric': level => level,
  'Druid': level => level,
  'Fighter (Eldritch Knight)': level => Math.floor(level / 3),
  'Paladin': level => Math.floor(level / 2),
  'Ranger': level => Math.floor(level / 2),
  'Rogue (Arcane Trickster)': level => Math.floor(level / 3),
  'Sorcerer': level => level,
  'Wizard': level => level
};

const SpellPointsManager = ({ 
  spellPoints = { total: 0, used: 0, highLevelUses: {} },
  setSpellPoints,
  spellbookName,
  showSpellSlotsEverywhere,
  setShowSpellSlotsEverywhere,
  allowMultipleHighLevelCasts = false,
  setAllowMultipleHighLevelCasts,
  useSpellPoints,
  setUseSpellPoints
}) => {
  // Use the highLevelUses from the spellPoints object
  const localHighLevelUses = spellPoints.highLevelUses || {};

  const handleTotalPointsChange = (newTotal) => {
    const total = Math.max(0, newTotal);
    setSpellPoints({
      ...spellPoints,
      total,
      used: isNaN(spellPoints.used) ? 0 : Math.min(spellPoints.used || 0, total),
      highLevelUses: spellPoints.highLevelUses || {}
    });
  };

  const handleUsePoints = (level) => {
    const cost = SPELL_POINT_COSTS[level];
    
    // Check for high level restrictions
    if (level >= 6) {
      if (!allowMultipleHighLevelCasts && (localHighLevelUses[level] || 0) >= 1) {
        return false;
      }
      
      // Prepare updated spell points with high level uses
      const updatedSpellPoints = {
        ...spellPoints,
        used: spellPoints.used + cost,
        highLevelUses: {
          ...(spellPoints.highLevelUses || {}),
          [level]: ((spellPoints.highLevelUses || {})[level] || 0) + 1
        }
      };
      
      setSpellPoints(updatedSpellPoints);
      return true;
    }
    
    // For normal level spells
    if (spellPoints.used + cost <= spellPoints.total) {
      setSpellPoints({
        ...spellPoints,
        used: spellPoints.used + cost
      });
      return true;
    }
    return false;
  };

  const handleRegainPoints = (level) => {
    const cost = SPELL_POINT_COSTS[level];
    
    // Handle high level spells
    if (level >= 6 && (localHighLevelUses[level] || 0) > 0) {
      const updatedSpellPoints = {
        ...spellPoints,
        used: Math.max(0, spellPoints.used - cost),
        highLevelUses: {
          ...(spellPoints.highLevelUses || {}),
          [level]: Math.max(0, ((spellPoints.highLevelUses || {})[level] || 0) - 1)
        }
      };
      
      setSpellPoints(updatedSpellPoints);
      return;
    }
    
    // For normal level spells
    if (spellPoints.used >= cost) {
      setSpellPoints({
        ...spellPoints,
        used: spellPoints.used - cost
      });
    }
  };

  const handleResetAll = () => {
    setSpellPoints({
      ...spellPoints,
      used: 0,
      highLevelUses: {} // Reset high level uses
    });
  };

  return (
    <div className="spell-points-manager">
      <div className="spell-points-header">
        <h3>Spell Points for: <span className="spellbook-name">{spellbookName}</span></h3>
        <div className="system-toggle-container">
          <div className="toggle-labels">
            <span className={!useSpellPoints ? 'active' : ''}>Spell Slots</span>
            <div className="toggle-switch" onClick={() => setUseSpellPoints(!useSpellPoints)}>
              <div className={`toggle-slider ${useSpellPoints ? 'points' : 'slots'}`}></div>
            </div>
            <span className={useSpellPoints ? 'active' : ''}>Spell Points</span>
          </div>
        </div>
      </div>
      
      <div className="spell-points-content">
        <div className="spell-points-main">
          <div className="points-display">
            <div className="points-remaining">
              <div className="points-controls-row">
                <div className="spacer"></div>
                <div className="total-points-input">
                  <label>Total Points:</label>
                  <input
                    type="number"
                    min="0"
                    value={spellPoints.total}
                    onChange={(e) => handleTotalPointsChange(parseInt(e.target.value) || 0)}
                  />
                </div>
                <div className="multiple-casts-toggle">
                  <label>
                    <input
                      type="checkbox"
                      checked={allowMultipleHighLevelCasts}
                      onChange={(e) => setAllowMultipleHighLevelCasts(e.target.checked)}
                    />
                    Allow Multiple High-Level Casts
                  </label>
                </div>
              </div>
              <span>
                Points Remaining: {
                  isNaN(spellPoints.total - spellPoints.used) 
                    ? 0 
                    : (spellPoints.total - spellPoints.used)
                } / {spellPoints.total || 0}
              </span>
              <div className="points-bar">
                <div 
                  className="points-bar-fill" 
                  style={{ 
                    width: `${
                      isNaN(spellPoints.total) || spellPoints.total === 0 
                        ? 0 
                        : Math.max(0, Math.min(100, ((spellPoints.total - (spellPoints.used || 0)) / spellPoints.total) * 100))
                    }%` 
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="spell-level-costs">
            {Object.entries(SPELL_POINT_COSTS).map(([level, cost]) => {
              const isHighLevel = parseInt(level) >= 6;
              const usesLeft = isHighLevel && !allowMultipleHighLevelCasts ? 
                (1 - (localHighLevelUses[level] || 0)) : 
                null;

              return (
                <div key={level} className="spell-level-cost">
                  <h4>Level {level}</h4>
                  <div className="cost-info">
                    <span>{cost} points</span>
                    {isHighLevel && !allowMultipleHighLevelCasts && (
                      <span className="uses-left">{usesLeft} use{usesLeft !== 1 ? 's' : ''} left</span>
                    )}
                  </div>
                  <div className="cost-controls">
                    <button 
                      onClick={() => handleUsePoints(parseInt(level))}
                      disabled={
                        spellPoints.total - spellPoints.used < cost ||
                        (isHighLevel && !allowMultipleHighLevelCasts && usesLeft === 0)
                      }
                    >
                      Use
                    </button>
                    <button 
                      onClick={() => handleRegainPoints(parseInt(level))}
                      disabled={spellPoints.used < cost || (isHighLevel && !(localHighLevelUses[level] || 0))}
                    >
                      Regain
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="spell-points-footer">
        <button className="reset-all-button" onClick={handleResetAll}>
          <TentIcon /> Reset
        </button>
        {setShowSpellSlotsEverywhere && (
          <button 
            className={`pin-button ${showSpellSlotsEverywhere ? 'pinned' : ''}`}
            onClick={(e) => setShowSpellSlotsEverywhere(!showSpellSlotsEverywhere)}
            title={showSpellSlotsEverywhere ? "Unpin from all tabs" : "Pin to all tabs"}
          >
            <PinIcon filled={showSpellSlotsEverywhere} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SpellPointsManager; 