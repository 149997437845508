import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { resetPassword } from '../firebase/authFunctions';
import '../styles/components/ForgotPassword.css';

// Import dice SVGs
import d4Svg from '../assets/dice/d4.svg';
import d6Svg from '../assets/dice/d6.svg';
import d8Svg from '../assets/dice/d8.svg';
import d10Svg from '../assets/dice/d10.svg';
import d12Svg from '../assets/dice/d12.svg';
import d20Svg from '../assets/dice/d20.svg';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isLoading) return;
    
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      await resetPassword(email);
      setSuccess('Password reset email sent. Please check your inbox.');
      setEmail('');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Array of dice SVGs
  const diceSvgs = {
    d4: d4Svg,
    d6: d6Svg,
    d8: d8Svg,
    d10: d10Svg,
    d12: d12Svg,
    d20: d20Svg
  };

  // Dice backgrounds to render around the login box
  const renderDiceBackgrounds = () => {
    const diceElements = [];
    const diceTypes = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20'];
    
    // Create 24 dice elements with various sizes and positions
    for (let i = 0; i < 24; i++) {
      const diceType = diceTypes[Math.floor(Math.random() * diceTypes.length)];
      const size = Math.random() * 40 + 15; // 15px to 55px
      
      // Create a distribution with a "no spawn zone" in the center and a more circular pattern
      let distance, angle, top, left;
      
      // Define the "no spawn zone" - this is smaller than the box
      const noSpawnZoneWidth = 25; // percentage of the screen width
      const noSpawnZoneHeight = 30; // percentage of the screen height
      
      // Keep generating positions until we find one that's not completely in the no spawn zone
      do {
        // Create a more circular distribution by using a different distribution for distance
        // This creates concentrations in rings around the center
        
        // Pick one of three distribution patterns for more interesting clustering
        const distributionPattern = Math.floor(Math.random() * 3);
        
        if (distributionPattern === 0) {
          // Inner ring - closer to the login box
          distance = Math.random() * 0.15 + 0.25; // 0.25 to 0.4
        } else if (distributionPattern === 1) {
          // Middle ring
          distance = Math.random() * 0.2 + 0.4; // 0.4 to 0.6
        } else {
          // Outer ring
          distance = Math.random() * 0.2 + 0.6; // 0.6 to 0.8
        }
        
        // Use full 360-degree angle distribution
        angle = Math.random() * Math.PI * 2; // 0 to 2π
        
        // Convert polar coordinates to x,y coordinates
        // Create a more circular pattern by using the same factor for both top and left
        top = 50 + Math.sin(angle) * distance * 45; // 50% +/- 45%
        left = 50 + Math.cos(angle) * distance * 45; // 50% +/- 45%
        
        // Check if this position is completely inside the no spawn zone
        const insideNoSpawnZone = 
          left > (50 - noSpawnZoneWidth/2) && 
          left < (50 + noSpawnZoneWidth/2) && 
          top > (50 - noSpawnZoneHeight/2) && 
          top < (50 + noSpawnZoneHeight/2);
          
        // If it's outside the no spawn zone or at least partially overlapping, we accept it
        if (!insideNoSpawnZone) {
          break;
        }
        
      } while (true);
      
      const rotate = Math.floor(Math.random() * 360);
      const opacity = Math.random() * 0.4 + 0.1; // 0.1 to 0.5
      
      diceElements.push(
        <img
          key={`forgot-password-dice-${i}`}
          src={diceSvgs[diceType]}
          alt=""
          className="login-dice"
          style={{
            width: `${size}px`,
            top: `${top}%`,
            left: `${left}%`,
            transform: `rotate(${rotate}deg)`,
            opacity: opacity,
            zIndex: Math.floor(opacity * 10)
          }}
        />
      );
    }
    
    return diceElements;
  };

  return (
    <div className="login-page">
      <div className="login-background">
        {renderDiceBackgrounds()}
      </div>
      <div className="login-container">
        <h2>Reset Password</h2>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email address"
            />
          </div>
          <button 
            type="submit" 
            className="submit-btn"
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Reset Password'}
          </button>
        </form>
        <div className="auth-options">
          <Link to="/login" className="forgot-password-link">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword; 