import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, collection, query, where, getDocs } from 'firebase/firestore';
import { logger } from './utils';

/**
 * Get ratings for a spell
 * @param {string} spellId - The spell ID
 * @returns {Promise<Object>} - Object with average rating, count, and ratings array
 */
export const getSpellRatings = async (spellId) => {
  try {
    if (!spellId) {
      logger('No spell ID provided to getSpellRatings');
      return { average: 0, count: 0, ratings: [] };
    }

    const spellRatingsRef = doc(db, 'spellRatings', spellId);
    const spellRatingsDoc = await getDoc(spellRatingsRef);

    if (!spellRatingsDoc.exists()) {
      return { average: 0, count: 0, ratings: [] };
    }

    const ratingsData = spellRatingsDoc.data();
    const ratings = ratingsData.ratings || [];
    const count = ratings.length;
    const sum = ratings.reduce((acc, rating) => acc + rating.value, 0);
    const average = count > 0 ? (sum / count).toFixed(1) : 0;

    return {
      average: parseFloat(average),
      count,
      ratings
    };
  } catch (error) {
    console.error('Error getting spell ratings:', error);
    return { average: 0, count: 0, ratings: [] };
  }
};

/**
 * Get a user's rating for a specific spell
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @returns {Promise<Object|null>} - The user's rating or null if not found
 */
export const getUserSpellRating = async (userId, spellId) => {
  try {
    if (!userId || !spellId) {
      logger('Missing required parameters in getUserSpellRating');
      return null;
    }

    const spellRatingsRef = doc(db, 'spellRatings', spellId);
    const spellRatingsDoc = await getDoc(spellRatingsRef);

    if (!spellRatingsDoc.exists()) {
      return null;
    }

    const ratingsData = spellRatingsDoc.data();
    const ratings = ratingsData.ratings || [];
    const userRating = ratings.find(rating => rating.userId === userId);

    return userRating || null;
  } catch (error) {
    console.error('Error getting user spell rating:', error);
    return null;
  }
};

/**
 * Add or update a rating for a spell
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @param {number} value - The rating value (1-5)
 * @returns {Promise<Object>} - The updated ratings data
 */
export const addSpellRating = async (userId, spellId, value) => {
  try {
    if (!userId || !spellId || typeof value !== 'number') {
      logger('Missing required parameters in addSpellRating');
      throw new Error('Missing required parameters');
    }

    // Validate value range
    if (value < 1 || value > 5) {
      throw new Error('Rating value must be between 1 and 5');
    }

    // Round value to nearest 0.5
    const roundedValue = Math.round(value * 2) / 2;

    const spellRatingsRef = doc(db, 'spellRatings', spellId);
    const spellRatingsDoc = await getDoc(spellRatingsRef);

    // Also store the rating in a user-centric collection for easier querying
    const userRatingsRef = doc(db, 'userRatings', userId);

    if (spellRatingsDoc.exists()) {
      const ratingsData = spellRatingsDoc.data();
      const ratings = ratingsData.ratings || [];
      const userRatingIndex = ratings.findIndex(rating => rating.userId === userId);

      if (userRatingIndex !== -1) {
        // Update existing rating
        const updatedRatings = [...ratings];
        updatedRatings[userRatingIndex] = {
          userId,
          value: roundedValue,
          timestamp: new Date()
        };

        await updateDoc(spellRatingsRef, {
          ratings: updatedRatings
        });

        // Update in user ratings
        await setDoc(userRatingsRef, {
          [spellId]: roundedValue
        }, { merge: true });

        const updatedData = {
          ...ratingsData,
          ratings: updatedRatings
        };
        return updatedData;
      } else {
        // Add new rating
        const newRating = {
          userId,
          value: roundedValue,
          timestamp: new Date()
        };

        await updateDoc(spellRatingsRef, {
          ratings: arrayUnion(newRating)
        });

        // Add to user ratings
        await setDoc(userRatingsRef, {
          [spellId]: roundedValue
        }, { merge: true });

        const updatedData = {
          ...ratingsData,
          ratings: [...ratings, newRating]
        };
        return updatedData;
      }
    } else {
      // Create new document with first rating
      const newRating = {
        userId,
        value: roundedValue,
        timestamp: new Date()
      };

      const newRatingsData = {
        ratings: [newRating]
      };

      await setDoc(spellRatingsRef, newRatingsData);

      // Add to user ratings
      await setDoc(userRatingsRef, {
        [spellId]: roundedValue
      }, { merge: true });

      return newRatingsData;
    }
  } catch (error) {
    console.error('Error adding spell rating:', error);
    throw error;
  }
};

/**
 * Remove a user's rating for a spell
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @returns {Promise<void>}
 */
export const removeSpellRating = async (userId, spellId) => {
  try {
    if (!userId || !spellId) {
      logger('Missing required parameters in removeSpellRating');
      throw new Error('Missing required parameters');
    }

    const spellRatingsRef = doc(db, 'spellRatings', spellId);
    const spellRatingsDoc = await getDoc(spellRatingsRef);

    if (!spellRatingsDoc.exists()) {
      logger(`No ratings found for spell ${spellId}`);
      return;
    }

    const ratingsData = spellRatingsDoc.data();
    const ratings = ratingsData.ratings || [];
    const userRating = ratings.find(rating => rating.userId === userId);

    if (!userRating) {
      logger(`No rating found for user ${userId} on spell ${spellId}`);
      return;
    }

    // Remove the rating
    await updateDoc(spellRatingsRef, {
      ratings: arrayRemove(userRating)
    });

    // Remove from user ratings
    const userRatingsRef = doc(db, 'userRatings', userId);
    const userRatingsDoc = await getDoc(userRatingsRef);

    if (userRatingsDoc.exists() && userRatingsDoc.data()[spellId]) {
      const updatedUserRatings = { ...userRatingsDoc.data() };
      delete updatedUserRatings[spellId];
      await setDoc(userRatingsRef, updatedUserRatings);
    }
  } catch (error) {
    console.error('Error removing spell rating:', error);
    throw error;
  }
}; 