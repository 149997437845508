import React, { useState, useMemo, useCallback } from 'react';
import '../styles/components/PreparedSpellsPresets.css';
import { TrashcanIcon } from '../data/Icons';
import spellsData from '../data/spells.json';
import ConfirmationPopup from './ConfirmationPopup';
import { updateUserPresets, removePreset } from '../firebase/firestoreOperations';

// Helper function to convert spell object to spell ID
const spellToId = (spell) => spell.id;

// Helper function to convert spell ID to spell object
const idToSpell = (id) => spellsData.find(spell => spell.id === id);

function PreparedSpellsPresets({ 
  presets, 
  setPresets,
  onSavePreset, 
  onLoadPreset, 
  onDeletePreset, 
  availableSpells, 
  currentPreset,
  setCurrentPreset,
  preparedSpells,
  currentSpellbook,
  currentUser,
  addNotification
}) {
  const [newPresetName, setNewPresetName] = useState('');
  const [confirmationPopup, setConfirmationPopup] = useState({ isOpen: false, message: '', onConfirm: null });

  // Get only the presets for the current spellbook
  const currentSpellbookPresets = useMemo(() => {
    return presets[currentSpellbook] || {};
  }, [presets, currentSpellbook]);

  const handleSavePreset = () => {
    if (!currentUser) {
      addNotification('You must be logged in to save presets', 'error');
      return;
    }
    
    if (newPresetName.trim()) {
      // Use prepared spells to generate IDs, not all available spells
      const spellIds = preparedSpells ? preparedSpells.map(spellToId) : [];
      // Call onSavePreset with the newPresetName
      onSavePreset(newPresetName.trim());
      setNewPresetName('');
    }
  };

  const handleLoadPreset = (presetName, spellIds) => {
    // Add null check for preparedSpells
    if (!preparedSpells || !Array.isArray(preparedSpells)) {
      // If preparedSpells is undefined or not an array, just load the preset directly
      onLoadPreset(currentSpellbook, presetName, spellIds);
      return;
    }
    
    const unsavedSpells = preparedSpells.filter(spell => !spellIds.includes(spell.id));
    if (unsavedSpells.length > 0) {
      setConfirmationPopup({
        isOpen: true,
        message: `You have ${unsavedSpells.length} unsaved prepared spell(s). Loading this preset will overwrite your current prepared spells. Do you want to continue?`,
        onConfirm: () => {
          onLoadPreset(currentSpellbook, presetName, spellIds);
          setConfirmationPopup({ isOpen: false, message: '', onConfirm: null });
        },
        confirmButtonText: 'Continue'
      });
    } else {
      onLoadPreset(currentSpellbook, presetName, spellIds);
    }
  };

  const validPresets = useMemo(() => {
    if (!currentSpellbookPresets) return [];
    
    return Object.entries(currentSpellbookPresets)
      .filter(([name, spellIds]) => name && spellIds)
      .map(([name, spellIds]) => {
        const validSpellIds = spellIds.filter(id => availableSpells.some(s => s.id === id));
        return { name, spellIds: validSpellIds };
      });
  }, [currentSpellbookPresets, availableSpells]);

  const deletePreset = useCallback((spellbookName, presetName) => {
    setConfirmationPopup({
      isOpen: true,
      message: `Are you sure you want to delete the preset "${presetName}"?`,
      onConfirm: async () => {
        try {
          if (currentUser) {
            // Remove preset from database
            await removePreset(currentUser.uid, spellbookName, presetName);
            
            // Update local state - create new object without the deleted preset
            setPresets(prev => {
              const newPresets = { ...prev };
              if (newPresets[spellbookName]) {
                // Create a new object excluding the deleted preset
                const { [presetName]: deleted, ...remaining } = newPresets[spellbookName];
                // Only update if there are remaining presets
                if (Object.keys(remaining).length > 0) {
                  newPresets[spellbookName] = remaining;
                } else {
                  // If no presets remain, delete the spellbook entry
                  delete newPresets[spellbookName];
                }
              }
              return newPresets;
            });
          } else {
            // For non-logged-in users
            const allPresets = JSON.parse(localStorage.getItem('guestPresets') || '{}');
            if (allPresets[spellbookName]) {
              const { [presetName]: deleted, ...remaining } = allPresets[spellbookName];
              if (Object.keys(remaining).length > 0) {
                allPresets[spellbookName] = remaining;
              } else {
                delete allPresets[spellbookName];
              }
              localStorage.setItem('guestPresets', JSON.stringify(allPresets));
            }
            
            // Update local state
            setPresets(prev => {
              const newPresets = { ...prev };
              if (newPresets[spellbookName]) {
                const { [presetName]: deleted, ...remaining } = newPresets[spellbookName];
                if (Object.keys(remaining).length > 0) {
                  newPresets[spellbookName] = remaining;
                } else {
                  delete newPresets[spellbookName];
                }
              }
              return newPresets;
            });
          }
          
          if (currentPreset === presetName) {
            setCurrentPreset(null);
          }
          
          addNotification(`Preset "${presetName}" deleted from ${spellbookName}`, 'info');
        } catch (error) {
          console.error('Error deleting preset:', error);
          addNotification('Failed to delete preset', 'error');
        }
        setConfirmationPopup({ isOpen: false, message: '', onConfirm: null });
      }
    });
  }, [currentUser, currentPreset, setCurrentPreset, setPresets, addNotification]);

  return (
    <div className="prepared-spells-presets-container">
      <div className="prepared-spells-presets">
        <h3>Prepared Spells Presets for {currentSpellbook}</h3>
        <div className="preset-actions">
          <input
            type="text"
            value={newPresetName}
            onChange={(e) => setNewPresetName(e.target.value.slice(0, 25))}
            placeholder={currentUser ? "Enter preset name" : "Login to save presets"}
            maxLength={25}
            className="preset-name-input"
            disabled={!currentUser}
          />
          <button 
            onClick={handleSavePreset}
            disabled={!currentUser}
            className={!currentUser ? 'disabled' : ''}
          >
            Save New
          </button>
        </div>
        <ul className="preset-list">
          {validPresets.map(({ name, spellIds }) => (
            <li 
              key={name} 
              className={currentPreset === name ? 'active' : ''}
              onClick={() => setCurrentPreset(name)}
            >
              <span className="preset-name">
                <span>{name}</span> ({spellIds.length} spells)
              </span>
              {currentPreset === name && (
                <div className="preset-buttons">
                  <button onClick={(e) => {
                    e.stopPropagation();
                    handleLoadPreset(name, spellIds);
                  }} className="load-button">
                    Load
                  </button>
                  <button onClick={(e) => {
                    e.stopPropagation();
                    onSavePreset(name);
                  }} className="save-button">
                    Save
                  </button>
                  <button onClick={(e) => {
                    e.stopPropagation();
                    deletePreset(currentSpellbook, name);
                  }} className="delete-button">
                    <TrashcanIcon />
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="preset-explanation">
        <h4>How Prepared Spells Presets Work</h4>
        <p>Prepared Spells Presets allow you to save and quickly load different sets of prepared spells. They are a great way to switch between different spell loadouts for various situations like cities, nature, or dungeons.</p>
        <ol>
          <li>Prepare the spells you want to save as a preset.</li>
          <li>Enter a name for your preset in the input field.</li>
          <li>Click "Save New" to create a new preset.</li>
          <li>To load a preset, click the "Load" button next to its name.</li>
          <li>To update a preset, click the "Save" button.</li>
          <li>To delete a preset, click the trash can icon next to its name.</li>
        </ol>
        <p>Remember, If a spell is no longer found in any of the spellbooks, the spell will also be removed from the preset!</p>
      </div>
      <ConfirmationPopup
        isOpen={confirmationPopup.isOpen}
        message={confirmationPopup.message}
        onConfirm={confirmationPopup.onConfirm}
        onCancel={() => setConfirmationPopup({ isOpen: false, message: '', onConfirm: null })}
        confirmButtonText={confirmationPopup.confirmButtonText}
      />
    </div>
  );
}

export default PreparedSpellsPresets;
