import React, { useState, useRef, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import '../styles/components/SpellCardGenerator.css';
import { generateDetailedPDF } from './pdfGenerators/DetailedPDFGenerator';
import { generateQuickLookupPDF } from './pdfGenerators/QuickLookupPDFGenerator';
import { generateCardsPDF } from './pdfGenerators/CardsPDFGenerator';
import { generateMediumCardsPDF } from './pdfGenerators/MediumCardsPDFGenerator';
import { generateSmallCardsPDF } from './pdfGenerators/SmallCardsPDFGenerator';
import { CloseIcon } from '../data/Icons';
import { useFeatureFlags } from '../FeatureFlagsContext';
import { convertUnits } from '../utils/converUnits';
import { formatSpellText } from '../utils/textFormatting';
import { capitalizeWords } from '../utils/textUtils';

const getShortSchoolName = (school) => {
  return school.substring(0, 3).toUpperCase();
};

const getFullSchoolName = (school) => {
  return school.charAt(0).toUpperCase() + school.slice(1);
};

const SpellCardGenerator = ({ spells, onClose, convertToMetric: initialConvertToMetric }) => {
  const { showOnlySRDDescriptions } = useFeatureFlags();
  const modalRef = useRef();

  // Update to handle click outside properly with overlay
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (modalRef) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Add Escape key handler
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  // Add this to see what we're getting
  console.log('Incoming spells:', spells);

  // Format spells immediately when they arrive
  const formattedSpells = spells.map(spell => ({
    ...spell,
    level: spell.level === 'Cantrip' || spell.level === 'cantrip' ? 0 :
      typeof spell.level === 'string' ? parseInt(spell.level) : spell.level
  }));

  const [layout, setLayout] = useState({
    fontSize: 10,
    orientation: 'portrait',
    cardStyle: 'full', // 'full', 'quick-lookup', or 'cards'
    cardSize: 'small', // 'small', 'medium', or 'large'
    columns: 1,        // Default to 1 column
    sortBy: 'name',
    convertToMetric: initialConvertToMetric,
    header: '',
    coloredHeaders: false // Default to false (gray headers)
  });

  // Import the conversion function
  const { convertUnits } = require('../utils/converUnits');

  // Function to convert text if needed
  const convertIfNeeded = (text) => {
    if (!text) return '';
    
    // Check if description should be shown based on SRD status for spell
    const processTextForSpell = (spell) => {
      // Always show custom spells regardless of SRD restriction
      const shouldShowDescription = !showOnlySRDDescriptions || spell.srd || spell.isCustom;
      
      if (!shouldShowDescription) {
        return "Spell description limited by content license\n\nThis spell's details are not part of the System Reference Document (SRD) and can't be displayed. Only the basic spell information is shown.";
      }
      
      // Apply metric conversion if needed
      return layout.convertToMetric ? convertUnits(text) : text;
    };
    
    // If the text is part of a specific spell processing context
    if (currentSpell) {
      return processTextForSpell(currentSpell);
    }
    
    // Standard conversion when not processing a specific spell
    return layout.convertToMetric ? convertUnits(text) : text;
  };

  // Track the current spell being processed to handle SRD restrictions
  let currentSpell = null;

  // Sort spells based on selected sort option
  const sortedSpells = [...formattedSpells].sort((a, b) => {
    if (layout.sortBy === 'level') {
      // First sort by level
      const levelCompare = a.level - b.level;
      // If same level, sort alphabetically
      if (levelCompare === 0) {
        return a.name.localeCompare(b.name);
      }
      return levelCompare;
    }
    // Sort alphabetically by name
    return a.name.localeCompare(b.name);
  });

  const renderHeader = (doc, pageWidth, margin) => {
    if (layout.header) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(layout.fontSize + 2);
      const textWidth = doc.getStringUnitWidth(layout.header) * (layout.fontSize + 2) / doc.internal.scaleFactor;
      doc.text(layout.header, (pageWidth / 2) - (textWidth / 2), margin + 5);
      return margin + layout.fontSize + 5; // Return the new starting Y position
    }
    return margin; // Return original margin if no header
  };

  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: layout.orientation,
      unit: 'mm'
    });

    // Function to add page number (shared between both views)
    const addPageNumber = () => {
      const totalPages = doc.internal.getNumberOfPages();
      const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 10;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(layout.fontSize - 1);
      const text = `Page ${currentPage} of ${totalPages}`;
      const textWidth = doc.getStringUnitWidth(text) * (layout.fontSize - 1) / doc.internal.scaleFactor;
      doc.text(text, pageWidth - margin - textWidth, pageHeight - margin);
    };

    let totalPages = 1;

    if (layout.cardStyle === 'quick-lookup') {
      totalPages = generateQuickLookupPDF(doc, {
        spells: sortedSpells,
        layout,
        margin: 10,
        convertIfNeeded: (text, spell) => {
          currentSpell = spell;
          const result = convertIfNeeded(text);
          currentSpell = null;
          return result;
        },
        renderHeader
      });
    } else if (layout.cardStyle === 'cards') {
      generateCardsPDF(doc, {
        spells: sortedSpells,
        layout,
        convertIfNeeded: (text, spell) => {
          currentSpell = spell;
          const result = convertIfNeeded(text);
          currentSpell = null;
          return result;
        },
        showOnlySRDDescriptions
      });
    } else {
      totalPages = generateDetailedPDF(doc, {
        spells: sortedSpells,
        layout,
        margin: 10,
        convertIfNeeded: (text, spell) => {
          currentSpell = spell;
          const result = convertIfNeeded(text);
          currentSpell = null;
          return result;
        },
        renderHeader,
        showOnlySRDDescriptions
      });
    }

    // Only add page numbers if not using cards
    if (layout.cardStyle !== 'cards') {
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        addPageNumber();
      }
    }

    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className="spellcard-overlay">
      <div className="spell-card-generator modal-content" ref={modalRef}>
        <div className="modal-header">
          <h2>Generate Spell Cards</h2>
          <button
            type="button"
            className="close-button"
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>

        <div className="modal-body">
          <div className="layout-options-section">
            <h3>Layout Options</h3>
            <div className="layout-options-container">
              <div className="form-row">
                <div className="form-group">
                  <label>Style</label>
                  <select
                    value={layout.cardStyle}
                    onChange={(e) => setLayout({
                      ...layout,
                      cardStyle: e.target.value,
                      orientation: e.target.value === 'cards' ? 'portrait' : layout.orientation,
                      cardSize: e.target.value === 'cards' ? 'small' : layout.cardSize
                    })}
                  >
                    <option value="full">Full (Detailed)</option>
                    <option value="cards">Cards</option>
                    <option value="quick-lookup">Quick Reference Sheet</option>
                  </select>
                </div>
                {layout.cardStyle !== 'cards' && layout.cardStyle !== 'quick-lookup' && (
                  <div className="form-group">
                    <label>Columns</label>
                    <select
                      value={layout.columns}
                      onChange={(e) => setLayout({ ...layout, columns: Number(e.target.value) })}
                    >
                      <option value="1">Single Column</option>
                      <option value="2">Two Columns</option>
                    </select>
                  </div>
                )}
              </div>

              <div className="form-row">
                {layout.cardStyle !== 'cards' && (
                  <div className="form-group">
                    <label>Page Orientation</label>
                    <select
                      value={layout.orientation}
                      onChange={(e) => setLayout({ ...layout, orientation: e.target.value })}
                    >
                      <option value="portrait">Portrait</option>
                      <option value="landscape">Landscape</option>
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <label>Font Size</label>
                  <select
                    value={layout.fontSize}
                    onChange={(e) => setLayout({ ...layout, fontSize: Number(e.target.value) })}
                  >
                    <option value="8">Small</option>
                    <option value="10">Medium</option>
                    <option value="12">Large</option>
                  </select>
                </div>
              </div>

              {layout.cardStyle === 'cards' && (
                <div className="form-group">
                  <label>Card Size</label>
                  <select
                    value={layout.cardSize}
                    onChange={(e) => setLayout({
                      ...layout,
                      cardSize: e.target.value,
                      orientation: e.target.value === 'medium' ? 'landscape' : 'portrait'
                    })}
                  >
                    <option value="small">Small (9 per page)</option>
                    <option value="medium">Medium (6 per page)</option>
                    <option value="large">Large (4 per page)</option>
                  </select>
                </div>
              )}

              <div className="form-row">
                <div className="form-group">
                  <label>Sort By</label>
                  <select
                    value={layout.sortBy}
                    onChange={(e) => setLayout({ ...layout, sortBy: e.target.value })}
                  >
                    <option value="name">Spell Name</option>
                    <option value="level">Spell Level</option>
                  </select>
                </div>
              </div>

              <div className="form-group checkbox-label">
                <label>
                  <input
                    type="checkbox"
                    checked={layout.convertToMetric}
                    onChange={(e) => setLayout({ ...layout, convertToMetric: e.target.checked })}
                  />
                  <span className="checkbox-text">Metric units</span>
                </label>
              </div>

              {layout.cardStyle === 'cards' && (
                <div className="form-group checkbox-label">
                  <label>
                    <input
                      type="checkbox"
                      checked={layout.coloredHeaders}
                      onChange={(e) => setLayout({ ...layout, coloredHeaders: e.target.checked })}
                    />
                    <span className="checkbox-text">Colored headers</span>
                  </label>
                </div>
              )}

              {layout.cardStyle !== 'cards' && (
                <div className="form-group">
                  <label>Page Header</label>
                  <input
                    type="text"
                    value={layout.header}
                    onChange={(e) => setLayout({ ...layout, header: e.target.value })}
                    placeholder="Optional page header"
                    className="header-input"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="buttons-container">
            <button onClick={generatePDF} className="generate-button">
              Generate PDF
            </button>
            <button onClick={onClose} className="cancel-button">
              Cancel
            </button>
          </div>

          <div className="selected-spells-section">
            <h3>Selected Spells ({sortedSpells.length})</h3>
            <div className="spell-list-preview">
              {sortedSpells.map(spell => (
                <div key={spell.id} className="spell-preview-item">
                  <span>{spell.name} (Level {spell.level})</span>
                  <span
                    className={`school-badge school-${spell.school.toLowerCase()}`}
                    title={getFullSchoolName(spell.school)}
                  >
                    {getShortSchoolName(spell.school)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpellCardGenerator; 