import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, deleteField, arrayUnion, arrayRemove } from 'firebase/firestore';
import { logger } from './utils';

/**
 * Get comments for a spell
 * @param {string} spellId - The spell ID
 * @returns {Promise<Array>} - Array of comments
 */
export const getSpellComments = async (spellId) => {
  try {
    if (!spellId) {
      logger('No spell ID provided to getSpellComments');
      return [];
    }

    const spellCommentsRef = doc(db, 'spellComments', spellId);
    const spellCommentsDoc = await getDoc(spellCommentsRef);

    if (!spellCommentsDoc.exists()) {
      return [];
    }

    const commentsData = spellCommentsDoc.data();
    return commentsData.comments || [];
  } catch (error) {
    console.error('Error getting spell comments:', error);
    return [];
  }
};

/**
 * Add a comment to a spell
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @param {string} commentText - The comment text
 * @param {string} userName - The user's display name
 * @returns {Promise<Object>} - The created comment
 */
export const addSpellComment = async (userId, spellId, commentText, userName) => {
  try {
    if (!userId || !spellId || !commentText) {
      logger('Missing required parameters in addSpellComment');
      throw new Error('Missing required parameters');
    }

    const commentId = `comment-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    const comment = {
      id: commentId,
      userId,
      userName: userName || 'Anonymous',
      text: commentText,
      timestamp: new Date(),
      likes: [],
      edited: false
    };

    const spellCommentsRef = doc(db, 'spellComments', spellId);
    const spellCommentsDoc = await getDoc(spellCommentsRef);

    if (spellCommentsDoc.exists()) {
      // Add to existing comments
      await updateDoc(spellCommentsRef, {
        comments: arrayUnion(comment)
      });
    } else {
      // Create new document with comment
      await setDoc(spellCommentsRef, {
        comments: [comment]
      });
    }

    return comment;
  } catch (error) {
    console.error('Error adding spell comment:', error);
    throw error;
  }
};

/**
 * Delete a comment from a spell
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @param {string} commentId - The comment ID
 * @returns {Promise<void>}
 */
export const deleteSpellComment = async (userId, spellId, commentId) => {
  try {
    if (!userId || !spellId || !commentId) {
      logger('Missing required parameters in deleteSpellComment');
      throw new Error('Missing required parameters');
    }

    const spellCommentsRef = doc(db, 'spellComments', spellId);
    const spellCommentsDoc = await getDoc(spellCommentsRef);

    if (!spellCommentsDoc.exists()) {
      logger(`No comments found for spell ${spellId}`);
      return;
    }

    const commentsData = spellCommentsDoc.data();
    const comments = commentsData.comments || [];
    const commentToDelete = comments.find(c => c.id === commentId);

    if (!commentToDelete) {
      logger(`Comment with ID ${commentId} not found`);
      return;
    }

    // Only allow deletion if the user is the author
    if (commentToDelete.userId !== userId) {
      throw new Error('You can only delete your own comments');
    }

    // Remove the comment
    await updateDoc(spellCommentsRef, {
      comments: arrayRemove(commentToDelete)
    });
  } catch (error) {
    console.error('Error deleting spell comment:', error);
    throw error;
  }
};

/**
 * Like or unlike a comment
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @param {string} commentId - The comment ID
 * @returns {Promise<void>}
 */
export const likeSpellComment = async (userId, spellId, commentId) => {
  try {
    if (!userId || !spellId || !commentId) {
      logger('Missing required parameters in likeSpellComment');
      throw new Error('Missing required parameters');
    }

    const spellCommentsRef = doc(db, 'spellComments', spellId);
    const spellCommentsDoc = await getDoc(spellCommentsRef);

    if (!spellCommentsDoc.exists()) {
      logger(`No comments found for spell ${spellId}`);
      return;
    }

    const commentsData = spellCommentsDoc.data();
    const comments = commentsData.comments || [];
    const commentIndex = comments.findIndex(c => c.id === commentId);

    if (commentIndex === -1) {
      logger(`Comment with ID ${commentId} not found`);
      return;
    }

    const comment = comments[commentIndex];
    const likes = comment.likes || [];
    const userLikedIndex = likes.indexOf(userId);

    // Toggle like status
    if (userLikedIndex === -1) {
      // User hasn't liked, so add like
      likes.push(userId);
    } else {
      // User already liked, so remove like
      likes.splice(userLikedIndex, 1);
    }

    // Create updated comment
    const updatedComment = {
      ...comment,
      likes
    };

    // Create updated comments array
    const updatedComments = [
      ...comments.slice(0, commentIndex),
      updatedComment,
      ...comments.slice(commentIndex + 1)
    ];

    // Update the document
    await updateDoc(spellCommentsRef, {
      comments: updatedComments
    });
  } catch (error) {
    console.error('Error liking spell comment:', error);
    throw error;
  }
}; 