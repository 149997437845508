import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { logout } from '../firebase/authFunctions';
import '../styles/components/NavBar.css';
import '../styles/components/NavBarMobile.css';
import logoImage from '../assets/images/crow logo2.webp';
import { AccountIcon } from '../data/Icons';
import ThemeToggle from './ThemeToggle';
import DiceRollerModal from './DiceRollerModal';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';

function NavBar() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDiceRollerOpen, setIsDiceRollerOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMenuOpen(false);
    };

    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      
      document.body.classList.add('resize-animation-stopper');
      
      resizeTimer = setTimeout(() => {
        document.body.classList.remove('resize-animation-stopper');
      }, 400);

      handleResize();
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer);
    };
  }, []);

  // Check if current user is an admin
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        setIsAdmin(false);
        return;
      }

      try {
        const userDoc = await getDocs(collection(db, 'admins'));
        const adminIds = userDoc.docs.map(doc => doc.id);
        
        setIsAdmin(adminIds.includes(currentUser.uid));
      } catch (err) {
        console.error('Error checking admin status:', err);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const openDiceRoller = (e) => {
    e.preventDefault();
    setIsDiceRollerOpen(true);
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="main-nav">
        <div className="wrapper">
          <div className="logo">
            <Link to="/">
              <img src={logoImage} alt="Logo" className="nav-logo" />
            </Link>
            <Link to="/">TableMancer</Link>
          </div>
          <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
            <button className="close-menu-btn" onClick={toggleMenu}>
              <span className="close-icon">×</span>
            </button>
            <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li>
              <input type="checkbox" id="showSpells" />
              <label htmlFor="showSpells" className="mobile-item">Spells</label>
              <a href="#" className="desktop-item">Spells</a>
              <ul className="drop-menu">
                <li><Link to="/spells" onClick={toggleMenu}>Spell List</Link></li>
                <li><Link to="/spellbooks" onClick={toggleMenu}>Spellbooks</Link></li>
                <li><Link to="/prepared-spells" onClick={toggleMenu}>Prepared Spells</Link></li>
              </ul>
            </li>
            <li>
              <input type="checkbox" id="showTools" />
              <label htmlFor="showTools" className="mobile-item">Tools</label>
              <a href="#" className="desktop-item">Tools</a>
              <ul className="drop-menu">
                <li><a href="#" onClick={openDiceRoller}>Dice Roller</a></li>
                <li><Link to="/tools/printables" onClick={toggleMenu}>Printables</Link></li>
              </ul>
            </li>
            <li>
              <input type="checkbox" id="showMore" />
              <label htmlFor="showMore" className="mobile-item">More</label>
              <a href="#" className="desktop-item">More</a>
              <ul className="drop-menu">
                <li><Link to="/how-to" onClick={toggleMenu}>How To Use</Link></li>
                <li><Link to="/faq" onClick={toggleMenu}>FAQ</Link></li>
                <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
                <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
                <li className="theme-toggle-item"><ThemeToggle /></li>
              </ul>
            </li>
            {currentUser ? (
              <li className="account-item">
                <input type="checkbox" id="showAccount" />
                <label htmlFor="showAccount" className="mobile-item">
                  <AccountIcon />
                </label>
                <a href="#" className="desktop-item">
                  <AccountIcon />
                </a>
                <ul className="drop-menu">
                  <li><Link to="/account" onClick={toggleMenu}>Account</Link></li>
                  {isAdmin && (
                    <li><Link to="/admin" onClick={toggleMenu}>Admin Dashboard</Link></li>
                  )}
                  <li><button onClick={handleLogout}>Log Out</button></li>
                </ul>
              </li>
            ) : (
              <li className="login-item">
                <Link to="/login" onClick={toggleMenu}>Log In / Register</Link>
              </li>
            )}
          </div>
          <div className="hamburger" onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </nav>
      
      <DiceRollerModal 
        isOpen={isDiceRollerOpen} 
        onClose={() => setIsDiceRollerOpen(false)} 
      />
    </>
  );
}

export default NavBar;
