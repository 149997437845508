import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { logger } from './utils';
import { debounce } from '../../utils/debounce';

/**
 * Get user settings from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing user settings
 */
export const getUserSettings = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserSettings');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() ? (userDoc.data().settings || {}) : {};
  } catch (error) {
    console.error('Error getting user settings:', error);
    return {};
  }
};

/**
 * Update user settings in Firestore
 * @param {string} userId - The user's ID
 * @param {Object} settings - The settings to update
 * @returns {Promise<void>}
 */
export const updateUserSettings = async (userId, settings) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserSettings');
      return;
    }

    await setDoc(doc(db, 'users', userId), { settings }, { merge: true });
  } catch (error) {
    console.error('Error updating user settings:', error);
    throw error;
  }
};

/**
 * Debounced version of updateUserSettings that waits 800ms before saving
 * @param {string} userId - The user's ID
 * @param {Object} settings - The settings to save
 * @returns {Promise<void>}
 */
export const debouncedUpdateUserSettings = debounce(async (userId, settings) => {
  try {
    logger('Saving settings after debounce delay');
    await setDoc(doc(db, 'users', userId), { settings }, { merge: true });
  } catch (error) {
    console.error('Error updating user settings:', error);
  }
}, 800);

/**
 * Clear all user data (used for account deletion)
 * @param {string} userId - The user's ID
 * @returns {Promise<void>}
 */
export const clearUserAccount = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to clearUserAccount');
      return;
    }

    // Delete associated data
    try {
      await deleteDoc(doc(db, 'users', userId));
      logger(`Deleted user document for ${userId}`);
    } catch (error) {
      console.error('Error deleting user document:', error);
    }

    // Delete any user spell ratings
    try {
      await deleteDoc(doc(db, 'userRatings', userId));
      logger(`Deleted user ratings for ${userId}`);
    } catch (error) {
      console.error('Error deleting user ratings:', error);
    }

    // Note: Comments are retained but anonymized
  } catch (error) {
    console.error('Error clearing user account:', error);
    throw error;
  }
};

/**
 * Get all user data in a single request
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - All user data
 */
export const getUserData = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserData');
      return null;
    }

    const userDoc = await getDoc(doc(db, 'users', userId));

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Process the data to ensure we have all necessary structures
      const spellbooks = userData.spellbooks || { 'My Spellbook': [] };
      const settings = userData.settings || {};
      const preparedSpells = userData.preparedSpells || {};
      
      // CRITICAL FIX: Use both field names to handle both old and new data formats
      // Prefer spellSlotsByBook, but fall back to spellSlots if it's not available
      const spellSlots = userData.spellSlotsByBook || userData.spellSlots || {};
      const spellPoints = userData.spellPoints || {};
      const characterLevelsByBook = userData.characterLevelsByBook || {};
      const spellNotes = userData.spellNotes || {};

      // Log for debugging
      console.log('Raw user data from Firebase:', {
        spellbooks: spellbooks,
        preparedSpells: preparedSpells,
        spellSlotsByBook: userData.spellSlotsByBook,
        spellSlots: userData.spellSlots,
        spellPoints: spellPoints
      });

      return {
        spellbooks: spellbooks,
        settings: settings,
        preparedSpells: preparedSpells,
        // CRITICAL FIX: Return with correct property names that match what the app expects
        spellSlotsByBook: spellSlots, // Use correct field name
        spellSlots: spellSlots, // Keep this for backward compatibility
        spellPoints: spellPoints,
        customSpells: userData.customSpells || {},
        characterLevelsByBook: characterLevelsByBook,
        activeSpellbook: userData.activeSpellbook || 'My Spellbook',
        // Include spellNotes in the returned data
        spellNotes: spellNotes,
        // IMPORTANT: Don't spread userData here as it would override our processed fields
      };
    }

    // If user document doesn't exist, create it with default values
    const defaultUserData = {
      spellbooks: { 'My Spellbook': [] },
      settings: {},
      preparedSpells: {},
      spellSlotsByBook: {}, // Use the correct field name
      spellSlots: {}, // Keep for backward compatibility
      spellPoints: {},
      customSpells: {},
      characterLevelsByBook: {},
      activeSpellbook: 'My Spellbook',
      spellNotes: {},
      createdAt: new Date().toISOString()
    };

    await setDoc(doc(db, 'users', userId), defaultUserData);
    return defaultUserData;
  } catch (error) {
    console.error('Error getting user data:', error);
    return null;
  }
};

/**
 * Updates the user's lastVisit timestamp in Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<void>}
 */
export const updateUserLastVisit = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserLastVisit');
      return;
    }

    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      await updateDoc(userDocRef, {
        lastVisit: new Date()
      });
      logger(`Updated last visit timestamp for user ${userId}`);
    } else {
      console.warn(`User document not found for ID: ${userId}`);
    }
  } catch (error) {
    console.error('Error updating last visit timestamp:', error);
  }
};

/**
 * Debounced version of updateUserLastVisit that waits 5000ms before saving
 * and only updates if the last update was more than 30 minutes ago
 * @param {string} userId - The user's ID
 * @returns {Promise<void>}
 */
export const debouncedUpdateUserLastVisit = debounce(async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to debouncedUpdateUserLastVisit');
      return;
    }

    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const lastVisit = userDoc.data().lastVisit;
      const now = new Date();

      // Only update if there's no lastVisit or it was more than 30 minutes ago
      if (!lastVisit ||
        (lastVisit.seconds &&
          (now - new Date(lastVisit.seconds * 1000)) > 30 * 60 * 1000)) {

        logger(`Updating last visit timestamp for user ${userId} after debounce delay`);
        await updateDoc(userDocRef, {
          lastVisit: now
        });
      }
    } else {
      console.warn(`User document not found for ID: ${userId}`);
    }
  } catch (error) {
    console.error('Error updating last visit timestamp:', error);
  }
}, 5000); 