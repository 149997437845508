import React from 'react';
import '../styles/components/SearchBar.css';
import { SearchIcon, ClearSearchIcon } from '../data/Icons';

const SearchBar = ({ searchTerm, onSearchChange }) => {
  const handleClearSearch = () => {
    onSearchChange('');
  };

  return (
    <div className="search-bar-container">
      <div className="search-bar">
        <SearchIcon className="search-icon" />
        <input
          type="text"
          placeholder="Search spells..."
          value={searchTerm || ''}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        {searchTerm && (
          <button className="clear-search" onClick={handleClearSearch}>
            <ClearSearchIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBar;

