import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';

/**
 * A component that protects routes by checking authentication and optionally admin status
 * 
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The route content to render if authorized
 * @param {boolean} props.requireAdmin - Whether the route requires admin privileges
 * @returns {React.ReactNode} - Either the protected content or a redirect
 */
const ProtectedRoute = ({ children, requireAdmin = false }) => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      if (requireAdmin) {
        try {
          const adminDocs = await getDocs(collection(db, 'admins'));
          const adminIds = adminDocs.docs.map(doc => doc.id);
          setIsAdmin(adminIds.includes(currentUser.uid));
        } catch (err) {
          console.error('Error checking admin status:', err);
          setIsAdmin(false);
        }
      }
      
      setLoading(false);
    };

    checkAdminStatus();
  }, [currentUser, requireAdmin]);

  if (loading) {
    // Show loading state while checking authentication/admin status
    return <div className="loading-container">Checking authorization...</div>;
  }

  // If not logged in, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If admin is required but user is not an admin, redirect to home
  if (requireAdmin && !isAdmin) {
    return <Navigate to="/" replace />;
  }

  // User is authorized, render the protected content
  return children;
};

export default ProtectedRoute; 