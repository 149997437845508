import { useState, useEffect, useMemo } from 'react';

const useSearchSpells = (spells, searchTerm) => {
  // Ensure spells is always an array
  const spellsArray = Array.isArray(spells) ? spells : [];

  return useMemo(() => {
    if (!searchTerm) return spellsArray;
    const filtered = spellsArray.filter(spell =>
      spell.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filtered;
  }, [spellsArray, searchTerm]);
};

export default useSearchSpells;