import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { addCustomSpell, updateCustomSpell } from '../firebase/firestoreOperations';
import '../styles/components/CustomSpellForm.css';
import { CloseIcon } from '../data/Icons';
import { useContext } from 'react';
import { NotificationContext } from '../App';

const CustomSpellForm = ({ onSpellAdded, onClose, initialData = null, isEditing = false }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState(initialData || {
    name: '',
    level: 'cantrip',
    school: 'abjuration',
    casting_time: '1 action',
    range: '',
    duration: '',
    description: '',
    shortDescription: '',
    components: {
      verbal: false,
      somatic: false,
      material: false,
      materials_needed: []
    },
    ritual: false,
    concentration: false,
    classes: []
  });
  const [error, setError] = useState(null);
  const { addNotification } = useContext(NotificationContext);

  const [materialComponent, setMaterialComponent] = useState(
    isEditing && initialData?.components?.materials_needed?.[0] || ''
  );

  // Add state to track if click started on overlay
  const [clickStartedOnOverlay, setClickStartedOnOverlay] = useState(false);

  const schools = ['abjuration', 'conjuration', 'divination', 'enchantment', 'evocation', 'illusion', 'necromancy', 'transmutation'];
  const spellLevels = ['Cantrip', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const availableClasses = ['artificer', 'bard', 'cleric', 'druid', 'paladin', 'ranger', 'sorcerer', 'warlock', 'wizard'];

  // If not logged in, show message and disable form
  if (!currentUser) {
    return (
      <div className="customspell-overlay" onClick={(e) => {
        if (e.target.className === 'customspell-overlay') onClose();
      }}>
        <div className="custom-spell-form">
          <div className="generator-header">
            <h2>Create Custom Spell</h2>
            <button 
              type="button" 
              className="close-button"
              onClick={onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="login-required-message">
            <p>You must be logged in to create custom spells.</p>
            <button onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    );
  }

  const handleClassToggle = (className) => {
    setFormData(prev => ({
      ...prev,
      classes: prev.classes.includes(className)
        ? prev.classes.filter(c => c !== className)
        : [...prev.classes, className]
    }));
  };

  const handleComponentChange = (type) => {
    setFormData(prev => ({
      ...prev,
      components: {
        ...prev.components,
        [type]: !prev.components[type]
      }
    }));
  };

  const handleMaterialsChange = (e) => {
    setMaterialComponent(e.target.value);
    setFormData(prev => ({
      ...prev,
      components: {
        ...prev.components,
        materials_needed: e.target.value ? [e.target.value] : []
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      let updatedSpell;
      if (isEditing) {
        updatedSpell = await updateCustomSpell(currentUser.uid, initialData.id, formData);
        addNotification(`Spell "${formData.name}" has been updated successfully`, 'success');
      } else {
        updatedSpell = await addCustomSpell(currentUser.uid, formData);
        addNotification(`Spell "${formData.name}" has been created successfully`, 'success');
      }
      
      onSpellAdded(updatedSpell);
      onClose();
    } catch (error) {
      setError(error.message);
      addNotification(error.message, 'error');
    }
  };

  const handleOverlayMouseDown = (e) => {
    if (e.target.className === 'customspell-overlay') {
      setClickStartedOnOverlay(true);
    } else {
      setClickStartedOnOverlay(false);
    }
  };

  const handleOverlayMouseUp = (e) => {
    if (e.target.className === 'customspell-overlay' && clickStartedOnOverlay) {
      onClose();
    }
    setClickStartedOnOverlay(false);
  };

  return (
    <div 
      className="customspell-overlay" 
      onMouseDown={handleOverlayMouseDown}
      onMouseUp={handleOverlayMouseUp}
    >
      <div className="custom-spell-form">
        <div className="generator-header">
          <h2>{isEditing ? 'Edit Custom Spell' : 'Create Custom Spell'}</h2>
          <button 
            type="button" 
            className="close-button"
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group name-group">
            <label>Spell Name:</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => {
                if (e.target.value.length <= 40) {
                  setFormData(prev => ({
                    ...prev,
                    name: e.target.value
                  }));
                }
              }}
              maxLength={40}
              required
              readOnly={isEditing}
              className={isEditing ? 'readonly' : ''}
            />
          </div>

          <div className="form-row">
            <div className="form-group level-group">
              <label>Level:</label>
              <select
                value={formData.level}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  level: e.target.value
                }))}
              >
                {spellLevels.map(level => (
                  <option key={level} value={level}>{level}</option>
                ))}
              </select>
            </div>

            <div className="form-group school-group">
              <label>School:</label>
              <select
                value={formData.school}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  school: e.target.value
                }))}
              >
                {schools.map(school => (
                  <option key={school} value={school}>
                    {school.charAt(0).toUpperCase() + school.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-row short-inputs">
            <div className="form-group">
              <label>Casting Time:</label>
              <input
                type="text"
                value={formData.casting_time}
                onChange={(e) => {
                  if (e.target.value.length <= 40) {
                    setFormData(prev => ({
                      ...prev,
                      casting_time: e.target.value
                    }));
                  }
                }}
                maxLength={40}
                required
              />
            </div>

            <div className="form-group">
              <label>Range:</label>
              <input
                type="text"
                value={formData.range}
                onChange={(e) => {
                  if (e.target.value.length <= 40) {
                    setFormData(prev => ({
                      ...prev,
                      range: e.target.value
                    }));
                  }
                }}
                maxLength={40}
                required
              />
            </div>

            <div className="form-group">
              <label>Duration:</label>
              <input
                type="text"
                value={formData.duration}
                onChange={(e) => {
                  if (e.target.value.length <= 40) {
                    setFormData(prev => ({
                      ...prev,
                      duration: e.target.value
                    }));
                  }
                }}
                maxLength={40}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label>Components:</label>
            <div className="components-group">
              <label>
                <input
                  type="checkbox"
                  checked={formData.components.verbal}
                  onChange={() => handleComponentChange('verbal')}
                /> Verbal
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.components.somatic}
                  onChange={() => handleComponentChange('somatic')}
                /> Somatic
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.components.material}
                  onChange={() => handleComponentChange('material')}
                /> Material
              </label>
            </div>
            {formData.components.material && (
              <input
                type="text"
                value={materialComponent}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setMaterialComponent(e.target.value);
                    setFormData(prev => ({
                      ...prev,
                      components: {
                        ...prev.components,
                        materials_needed: e.target.value ? [e.target.value] : []
                      }
                    }));
                  }
                }}
                placeholder="Material components..."
                className="material-input"
                maxLength={200}
              />
            )}
          </div>

          <div className="form-group">
            <label>Description:</label>
            <textarea
              value={formData.description}
              onChange={(e) => {
                if (e.target.value.length <= 4000) {
                  setFormData(prev => ({
                    ...prev,
                    description: e.target.value
                  }));
                }
              }}
              required
              rows={4}
              maxLength={4000}
            />
          </div>

          <div className="form-group">
            <label>Short Description: <span className="char-limit">({formData.shortDescription.length}/120)</span></label>
            <textarea
              className="short-description"
              value={formData.shortDescription}
              onChange={(e) => {
                if (e.target.value.length <= 120) {
                  setFormData(prev => ({
                    ...prev,
                    shortDescription: e.target.value
                  }));
                }
              }}
              rows={2}
              maxLength={120}
            />
          </div>

          <div className="form-group">
            <label>Classes:</label>
            <div className="classes-group">
              {availableClasses.map(className => (
                <label key={className}>
                  <input
                    type="checkbox"
                    checked={formData.classes.includes(className)}
                    onChange={() => handleClassToggle(className)}
                  />
                  {className.charAt(0).toUpperCase() + className.slice(1)}
                </label>
              ))}
            </div>
          </div>

          <div className="form-group">
            <div className="spell-properties">
              <label>
                <input
                  type="checkbox"
                  checked={formData.ritual}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    ritual: e.target.checked
                  }))}
                /> Ritual
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.concentration}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    concentration: e.target.checked
                  }))}
                /> Concentration
              </label>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit">
              {isEditing ? 'Save Changes' : 'Create Spell'}
            </button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomSpellForm; 