import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/SpellTable.css';
import { formatUnits } from '../utils/formatUnits';
import { convertUnits } from '../utils/converUnits';
import PaginationControls from './PaginationControls';
import { updateSortConfig } from '../utils/sortSpells';
import { ResetIcon } from '../data/Icons';

const CHECK_MARK = '✓';

const getShortSchoolName = (school) => {
  return school.substring(0, 3).toUpperCase();
};

const getFullSchoolName = (school) => {
  return school.charAt(0).toUpperCase() + school.slice(1);
};

const columnWidths = {
  0: 20,   // Checkbox column
  1: 325,  // Name column
  2: 45,   // Level column
  3: 50,   // School column
  4: 90,   // Components column
  5: 110,  // Casting Time column
  6: 100,  // Range column
  7: 110,  // Duration column
  8: 50,   // Concentration column
  9: 50    // Ritual column
};

const SpellTable = ({
  spells = [],
  selectedSpells,
  toggleItem,
  selectAll,
  areAllSelected,
  showShortDescriptions,
  convertToMetric,
  expandedRows,
  setExpandedRows,
  visibleColumns,
  renderIcons,
  emptyMessage = "No spells match the current criteria.",
  showRitualStyling = false,
  className = '',
  itemsPerPage: defaultItemsPerPage = 25,
  onItemsPerPageChange,
  sortConfig,
  setSortConfig,
  pagination = { itemsPerPage: 25, currentPage: 1 },
  setPagination = () => {},
  tabName = 'spellList',
  totalSpellCount,
  useSpellPoints = false,
  spellPoints = { total: 0, used: 0 },
  setSpellPoints = () => {},
  allowMultipleHighLevelCasts = false,
  spellPointsByBook = {},
  currentSpellbook = '',
  updateSpellPoints = () => {},
  currentSpellSlots = [],
  updateSpellSlots = () => {},
  showNotification = () => {},
  showSchoolTags = true,
}) => {
  const { itemsPerPage, currentPage } = pagination;

  const spellsArray = Array.isArray(spells) ? spells : [];

  const totalItems = spellsArray.filter(spell => !spell.isSeparator).length;

  useEffect(() => {
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  }, [spellsArray, setPagination]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentSpells = spellsArray.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setPagination({ itemsPerPage: newItemsPerPage, currentPage: 1 });
  };

  const getSpellLevel = (level) => {
    if (level === 'cantrip') return 'Cantrip';
    return level.toString();
  };

  const getComponents = (spell) => {
    if (!spell.components) return '';
    
    const components = [];
    if (spell.components.verbal) components.push('V');
    if (spell.components.somatic) components.push('S');
    if (spell.components.material) {
      const materialsStr = spell.components.materials_needed?.join(' ') || '';
      if (materialsStr.includes(' GP') || materialsStr.includes(' SP')) {
        components.push('M gp');
      } else {
        components.push('M');
      }
    }
    
    return components.join(', ');
  };

  const formatTableValue = (value) => {
    if (typeof value === 'string') {
      if (value.startsWith('1 reaction')) {
        return '1 reaction';
      }
      if (value.startsWith('Concentration,')) {
        return value.replace('Concentration, ', '');
      }
      if (value.includes('emanation')) {
        return value.replace(/(\d+)-?foot emanation/, '$1ft emanation')
                   .replace(/(\d+)-?ft emanation/, '$1ft emanation');
      }
    }
    // First format the units for consistency, then apply metric conversion if needed
    const formattedValue = formatUnits(value);
    return convertToMetric ? convertUnits(formattedValue) : formattedValue;
  };

  const formatShortDescription = (description) => {
    return convertToMetric ? convertUnits(description) : description;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string || typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const toggleSpellDescription = useCallback((spellName, event) => {
    if (event.target.type === 'checkbox' || event.target.closest('.icon-container')) {
      return;
    }
    setExpandedRows(prev => ({
      ...prev,
      [spellName]: !prev[spellName]
    }));
  }, [setExpandedRows]);

  const isRowExpanded = useCallback((spellName) => {
    return showShortDescriptions || expandedRows[spellName];
  }, [showShortDescriptions, expandedRows]);

  const columns = [
    { key: 'checkbox', label: <input type="checkbox" checked={areAllSelected} onChange={(e) => selectAll(e.target.checked)} /> },
    { key: 'name', label: 'Name' },
    { key: 'level', label: 'Level' },
    { key: 'school', label: 'School' },
    { key: 'components', label: 'Components' },
    { key: 'castingTime', label: 'Casting Time' },
    { key: 'range', label: 'Range' },
    { key: 'duration', label: 'Duration' },
    { key: 'concentration', label: <span className="header-badge" title="Concentration">C.</span> },
    { key: 'ritual', label: <span className="header-badge" title="Ritual">R.</span> }
  ];

  const remainingSpells = spellsArray.filter(spell => !spell.isSeparator).length - endIndex;
  const hasMoreSpells = remainingSpells > 0 && itemsPerPage !== totalItems;

  const requestSort = (key) => {
    if (key === 'checkbox') return;
    const newSortConfig = updateSortConfig(sortConfig, key);
    setSortConfig(newSortConfig);
  };

  const handleCastSpell = (spell) => {
    if (!spell.level) return; // Cantrips don't use resources

    if (useSpellPoints) {
      // Get the spell point cost for this level
      const spellPointCost = {
        1: 2,
        2: 3,
        3: 5,
        4: 6,
        5: 7,
        6: 9,
        7: 10,
        8: 11,
        9: 13
      }[spell.level];

      const currentPoints = spellPoints.total - spellPoints.used;
      
      if (currentPoints < spellPointCost) {
        showNotification("Not enough spell points to cast this spell.", "error");
        return;
      }

      // For 6th level and higher spells, check if multiple casts are allowed
      if (spell.level >= 6 && !allowMultipleHighLevelCasts) {
        const highLevelUses = spellPointsByBook[currentSpellbook]?.highLevelUses?.[spell.level] || 0;
        if (highLevelUses >= 1) {
          showNotification("You've already cast a spell of this level.", "error");
          return;
        }
      }

      // If we have enough points, deduct them
      setSpellPoints({
        ...spellPoints,
        used: spellPoints.used + spellPointCost
      });

      // Track high level spell usage if needed
      if (spell.level >= 6) {
        const currentHighLevelUses = spellPointsByBook[currentSpellbook]?.highLevelUses || {};
        const newHighLevelUses = {
          ...currentHighLevelUses,
          [spell.level]: (currentHighLevelUses[spell.level] || 0) + 1
        };
        updateSpellPoints(currentSpellbook, {
          ...spellPointsByBook[currentSpellbook],
          highLevelUses: newHighLevelUses
        });
      }

      showNotification(`Cast ${spell.name} using ${spellPointCost} spell points.`, "success");
    } else {
      // Existing spell slot logic
      if (currentSpellSlots[spell.level - 1].used >= currentSpellSlots[spell.level - 1].max) {
        showNotification("Not enough spell slots of this level remaining.", "error");
        return;
      }

      const newSlots = [...currentSpellSlots];
      newSlots[spell.level - 1] = {
        ...newSlots[spell.level - 1],
        used: newSlots[spell.level - 1].used + 1
      };
      updateSpellSlots(currentSpellbook, newSlots);
      showNotification(`Cast ${spell.name} using a level ${spell.level} spell slot.`, "success");
    }
  };

  return (
    <div className={`spell-table-container ${className}`}>
      <table className={className}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th 
                key={column.key}
                style={{ width: `${columnWidths[index]}px` }}
                onClick={() => requestSort(column.key)}
                className={`
                  ${column.key === 'checkbox' ? 'no-sort' : ''}
                  ${sortConfig?.key === column.key ? `sort-${sortConfig.direction}` : ''}
                `}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentSpells.length > 0 ? (
            <>
              {currentSpells.map((spell) => {
                if (spell.isSeparator) {
                  return (
                    <tr key="separator" className="separator-row">
                      <td colSpan={visibleColumns}></td>
                    </tr>
                  );
                }

                const isUnpreparedRitual = showRitualStyling && spell.ritual && !spell.isPrepared;
                
                return (
                  <React.Fragment key={spell.name}>
                    <tr 
                      className={`
                        spell-row
                        ${isRowExpanded(spell.name) ? 'expanded' : ''}
                        ${spell.isCustom ? 'custom-spell' : ''}
                        ${isUnpreparedRitual ? 'ritual' : ''}
                      `}
                      onClick={(event) => toggleSpellDescription(spell.name, event)}
                    >
                      <td style={{ width: `${columnWidths[0]}px` }} onClick={(e) => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={selectedSpells[spell.name] || false}
                          onChange={() => toggleItem(spell.name)}
                        />
                      </td>
                      <td className="spell-name-cell" style={{ width: `${columnWidths[1]}px` }}>
                        <div className="spell-name-with-notes">
                          <Link to={`/spell/${encodeURIComponent(spell.name)}`}>{spell.name}</Link>
                          {spell.notes && (
                            <span className="notes-icon-inline" title="This spell has personal notes">
                              <svg viewBox="-3.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g id="icomoon-ignore"> </g>
                                <path d="M6.294 14.164h12.588v1.049h-12.588v-1.049z"></path>
                                <path d="M6.294 18.36h12.588v1.049h-12.588v-1.049z"></path>
                                <path d="M6.294 22.557h8.392v1.049h-8.392v-1.049z"></path>
                                <path d="M15.688 3.674c-0.25-1.488-1.541-2.623-3.1-2.623s-2.85 1.135-3.1 2.623h-9.489v27.275h25.176v-27.275h-9.488zM10.49 6.082v-1.884c0-1.157 0.941-2.098 2.098-2.098s2.098 0.941 2.098 2.098v1.884l0.531 0.302c1.030 0.586 1.82 1.477 2.273 2.535h-9.803c0.453-1.058 1.243-1.949 2.273-2.535l0.53-0.302zM24.128 29.9h-23.078v-25.177h8.392v0.749c-1.638 0.932-2.824 2.566-3.147 4.496h12.588c-0.322-1.93-1.509-3.563-3.147-4.496v-0.749h8.392v25.177z"></path>
                              </svg>
                            </span>
                          )}
                        </div>
                        <div className="icon-container">
                          {renderIcons(spell)}
                        </div>
                      </td>
                      <td style={{ width: `${columnWidths[2]}px` }}>{getSpellLevel(spell.level)}</td>
                      <td style={{ width: `${columnWidths[3]}px` }}>
                        {showSchoolTags ? (
                          <span 
                            className={`school-badge school-${spell.school.toLowerCase()}`}
                            title={getFullSchoolName(spell.school)}
                          >
                            {getShortSchoolName(spell.school)}
                          </span>
                        ) : (
                          <span title={getFullSchoolName(spell.school)}>
                            {getShortSchoolName(spell.school)}
                          </span>
                        )}
                      </td>
                      <td style={{ width: `${columnWidths[4]}px` }}>{getComponents(spell)}</td>
                      <td style={{ width: `${columnWidths[5]}px` }}>{formatTableValue(spell.casting_time)}</td>
                      <td style={{ width: `${columnWidths[6]}px` }}>{formatTableValue(spell.range)}</td>
                      <td style={{ width: `${columnWidths[7]}px` }}>{formatTableValue(spell.duration)}</td>
                      <td style={{ width: `${columnWidths[8]}px` }}>
                        {(spell.concentration === true || (!spell.concentration && spell.duration?.toLowerCase().includes('concentration'))) && (
                          <span className="check-mark">{CHECK_MARK}</span>
                        )}
                      </td>
                      <td style={{ width: `${columnWidths[9]}px` }}>
                        {spell.ritual && (
                          <span className="check-mark">{CHECK_MARK}</span>
                        )}
                      </td>
                    </tr>
                    {isRowExpanded(spell.name) && (
                      <tr 
                        className={`
                          spell-description-row
                          ${spell.isCustom ? 'custom-spell' : ''}
                          ${isUnpreparedRitual ? 'ritual' : ''}
                        `}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSpellDescription(spell.name, e);
                        }}
                      >
                        <td colSpan={visibleColumns} className="short-description">
                          {formatShortDescription(spell.shortDescription) || 'No short description available.'}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
              {hasMoreSpells && (
                <tr className="remaining-spells-row">
                  <td colSpan={visibleColumns}>
                    {remainingSpells} more spell{remainingSpells !== 1 ? 's' : ''} available. Use the pagination controls below to view more.
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr className="empty-table-row">
              <td colSpan={visibleColumns}>{emptyMessage}</td>
            </tr>
          )}
        </tbody>
      </table>
      
      {spellsArray.length > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          totalItems={totalItems}
        />
      )}
    </div>
  );
};

export default SpellTable; 