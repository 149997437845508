import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import spellsData from '../data/spells.json';
import '../styles/components/SpellList.css';
import useSpellFilters from '../hooks/useSpellFilters';
import useSortSpells from '../hooks/useSortSpells';
import useSearchSpells from '../hooks/useSearchSpells';
import useCheckboxSelection from '../hooks/useCheckboxSelection';
import useUnitConversion from '../hooks/useUnitConversion';
import AddToSpellbookModal from './AddToSpellbookModal';
import NotificationPopup from './NotificationPopup';
import { AddToSpellbookIcon, NewInfoIcon, NotesIcon } from '../data/Icons';
import { BackToTopIcon } from '../data/Icons';
import SpellInfoBox from './SpellInfoBox';
import { NotificationContext } from '../App';
import { convertUnits } from '../utils/converUnits'; // Make sure this import is present
import { debounce } from 'lodash'; // Make sure to install lodash if not already present
import SpellTable from './SpellTable';
import HowToUseBox from './HowToUseBox';
import SpellFilters from './SpellFilters';
import SpellResourceManager from './SpellResourceManager';
import { useAuth } from '../AuthContext';
import { 
  getAllSpells, 
  getUserCustomSpells, 
  addCustomSpell, 
  updateCustomSpell, 
  deleteCustomSpell,
  refreshCustomSpells,
  subscribeToCustomSpells,
  getUserData
} from '../firebase/firestoreOperations';
import CustomSpellForm from './CustomSpellForm';
import SpellCardGenerator from './SpellCardGenerator';
import SpellTableControls from './SpellTableControls';
import ActiveSpellbookHeader from './ActiveSpellbookHeader';
import AdvancedFilterTags from './AdvancedFilterTags';
import { filterSpells, prepareFilterOptions } from '../utils/spellFilters';
import { sortSpells } from '../utils/sortSpells';
import { searchSpells } from '../utils/searchSpells';

const SpellList = React.memo(({ 
  filters, 
  setFilters, 
  searchTerm, 
  setSearchTerm, 
  convertToMetric,
  setConvertToMetric,
  spellbooks, 
  addToSpellbook, 
  showShortDescriptions,
  setShowShortDescriptions,
  sortConfig,
  setSortConfig,
  pagination,
  setPagination,
  showSpellSlotsEverywhere,
  currentSpellbook,
  setCurrentSpellbook,
  spellSlotsByBook,
  updateSpellSlots,
  setShowSpellSlotsEverywhere,
  spellPointsByBook,
  updateSpellPoints,
  characterLevel,
  setCharacterLevel,
  highLevelSpellUses,
  setHighLevelSpellUses,
  allowMultipleHighLevelCasts,
  setAllowMultipleHighLevelCasts,
  useSpellPoints,
  setUseSpellPoints,
  showSchoolTags,
  setShowSchoolTags
}) => {
  const [spells, setSpells] = useState([]);
  const [localSelectedSpells, setLocalSelectedSpells] = useState({});
  const [isAnySpellSelected, setIsAnySpellSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spellsToAdd, setSpellsToAdd] = useState([]);
  const [notification, setNotification] = useState(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedSpell, setSelectedSpell] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(10);
  const { currentUser } = useAuth();
  const [customSpells, setCustomSpells] = useState({});
  const [showCustomSpellForm, setShowCustomSpellForm] = useState(false);
  const [showCardGenerator, setShowCardGenerator] = useState(false);
  const [resetColumnsFunc, setResetColumnsFunc] = useState(() => () => {});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { addNotification } = useContext(NotificationContext);

  const memoizedData = useMemo(() => spells, [spells]);

  const {
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters
  } = useSpellFilters(spells, filters, setFilters);

  const { sortedSpells, requestSort } = useSortSpells(filteredSpells, sortConfig, setSortConfig);
  const searchedSpells = useSearchSpells(sortedSpells, searchTerm);
  const { toggleItem, selectAll, areAllSelected, clearSelection } = useCheckboxSelection(searchedSpells, localSelectedSpells, setLocalSelectedSpells);
  const { toggleConversion, convertIfNeeded } = useUnitConversion(convertToMetric, setConvertToMetric);

  useEffect(() => {
    let isMounted = true;
    
    const fetchCustomSpells = async () => {
      if (currentUser && isMounted) {
        try {
          const newCustomSpells = await refreshCustomSpells(currentUser.uid);
          
          // Only update if the custom spells have actually changed
          // This prevents unnecessary re-renders
          const currentIds = Object.keys(customSpells).sort().join(',');
          const newIds = Object.keys(newCustomSpells).sort().join(',');
          
          if (currentIds !== newIds) {
            setCustomSpells(newCustomSpells);
          } else {
            // Check if any spell properties have changed
            let hasChanges = false;
            Object.keys(newCustomSpells).some(id => {
              if (JSON.stringify(newCustomSpells[id]) !== JSON.stringify(customSpells[id])) {
                hasChanges = true;
                return true;
              }
              return false;
            });
            
            if (hasChanges) {
              setCustomSpells(newCustomSpells);
            }
          }
        } catch (error) {
          console.error('Error fetching custom spells:', error);
        }
      }
    };

    fetchCustomSpells();
    
    // Reduced frequency from 60 seconds to 300 seconds (5 minutes)
    // This significantly reduces database reads while still keeping data reasonably fresh
    const intervalId = setInterval(fetchCustomSpells, 300000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [currentUser]);

  // Add a function to manually refresh custom spells
  const handleRefreshCustomSpells = useCallback(async () => {
    if (currentUser) {
      try {
        const newCustomSpells = await refreshCustomSpells(currentUser.uid);
        setCustomSpells(newCustomSpells);
      } catch (error) {
        console.error('Error manually refreshing custom spells:', error);
      }
    }
  }, [currentUser]);

  // Update the handleAddCustomSpell function to refresh spells after adding
  const handleAddCustomSpell = async (newSpell) => {
    try {
      const createdSpell = await addCustomSpell(currentUser.uid, newSpell);
      // Refresh custom spells to get the latest data
      handleRefreshCustomSpells();
      addNotification(`Custom spell "${newSpell.name}" created successfully`, 'success');
      return createdSpell;
    } catch (error) {
      addNotification(error.message, 'error');
      throw error;
    }
  };

  // Update the handleUpdateCustomSpell function to refresh spells after updating
  const handleUpdateCustomSpell = async (spellId, updatedSpell) => {
    try {
      await updateCustomSpell(currentUser.uid, spellId, updatedSpell);
      // Refresh custom spells to get the latest data
      handleRefreshCustomSpells();
      addNotification(`Spell "${updatedSpell.name}" updated successfully`, 'success');
    } catch (error) {
      addNotification(error.message, 'error');
    }
  };

  // Update the handleDeleteCustomSpell function to refresh spells after deleting
  const handleDeleteCustomSpell = async (spellId, spellName) => {
    try {
      await deleteCustomSpell(currentUser.uid, spellId);
      // Refresh custom spells to get the latest data
      handleRefreshCustomSpells();
      addNotification(`Spell "${spellName}" deleted successfully`, 'success');
      setShowDeleteConfirm(false);
      setSelectedSpell(null);
    } catch (error) {
      addNotification(`Error deleting spell: ${error.message}`, 'error');
    }
  };

  // Fetch user's spell notes and add them to the spell objects
  useEffect(() => {
    const fetchSpellNotes = async () => {
      if (currentUser) {
        try {
          const userData = await getUserData(currentUser.uid);
          const userNotes = userData?.spellNotes || {};
          
          // Create a map of spells with notes
          const spellsWithNotes = {};
          Object.keys(userNotes).forEach(spellId => {
            if (userNotes[spellId] && userNotes[spellId].trim() !== '') {
              spellsWithNotes[spellId] = true;
            }
          });
          
          // Update the existing spells array directly without creating a new one
          let spellsUpdated = false;
          spells.forEach(spell => {
            const hasNotes = spellsWithNotes[spell.id] || false;
            if (spell.notes !== hasNotes) {
              spell.notes = hasNotes;
              spellsUpdated = true;
            }
          });
          
          // Also update the customSpells object to ensure custom spells have the notes property
          const updatedCustomSpells = {};
          let customSpellsUpdated = false;
          
          Object.entries(customSpells).forEach(([id, spell]) => {
            const hasNotes = spellsWithNotes[id] || false;
            if (spell.notes !== hasNotes) {
              customSpellsUpdated = true;
              updatedCustomSpells[id] = {
                ...spell,
                notes: hasNotes
              };
            } else {
              updatedCustomSpells[id] = spell;
            }
          });
          
          // Only update states if there were actual changes
          if (spellsUpdated) {
            // Force a re-render by updating a different state
            setExpandedRows(prev => ({...prev}));
          }
          
          if (customSpellsUpdated) {
            setCustomSpells(updatedCustomSpells);
          }
        } catch (error) {
          console.error('Error fetching spell notes:', error);
        }
      }
    };
    
    fetchSpellNotes();
  }, [currentUser, spells]); // Remove customSpells from dependencies

  useEffect(() => {
    const allSpells = [
      ...spellsData,
      ...Object.values(customSpells)
    ];
    setSpells(allSpells);
  }, [customSpells]);

  useEffect(() => {
    localStorage.setItem('showShortDescriptions', JSON.stringify(showShortDescriptions));
    // Reset expanded rows when global checkbox changes
    setExpandedRows({});
  }, [showShortDescriptions]);

  useEffect(() => {
    const selectedCount = Object.values(localSelectedSpells).filter(Boolean).length;
    setIsAnySpellSelected(selectedCount > 0);
  }, [localSelectedSpells]);

  const handleAddToSpellbook = (spell) => {
    setSpellsToAdd([spell.name]);
    setIsModalOpen(true);
  };

  const handleAddSelectedToSpellbook = () => {
    const selectedSpellsList = Object.keys(localSelectedSpells).filter(spell => localSelectedSpells[spell]);
    setSpellsToAdd(selectedSpellsList);
    setIsModalOpen(true);
  };

  const handleModalSubmit = useCallback(async (spellbookName) => {
    try {
      // Pass a callback function to refresh the spellbooks data immediately after adding spells
      const result = await addToSpellbook(spellbookName, spellsToAdd, (updatedSpellbooks) => {
        console.log(`Spells added to ${spellbookName}. Spellbooks updated:`, updatedSpellbooks);
      });
      const { added: addedSpells, duplicates: duplicateSpells } = result;

      setLocalSelectedSpells({}); // Clear local selection
      
      // Set notification based on the number of spells added and already existing
      if (addedSpells.length === 1 && duplicateSpells.length === 0) {
        addNotification(`${addedSpells[0]} has been added to ${spellbookName}`, 'success');
      } else if (addedSpells.length === 0 && duplicateSpells.length === 1) {
        addNotification(`${duplicateSpells[0]} is already in ${spellbookName}`, 'warning');
      } else {
        const addedMessage = addedSpells.length > 0 ? `${addedSpells.length} spell${addedSpells.length !== 1 ? 's' : ''} added to ${spellbookName}` : '';
        const existingMessage = duplicateSpells.length > 0 ? `${duplicateSpells.length} spell${duplicateSpells.length !== 1 ? 's were' : ' was'} already in ${spellbookName}` : '';
        
        if (addedMessage && existingMessage) {
          addNotification(`${addedMessage}, ${existingMessage}`, 'info');
        } else {
          addNotification(addedMessage || existingMessage, 'info');
        }
      }
      
      // Close modal and clear spellsToAdd
      setIsModalOpen(false);
      setSpellsToAdd([]);
    } catch (error) {
      console.error('Error adding spells to spellbook:', error);
      // Only show error notification if we actually failed to add spells
      addNotification('Failed to add spells to spellbook', 'error');
      // Still close the modal and clear spellsToAdd to prevent UI from getting stuck
      setIsModalOpen(false);
      setSpellsToAdd([]);
    }
  }, [addNotification, addToSpellbook, spellsToAdd]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSpellsToAdd([]);
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowBackToTop(scrollTop > 300);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleSpellInfoClick = (spell) => {
    setSelectedSpell(spell);
  };

  const handleConvertToMetricChange = (event) => {
    setConvertToMetric(event.target.checked);
  };

  const updateVisibleColumns = useCallback(debounce(() => {
    const width = window.innerWidth;
    let count = 10;

    if (width <= 600) count = 3;
    else if (width <= 700) count = 4;
    else if (width <= 800) count = 5;
    else if (width <= 900) count = 6;
    else if (width <= 1000) count = 7;
    else if (width <= 1100) count = 8;
    else if (width <= 1200) count = 9;

    setVisibleColumns(count);
  }, 200), []);

  useEffect(() => {
    updateVisibleColumns();
    window.addEventListener('resize', updateVisibleColumns);
    return () => {
      window.removeEventListener('resize', updateVisibleColumns);
      updateVisibleColumns.cancel();
    };
  }, [updateVisibleColumns]);

  const renderIcons = (spell) => {
    return (
      <>
        <span 
          className="info-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleSpellInfoClick(spell);
          }}
          title="Spell Info"
        >
          <NewInfoIcon />
        </span>
        <span 
          className="add-to-spellbook-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleAddToSpellbook(spell);
          }}
          title="Add to Spellbook"
        >
          <AddToSpellbookIcon />
        </span>
      </>
    );
  };

  const handleCustomSpellAdded = (newSpell) => {
    setCustomSpells(prev => ({
      ...prev,
      [newSpell.id]: newSpell
    }));
    addNotification(`Custom spell "${newSpell.name}" created successfully`, 'success');
  };

  const getSelectedSpells = () => {
    const selectedSpellNames = Object.keys(localSelectedSpells).filter(name => localSelectedSpells[name]);
    return spells.filter(spell => selectedSpellNames.includes(spell.name));
  };

  const handleResetColumns = useCallback((resetFunc) => {
    setResetColumnsFunc(() => resetFunc);
  }, []);

  // When spellbooks changes, log the new structure
  useEffect(() => {
    console.log("SpellList received spellbooks:", spellbooks);
  }, [spellbooks]);

  // Get filtered and sorted spells
  const filteredAndSortedSpells = useMemo(() => {
    if (!spells || spells.length === 0) return [];
    
    console.log("SpellList filtering spells with spellbooks:", spellbooks);
    
    // Apply filters first
    const filtered = filterSpells(spells, filters, spellbooks || {});
    
    // Then apply search
    const searched = searchSpells(filtered, searchTerm);
    
    // Finally sort
    return sortSpells(searched, sortConfig);
  }, [spells, searchTerm, filters, sortConfig, spellbooks]);

  // Get filter options
  const filterOptions = useMemo(() => {
    return prepareFilterOptions(spells, spellbooks || {});
  }, [spells, spellbooks]);

  return (
    <div className="spell-list">
      <HowToUseBox pageType="spellList" />
      {showSpellSlotsEverywhere && (
        <>
          <SpellResourceManager 
            spellSlots={spellSlotsByBook[currentSpellbook] || Array(9).fill().map(() => ({ max: 0, used: 0 }))}
            setSpellSlots={(newSlots) => updateSpellSlots(currentSpellbook, newSlots)}
            spellPoints={spellPointsByBook[currentSpellbook] || { total: 0, used: 0 }}
            setSpellPoints={(newPoints) => updateSpellPoints(currentSpellbook, newPoints)}
            spellbookName={currentSpellbook}
            showSpellSlotsEverywhere={showSpellSlotsEverywhere}
            setShowSpellSlotsEverywhere={setShowSpellSlotsEverywhere}
            characterLevel={characterLevel}
            setCharacterLevel={setCharacterLevel}
            highLevelSpellUses={highLevelSpellUses}
            setHighLevelSpellUses={setHighLevelSpellUses}
            allowMultipleHighLevelCasts={allowMultipleHighLevelCasts}
            setAllowMultipleHighLevelCasts={setAllowMultipleHighLevelCasts}
            useSpellPoints={useSpellPoints}
            setUseSpellPoints={setUseSpellPoints}
          />
          <ActiveSpellbookHeader
            currentSpellbook={currentSpellbook}
            setCurrentSpellbook={setCurrentSpellbook}
            spellbooks={spellbooks}
            useSpellPoints={useSpellPoints}
            setUseSpellPoints={setUseSpellPoints}
          />
        </>
      )}
      
      <SpellFilters
        filters={filters}
        setFilters={setFilters}
        filterOptions={{
          classes: uniqueClasses,
          levels: uniqueLevels,
          schools: uniqueSchools,
          components: componentOptions,
          sources: uniqueSources
        }}
      />

      <div className="checkbox-container">
        <div className="checkbox-group">
        </div>
      </div>

      {showCustomSpellForm && (
        <CustomSpellForm
          onSpellAdded={handleCustomSpellAdded}
          onClose={() => setShowCustomSpellForm(false)}
        />
      )}

      <AdvancedFilterTags 
        filters={filters}
        setFilters={setFilters}
      />

      <SpellTableControls
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        totalSpellCount={spells.length}
        currentSpellCount={filteredAndSortedSpells.length}
        onShowCustomSpellForm={() => setShowCustomSpellForm(true)}
        convertToMetric={convertToMetric}
        onToggleMetric={() => setConvertToMetric(!convertToMetric)}
        showShortDescriptions={showShortDescriptions}
        onToggleShortDescriptions={() => setShowShortDescriptions(!showShortDescriptions)}
        showSchoolTags={showSchoolTags}
        onToggleSchoolTags={() => setShowSchoolTags(!showSchoolTags)}
        filters={filters}
        setFilters={setFilters}
        spells={spells}
        spellbooks={spellbooks}
      />

      <SpellTable
        spells={filteredAndSortedSpells}
        selectedSpells={localSelectedSpells}
        toggleItem={toggleItem}
        selectAll={selectAll}
        areAllSelected={areAllSelected}
        showShortDescriptions={showShortDescriptions}
        convertToMetric={convertToMetric}
        showSchoolTags={showSchoolTags}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        visibleColumns={visibleColumns}
        renderIcons={renderIcons}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        pagination={pagination}
        setPagination={setPagination}
        tabName="spellList"
        totalSpellCount={spells.length}
        onResetColumns={handleResetColumns}
      />

      {isAnySpellSelected && !isModalOpen && (
        <div className="floating-buttons">
          <button 
            className="floating-close-button" 
            onClick={() => {
              clearSelection();
              setLocalSelectedSpells({});
            }}
            title="Clear Selection"
          >
            ×
          </button>
          <button 
            onClick={handleAddSelectedToSpellbook} 
            className="floating-add-button"
          >
            Add Selected to Spellbook
          </button>
          <button 
            onClick={() => setShowCardGenerator(true)} 
            className="floating-button generate-cards-button"
          >
            Generate Spell Cards
          </button>
        </div>
      )}

      <AddToSpellbookModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        spellbooks={spellbooks || {}} // Provide a default empty object if spellbooks is undefined
        selectedSpells={spellsToAdd}
      />

      {notification && (
        <NotificationPopup
          message={notification}
          onClose={() => setNotification(null)}
        />
      )}

      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <BackToTopIcon />
        </button>
      )}

      {selectedSpell && (
        <>
          <div className="spellinfo-overlay"></div>
          <SpellInfoBox
            spell={selectedSpell}
            onClose={() => setSelectedSpell(null)}
            convertToMetric={convertToMetric}
            onToggleMetric={(e) => handleConvertToMetricChange({ target: { checked: !convertToMetric } })}
          />
        </>
      )}

      {showCardGenerator && (
        <>
          <div className="spellcard-overlay"></div>
          <SpellCardGenerator
            spells={getSelectedSpells()}
            onClose={() => setShowCardGenerator(false)}
          />
        </>
      )}
    </div>
  );
});

export default SpellList;
