import React, { useRef, useEffect, useState, useMemo } from 'react';
import '../styles/components/AdvancedFilteringModal.css';
import { CloseIcon } from '../data/Icons';
import spellsData from '../data/spells.json';
import { prepareFilterOptions } from '../utils/spellFilters';

function AdvancedFilteringModal({ isOpen, onClose, filters, setFilters, spells, spellbooks = [] }) {
  const modalRef = useRef();
  const [durationOptions, setDurationOptions] = useState([]);
  const [rangeOptions, setRangeOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [selectedRanges, setSelectedRanges] = useState([]);

  // Extract all filter options from spells
  useEffect(() => {
    const spellsToUse = (spells && spells.length > 0) ? spells : spellsData;
    
    // Get unique durations
    const uniqueDurations = [...new Set(
      spellsToUse
        .map(spell => spell.duration)
        .filter(Boolean)
        .map(duration => {
          let normalized = duration.toLowerCase();
          if (normalized.includes('concentration')) {
            return 'Concentration, ' + normalized.replace('concentration, ', '');
          }
          return duration;
        })
    )].sort();
    
    const uniqueRanges = [...new Set(
      spellsToUse
        .map(spell => spell.range)
        .filter(Boolean)
    )].sort();
    
    setDurationOptions(uniqueDurations);
    setRangeOptions(uniqueRanges);
    
    // Get all other filter options
    const options = prepareFilterOptions(spellsToUse, spellbooks);
    setFilterOptions(options);
  }, [spells, spellbooks]);

  // Initialize selected values from filters
  useEffect(() => {
    if (filters) {
      setSelectedDurations(filters.duration || []);
      setSelectedRanges(filters.range || []);
    }
  }, [filters]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    function handleEscKey(event) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  const toggleFilter = (filterName, value) => {
    setFilters(prevFilters => {
      const currentValues = prevFilters[filterName] || [];
      const currentExcludeValues = prevFilters[`exclude_${filterName}`] || [];
      
      // Check if value is in the include list
      if (currentValues.includes(value)) {
        // If it's in the include list, remove it and add to exclude list
        return {
          ...prevFilters,
          [filterName]: currentValues.filter(v => v !== value),
          [`exclude_${filterName}`]: [...currentExcludeValues, value]
        };
      } 
      // Check if value is in the exclude list
      else if (currentExcludeValues.includes(value)) {
        // If it's in the exclude list, remove it from there too (turn off completely)
        return {
          ...prevFilters,
          [filterName]: currentValues,
          [`exclude_${filterName}`]: currentExcludeValues.filter(v => v !== value)
        };
      } 
      // If it's in neither list, add to include list
      else {
        return {
          ...prevFilters,
          [filterName]: [...currentValues, value],
          [`exclude_${filterName}`]: currentExcludeValues
        };
      }
    });
  };

  const toggleDuration = (duration) => {
    const currentDurations = filters.duration || [];
    const currentExcludeDurations = filters.exclude_duration || [];
    
    // Check if duration is in the include list
    if (currentDurations.includes(duration)) {
      // If it's in the include list, remove it and add to exclude list
      const newDurations = currentDurations.filter(d => d !== duration);
      const newExcludeDurations = [...currentExcludeDurations, duration];
      
      setSelectedDurations(newDurations);
      setFilters(prevFilters => ({
        ...prevFilters,
        duration: newDurations,
        exclude_duration: newExcludeDurations
      }));
    } 
    // Check if duration is in the exclude list
    else if (currentExcludeDurations.includes(duration)) {
      // If it's in the exclude list, remove it from there too (turn off completely)
      const newExcludeDurations = currentExcludeDurations.filter(d => d !== duration);
      
      setFilters(prevFilters => ({
        ...prevFilters,
        duration: currentDurations,
        exclude_duration: newExcludeDurations
      }));
    } 
    // If it's in neither list, add to include list
    else {
      const newDurations = [...currentDurations, duration];
      
      setSelectedDurations(newDurations);
      setFilters(prevFilters => ({
        ...prevFilters,
        duration: newDurations,
        exclude_duration: currentExcludeDurations
      }));
    }
  };

  const toggleRange = (range) => {
    const currentRanges = filters.range || [];
    const currentExcludeRanges = filters.exclude_range || [];
    
    // Check if range is in the include list
    if (currentRanges.includes(range)) {
      // If it's in the include list, remove it and add to exclude list
      const newRanges = currentRanges.filter(r => r !== range);
      
      if (setFilters) {
        setFilters(prevFilters => ({
          ...prevFilters,
          range: newRanges,
          exclude_range: [...currentExcludeRanges, range]
        }));
      }
    } 
    // Check if range is in the exclude list
    else if (currentExcludeRanges.includes(range)) {
      // If it's in the exclude list, remove it from there too (turn off completely)
      const newExcludeRanges = currentExcludeRanges.filter(r => r !== range);
      
      if (setFilters) {
        setFilters(prevFilters => ({
          ...prevFilters,
          range: currentRanges,
          exclude_range: newExcludeRanges
        }));
      }
    } 
    // If it's in neither list, add to include list
    else {
      if (setFilters) {
        setFilters(prevFilters => ({
          ...prevFilters,
          range: [...currentRanges, range],
          exclude_range: currentExcludeRanges
        }));
      }
    }
  };

  // Notes filter is now handled by toggleNotesFilter

  const toggleNotesFilter = () => {
    setFilters(prevFilters => {
      const hasIncludeNotes = prevFilters.notes?.includes('Yes') || false;
      const hasExcludeNotes = prevFilters.exclude_notes?.includes('Yes') || false;
      
      // Cycle through: not selected -> include -> exclude -> not selected
      if (hasIncludeNotes) {
        // If it's in the include list, remove it and add to exclude list
        return {
          ...prevFilters,
          notes: [],
          exclude_notes: ['Yes']
        };
      } else if (hasExcludeNotes) {
        // If it's in the exclude list, remove it from there too (turn off completely)
        return {
          ...prevFilters,
          notes: [],
          exclude_notes: []
        };
      } else {
        // If it's in neither list, add to include list
        return {
          ...prevFilters,
          notes: ['Yes'],
          exclude_notes: []
        };
      }
    });
  };

  const handleApplyFilters = () => {
    onClose();
  };

  const handleClearFilters = () => {
    setSelectedDurations([]);
    setSelectedRanges([]);
    
    if (setFilters) {
      setFilters(prevFilters => {
        // Create a new object with all filters cleared
        const newFilters = {};
        
        // Preserve any non-filter properties
        Object.keys(prevFilters).forEach(key => {
          if (!key.startsWith('exclude_') && 
              key !== 'class' && 
              key !== 'level' && 
              key !== 'school' && 
              key !== 'components' && 
              key !== 'concentration' && 
              key !== 'ritual' && 
              key !== 'source' && 
              key !== 'duration' && 
              key !== 'range' && 
              key !== 'notes' &&
              key !== 'spellbook') {
            newFilters[key] = prevFilters[key];
          }
        });
        
        return newFilters;
      });
    }
  };

  // Group durations by type for better organization
  const groupedDurations = useMemo(() => {
    const groups = {
      'Instantaneous': [],
      'Concentration': [],
      'Time-Based': [],
      'Special': []
    };

    durationOptions.forEach(duration => {
      const lowerDuration = duration.toLowerCase();
      if (lowerDuration === 'instantaneous') {
        groups['Instantaneous'].push(duration);
      } else if (lowerDuration.includes('concentration')) {
        groups['Concentration'].push(duration);
      } else if (lowerDuration.includes('minute') || 
                lowerDuration.includes('hour') || 
                lowerDuration.includes('day') ||
                lowerDuration.includes('round')) {
        groups['Time-Based'].push(duration);
      } else {
        groups['Special'].push(duration);
      }
    });

    return groups;
  }, [durationOptions]);

  // Group ranges by type for better organization
  const groupedRanges = useMemo(() => {
    const groups = {
      'Self': [],
      'Touch': [],
      'Short Range': [],
      'Medium Range': [],
      'Long Range': [],
      'Special': []
    };

    rangeOptions.forEach(range => {
      const lowerRange = range.toLowerCase();
      if (lowerRange === 'self') {
        groups['Self'].push(range);
      } else if (lowerRange === 'touch') {
        groups['Touch'].push(range);
      } else if (lowerRange.includes('feet') && parseInt(lowerRange) <= 30) {
        groups['Short Range'].push(range);
      } else if (lowerRange.includes('feet') && parseInt(lowerRange) <= 100) {
        groups['Medium Range'].push(range);
      } else if (lowerRange.includes('feet') || lowerRange.includes('mile')) {
        groups['Long Range'].push(range);
      } else {
        groups['Special'].push(range);
      }
    });

    return groups;
  }, [rangeOptions]);

  // Helper function to determine button class based on filter state
  const getButtonClass = (filterName, value) => {
    if (filters[filterName]?.includes(value)) {
      return 'active';
    } else if (filters[`exclude_${filterName}`]?.includes(value)) {
      return 'exclude';
    }
    return '';
  };

  // Add a helper function to determine the button class for notes filter
  const getNotesButtonClass = () => {
    if (filters.notes?.includes('Yes')) {
      return 'active';
    } else if (filters.exclude_notes?.includes('Yes')) {
      return 'exclude';
    }
    return '';
  };

  if (!isOpen) return null;

  return (
    <div className="advanced-filtering-overlay">
      <div className="advanced-filtering-content" ref={modalRef}>
        <div className="modal-header">
          <h2>Advanced Filtering</h2>
          <button 
            className="close-button"
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="modal-body">
          <div className="warning-banner">
            <span className="warning-icon">⚠️</span>
            <span className="warning-text">
              Advanced filtering is currently in development and may have bugs or lack options. In the future it might become a premium feature.
            </span>
          </div>
          <div className="filters-grid">
            {/* Class Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>Class</h3>
              <div className="filter-tags-container">
                <div className="filter-tags">
                  {filterOptions.class?.map(classOption => (
                    <button
                      key={classOption}
                      className={`filter-tag-button ${getButtonClass('class', classOption)}`}
                      onClick={() => toggleFilter('class', classOption)}
                    >
                      {classOption}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Level Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>Spell Level</h3>
              <div className="filter-tags-container">
                <div className="filter-tags">
                  {filterOptions.level?.map(levelOption => (
                    <button
                      key={levelOption}
                      className={`filter-tag-button ${getButtonClass('level', levelOption)}`}
                      onClick={() => toggleFilter('level', levelOption)}
                    >
                      {levelOption}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* School Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>School</h3>
              <div className="filter-tags-container">
                <div className="filter-tags">
                  {filterOptions.school?.map(schoolOption => (
                    <button
                      key={schoolOption}
                      className={`filter-tag-button ${getButtonClass('school', schoolOption)}`}
                      onClick={() => toggleFilter('school', schoolOption)}
                    >
                      {schoolOption}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Components Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>Components</h3>
              <div className="filter-tags-container">
                <div className="filter-tags">
                  {filterOptions.components?.map(componentOption => (
                    <button
                      key={componentOption}
                      className={`filter-tag-button ${getButtonClass('components', componentOption)}`}
                      onClick={() => toggleFilter('components', componentOption)}
                    >
                      {componentOption}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Yes/No Filters Group */}
            <div className="filter-section yes-no-group">
              {/* Concentration Filter */}
              <div className="yes-no-filter">
                <h3>Concentration</h3>
                <div className="filter-tags-container">
                  <div className="filter-tags">
                    {filterOptions.concentration?.map(concentrationOption => (
                      <button
                        key={concentrationOption}
                        className={`filter-tag-button ${getButtonClass('concentration', concentrationOption)}`}
                        onClick={() => toggleFilter('concentration', concentrationOption)}
                      >
                        {concentrationOption}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Ritual Filter */}
              <div className="yes-no-filter">
                <h3>Ritual</h3>
                <div className="filter-tags-container">
                  <div className="filter-tags">
                    {filterOptions.ritual?.map(ritualOption => (
                      <button
                        key={ritualOption}
                        className={`filter-tag-button ${getButtonClass('ritual', ritualOption)}`}
                        onClick={() => toggleFilter('ritual', ritualOption)}
                      >
                        {ritualOption}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Personal Notes Filter */}
              <div className="yes-no-filter">
                <h3>Personal Notes</h3>
                <div className="filter-tags-container">
                  <div className="filter-tags">
                    <button
                      className={`filter-tag-button ${getNotesButtonClass()}`}
                      onClick={toggleNotesFilter}
                    >
                      Contains Personal Notes
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Source Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>Source</h3>
              <div className="filter-tags-container">
                <div className="filter-tags">
                  {filterOptions.source?.map(sourceOption => (
                    <button
                      key={sourceOption}
                      className={`filter-tag-button ${getButtonClass('source', sourceOption)}`}
                      onClick={() => toggleFilter('source', sourceOption)}
                    >
                      {sourceOption}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Spellbook Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>In Spellbook</h3>
              <div className="filter-tags-container">
                <div className="filter-tags">
                  {filterOptions.spellbook?.map(spellbookOption => (
                    <button
                      key={spellbookOption}
                      className={`filter-tag-button ${getButtonClass('spellbook', spellbookOption)}`}
                      onClick={() => toggleFilter('spellbook', spellbookOption)}
                    >
                      {spellbookOption}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Duration Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>Duration</h3>
              <div className="filter-tags-container">
                {Object.entries(groupedDurations).map(([group, durations]) => (
                  durations.length > 0 && (
                    <div key={group} className="filter-group">
                      <div className="filter-group-label">{group}</div>
                      <div className="filter-tags">
                        {durations.map(duration => (
                          <button
                            key={duration}
                            className={`filter-tag-button ${getButtonClass('duration', duration)}`}
                            onClick={() => toggleDuration(duration)}
                          >
                            {duration}
                          </button>
                        ))}
                      </div>
                    </div>
                  )
                ))}
              </div>
            </div>

            {/* Range Filter - Full Width */}
            <div className="filter-section full-width">
              <h3>Range</h3>
              <div className="filter-tags-container">
                {Object.entries(groupedRanges).map(([group, ranges]) => (
                  ranges.length > 0 && (
                    <div key={group} className="filter-group">
                      <div className="filter-group-label">{group}</div>
                      <div className="filter-tags">
                        {ranges.map(range => (
                          <button
                            key={range}
                            className={`filter-tag-button ${getButtonClass('range', range)}`}
                            onClick={() => toggleRange(range)}
                          >
                            {range}
                          </button>
                        ))}
                      </div>
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="clear-button" onClick={handleClearFilters}>
            Clear All Filters
          </button>
          <button className="apply-button" onClick={handleApplyFilters}>
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdvancedFilteringModal; 