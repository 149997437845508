import React, { useState, useRef, useEffect } from 'react';
import { generateSpellbook, getSpellbookGeneratorOptions, WIZARD_SUBCLASSES } from '../utils/spellbookGenerator';
import { calculateWizardSpells, getMaxSpellLevel } from '../utils/wizardSpellCalculator';
import '../styles/components/SpellbookGenerator.css';
import { CloseIcon, NewInfoIcon } from '../data/Icons';

const getShortSchoolName = (school) => {
  return school.substring(0, 3).toUpperCase();
};

const getFullSchoolName = (school) => {
  return school.charAt(0).toUpperCase() + school.slice(1);
};

const SpellbookGenerator = ({ onGenerate, onClose, spellbooks }) => {
  const [name, setName] = useState('');
  const [wizardLevel, setWizardLevel] = useState(1);
  const [spellsPerLevel, setSpellsPerLevel] = useState(0);
  const [generatedSpells, setGeneratedSpells] = useState(null);
  const [spellDistribution, setSpellDistribution] = useState(null);
  const [isManualMode, setIsManualMode] = useState(false);
  const [manualDistribution, setManualDistribution] = useState({
    cantrips: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0
  });
  const [nameError, setNameError] = useState('');
  const [phb2024Only, setPhb2024Only] = useState(false);
  const [selectedSubclass, setSelectedSubclass] = useState('');
  const options = getSpellbookGeneratorOptions();

  const spellsPerLevelOptions = Array.from({ length: 11 }, (_, i) => i);

  const modalRef = useRef();
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);
  
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  useEffect(() => {
    if (isManualMode) {
      setSpellDistribution(manualDistribution);
    } else {
      const baseDistribution = calculateWizardSpells(wizardLevel);
      if (spellsPerLevel > 0) {
        const enhancedDistribution = {
          ...baseDistribution,
          ...Object.fromEntries(
            Object.entries(baseDistribution)
              .filter(([level]) => level !== 'cantrips')
              .map(([level, count]) => [level, count + spellsPerLevel])
          )
        };
        setSpellDistribution(enhancedDistribution);
      } else {
        setSpellDistribution(baseDistribution);
      }
    }
  }, [wizardLevel, spellsPerLevel, isManualMode, manualDistribution]);

  const handlePreview = (e) => {
    e.preventDefault();
    
    const spells = {};
    const maxLevel = isManualMode ? 9 : getMaxSpellLevel(wizardLevel);
    
    // Generate cantrips
    if (spellDistribution.cantrips > 0) {
      const cantrips = generateSpellbook({
        criteria: {
          classes: ['wizard'],
          levels: [0],
          phb2024Only: phb2024Only,
        },
        spellCount: spellDistribution.cantrips,
        allowDuplicates: false,
        subclass: selectedSubclass
      });
      spells.cantrips = cantrips;
    }

    // Generate spells for each level
    const maxLevelToCheck = isManualMode ? 9 : maxLevel;
    for (let level = 1; level <= maxLevelToCheck; level++) {
      if (spellDistribution[level] && spellDistribution[level] > 0) {
        const levelSpells = generateSpellbook({
          criteria: {
            classes: ['wizard'],
            levels: [level],
            phb2024Only: phb2024Only,
          },
          spellCount: spellDistribution[level],
          allowDuplicates: false,
          subclass: selectedSubclass
        });
        spells[level] = levelSpells;
      }
    }

    console.log('Generated spells:', spells);
    setGeneratedSpells(spells);
  };

  const handleGenerate = () => {
    if (!generatedSpells) return;
    
    if (!name.trim()) {
      setNameError('Please enter a name for your spellbook');
      return;
    }

    // Case-insensitive check for existing spellbook names
    const existingNames = Object.keys(spellbooks).map(name => name.toLowerCase());
    if (existingNames.includes(name.toLowerCase())) {
      setNameError('A spellbook with this name already exists');
      return;
    }

    setNameError('');

    // Ensure each spell has an ID and proper structure
    const allSpells = [
      ...(generatedSpells.cantrips || []),
      ...Object.entries(generatedSpells)
        .filter(([level]) => level !== 'cantrips' && Array.isArray(generatedSpells[level]))
        .flatMap(([_, spells]) => spells)
    ].map(spell => ({
      ...spell,
      id: spell.id || `spell-${spell.name.toLowerCase().replace(/\s+/g, '-')}`
    }));

    onGenerate(name, allSpells);
    onClose();
  };

  const handleManualInputChange = (level, value) => {
    const numValue = Math.max(0, parseInt(value) || 0);
    setManualDistribution(prev => ({
      ...prev,
      [level]: numValue
    }));
  };

  const calculateTotalSpells = (distribution) => {
    return Object.entries(distribution)
      .reduce((total, [level, count]) => total + count, 0);
  };

  return (
    <div className="spellbook-overlay">
      <div className="spellbook-generator" ref={modalRef}>
        <div className="generator-header">
          <h2>Generate Spellbook</h2>
          <button 
            type="button" 
            className="close-button"
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
        <form onSubmit={handlePreview}>
          <div className="form-header">
            <div className="form-header-top">
              <div className="form-group name-group">
                <label htmlFor="name">
                  Spellbook Name:
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value.slice(0, 25));
                    setNameError('');
                  }}
                  placeholder="Enter name"
                  maxLength={25}
                  className={`small-input ${nameError ? 'error' : ''}`}
                />
                {nameError && <div className="error-message">{nameError}</div>}
              </div>

              <div className="form-group level-group">
                <label htmlFor="wizardLevel">Level:</label>
                <select
                  id="wizardLevel"
                  value={wizardLevel}
                  onChange={(e) => setWizardLevel(parseInt(e.target.value))}
                  className="level-select"
                >
                  {Array.from({ length: 20 }, (_, i) => i + 1).map(level => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>

              {!isManualMode && (
                <div className="form-group spells-per-level-group">
                  <label htmlFor="spellsPerLevel">
                    Spells per Level
                    <span className="info-tooltip-icon" title="Additional spells added to each spell level beyond the minimum">
                      <NewInfoIcon />
                    </span>
                  </label>
                  <select
                    id="spellsPerLevel"
                    value={spellsPerLevel}
                    onChange={(e) => setSpellsPerLevel(parseInt(e.target.value))}
                    className="spells-per-level-select"
                  >
                    <option value={0}>Minimum</option>
                    {spellsPerLevelOptions.slice(1).map(num => (
                      <option key={num} value={num}>+{num}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <div className="form-group subclass-group">
              <label htmlFor="subclass">
                Subclass
                <span className="info-tooltip-icon" title="Selecting a subclass ensures some spells from that school are included">
                  <NewInfoIcon />
                </span>
              </label>
              <select
                id="subclass"
                value={selectedSubclass}
                onChange={(e) => setSelectedSubclass(e.target.value)}
                className="subclass-select"
              >
                <option value="">None</option>
                {Object.entries(WIZARD_SUBCLASSES).map(([name, value]) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="manual-mode-toggle">
            <label>
              <input
                type="checkbox"
                checked={isManualMode}
                onChange={(e) => setIsManualMode(e.target.checked)}
              />
              Manual Distribution
              <span className="info-tooltip-icon" title="Manually specify how many spells you want for each spell level">
                <NewInfoIcon />
              </span>
            </label>
            <label>
              <input
                type="checkbox"
                checked={phb2024Only}
                onChange={(e) => setPhb2024Only(e.target.checked)}
              />
              PHB 2024 Spells Only
            </label>
          </div>

          <div className="distribution-container">
            {isManualMode && (
              <div className="manual-distribution">
                <div className="manual-inputs">
                  <div className="manual-input-group">
                    <label htmlFor="cantrips">Cantrips:</label>
                    <input
                      type="number"
                      id="cantrips"
                      min="0"
                      value={manualDistribution.cantrips}
                      onChange={(e) => handleManualInputChange('cantrips', e.target.value)}
                    />
                  </div>
                  {Array.from({ length: 9 }, (_, i) => i + 1).map(level => (
                    <div key={level} className="manual-input-group">
                      <label htmlFor={`level${level}`}>Level {level}:</label>
                      <input
                        type="number"
                        id={`level${level}`}
                        min="0"
                        value={manualDistribution[level]}
                        onChange={(e) => handleManualInputChange(level, e.target.value)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {spellDistribution && (
              <div className="spell-distribution">
                <h3>Spell Distribution:</h3>
                <p>Cantrips: {spellDistribution.cantrips}</p>
                {Object.entries(spellDistribution)
                  .filter(([level]) => level !== 'cantrips')
                  .map(([level, count]) => (
                    <p key={level}>Level {level}: {count}</p>
                  ))}
                <div className="total-spells">
                  Total Spells: {calculateTotalSpells(spellDistribution)}
                </div>
              </div>
            )}
          </div>

          <div className="generator-buttons">
            <button type="submit" className="preview-button">Generate Spells</button>
            {generatedSpells && (
              <button type="button" className="generate-button" onClick={handleGenerate}>
                Create Spellbook
              </button>
            )}
            <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
          </div>
        </form>

        {generatedSpells && (
          <div className="generated-spells">
            <h3>Generated Spells:</h3>
            <div className="spell-list-preview">
              {generatedSpells.cantrips?.length > 0 && (
                <>
                  <h4>Cantrips:</h4>
                  <ul>
                    {generatedSpells.cantrips.map(spell => (
                      <li key={spell.name}>
                        <span>{spell.name}</span>
                        <span 
                          className={`school-badge school-${spell.school.toLowerCase()}`}
                          title={getFullSchoolName(spell.school)}
                        >
                          {getShortSchoolName(spell.school)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {Object.entries(generatedSpells)
                .filter(([level, spells]) => level !== 'cantrips' && spells.length > 0)
                .map(([level, spells]) => (
                  <div key={level}>
                    <h4>Level {level}:</h4>
                    <ul>
                      {spells.map(spell => (
                        <li key={spell.name}>
                          <span>{spell.name}</span>
                          <span 
                            className={`school-badge school-${spell.school.toLowerCase()}`}
                            title={getFullSchoolName(spell.school)}
                          >
                            {getShortSchoolName(spell.school)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpellbookGenerator; 