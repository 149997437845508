import React from 'react';
import '../styles/components/ConfirmationPopup.css';
import { CloseIcon } from '../data/Icons';

const ConfirmationPopup = ({ isOpen, message, onConfirm, onCancel, confirmButtonText = 'Delete' }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-popup-overlay">
      <div className="confirmation-popup">
        <div className="confirmation-header">
          <h2>Confirm Action</h2>
          <button 
            className="close-button"
            onClick={onCancel}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="confirmation-content">
          <p>{message}</p>
        </div>
        <div className="confirmation-actions">
          <button 
            className={`confirm-button ${confirmButtonText.toLowerCase() !== 'delete' ? 'confirm-button-blue' : ''}`}
            onClick={onConfirm}
          >
            {confirmButtonText}
          </button>
          <button 
            className="cancel-button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
