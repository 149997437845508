import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { logger } from './utils';

/**
 * Get user's spell slots from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing spell slots by spellbook
 */
export const getUserSpellSlots = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserSpellSlots');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      console.log('User document does not exist');
      return {};
    }
    
    const userData = userDoc.data();
    // CRITICAL FIX: Use spellSlotsByBook field instead of spellSlots
    // Check for both field names to handle both old and new data formats
    const spellSlots = userData.spellSlotsByBook || userData.spellSlots || {};
    
    // Log for debugging
    console.log('Retrieved raw spell slots data from database:', spellSlots);
    
    // Ensure each spellbook has properly structured spell slots
    // Some spellbooks might have Array spell slots instead of the expected structure
    const processedSpellSlots = {};
    
    // Process each spellbook's spell slots
    Object.keys(spellSlots).forEach(spellbookName => {
      const bookSlots = spellSlots[spellbookName];
      console.log(`Processing spell slots for spellbook "${spellbookName}":`, bookSlots);
      
      // Check if it's already an array of 9 slots (old format)
      if (Array.isArray(bookSlots) && bookSlots.length === 9) {
        // Convert to ensure each slot has the expected structure
        processedSpellSlots[spellbookName] = bookSlots.map(slot => ({
          max: Number(slot.max) || 0,
          used: Number(slot.used) || 0
        }));
      } else if (Array.isArray(bookSlots)) {
        // It's an array but not the expected length
        // Create a new array with 9 elements
        processedSpellSlots[spellbookName] = Array(9).fill().map((_, i) => {
          const existingSlot = i < bookSlots.length ? bookSlots[i] : null;
          return {
            max: existingSlot && Number(existingSlot.max) || 0,
            used: existingSlot && Number(existingSlot.used) || 0
          };
        });
      } else if (typeof bookSlots === 'object' && bookSlots !== null) {
        // Keep the structure as is since it's already an object
        processedSpellSlots[spellbookName] = bookSlots;
      } else {
        // Default fallback
        processedSpellSlots[spellbookName] = Array(9).fill().map(() => ({ max: 0, used: 0 }));
      }
    });
    
    console.log('Processed spell slots data:', processedSpellSlots);
    return processedSpellSlots;
  } catch (error) {
    console.error('Error getting spell slots:', error);
    return {};
  }
};

/**
 * Update user's spell slots in Firestore
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {Object} spellSlots - The spell slots to update
 * @returns {Promise<void>}
 */
export const updateUserSpellSlots = async (userId, spellbookName, spellSlots) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserSpellSlots');
      throw new Error('User ID is required');
    }

    if (!spellbookName) {
      console.warn('No spellbook name provided to updateUserSpellSlots');
      throw new Error('Spellbook name is required');
    }

    // Validate spellSlots is an array
    if (!Array.isArray(spellSlots)) {
      console.error('Invalid spell slots format:', spellSlots);
      throw new Error('Spell slots must be an array');
    }

    // Ensure the array has exactly 9 slots
    const validatedSlots = Array(9).fill().map((_, i) => {
      if (i < spellSlots.length && spellSlots[i]) {
        return {
          max: Number(spellSlots[i].max) || 0,
          used: Number(spellSlots[i].used) || 0
        };
      } else {
        return { max: 0, used: 0 };
      }
    });

    console.log(`Saving validated spell slots for ${spellbookName}:`, validatedSlots);

    // Get the current user document
    const userRef = doc(db, 'users', userId);
    
    // Try to get the document to see if it exists
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      // CRITICAL FIX: Use spellSlotsByBook field instead of spellSlots
      await updateDoc(userRef, {
        [`spellSlotsByBook.${spellbookName}`]: validatedSlots
      });
      console.log('Successfully updated spell slots in database');
    } else {
      // Document doesn't exist, create it
      // CRITICAL FIX: Use spellSlotsByBook field
      await setDoc(userRef, {
        spellSlotsByBook: {
          [spellbookName]: validatedSlots
        }
      }, { merge: true });
      console.log('Created new user document with spell slots');
    }
    
    // Return the validated slots for consistency
    return validatedSlots;
  } catch (error) {
    console.error('Error updating spell slots:', error);
    throw error;
  }
};

/**
 * Get user's spell points from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing spell points by spellbook
 */
export const getUserSpellPoints = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserSpellPoints');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      console.log('User document does not exist');
      return {};
    }
    
    const userData = userDoc.data();
    // Get the spellPoints data from the user document
    const spellPoints = userData.spellPoints || {};
    
    // Log for debugging
    console.log('Retrieved raw spell points data from database:', spellPoints);
    
    // Ensure each spellbook has properly structured spell points
    const processedSpellPoints = {};
    
    // Process each spellbook's spell points
    Object.keys(spellPoints).forEach(spellbookName => {
      const bookPoints = spellPoints[spellbookName];
      console.log(`Processing spell points for spellbook "${spellbookName}":`, bookPoints);
      
      if (bookPoints && typeof bookPoints === 'object') {
        // Make sure it has the expected structure with points, used, and highLevelSpellUses
        processedSpellPoints[spellbookName] = {
          points: Number(bookPoints.points) || 0,
          used: Number(bookPoints.used) || 0,
          highLevelSpellUses: bookPoints.highLevelSpellUses || {}
        };
      } else {
        // Default fallback
        processedSpellPoints[spellbookName] = {
          points: 0,
          used: 0,
          highLevelSpellUses: {}
        };
      }
    });
    
    console.log('Processed spell points data:', processedSpellPoints);
    return processedSpellPoints;
  } catch (error) {
    console.error('Error getting spell points:', error);
    return {};
  }
};

/**
 * Update user's spell points in Firestore
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {Object} spellPoints - The spell points to update
 * @returns {Promise<void>}
 */
export const updateUserSpellPoints = async (userId, spellbookName, spellPoints) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserSpellPoints');
      return;
    }

    await updateDoc(doc(db, 'users', userId), {
      [`spellPoints.${spellbookName}`]: spellPoints
    });
  } catch (error) {
    console.error('Error updating spell points:', error);
    throw error;
  }
};

/**
 * Get user's character levels from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing character levels by spellbook
 */
export const getUserCharacterLevels = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserCharacterLevels');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() ? (userDoc.data().characterLevelsByBook || {}) : {};
  } catch (error) {
    console.error('Error getting character levels:', error);
    return {};
  }
};

/**
 * Update user's character level in Firestore
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {number} level - The character level
 * @returns {Promise<void>}
 */
export const updateUserCharacterLevel = async (userId, spellbookName, level) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserCharacterLevel');
      return;
    }

    await updateDoc(doc(db, 'users', userId), {
      [`characterLevelsByBook.${spellbookName}`]: level
    });
  } catch (error) {
    console.error('Error updating character level:', error);
    throw error;
  }
};

/**
 * Get user's prepared spells from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing prepared spells by spellbook
 */
export const getUserPreparedSpells = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserPreparedSpells');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    
    if (!userDoc.exists()) {
      return {};
    }

    const userData = userDoc.data();
    const preparedSpells = userData.preparedSpells || {};
    
    // CRITICAL FIX: Don't return preparedSpells directly - some might be IDs and need conversion
    // We handle this in the component instead

    return preparedSpells;
  } catch (error) {
    console.error('Error getting prepared spells:', error);
    return {};
  }
};

/**
 * Update user's prepared spells in Firestore
 * @param {string} userId - The user's ID
 * @param {string} spellbookName - The name of the spellbook
 * @param {Array} preparedSpells - Array of prepared spell IDs
 * @returns {Promise<void>}
 */
export const updateUserPreparedSpells = async (userId, spellbookName, preparedSpells) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserPreparedSpells');
      return;
    }

    // Ensure preparedSpells is an array
    if (!Array.isArray(preparedSpells)) {
      console.warn('preparedSpells is not an array');
      preparedSpells = [];
    }

    // For each spellbook, prepared spells should be an array of spell IDs
    await updateDoc(doc(db, 'users', userId), {
      [`preparedSpells.${spellbookName}`]: preparedSpells
    });
  } catch (error) {
    console.error('Error updating prepared spells:', error);
    throw error;
  }
}; 