import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFeatureFlags } from './firebase/firestoreOperations';

const FeatureFlagsContext = createContext(null);

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }
  return context;
};

export const FeatureFlagsProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState({
    enableRatings: false,
    enableComments: false,
    showOnlySRDDescriptions: false,
    loading: true
  });

  useEffect(() => {
    const loadFeatureFlags = async () => {
      try {
        const flags = await getFeatureFlags();
        setFeatureFlags({
          ...flags,
          loading: false
        });
      } catch (error) {
        console.error('Error loading feature flags:', error);
        setFeatureFlags(prev => ({
          ...prev,
          loading: false
        }));
      }
    };

    loadFeatureFlags();
  }, []);

  return (
    <FeatureFlagsContext.Provider value={{ ...featureFlags, setFeatureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContext; 