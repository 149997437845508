import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, deleteField, onSnapshot } from 'firebase/firestore';
import { logger, idToSpell } from './utils';
import { isUserAdmin } from '../../utils/setupAdmin';
import { getUserLimits } from './admin';
import { debounce } from '../../utils/debounce';
import spellsData from '../../data/spells.json';

/**
 * Get user's custom spells from Firestore
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - Object containing custom spells
 */
export const getUserCustomSpells = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserCustomSpells');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() ? (userDoc.data().customSpells || {}) : {};
  } catch (error) {
    console.error('Error getting custom spells:', error);
    return {};
  }
};

/**
 * Check if a spell name is already taken
 * @param {string} userId - The user's ID
 * @param {string} spellName - The name to check
 * @returns {Promise<boolean>} - True if the name is taken
 */
const isSpellNameTaken = async (userId, spellName) => {
  try {
    // Check if the name exists in the built-in spells
    const builtInSpellExists = spellsData.some(spell => 
      spell.name.toLowerCase() === spellName.toLowerCase()
    );
    
    if (builtInSpellExists) {
      return true;
    }
    
    // Check if the name exists in the user's custom spells
    const userCustomSpells = await getUserCustomSpells(userId);
    
    const customSpellExists = Object.values(userCustomSpells).some(spell => 
      spell.name.toLowerCase() === spellName.toLowerCase()
    );
    
    return customSpellExists;
  } catch (error) {
    console.error('Error checking if spell name is taken:', error);
    return false;
  }
};

/**
 * Add a custom spell
 * @param {string} userId - The user's ID
 * @param {Object} spellData - The spell data
 * @returns {Promise<Object>} - The created spell
 */
export const addCustomSpell = async (userId, spellData) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to addCustomSpell');
      throw new Error('No user ID provided');
    }
    
    // Check if user is an admin - admins bypass limits
    const isAdmin = await isUserAdmin(userId);
    
    // Get user limits
    const userLimits = await getUserLimits();
    
    // Get current custom spells
    const userCustomSpells = await getUserCustomSpells(userId);
    
    // Check if user has reached the custom spell limit
    if (!isAdmin && Object.keys(userCustomSpells).length >= userLimits.maxCustomSpells) {
      throw new Error(`You have reached the maximum limit of ${userLimits.maxCustomSpells} custom spells. Please delete an existing custom spell to continue.`);
    }
    
    // Check if the spell name is already taken
    if (await isSpellNameTaken(userId, spellData.name)) {
      throw new Error(`A spell with the name "${spellData.name}" already exists. Please choose a different name.`);
    }

    // Generate a unique ID for the custom spell
    const customSpellId = `custom-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    
    // Add isCustom flag
    const customSpell = {
      ...spellData,
      id: customSpellId,
      isCustom: true,
      createdAt: new Date().toISOString(),
      createdBy: userId
    };
    
    // Add the custom spell to the user's customSpells
    await setDoc(doc(db, 'users', userId), {
      customSpells: {
        [customSpellId]: customSpell
      }
    }, { merge: true });
    
    return customSpell;
  } catch (error) {
    console.error('Error adding custom spell:', error);
    throw error;
  }
};

/**
 * Update a custom spell
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @param {Object} spellData - The updated spell data
 * @returns {Promise<Object>} - The updated spell
 */
export const updateCustomSpell = async (userId, spellId, spellData) => {
  try {
    if (!userId || !spellId) {
      console.warn('Missing required parameters in updateCustomSpell');
      throw new Error('Missing required parameters');
    }
    
    // Get the user's custom spells
    const userCustomSpells = await getUserCustomSpells(userId);
    
    // Check if the spell exists
    if (!userCustomSpells[spellId]) {
      throw new Error(`Custom spell with ID ${spellId} does not exist`);
    }
    
    // Check if the user is trying to change the name and if the new name is already taken
    if (spellData.name !== userCustomSpells[spellId].name && 
        await isSpellNameTaken(userId, spellData.name)) {
      throw new Error(`A spell with the name "${spellData.name}" already exists. Please choose a different name.`);
    }
    
    // Update the spell
    const updatedSpell = {
      ...userCustomSpells[spellId],
      ...spellData,
      id: spellId, // Ensure ID doesn't change
      isCustom: true, // Ensure isCustom flag is preserved
      updatedAt: new Date().toISOString()
    };
    
    // Update the custom spell in Firestore
    await setDoc(doc(db, 'users', userId), {
      customSpells: {
        [spellId]: updatedSpell
      }
    }, { merge: true });
    
    return updatedSpell;
  } catch (error) {
    console.error('Error updating custom spell:', error);
    throw error;
  }
};

/**
 * Delete a custom spell
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID to delete
 * @returns {Promise<void>}
 */
export const deleteCustomSpell = async (userId, spellId) => {
  try {
    if (!userId || !spellId) {
      console.warn('Missing required parameters in deleteCustomSpell');
      throw new Error('Missing required parameters');
    }
    
    // Get the user doc ref
    const userRef = doc(db, 'users', userId);
    
    // Get the current user data
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      console.warn('User document does not exist');
      return;
    }
    
    const userData = userDoc.data();
    
    // Check if the spell exists in the user's custom spells
    if (!userData.customSpells || !userData.customSpells[spellId]) {
      console.warn(`Custom spell with ID ${spellId} does not exist`);
      return;
    }
    
    // Remove the spell from all spellbooks
    const updatedSpellbooks = { ...userData.spellbooks };
    
    // For each spellbook, remove this spell ID if it exists
    Object.keys(updatedSpellbooks).forEach(spellbookName => {
      const spellbookSpells = updatedSpellbooks[spellbookName];
      const spellIndex = spellbookSpells.indexOf(spellId);
      
      if (spellIndex !== -1) {
        // Create a new array without this spell
        updatedSpellbooks[spellbookName] = [
          ...spellbookSpells.slice(0, spellIndex),
          ...spellbookSpells.slice(spellIndex + 1)
        ];
      }
    });
    
    // Remove the spell from prepared spells for each spellbook
    const updatedPreparedSpells = { ...userData.preparedSpells };
    
    Object.keys(updatedPreparedSpells).forEach(spellbookName => {
      const preparedList = updatedPreparedSpells[spellbookName];
      const spellIndex = preparedList.indexOf(spellId);
      
      if (spellIndex !== -1) {
        // Create a new array without this spell
        updatedPreparedSpells[spellbookName] = [
          ...preparedList.slice(0, spellIndex),
          ...preparedList.slice(spellIndex + 1)
        ];
      }
    });
    
    // Update the user doc with the modified spellbooks and prepared spells,
    // and remove the custom spell
    await updateDoc(userRef, {
      [`customSpells.${spellId}`]: deleteField(),
      spellbooks: updatedSpellbooks,
      preparedSpells: updatedPreparedSpells
    });
  } catch (error) {
    console.error('Error deleting custom spell:', error);
    throw error;
  }
};

/**
 * Get spells by their IDs
 * @param {string} userId - The user's ID
 * @param {Array} spellIds - Array of spell IDs
 * @returns {Promise<Array>} - Array of spell objects
 */
export const getSpellsByIds = async (userId, spellIds = []) => {
  try {
    if (!Array.isArray(spellIds)) {
      console.warn('spellIds is not an array in getSpellsByIds');
      return [];
    }
    
    // Create a set of IDs for faster lookup
    const idsSet = new Set(spellIds);
    
    // Get built-in spells
    const builtInSpells = spellsData.filter(spell => idsSet.has(spell.id));
    
    // Get custom spells
    let customSpells = [];
    if (userId) {
      const userCustomSpells = await getUserCustomSpells(userId);
      
      customSpells = Object.values(userCustomSpells)
        .filter(spell => idsSet.has(spell.id));
    }
    
    // Combine both types of spells and sort by the original order
    const allFoundSpells = [...builtInSpells, ...customSpells];
    
    // Create a map for quick lookups
    const spellsMap = new Map();
    allFoundSpells.forEach(spell => spellsMap.set(spell.id, spell));
    
    // Return spells in the same order as the input spellIds
    return spellIds.map(id => spellsMap.get(id)).filter(spell => spell !== undefined);
  } catch (error) {
    console.error('Error getting spells by IDs:', error);
    return [];
  }
};

/**
 * Get all spells (both built-in and custom)
 * @param {string} userId - The user's ID
 * @returns {Promise<Array>} - Array of all spells
 */
export const getAllSpells = async (userId) => {
  try {
    // Get built-in spells
    const builtInSpells = [...spellsData];
    
    // Get custom spells if a userId is provided
    let customSpells = [];
    if (userId) {
      const userCustomSpells = await getUserCustomSpells(userId);
      customSpells = Object.values(userCustomSpells);
    }
    
    // Combine both types of spells
    return [...builtInSpells, ...customSpells];
  } catch (error) {
    console.error('Error getting all spells:', error);
    return [...spellsData];
  }
};

/**
 * Subscribe to changes in the user's custom spells
 * @param {string} userId - The user's ID
 * @param {Function} callback - Function to call when spells change
 * @returns {Function} - Unsubscribe function
 */
export const subscribeToCustomSpells = (userId, callback) => {
  if (!userId) {
    console.warn('No user ID provided to subscribeToCustomSpells');
    return () => {};
  }
  
  const userRef = doc(db, 'users', userId);
  
  return onSnapshot(userRef, (doc) => {
    if (doc.exists()) {
      const customSpells = doc.data().customSpells || {};
      callback(customSpells);
    } else {
      callback({});
    }
  }, (error) => {
    console.error('Error subscribing to custom spells:', error);
    callback({});
  });
};

/**
 * Refresh the user's custom spells
 * @param {string} userId - The user's ID
 * @returns {Promise<Object>} - The updated custom spells
 */
export const refreshCustomSpells = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to refreshCustomSpells');
      return {};
    }
    
    return await getUserCustomSpells(userId);
  } catch (error) {
    console.error('Error refreshing custom spells:', error);
    return {};
  }
};

/**
 * Get the user's spell notes
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @returns {Promise<string>} - The spell note
 */
export const getUserSpellNotes = async (userId, spellId) => {
  try {
    if (!userId || !spellId) {
      console.warn('Missing required parameters in getUserSpellNotes');
      return '';
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return '';

    const userData = userDoc.data();
    return userData.spellNotes && userData.spellNotes[spellId] ? userData.spellNotes[spellId] : '';
  } catch (error) {
    console.error('Error getting spell note:', error);
    return '';
  }
};

/**
 * Update a spell note for a user
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @param {string} note - The note text
 * @returns {Promise<void>}
 */
export const updateSpellNote = async (userId, spellId, note) => {
  try {
    if (!userId || !spellId) {
      console.warn('Missing required parameters in updateSpellNote');
      return;
    }
    
    const userRef = doc(db, 'users', userId);

    // If note is empty, remove the field completely
    if (!note.trim()) {
      await updateDoc(userRef, {
        [`spellNotes.${spellId}`]: deleteField()
      });
    } else {
      // Otherwise, update with the new note
      await setDoc(userRef, {
        spellNotes: {
          [spellId]: note
        }
      }, { merge: true });
    }
  } catch (error) {
    console.error('Error updating spell note:', error);
    throw error;
  }
};

/**
 * Debounced version of updateSpellNote that waits 1000ms before saving
 * @param {string} userId - The user's ID
 * @param {string} spellId - The spell ID
 * @param {string} note - The note text
 * @returns {Promise<void>}
 */
export const debouncedUpdateSpellNote = debounce(async (userId, spellId, note) => {
  try {
    logger('Saving spell note after debounce delay');
    const userRef = doc(db, 'users', userId);

    // If note is empty, remove the field completely
    if (!note.trim()) {
      await updateDoc(userRef, {
        [`spellNotes.${spellId}`]: deleteField()
      });
    } else {
      // Otherwise, update with the new note
      await setDoc(userRef, {
        spellNotes: {
          [spellId]: note
        }
      }, { merge: true });
    }
  } catch (error) {
    console.error('Error updating spell note:', error);
    throw error;
  }
}, 1000); 