import { jsPDF } from 'jspdf';
import { generateSmallCardsPDF } from './SmallCardsPDFGenerator';
import { generateMediumCardsPDF } from './MediumCardsPDFGenerator';

// Card dimensions for large size (4 cards per page in a 2x2 grid)
const CARD_DIMENSIONS = {
  width: 95, // mm (roughly 3.7 inches)
  height: 132, // mm (roughly 5.2 inches)
  margin: 5
};

// Create a global word index tracker to maintain state between function calls
let spellNextWordIndex = {};

const capitalizeWords = (str) => {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase());
};

// Helper function to render a property with wrapping for long values
const renderPropertyWithWrapping = (doc, label, value, contentX, currentY, labelWidth, maxWidth) => {
  // Write the label in bold
  doc.setFont("helvetica", "bold");
  doc.text(label, contentX, currentY);
  
  // Write the value in normal font
  doc.setFont("helvetica", "normal");
  
  if (value) {
    // Calculate remaining width for value
    const remainingWidth = maxWidth - labelWidth;
    
    // Split value if it's too long for remaining space
    const valueLines = doc.splitTextToSize(value, remainingWidth);
    
    // Write first line after label
    doc.text(valueLines[0], contentX + labelWidth, currentY);
    
    // Write any additional lines with full width (from left margin)
    // This allows multiline values to use the full width of the card
    if (valueLines.length > 1) {
      // For continuation lines, use the full available width for better space usage
      const fullWidthLines = doc.splitTextToSize(
        valueLines.slice(1).join(' '), // Join remaining text
        maxWidth // Use full width for continuation lines
      );
      
      for (let i = 0; i < fullWidthLines.length; i++) {
        currentY += doc.getFontSize() * 0.35;
        doc.text(fullWidthLines[i], contentX, currentY);
      }
    }
  }
  
  return currentY + doc.getFontSize() * 0.35;
};

// Create and pre-render the card set
const createSpellCards = (doc, spell, cardWidth, cardHeight, headerHeight, cardMargin, fontSize, fontScale = 1.0) => {
  // Get description text
  const description = spell.description || '';
  
  // Set up margins and dimensions - ensure text fills to right edge
  const leftMargin = cardMargin * 0.7;  // Reduced left margin (was 0.9)
  const rightMargin = cardMargin * 0.3;  // Increased right margin (was 0.1)
  const availableWidth = cardWidth - leftMargin - rightMargin;
  
  // Default line height
  const lineHeight = fontSize * 0.35;
  
  // Calculate available heights with EXACT measurements
  const availableHeightFirstCard = cardHeight - headerHeight - cardMargin;
  const linesPerFirstCard = Math.floor(availableHeightFirstCard / lineHeight);
  
  // Calculate space on continuation cards
  const contHeaderHeight = fontSize + 4; // Fixed header height
  const availableHeightCont = cardHeight - contHeaderHeight - cardMargin;
  const linesPerContCard = Math.floor(availableHeightCont / lineHeight);
  
  // Split text into words to count them
  const words = description.split(' ');
  
  // Find how many cards we need - start with just first card
  let totalCards = 1;
  
  // Mock document for testing text fitting
  const mockDoc = {
    getStringUnitWidth: (str) => doc.getStringUnitWidth(str),
    getFontSize: () => fontSize * fontScale,
    getFontStyle: () => 'normal', // Fixed value
    setFont: () => {}, // Empty setFont function
    internal: { scaleFactor: doc.internal.scaleFactor },
    text: () => {} // Dummy function
  };
  
  // Do a test wrap to see how many words fit on first card
  const firstCardTest = wrapAndRenderText(
    mockDoc,
    description,
    0, 0, // Dummy positions
    availableWidth,
    lineHeight,
    linesPerFirstCard,
    0,
    true  // isDryRun = true - just test without rendering
  );
  
  // If we have more words, calculate continuation cards needed
  if (firstCardTest.hasMore) {
    // Calculate how many words fit on a continuation card
    const contCardTest = wrapAndRenderText(
      mockDoc,
      description,
      0, 0, // Dummy positions
      availableWidth,
      lineHeight,
      linesPerContCard,
      firstCardTest.wordsRendered,
      true  // isDryRun = true
    );
    
    const wordsPerContCard = contCardTest.wordsRendered - firstCardTest.wordsRendered;
    const wordsRemaining = words.length - firstCardTest.wordsRendered;
    
    // Calculate total cards needed based on actual words per card
    if (wordsRemaining > 0 && wordsPerContCard > 0) {
      totalCards = 1 + Math.ceil(wordsRemaining / wordsPerContCard);
    }
  }
  
  // Create the cards array
      const cards = [];
      
  // First card always gets the title and properties
      cards.push({
        title: spell.name,
    isFirstCard: true,
    spell: spell,
    description: description,
    pageInfo: totalCards > 1 ? `1/${totalCards}` : null,
    leftMargin: leftMargin,
    rightMargin: rightMargin,
    availableWidth: availableWidth,
    lineHeight: lineHeight,
    maxLines: linesPerFirstCard,
    wordIndex: 0, // Start from the beginning
    cardHeight: cardHeight,
    bottomMargin: cardMargin
  });
  
  // Create continuation cards if needed
  if (totalCards > 1) {
    // Start with the exact word count from the test run
    let wordOffset = firstCardTest.wordsRendered;
    
    for (let i = 2; i <= totalCards; i++) {
      // Only add a card if we have more words to render
      if (wordOffset < words.length) {
        cards.push({
          title: spell.name,
          isContinuation: true,
          description: description,
          pageInfo: `${i}/${totalCards}`,
          leftMargin: leftMargin,
          rightMargin: rightMargin,
          availableWidth: availableWidth,
          lineHeight: lineHeight,
          maxLines: linesPerContCard,
          wordIndex: wordOffset, // Words to skip
          cardHeight: cardHeight,
          headerHeight: contHeaderHeight,
          bottomMargin: cardMargin
        });
        
        // Test exactly how many words fit on this cont. card
        if (i < totalCards) {
          const contCardTest = wrapAndRenderText(
            mockDoc,
            description,
            0, 0, // Dummy positions
            availableWidth,
            lineHeight,
            linesPerContCard,
            wordOffset,
            true  // isDryRun = true
          );
          
          // Update offset for next card based on exact test
          wordOffset = contCardTest.wordsRendered;
        }
      }
    }
  }
  
  return cards;
};

// Improved direct text wrapping function with boundary checking and support for bold text
const wrapAndRenderText = (doc, text, x, y, maxWidth, lineHeight, maxLines = Infinity, wordsToSkip = 0, isDryRun = false) => {
  // Function to process a text segment (may contain bold markers)
  const processTextSegment = (segment, xPos, yPos, isDryRun) => {
    // Check for bold text markers
    if (segment.includes('**')) {
      const parts = segment.split('**');
      let currentX = xPos;
      
      parts.forEach((part, index) => {
        if (part.length === 0) return;
        
        // Toggle bold style for odd-indexed parts (inside ** **)
        const isBold = index % 2 === 1;
        doc.setFont("helvetica", isBold ? "bold" : "normal");
        
        if (!isDryRun) {
          doc.text(part, currentX, yPos);
        }
        
        // Move x position for next part
        currentX += doc.getStringUnitWidth(part) * doc.getFontSize() / doc.internal.scaleFactor;
      });
      
      // Reset to normal font after processing
      doc.setFont("helvetica", "normal");
      return true;
    }
    return false;
  };
  
  // Split the text into words
  const words = text.split(' ');
  let currentLine = '';
  let currentWidth = 0;
  let currentY = y;
  let linesRendered = 0;
  let wordsRendered = wordsToSkip;
  const fontSize = doc.getFontSize();
  
  // Check if we're starting in the middle of a bold section (for continuation cards)
  let isBoldOpen = false;
  
  // Count the number of '**' before our starting word
  if (wordsToSkip > 0) {
    const textBeforeSkip = words.slice(0, wordsToSkip).join(' ');
    const boldMarkerCount = (textBeforeSkip.match(/\*\*/g) || []).length;
    // If there's an odd number of bold markers, we're starting inside a bold section
    isBoldOpen = boldMarkerCount % 2 === 1;
    
    // If we're starting in a bold section, we need to add the opening marker
    if (isBoldOpen && wordsToSkip < words.length) {
      // Check if we're starting with "At Higher Levels:" or any text that should be bold
      const nextWordIndex = Math.min(wordsToSkip, words.length - 1);
      
      // Look ahead to see if the continuation has a common phrase that should be bold
      // This handles cases like "At Higher Levels:" being split across cards
      const nextWords = words.slice(nextWordIndex, nextWordIndex + 5).join(' ');
      if (nextWords.includes("Higher Levels:") || nextWords.includes("At Higher Levels:")) {
        // Add opening bold marker if we detect a header phrase at the start of continuation
        currentLine = '**';
      } else if (isBoldOpen) {
        // Otherwise, just respect the ongoing bold formatting
        currentLine = '**';
      }
    }
  }
  
  // Skip words if requested (for continuation cards)
  let i = wordsToSkip > 0 ? Math.min(wordsToSkip, words.length - 1) : 0;
  
  // Store initial font style - always use "normal" as default
  const originalFontStyle = "normal";
  
  // Now actually render starting from our skip point
  for (; i < words.length && linesRendered < maxLines; i++) {
    const word = words[i];
    if (!word) continue; // Skip empty words
    
    // Calculate the width of this word plus a space
    const wordWidth = doc.getStringUnitWidth(word + ' ') * fontSize / doc.internal.scaleFactor;
    
    // Check if adding this word would exceed the available width
    if (currentWidth + wordWidth > maxWidth) {
      // Render the current line if not empty
      if (currentLine) {
        if (!isDryRun) {
          const containsBold = processTextSegment(currentLine, x, currentY, isDryRun);
          if (!containsBold) {
            doc.text(currentLine, x, currentY);
          }
        }
        
        currentY += lineHeight;
        linesRendered++;
        
        if (linesRendered >= maxLines) {
          // We hit the line limit - don't count this word as rendered
          break;
        }
        
        currentLine = word + ' ';
        currentWidth = wordWidth;
      } else {
        // This word alone is too long, force break it
        if (!isDryRun) {
          const containsBold = processTextSegment(word, x, currentY, isDryRun);
          if (!containsBold) {
            doc.text(word, x, currentY);
          }
        }
        currentY += lineHeight;
        linesRendered++;
        
        if (linesRendered >= maxLines) {
          // We hit the line limit - count this word as rendered
          wordsRendered = i + 1;
          break;
        }
        
        currentLine = '';
        currentWidth = 0;
      }
    } else {
      // Add the word to the current line
      currentLine += word + ' ';
      currentWidth += wordWidth;
    }
    
    // Handle line breaks in the original text
    if (word.includes('\n')) {
      // Render the current line up to this point
      if (currentLine) {
        if (!isDryRun) {
          const containsBold = processTextSegment(currentLine.replace(word + ' ', word.split('\n')[0]), x, currentY, isDryRun);
          if (!containsBold) {
            doc.text(currentLine.replace(word + ' ', word.split('\n')[0]), x, currentY);
          }
        }
        
        currentY += lineHeight;
        linesRendered++;
        
        if (linesRendered >= maxLines) {
          wordsRendered = i + 1;
          break;
        }
      }
      
      // Start a new line with anything after the last newline
      const parts = word.split('\n');
      if (parts.length > 1) {
        currentLine = parts[parts.length - 1] + ' ';
        currentWidth = doc.getStringUnitWidth(currentLine) * fontSize / doc.internal.scaleFactor;
        
        // If there are middle parts, add them as separate lines
        for (let j = 1; j < parts.length - 1 && linesRendered < maxLines; j++) {
          if (!isDryRun) {
            const containsBold = processTextSegment(parts[j], x, currentY, isDryRun);
            if (!containsBold) {
              doc.text(parts[j], x, currentY);
            }
          }
          
          currentY += lineHeight;
          linesRendered++;
          
          if (linesRendered >= maxLines) {
            wordsRendered = i + 1;
            break;
          }
        }
      } else {
        currentLine = '';
        currentWidth = 0;
      }
    }
    
    // Update the count of words successfully rendered
    wordsRendered = i + 1;
  }
  
  // Render the final line if there's anything left
  if (currentLine.trim() && linesRendered < maxLines && !isDryRun) {
    const containsBold = processTextSegment(currentLine.trim(), x, currentY, isDryRun);
    if (!containsBold) {
      doc.text(currentLine.trim(), x, currentY);
    }
    
    currentY += lineHeight;
    linesRendered++;
  }
  
  // Restore original font settings
  doc.setFont("helvetica", originalFontStyle);
  
  // Return information about what was rendered
  return { 
    endY: currentY, 
    linesRendered, 
    hasMore: wordsRendered < words.length,
    wordsRendered: wordsRendered, // Return the exact position we ended at
    totalWords: words.length
  };
};

// Main PDF generation function for large cards (2x2 grid)
export const generateLargeCardsPDF = (doc, {
  spells,
  layout,
  convertIfNeeded,
  showOnlySRDDescriptions
}) => {
  // Start with a fresh PDF
  if (!doc) {
    doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm'
    });
  }
  
  // Set up canvas
  doc.setFillColor(255, 255, 255);
  doc.setDrawColor(0);
  doc.setTextColor(0);
  doc.setLineWidth(0.3); // Set consistent line width for all borders
  
  // Define school colors - more vibrant and modern
  const schoolColors = {
    'abjuration': [111, 188, 255],   // Brighter blue
    'necromancy': [88, 219, 88],     // Vibrant green
    'evocation': [255, 70, 70],      // More intense red (decreased green/blue components)
    'divination': [180, 180, 210],   // Soft lavender gray
    'conjuration': [255, 255, 120],  // Bright yellow
    'enchantment': [255, 120, 220],  // Vibrant pink
    'transmutation': [255, 140, 0],  // Pure vivid orange
    // Default for any missing school
    'default': [240, 240, 240] // light gray
  };
  
  // Helper function to get school color
  const getSchoolColor = (school) => {
    // If coloredHeaders is false, return default gray color
    if (layout.coloredHeaders === false) {
      return [240, 240, 240]; // light gray for all schools
    }
    
    const normalizedSchool = school ? school.toLowerCase() : 'default';
    return schoolColors[normalizedSchool] || schoolColors.default;
  };
  
  // Get page dimensions
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  
  // Card layout settings
  const cardsPerRow = 2;
  const cardsPerCol = 2;
  const cardsPerPage = cardsPerRow * cardsPerCol;
  
  // Calculate fixed card dimensions for large cards (4 per page - 2x2 grid)
  const cardWidth = (pageWidth / 2) - 10;
  const cardHeight = (pageHeight / 2) - 10;
  const cardMargin = 5;
  const headerHeight = 15; // Keep header height at 15mm
  const fontSize = layout.fontSize;
  const fontScale = 1.2;
  const smallerFontScale = 1.0;
  
  // Card positions and tracking
  let currentPage = 0;
  let cardPosition = 0;
  
  // Function to add a new page if needed
  const addPageIfNeeded = () => {
    if (currentPage === 0 || cardPosition >= cardsPerPage) {
      if (currentPage > 0) {
        doc.addPage();
      }
      currentPage++;
      cardPosition = 0;
    }
  };
  
  // Function to calculate card position
  const getCardPosition = () => {
    const col = cardPosition % cardsPerRow;
    const row = Math.floor(cardPosition / cardsPerRow);
    
    const x = col * (cardWidth + 2 * cardMargin) + cardMargin;
    const y = row * (cardHeight + 2 * cardMargin) + cardMargin;
    
    return { x, y };
  };
  
  // First, pre-calculate the exact number of cards needed for each spell
  // This ensures we have accurate pagination from the start
  const calculateTotalCardsPerSpell = () => {
    const results = {};
    
    spells.forEach(spell => {
      // Use card_friendly_description if available, otherwise use regular description
      let description = spell.card_friendly_description || spell.description || '';
      
      // Combine main description with upgrade text if available
      if (spell.level === 0 && spell.cantrip_upgrade) {
        description += '\n\n**At Higher Levels:** ' + spell.cantrip_upgrade;
      }
      else if (spell.level > 0 && spell.higher_levels) {
        description += '\n\n**At Higher Levels:** ' + spell.higher_levels;
      }
      
      // Skip empty descriptions
      if (!description || description.trim() === '') {
        results[spell.name] = { totalCards: 1 };
        return;
      }
      
      // Setup dimensions for calculation
      const leftMargin = cardMargin * 0.7;
      const rightMargin = cardMargin * 0.3;
      const availableWidth = cardWidth - leftMargin - rightMargin;
      const lineHeight = (fontSize - 2) * smallerFontScale * 0.35;
      
      // For first card - calculate space after properties
      const firstCardHeaderAndPropsHeight = headerHeight + 28; // Header + properties + divider 
      const firstCardAvailableHeight = cardHeight - firstCardHeaderAndPropsHeight - cardMargin;
      const firstCardMaxLines = Math.floor(firstCardAvailableHeight / lineHeight);
      
      // For continuation cards
      const contHeaderHeight = fontSize + 6 + ((fontSize - 2) * smallerFontScale * 0.6); // Header + "Continued" text
      const contAvailableHeight = cardHeight - contHeaderHeight - cardMargin;
      const contMaxLines = Math.floor(contAvailableHeight / lineHeight);
      
      // Split text into words and create a complete copy for safety
      const completeText = description;
      const allWords = completeText.split(' ');
      let processedText = '';
      let cardCount = 1;
      
      // Actually render first card (more accurate than just counting words)
      const mockDoc = {
        getStringUnitWidth: (str) => doc.getStringUnitWidth(str),
        getFontSize: () => fontSize * smallerFontScale,
        getFontStyle: () => 'normal',
        setFont: () => {},
        internal: { scaleFactor: doc.internal.scaleFactor },
        text: () => {} // Dummy function for dry run
      };
      
      // First card test with maximum precision
      let firstCardResult = wrapAndRenderText(
        mockDoc,
        completeText,
        0, 0, // Dummy position
        availableWidth,
        lineHeight,
        firstCardMaxLines,
        0, // Start from beginning
        true // Dry run
      );
      
      if (!firstCardResult.hasMore) {
        // All text fits on one card
        results[spell.name] = { 
          totalCards: 1,
          wordsOnFirstCard: allWords.length
        };
        return;
      }
      
      // Get exactly what text was rendered on the first card
      const firstCardWords = allWords.slice(0, firstCardResult.wordsRendered);
      const firstCardText = firstCardWords.join(' ');
      
      // Get remaining text after first card
      let remainingText = completeText.substring(firstCardText.length).trim();
      
      // Already need at least two cards
      cardCount = 1;
      
      // Process continuation cards until all text is covered
      while (remainingText.length > 0) {
        cardCount++;
        
        // Test this continuation card
        const contCardResult = wrapAndRenderText(
          mockDoc,
          remainingText,
          0, 0, // Dummy position
          availableWidth,
          lineHeight,
          contMaxLines,
          0, // Start from beginning of remaining text
          true // Dry run
        );
        
        if (!contCardResult.hasMore) {
          // All remaining text fits on this card
          break;
        }
        
        // Get the words that fit on this card
        const contWords = remainingText.split(' ').slice(0, contCardResult.wordsRendered);
        const contText = contWords.join(' ');
        
        // Update remaining text
        remainingText = remainingText.substring(contText.length).trim();
      }
      
      results[spell.name] = { 
        totalCards: cardCount,
        firstCardText: firstCardText,
        completeText: completeText
      };
    });
    
    return results;
  };
  
  // Calculate exactly how many cards each spell needs
  const cardsPerSpell = calculateTotalCardsPerSpell();
  
  // Process each spell completely before moving to the next
  spells.forEach(spell => {
    // Check if description should be shown based on SRD status
    const shouldShowDescription = !showOnlySRDDescriptions || spell.srd || spell.isCustom;
    
    // Use card_friendly_description if available, otherwise use regular description
    let description = spell.card_friendly_description || spell.description || '';
    
    // Override description if restricted by SRD settings
    if (!shouldShowDescription) {
      description = "Spell description limited by content license\n\nThis spell's details are not part of the System Reference Document (SRD) and can't be displayed. Only the basic spell information is shown.";
    } else {
      // Combine main description with upgrade text if available (only if showing full description)
      if (spell.level === 0 && spell.cantrip_upgrade) {
        description += '\n\n**At Higher Levels:** ' + spell.cantrip_upgrade;
      }
      else if (spell.level > 0 && spell.higher_levels) {
        description += '\n\n**At Higher Levels:** ' + spell.higher_levels;
      }
    }
    
    // Skip empty descriptions
    if (!description || description.trim() === '') {
      return;
    }
    
    // Get the pre-calculated card count
    const spellInfo = cardsPerSpell[spell.name] || { totalCards: 1 };
    const totalCards = spellInfo.totalCards;
    
    // Split description into words for tracking progress
    const words = description.split(' ');
    let currentWordIndex = 0;
    
    // Process all cards for this spell
    for (let cardNumber = 1; cardNumber <= totalCards; cardNumber++) {
      // Skip if we've rendered all words
      if (currentWordIndex >= words.length) break;
      
      // Ensure we have space for this card
      addPageIfNeeded();
      
      // Get card position
      const { x, y } = getCardPosition();
      
      // Draw card background (white)
      doc.setFillColor(255, 255, 255);
      doc.rect(x, y, cardWidth, cardHeight, 'F');
      
      // Setup content margins
      const leftMargin = cardMargin * 0.7;
      const rightMargin = cardMargin * 0.3;
      const contentX = x + leftMargin;
      
      // Draw card title area with school-colored background
      const schoolColor = getSchoolColor(spell.school);
      doc.setFillColor(schoolColor[0], schoolColor[1], schoolColor[2]);
      
      // Different header heights for first vs continuation cards
      const isFirstCard = cardNumber === 1;
      const cardHeaderHeight = isFirstCard ? headerHeight : (fontSize + 4);
      doc.rect(x, y, cardWidth, cardHeaderHeight, 'F');
      
      // Draw the header content
      const contentY = y + 3;
      
      // Draw title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(fontSize * fontScale * 1.1);
      const titleY = contentY + 5;
      doc.text(spell.name, contentX, titleY);
      
      // Show pagination if multiple cards - move to top right corner
      if (totalCards > 1) {
        const pageInfo = `${cardNumber}/${totalCards}`;
        const pageInfoWidth = doc.getStringUnitWidth(pageInfo) * (fontSize * fontScale * 0.8) / doc.internal.scaleFactor;
        // Position pagination at the top right of the card header with larger font and moved up
        doc.setFontSize(fontSize * fontScale * 0.8);
        doc.text(pageInfo, x + cardWidth - pageInfoWidth - cardMargin, y + 7);
        // Reset font size for title
        doc.setFontSize(fontSize * fontScale * 1.1);
      }

      // Add source right beneath pagination (only on first card)
      if (isFirstCard && spell.sources) {
        // Format sources to include page numbers: "PHB24:p39, XGE:p42"
        const sourceStr = Array.isArray(spell.sources) ? 
          spell.sources.map(src => {
            if (typeof src === 'string') return src;
            // Replace PHB2024 with PHB24 and include page number
            const book = src.book === 'PHB2024' ? 'PHB24' : src.book;
            return src.page ? `${book}:p${src.page}` : book;
          }).join(', ') : 
          (typeof spell.sources === 'string' ? spell.sources : '');
        
        if (sourceStr) {
          doc.setFont("helvetica", "italic");
          doc.setFontSize((fontSize - 4) * fontScale);
          const sourceWidth = doc.getStringUnitWidth(sourceStr) * ((fontSize - 4) * fontScale) / doc.internal.scaleFactor;
          // Position source right below pagination in top right with smaller font
          doc.text(sourceStr, x + cardWidth - sourceWidth - cardMargin, y + 10 + (fontSize * 0.35));
        }
      }

      let currentY = y;
      
      // First card gets level, school, and properties
      if (isFirstCard) {
        // Calculate the height of title
        const nameWidth = cardWidth - (2 * cardMargin);
        const titleLines = doc.splitTextToSize(spell.name, nameWidth);
        const nameHeight = titleLines.length * (fontSize * fontScale * 1.1 * 0.4);
        
        // Draw level and school text
      doc.setFont("helvetica", "italic");
        doc.setFontSize((fontSize - 2) * fontScale);
      const levelText = spell.level === 0 ? 
        `${capitalizeWords(spell.school)} Cantrip` : 
        `Level ${spell.level} ${capitalizeWords(spell.school)}`;
        const levelY = titleY + nameHeight * 0.7;
        doc.text(levelText, contentX, levelY);
      
        // Start content after the header
        currentY = y + headerHeight + 4;
        
        // Draw properties
      doc.setFont("helvetica", "normal");
        doc.setFontSize((fontSize - 2) * smallerFontScale);
        
        // Get components string and material component details
      const componentsStr = Object.entries(spell.components || {})
        .filter(([key, value]) => value && ['verbal', 'somatic', 'material'].includes(key))
        .map(([key]) => key[0].toUpperCase())
        .join(', ');
      
      const materialDetails = spell.components && spell.components.materials_needed && 
        spell.components.materials_needed.length > 0 ? 
        `(${Array.isArray(spell.components.materials_needed) ? 
          spell.components.materials_needed[0] : 
          spell.components.materials_needed})` : '';
      
        // Property rendering
        const lineHeight = (fontSize - 2) * smallerFontScale * 0.4;
        const availablePropertiesWidth = cardWidth - leftMargin - rightMargin;
        const labelSpacing = 2;
        
        // Casting Time
        currentY = renderPropertyWithWrapping(
          doc,
          "Casting Time:",
          spell.casting_time,
          contentX,
          currentY,
          doc.getStringUnitWidth("Casting Time:") * (fontSize - 2) * smallerFontScale / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );
        
        // Range
        currentY = renderPropertyWithWrapping(
          doc,
          "Range:",
          convertIfNeeded(spell.range),
          contentX,
          currentY,
          doc.getStringUnitWidth("Range:") * (fontSize - 2) * smallerFontScale / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );
        
        // Components
        currentY = renderPropertyWithWrapping(
          doc,
          "Components:",
          `${componentsStr} ${materialDetails}`,
          contentX,
          currentY,
          doc.getStringUnitWidth("Components:") * (fontSize - 2) * smallerFontScale / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );
        
        // Duration
        currentY = renderPropertyWithWrapping(
          doc,
          "Duration:",
          spell.duration,
          contentX,
          currentY,
          doc.getStringUnitWidth("Duration:") * (fontSize - 2) * smallerFontScale / doc.internal.scaleFactor + labelSpacing,
          availablePropertiesWidth
        );
        
        // Ritual (if applicable)
        if (spell.ritual) {
      doc.setFont("helvetica", "bold");
          doc.text("Ritual", contentX, currentY);
          currentY += lineHeight;
        }
        
        // Add divider line
        doc.setDrawColor(180, 180, 180);
        doc.setLineWidth(0.1);
        doc.line(contentX, currentY, x + cardWidth - cardMargin, currentY);
        currentY += 3; // Space after divider
          } else {
        // For continuation cards, just a small header
        // Start content after header
        currentY = y + cardHeaderHeight + 3;
        
        // Add "Continued" indicator at the top of continuation cards
        doc.setFont("helvetica", "italic");
        doc.setFontSize((fontSize - 2) * smallerFontScale);
        doc.text("(Continued)", contentX, currentY);
        currentY += (fontSize - 2) * smallerFontScale * 0.6;
      }
      
      // Draw description text
        doc.setFont("helvetica", "normal");
      doc.setFontSize((fontSize - 2) * smallerFontScale);
      
      // Calculate available space for text
      const maxY = y + cardHeight - cardMargin;
      const availableHeight = maxY - currentY;
      const descriptionLineHeight = (fontSize - 2) * smallerFontScale * 0.35;
      const maxLines = Math.floor(availableHeight / descriptionLineHeight);
      const availableWidth = cardWidth - leftMargin - rightMargin;
      
      // Render text and get updated word index
      const renderResult = wrapAndRenderText(
        doc,
        description,
        contentX,
        currentY,
        availableWidth,
        descriptionLineHeight,
        maxLines,
        currentWordIndex
      );
      
      // Update word index for next card
      currentWordIndex = renderResult.wordsRendered;
      
      // Draw card border
      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.rect(x, y, cardWidth, cardHeight, 'S');
      
      // Add tiny footer with website
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6); // Very small font
      doc.setTextColor(150, 150, 150); // Light gray text
      const footerText = "made with www.tablemancer.com";
      const footerWidth = doc.getStringUnitWidth(footerText) * 6 / doc.internal.scaleFactor;
      const footerX = x + (cardWidth - footerWidth) / 2; // Center the text
      const footerY = y + cardHeight - 2; // 2mm from bottom
      doc.text(footerText, footerX, footerY);
      doc.setTextColor(0, 0, 0); // Reset text color to black
      
      // Move to next card position
      cardPosition++;
    }
  });
  
  return doc;
};

// Main PDF generation function that routes to the appropriate size-specific generator
export const generateCardsPDF = (doc, options) => {
  // Based on the card size, delegate to the correct generator
  if (options.layout.cardSize === 'small') {
    return generateSmallCardsPDF(doc, options);
  } else if (options.layout.cardSize === 'medium') {
    return generateMediumCardsPDF(doc, options);
  } else {
    // For large cards, use the function in this file
    return generateLargeCardsPDF(doc, options);
  }
}; 