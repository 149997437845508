const conversionRates = {
  ft: 0.3, // 1 ft = 30 cm = 0.3 m
  mile: 1.5, // 1 mile = 1.5 km
  yard: 1, // 1 yard = 1 m
  inch: 2.5, // 1 inch = 2.5 cm
  pound: 0.5, // 1 pound = 0.5 kg
};

const unitPatterns = {
  ft: /(\d+(?:\.\d+)?)\s*-?\s*(?:ft|foot|feet)(?!\w)/gi,
  mile: /(\d+(?:\.\d+)?)\s*-?\s*(?:mile|miles)(?!\w)/gi,
  yard: /(\d+(?:\.\d+)?)\s*-?\s*(?:yard|yards|yd)(?!\w)/gi,
  inch: /(\d+(?:\.\d+)?)\s*-?\s*(?:inch|inches|in)(?!\w)/gi,
  pound: /(\d+(?:\.\d+)?)\s*-?\s*(?:pound|pounds|lb|lbs)(?!\w)/gi,
  selfMeasurement: /Self\s*\((\d+)\s*-?\s*foot (radius|cube|cone|line|sphere)\)/gi,
};

const roundToNearestHalf = (value) => {
  return Math.round(value * 2) / 2; // Round to the nearest half
};

const convertValue = (value, unit) => {
  const numericValue = parseFloat(value);
  
  switch (unit) {
    case 'ft':
      if ([50, 500, 5000].includes(numericValue)) {
        // Special case for 50, 500, 5000 feet
        return (numericValue / 5 * 1.5) + 'm';
      } else if (numericValue === 5) {
        // Special case for 5 feet
        return '1,5 m';
      } else if (numericValue >= 5) {
        // For 5 feet and above, use 3ft = 1m conversion
        return Math.round(numericValue / 3) + 'm';
      } else if (numericValue % 3 === 0) {
        // For multiples of 3 feet below 5 feet
        return (numericValue / 3) + 'm';
      } else {
        // For other values below 5 feet
        return Math.round(numericValue * 30) + 'cm';
      }
    case 'mile':
      // Convert miles to kilometers
      return roundToNearestHalf(numericValue * conversionRates[unit]) + 'km';
    case 'yard':
      // Convert yards to meters
      return Math.round(numericValue * conversionRates[unit]) + 'm';
    case 'inch':
      // Convert inches to centimeters
      return (numericValue * conversionRates[unit]) + 'cm';
    case 'pound':
      // Convert pounds to kilograms
      return Math.round(numericValue * conversionRates[unit] * 10) / 10 + 'kg';
    default:
      // Return the original value if the unit is not recognized
      return value + ' ' + unit;
  }
};

// Add a list of common phrases where numbers should not be converted
const excludedPhrases = [
  /DC \d+/i,
  /level \d+/i,
  /\d+ times/i,
  /\d+ creatures?/i,
  /\d+ targets?/i,
  /\d+ hours?/i,
  /\d+ minutes?/i,
  /\d+ rounds?/i,
  /\d+ actions?/i,
  /\d+d\d+/i,  // dice notation
  /\d+\s*\+\s*\d+/i,  // additions
  /\d+th/i,  // ordinal numbers
];

const convertUnits = (text) => {
  if (!text) return text;
  
  // First, protect excluded phrases by temporarily replacing them
  let protectedText = text;
  const protectedPhrases = [];
  
  excludedPhrases.forEach((pattern) => {
    protectedText = protectedText.replace(pattern, (match) => {
      protectedPhrases.push(match);
      return `@@${protectedPhrases.length - 1}@@`;
    });
  });
  
  // Perform the regular unit conversions
  let convertedText = protectedText;
  
  Object.entries(unitPatterns).forEach(([unit, pattern]) => {
    if (unit === 'selfMeasurement') {
      convertedText = convertedText.replace(pattern, (match, value, shape) => {
        const convertedValue = convertValue(value, 'ft');
        return `Self (${convertedValue} ${shape})`;
      });
    } else {
      convertedText = convertedText.replace(pattern, (match, value) => {
        return convertValue(value, unit);
      });
    }
  });
  
  // Restore protected phrases
  protectedPhrases.forEach((phrase, index) => {
    convertedText = convertedText.replace(`@@${index}@@`, phrase);
  });
  
  return convertedText;
};

export { convertUnits }; // Export the convertUnits function for use in other modules