/**
 * Creates a debounced function that delays invoking the provided function
 * until after the specified wait time has elapsed since the last time it was invoked.
 * 
 * @param {Function} func - The function to debounce
 * @param {number} wait - The number of milliseconds to delay
 * @param {boolean} immediate - Whether to invoke the function immediately on the leading edge
 * @returns {Function} The debounced function
 */
export const debounce = (func, wait = 500, immediate = false) => {
  let timeout;
  
  return function executedFunction(...args) {
    const context = this;
    
    // Store the current state for immediate calls
    const callNow = immediate && !timeout;
    
    // Clear the previous timeout to restart the timer
    if (timeout) {
      clearTimeout(timeout);
    }
    
    // Set a new timeout
    timeout = setTimeout(() => {
      timeout = null;
      
      // Only execute if we're not doing immediate calls
      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);
    
    // If we want to call immediately (on the leading edge)
    if (callNow) {
      func.apply(context, args);
    }
  };
};

/**
 * Creates a throttled function that only invokes the provided function
 * at most once per every specified wait period.
 * 
 * @param {Function} func - The function to throttle
 * @param {number} wait - The number of milliseconds to throttle invocations to
 * @returns {Function} The throttled function
 */
export const throttle = (func, wait = 300) => {
  let lastCall = 0;
  
  return function executedFunction(...args) {
    const now = Date.now();
    
    if (now - lastCall >= wait) {
      func.apply(this, args);
      lastCall = now;
    }
  };
}; 