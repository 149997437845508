import React, { createContext, useState, useEffect, useContext } from 'react';
import { 
  enable as enableDarkMode, 
  disable as disableDarkMode
} from 'darkreader';

// Create the theme context
const ThemeContext = createContext();

// Dark Reader configuration
const darkReaderConfig = {
  brightness: 100,
  contrast: 100,
  sepia: 0,
};

export const ThemeProvider = ({ children }) => {
  // Initialize theme state from localStorage or default to 'light'
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  // Apply the theme effect when theme changes
  useEffect(() => {
    // Save theme preference to localStorage
    localStorage.setItem('theme', theme);
    
    // Apply Dark Reader based on theme
    if (theme === 'dark') {
      enableDarkMode(darkReaderConfig);
    } else {
      disableDarkMode();
    }
  }, [theme]);

  // Toggle between light and dark themes
  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  // Set a specific theme
  const setSpecificTheme = (newTheme) => {
    if (['light', 'dark'].includes(newTheme)) {
      setTheme(newTheme);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, setTheme: setSpecificTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use the theme context
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default ThemeContext; 