import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { signup, login, isUserVerified, resendVerificationEmail } from '../firebase/authFunctions';
import { useAuth } from '../AuthContext';
import { ClosedEyeIcon, OpenEyeIcon } from '../data/Icons';
import '../styles/components/Login.css';

// Import dice SVGs
import d4Svg from '../assets/dice/d4.svg';
import d6Svg from '../assets/dice/d6.svg';
import d8Svg from '../assets/dice/d8.svg';
import d10Svg from '../assets/dice/d10.svg';
import d12Svg from '../assets/dice/d12.svg';
import d20Svg from '../assets/dice/d20.svg';

function Login({ initialMode = "login" }) {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLogin, setIsLogin] = useState(
    location.state?.initialMode === "signup" ? false : initialMode !== "signup"
  );
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!isLogin) {
      checkPasswordStrength(password);
    }
  }, [password, isLogin]);

  const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length >= minLength && hasNumber && hasSpecialChar) {
      setPasswordStrength('strong');
    } else if (password.length >= minLength && (hasNumber || hasSpecialChar)) {
      setPasswordStrength('medium');
    } else if (password.length > 0) {
      setPasswordStrength('weak');
    } else {
      setPasswordStrength('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isLoading) return;
    
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (!isLogin && passwordStrength !== 'strong') {
      setError('Password does not meet the required criteria');
      setIsLoading(false);
      return;
    }

    try {
      if (isLogin) {
        await login(email, password);
        if (!isUserVerified()) {
          setError("Please verify your email before logging in.");
          setIsLoading(false);
          return;
        }
        navigate('/');
      } else {
        if (password !== confirmPassword) {
          setError("Passwords don't match");
          setIsLoading(false);
          return;
        }
        await signup(email, password, username);
        setSuccess('Registration successful! Please check your email for verification.');
        // Clear form fields after successful registration
        setEmail('');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setPasswordStrength('');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError('');
    setSuccess('');
    setPasswordStrength('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setUsername('');
  };

  const handlePasswordVisibility = (event, setVisibility) => {
    if (event.type === 'mousedown' || event.type === 'touchstart') {
      setVisibility(true);
    } else if (event.type === 'mouseup' || event.type === 'mouseleave' || event.type === 'touchend') {
      setVisibility(false);
    }
  };

  const handleResendVerification = async () => {
    if (resendCooldown) return;

    try {
      await resendVerificationEmail();
      setSuccess('Verification email resent. Please check your inbox.');
      setResendCooldown(true);
      
      // Start cooldown timer
      let timeLeft = 60;
      const timer = setInterval(() => {
        timeLeft -= 1;
        setCooldownTime(timeLeft);
        if (timeLeft === 0) {
          setResendCooldown(false);
          clearInterval(timer);
        }
      }, 1000);
    } catch (error) {
      setError(error.message);
    }
  };

  if (currentUser) {
    return null;
  }

  // Array of dice SVGs
  const diceSvgs = {
    d4: d4Svg,
    d6: d6Svg,
    d8: d8Svg,
    d10: d10Svg,
    d12: d12Svg,
    d20: d20Svg
  };

  // Dice backgrounds to render around the login box
  const renderDiceBackgrounds = () => {
    const diceElements = [];
    const diceTypes = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20'];
    
    // Create 24 dice elements with various sizes and positions
    for (let i = 0; i < 24; i++) {
      const diceType = diceTypes[Math.floor(Math.random() * diceTypes.length)];
      const size = Math.random() * 40 + 15; // 15px to 55px
      
      // Create a distribution with a "no spawn zone" in the center and a more circular pattern
      let distance, angle, top, left;
      
      // Define the "no spawn zone" - this is smaller than the box
      const noSpawnZoneWidth = 25; // percentage of the screen width
      const noSpawnZoneHeight = 30; // percentage of the screen height
      
      // Keep generating positions until we find one that's not completely in the no spawn zone
      do {
        // Create a more circular distribution by using a different distribution for distance
        // This creates concentrations in rings around the center
        
        // Pick one of three distribution patterns for more interesting clustering
        const distributionPattern = Math.floor(Math.random() * 3);
        
        if (distributionPattern === 0) {
          // Inner ring - closer to the login box
          distance = Math.random() * 0.15 + 0.25; // 0.25 to 0.4
        } else if (distributionPattern === 1) {
          // Middle ring
          distance = Math.random() * 0.2 + 0.4; // 0.4 to 0.6
        } else {
          // Outer ring
          distance = Math.random() * 0.2 + 0.6; // 0.6 to 0.8
        }
        
        // Use full 360-degree angle distribution
        angle = Math.random() * Math.PI * 2; // 0 to 2π
        
        // Convert polar coordinates to x,y coordinates
        // Create a more circular pattern by using the same factor for both top and left
        top = 50 + Math.sin(angle) * distance * 45; // 50% +/- 45%
        left = 50 + Math.cos(angle) * distance * 45; // 50% +/- 45%
        
        // Check if this position is completely inside the no spawn zone
        const insideNoSpawnZone = 
          left > (50 - noSpawnZoneWidth/2) && 
          left < (50 + noSpawnZoneWidth/2) && 
          top > (50 - noSpawnZoneHeight/2) && 
          top < (50 + noSpawnZoneHeight/2);
          
        // If it's outside the no spawn zone or at least partially overlapping, we accept it
        if (!insideNoSpawnZone) {
          break;
        }
        
      } while (true);
      
      const rotate = Math.floor(Math.random() * 360);
      const opacity = Math.random() * 0.4 + 0.1; // 0.1 to 0.5
      
      diceElements.push(
        <img
          key={`login-dice-${i}`}
          src={diceSvgs[diceType]}
          alt=""
          className="login-dice"
          style={{
            width: `${size}px`,
            top: `${top}%`,
            left: `${left}%`,
            transform: `rotate(${rotate}deg)`,
            opacity: opacity,
            zIndex: Math.floor(opacity * 10)
          }}
        />
      );
    }
    
    return diceElements;
  };

  return (
    <div className="login-page">
      <div className="login-background">
        {renderDiceBackgrounds()}
      </div>
      <div className="login-container">
        <h2>{isLogin ? "Login" : "Sign Up"}</h2>
        {error && <div className="error-message">{error}</div>}
        {success && (
          <div className="success-message">
            {success}
            {!isLogin && (
              <div className="resend-verification">
                <button 
                  onClick={handleResendVerification} 
                  disabled={resendCooldown}
                  className="resend-btn"
                >
                  {resendCooldown ? `Resend in ${cooldownTime}s` : "Resend verification email"}
                </button>
              </div>
            )}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {!isLogin && (
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
          )}
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <div className="password-input">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onMouseDown={(e) => handlePasswordVisibility(e, setShowPassword)}
                onMouseUp={(e) => handlePasswordVisibility(e, setShowPassword)}
                onMouseLeave={(e) => handlePasswordVisibility(e, setShowPassword)}
                onTouchStart={(e) => handlePasswordVisibility(e, setShowPassword)}
                onTouchEnd={(e) => handlePasswordVisibility(e, setShowPassword)}
              >
                {showPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
              </button>
            </div>
            {!isLogin && (
              <div className={`password-strength ${passwordStrength}`}>
                {passwordStrength && `Password strength: ${passwordStrength}`}
              </div>
            )}
            {!isLogin && (
              <div className="password-criteria">
                Password must be at least 8 characters long, contain 1 number and 1 special character.
              </div>
            )}
          </div>
          {!isLogin && (
            <div className="input-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="password-input">
                <input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="password-toggle"
                  onMouseDown={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onMouseUp={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onMouseLeave={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onTouchStart={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onTouchEnd={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                >
                  {showConfirmPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                </button>
              </div>
            </div>
          )}
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLogin ? (isLoading ? "Logging in..." : "Login") 
                    : (isLoading ? "Signing up..." : "Sign Up")}
          </button>
        </form>
        <div className="auth-options">
          <button onClick={toggleMode} className="switch-mode-btn">
            {isLogin ? "Need an account? Sign Up" : "Already have an account? Login"}
          </button>
          {isLogin && (
            <Link to="/forgot-password" className="forgot-password-link">
              Forgot Password?
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
