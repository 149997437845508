import { useState, useEffect, useMemo } from 'react';
import { 
  filterSpells, 
  getUniqueValues, 
  getComponentOptions, 
  addFilter, 
  removeFilter, 
  clearFilters,
  getActiveFilters
} from '../utils/spellFilters';

const useSpellFilters = (spells, filters, setFilters) => {
  // Wrap spellsArray initialization in useMemo
  const spellsArray = useMemo(() => Array.isArray(spells) ? spells : [], [spells]);

  const [filteredSpells, setFilteredSpells] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const uniqueLevels = useMemo(() => getUniqueValues(spellsArray, 'level'), [spellsArray]);
  const uniqueSchools = useMemo(() => getUniqueValues(spellsArray, 'school'), [spellsArray]);
  const uniqueClasses = useMemo(() => getUniqueValues(spellsArray, 'classes'), [spellsArray]);
  const componentOptions = useMemo(() => getComponentOptions(spellsArray), [spellsArray]);
  const uniqueSources = useMemo(() => {
    const existingSources = new Set();
    spellsArray.forEach(spell => {
      // Add Custom Spells if there are any custom spells
      if (spell.id?.startsWith('custom-')) {
        existingSources.add('Custom Spells');
      }
      // Add regular sources
      if (spell.sources) {
        spell.sources.forEach(source => {
          if (source.book) {
            existingSources.add(source.book);
          }
        });
      }
    });
    
    const additionalSources = [
      '5.0 Free Rules',
      "Custom Spells"
    ];
    
    return [...new Set([...existingSources, ...additionalSources])].sort();
  }, [spellsArray]);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    try {
      const filtered = filterSpells(spellsArray, filters);
      setFilteredSpells(filtered);
    } catch (err) {
      setError('Error filtering spells: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  }, [filters, spellsArray]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setFilters(clearFilters());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [setFilters]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => addFilter(prevFilters, name, value));
  };

  const handleRemoveFilter = (filterName, value) => {
    setFilters(prevFilters => removeFilter(prevFilters, filterName, value));
  };

  const handleClearFilters = () => {
    setFilters(clearFilters());
  };

  return {
    filters,
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters,
    isLoading,
    error
  };
};

export default useSpellFilters;