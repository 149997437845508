// src/authFunctions.js
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  updateProfile, 
  updatePassword, 
  updateEmail, 
  reauthenticateWithCredential, 
  EmailAuthProvider,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail
} from 'firebase/auth';
import { auth } from './firebase';
import { db } from './firebase';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

/**
 * Checks if a username is already taken by another user
 * @param {string} username - The username to check
 * @returns {Promise<boolean>} - True if the username is already taken, false otherwise
 */
export const isUsernameTaken = async (username) => {
  try {
    if (!username) return false;
    
    // For non-authenticated users (during signup), we need a different approach
    // We'll check if any user document exists with this username
    const userDoc = await getDoc(doc(db, 'usernames', username.toLowerCase()));
    return userDoc.exists();
  } catch (error) {
    console.error('Error checking username availability:', error);
    // Return false on error to allow signup to proceed
    // The uniqueness will be enforced by the usernames collection
    return false;
  }
};

// Sign-up Function
export const signup = async (email, password, username) => {
  // Check if email is already in use
  const signInMethods = await fetchSignInMethodsForEmail(auth, email);
  if (signInMethods.length > 0) {
    throw new Error('An account with this email already exists. Please login instead.');
  }
  
  // Check if username is already taken
  const usernameTaken = await isUsernameTaken(username);
  if (usernameTaken) {
    throw new Error('This username is already taken. Please choose a different username.');
  }

  try {
    // Create the user in Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Set the display name in Firebase Auth
    await updateProfile(user, {
      displayName: username
    });

    // Save the user data to Firestore
    await setDoc(doc(db, 'users', user.uid), {
      username: username,
      email: email,
      createdAt: new Date().toISOString(),
      spellbooks: { 'My Spellbook': [] }  // Initialize with default spellbook
    });
    
    // Create a document in the usernames collection to ensure uniqueness
    await setDoc(doc(db, 'usernames', username.toLowerCase()), {
      uid: user.uid,
      username: username,  // Include the original username for security rule validation
      createdAt: new Date().toISOString()
    });

    // Send verification email
    await sendEmailVerification(user);
    
    return user;
  } catch (error) {
    throw error;
  }
};

// New function to check if user is verified
export const isUserVerified = () => {
  const user = auth.currentUser;
  return user && user.emailVerified;
};

// Login Function
export const login = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  if (!userCredential.user.emailVerified) {
    throw new Error("Please verify your email before logging in.");
  }
  
  // Migrate user data if needed
  await migrateUserToFirestore(userCredential.user);
  
  return userCredential;
};

// Logout Function
export const logout = async () => {
  return await signOut(auth);
};

// Change Password Function
export const changePassword = async (currentPassword, newPassword) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, currentPassword);

  try {
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  } catch (error) {
    throw error;
  }
};

// Change Email Function
export const changeEmail = async (currentPassword, newEmail) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, currentPassword);

  try {
    await reauthenticateWithCredential(user, credential);
    await updateEmail(user, newEmail);
  } catch (error) {
    throw error;
  }
};

// Reset Password Function
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw error;
  }
};

// New function to resend verification email
export const resendVerificationEmail = async () => {
  const user = auth.currentUser;
  if (user && !user.emailVerified) {
    await sendEmailVerification(user);
  } else {
    throw new Error("No user found or email already verified");
  }
};

// Add this new function
export const migrateUserToFirestore = async (user) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    
    // Only create document if it doesn't exist
    if (!userDoc.exists()) {
      await setDoc(doc(db, 'users', user.uid), {
        username: user.displayName || '',
        email: user.email,
        createdAt: user.metadata.creationTime,
        spellbooks: { 'My Spellbook': [] }
      });
      console.log('User data migrated to Firestore');
    }
  } catch (error) {
    console.error('Error migrating user data:', error);
    throw error;
  }
};
