import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Create the context
const DiceContext = createContext({
  backgroundDice: [],
  smallDice: [],
  generateDice: () => {},
  isPaused: false,
  togglePause: () => {}
});

// Provider component
export const DiceProvider = ({ children }) => {
  const [backgroundDice, setBackgroundDice] = useState([]);
  const [smallDice, setSmallDice] = useState([]);
  const [initialized, setInitialized] = useState(false);
  
  // Load initial paused state from localStorage
  const [isPaused, setIsPaused] = useState(() => {
    const savedState = localStorage.getItem('diceAnimationPaused');
    return savedState === 'true';
  });

  const togglePause = () => {
    console.log('Toggle pause called. Current state:', isPaused);
    setIsPaused(prevState => {
      const newState = !prevState;
      console.log('Setting isPaused to:', newState);
      // Save the new state to localStorage
      localStorage.setItem('diceAnimationPaused', newState.toString());
      return newState;
    });
  };

  // Generate dice positions only once on first render
  const generateDice = () => {
    if (initialized) return; // Skip if already generated

    const generateBackgroundDice = () => {
      const diceTypes = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20'];
      const dice = [];
      const usedRotations = new Set();
      
      for (let i = 0; i < 18; i++) {
        const diceType = diceTypes[Math.floor(Math.random() * diceTypes.length)];
        const size = Math.random() * 40 + 25; // 25px to 65px
        
        // Limit top value to 85% to create a safe zone at the bottom
        // This prevents dice from appearing to be cut off at the bottom edge
        const top = Math.random() * 85; 
        const left = Math.random() * 100;
        
        // Ensure unique rotation
        let rotate;
        do {
          rotate = Math.floor(Math.random() * 360);
        } while (usedRotations.has(rotate));
        usedRotations.add(rotate);
        
        const delay = Math.random() * 20;
        const duration = Math.random() * 15 + 20; // 20-35 seconds
        const opacity = Math.random() * 0.15 + 0.05; // 0.05 to 0.2
        
        dice.push({
          key: `bg-dice-${i}`,
          diceType,
          style: {
            width: `${size}px`,
            top: `${top}%`,
            left: `${left}%`,
            rotate: `${rotate}deg`,
            animationDelay: `${delay}s`,
            animationDuration: `${duration}s`,
            opacity: opacity
          }
        });
      }
      
      return dice;
    };

    const generateSmallDice = () => {
      const diceTypes = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20'];
      const dice = [];
      const usedRotations = new Set();
      
      for (let i = 0; i < 8; i++) {
        const diceType = diceTypes[i % diceTypes.length];
        const size = Math.random() * 25 + 20; // 20px to 45px
        
        // Distribute dice evenly and ensure they don't appear at the bottom edge
        let top, left;
        
        if (i < 4) {
          // Left side and top
          top = 10 + (i * 18); // Reduced vertical spacing
          left = 5 + (Math.random() * 30);
        } else {
          // Right side and top-middle (no longer at bottom)
          top = 10 + ((i - 4) * 18); // Reduced vertical spacing
          left = 65 + (Math.random() * 30);
        }
        
        // Ensure top value never exceeds 80% to prevent bottom cutoff
        top = Math.min(top, 80);
        
        // Ensure unique rotation
        let rotate;
        do {
          rotate = Math.floor(Math.random() * 360);
        } while (usedRotations.has(rotate));
        usedRotations.add(rotate);
        
        const delay = Math.random() * 10;
        const duration = Math.random() * 5 + 8; // 8-13 seconds
        const opacity = Math.random() * 0.3 + 0.3; // 0.3 to 0.6
        
        dice.push({
          key: `small-dice-${i}`,
          diceType,
          style: {
            width: `${size}px`,
            top: `${top}%`,
            left: `${left}%`,
            rotate: `${rotate}deg`,
            animationDelay: `${delay}s`,
            animationDuration: `${duration}s`,
            opacity: opacity
          }
        });
      }
      
      return dice;
    };

    setBackgroundDice(generateBackgroundDice());
    setSmallDice(generateSmallDice());
    setInitialized(true);
  };

  // Generate dice on first render
  useEffect(() => {
    generateDice();
  }, []);

  return (
    <DiceContext.Provider value={{ backgroundDice, smallDice, generateDice, isPaused, togglePause }}>
      {children}
    </DiceContext.Provider>
  );
};

// Custom hook to use the dice context
export const useDice = () => {
  const location = useLocation();
  const context = useContext(DiceContext);
  
  // If we're on the homepage, return empty arrays to not interfere with homepage styling
  if (location.pathname === '/') {
    return {
      backgroundDice: [],
      smallDice: [],
      generateDice: () => {},
      isPaused: false,
      togglePause: () => {
        console.log('Toggle pause called on homepage (no-op)');
      }
    };
  }
  
  return context;
};

export default DiceContext; 