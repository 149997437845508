import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/FAQPage.css';
import { useUserLimits } from '../UserLimitsContext';

function FAQPage() {
  const [openIndex, setOpenIndex] = useState(null);
  const { maxSpellbooks, maxCustomSpells, maxPreparedPresets } = useUserLimits();

  const faqs = [
    {
      question: "What is TableMancer?",
      answer: "TableMancer is a Dungeons & Dragons tool for players and Dungeon Masters to manage their spells and spellbooks."
    },
    {
      question: "Which D&D edition does this spell manager support?",
      answer: "The spell list contains spells updated for the latest edition 5 from 2024."
    },
    {
      question: "Is this site free?",
      answer: "We aim to keep most of the important functionality free but might have some premium features and/or ads in the future."
    },
    {
      question: "I found a bug, have some feedback, have an idea, or want to ask a question.",
      answer: "You can contact us for these things on the Contact page. See our Contact page."
    },
    {
      question: "How can I help?",
      answer: "If you like the project, please spread the word to your friends or on social media. If you have ideas or feedback, please contact us. If you want to contribute financially, we will have options for that soon."
    },
    {
      question: "Is there a mobile app available?",
      answer: "Not yet, but this is planned for the future."
    },
    {
      question: "Can I use this site without logging in?",
      answer: "Yes, but functionality will be limited and changes won't be saved. To create spellbooks or custom spells, you need to be logged in."
    },
    {
      question: "Is there a limit to the amount of spellbooks and custom spells I can create?",
      answer: `Yes. At this moment you can create ${maxSpellbooks} spellbooks, ${maxCustomSpells} custom spells, and ${maxPreparedPresets} prepared spell presets. This amount may change in the future.`
    },
    {
      question: "Can I share my spellbook or custom spells with other users?",
      answer: "At this moment it is not possible to send spellbooks or custom spells to other users but it is a feature we're planning to implement."
    },
    {
      question: "This site is confusing, I don't understand how it works.",
      answer: "We tried making the spell management system as intuitive as possible, but D&D is a complex game so it might take some playing around with it. Visit the How To page for guides, or Contact us if you have ideas on how to improve the site."
    },
    {
      question: "I don't play a wizard. Is this site still useful for me?",
      answer: "Yes! A spellbook is just a personal collection of spells. If you play a druid, sorcerer, or any other spellcaster, you can still create a spellbook and easily add all that class's spells to it. After that you can use the Prepared Spells page to keep track of your daily spells and spellslots."
    },
    {
      question: "Why would i need more than one spellbook?",
      answer: "There are many posibilities. You can make a spellbook for each character you play. You can create one for a spellbook you might find in-game. If you're a DM you can use them for NPC's. Or you can make a spellbook and use it as a favorites list or as a wishlist of spells you want to learn."
    },
    {
      question: "Why can't i just fill in my character's class and level and have it show the right amount of spell slots/points?",
      answer: "The current system requires you to manually fill in the spell slots. This means you will have to look up how many you need yourself. We have chosen for this because it makes for a more flexible system where it's easy to handle exceptions. It's common for players to have homebrew rules or magical items that change the amount of spell slots they have."
    },
    {
      question: "Why is there a limit on the spellbooks and custom spells i can create?",
      answer: "The site is still in development and being tested. The limits are there to prevent abuse and to see how people use the site. The limits are not set in stone and might change in the future."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Render function to handle answers with links
  const renderAnswer = (answer, index) => {
    if (index === 3) { // "I found a bug..." question
      return (
        <p>
          You can contact us for these things on the Contact page. See our{' '}
          <Link to="/contact" className="faq-link">Contact page</Link>.
        </p>
      );
    }
    else if (index === 9) { // "This site is confusing..." question
      return (
        <p>
          We tried making the spell management system as intuitive as possible, but D&D is a complex game so it might take some playing around with it. Visit the How To page for guides, or{' '}
          <Link to="/contact" className="faq-link">Contact us</Link> if you have ideas on how to improve the site.
        </p>
      );
    }
    else {
      return <p>{answer}</p>;
    }
  };

  return (
    <div className="faq-container">
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <h3>{faq.question}</h3>
              <span className="faq-icon"></span>
            </div>
            <div className="faq-answer">
              {renderAnswer(faq.answer, index)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQPage; 