import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/components/PageHero.css';
import { useDice } from '../contexts/DiceContext';

// Import dice SVGs
import d4Svg from '../assets/dice/d4.svg';
import d6Svg from '../assets/dice/d6.svg';
import d8Svg from '../assets/dice/d8.svg';
import d10Svg from '../assets/dice/d10.svg';
import d12Svg from '../assets/dice/d12.svg';
import d20Svg from '../assets/dice/d20.svg';

function PageHero() {
  const location = useLocation();
  const { backgroundDice, smallDice, generateDice, isPaused, togglePause } = useDice();
  const [pageInfo, setPageInfo] = useState({ 
    title: '', 
    description: '', 
    diceType: 'd20'
  });
  const pauseButtonRef = useRef(null);

  useEffect(() => {
    // Make sure dice are generated (should already be done by the context)
    generateDice();
    
    // Define page-specific information
    const path = location.pathname;
    let pageData = { 
      title: '', 
      description: '', 
      diceType: 'd20'
    };

    if (path === '/spells') {
      pageData = {
        title: 'Spell Library',
        description: 'Browse and discover magical abilities',
        diceType: 'd20'
      };
    } else if (path === '/spellbooks') {
      pageData = {
        title: 'Spellbooks',
        description: 'Organize your collection of spells',
        diceType: 'd12'
      };
    } else if (path === '/prepared-spells') {
      pageData = {
        title: 'Prepared Spells',
        description: 'Spells ready to cast',
        diceType: 'd8'
      };
    } else if (path.startsWith('/spell/')) {
      pageData = {
        title: 'Spell Details',
        description: 'Complete information about this spell',
        diceType: 'd10'
      };
    } else if (path === '/about') {
      pageData = {
        title: 'About',
        description: 'Learn about D&D Spell Manager',
        diceType: 'd6'
      };
    } else if (path === '/faq') {
      pageData = {
        title: 'FAQ',
        description: 'Frequently asked questions',
        diceType: 'd4'
      };
    } else if (path === '/how-to') {
      pageData = {
        title: 'How To Use',
        description: 'Guide to using D&D Spell Manager',
        diceType: 'd10'
      };
    } else if (path === '/contact') {
      pageData = {
        title: 'Contact',
        description: 'Get in touch with us',
        diceType: 'd4'
      };
    } else if (path === '/tools/printables') {
      pageData = {
        title: 'Printables',
        description: 'Resources for your game table',
        diceType: 'd12'
      };
    } else if (path === '/account') {
      pageData = {
        title: 'My Account',
        description: 'Manage your account settings',
        diceType: 'd6'
      };
    } else if (path === '/admin') {
      pageData = {
        title: 'Admin Dashboard',
        description: 'Manage users and monitor application metrics',
        diceType: 'd20'
      };
    }
    
    setPageInfo(pageData);
  }, [location.pathname, generateDice]);

  // Set up direct DOM event handlers for the pause button
  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log('Direct DOM pause button clicked');
      togglePause();
    };

    const button = pauseButtonRef.current;
    if (button) {
      // Remove any existing listeners first
      button.removeEventListener('click', handleClick);
      // Add the click listener
      button.addEventListener('click', handleClick);
    }

    // Cleanup function
    return () => {
      if (button) {
        button.removeEventListener('click', handleClick);
      }
    };
  }, [togglePause]);

  // Don't render on homepage or login pages
  if (location.pathname === '/' || 
      location.pathname === '/login' ||
      location.pathname === '/forgot-password') {
    return null;
  }

  // Get the right dice SVG based on the dice type
  const getDiceSvg = (diceType) => {
    switch(diceType || pageInfo.diceType) {
      case 'd4': return d4Svg;
      case 'd6': return d6Svg;
      case 'd8': return d8Svg;
      case 'd10': return d10Svg;
      case 'd12': return d12Svg;
      case 'd20':
      default: return d20Svg;
    }
  };

  // Render background dice from context
  const renderBackgroundDice = () => {
    return backgroundDice.map(dice => (
      <img 
        key={dice.key}
        src={getDiceSvg(dice.diceType)} 
        alt={dice.diceType}
        className={`background-dice ${isPaused ? 'paused' : ''}`}
        style={dice.style}
      />
    ));
  };

  // Render small foreground dice from context
  const renderSmallDice = () => {
    return smallDice.map(dice => (
      <img 
        key={dice.key}
        src={getDiceSvg(dice.diceType)} 
        alt={dice.diceType}
        className={`foreground-dice ${isPaused ? 'paused' : ''}`}
        style={dice.style}
      />
    ));
  };

  // Create custom SVG-based pause/play icons for better cross-platform compatibility
  const renderPauseIcon = () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="14" 
      height="14" 
      viewBox="0 0 24 24"
      fill="currentColor"
      style={{ 
        display: 'block',
        pointerEvents: 'none'
      }}
      preserveAspectRatio="xMidYMid meet"
    >
      <rect x="6" y="4" width="4" height="16"/>
      <rect x="14" y="4" width="4" height="16"/>
    </svg>
  );

  const renderPlayIcon = () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="14" 
      height="14" 
      viewBox="0 0 24 24"
      fill="currentColor"
      style={{ 
        display: 'block',
        pointerEvents: 'none'
      }}
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M8 5v14l11-7z"/>
    </svg>
  );

  // We'll directly create the DOM element for the pause button after the component mounts
  return (
    <div className="dice-hero-wrapper">
      <div className="dice-hero">
        <div className="dice-hero-backdrop">
          {renderBackgroundDice()}
        </div>
        
        <div className="dice-hero-content">
          <div className="hero-text">
            <h1>{pageInfo.title}</h1>
            <div className="hero-divider">
              <span className={`divider-dice d4 ${isPaused ? 'paused' : ''}`}></span>
              <span className={`divider-dice d6 ${isPaused ? 'paused' : ''}`}></span>
              <span className={`divider-dice d8 ${isPaused ? 'paused' : ''}`}></span>
              <span className={`divider-dice d10 ${isPaused ? 'paused' : ''}`}></span>
              <span className={`divider-dice d12 ${isPaused ? 'paused' : ''}`}></span>
              <span className={`divider-dice d20 ${isPaused ? 'paused' : ''}`}></span>
            </div>
            <p>{pageInfo.description}</p>
          </div>
          
          {renderSmallDice()}
        </div>
      </div>

      {/* Completely separate pause button outside the hero structure */}
      <div 
        ref={pauseButtonRef}
        id="animation-pause-btn"
        role="button"
        tabIndex={0}
        title={isPaused ? "Resume animations" : "Pause animations"}
        aria-label={isPaused ? "Resume animations" : "Pause animations"}
        className="animation-pause-button"
        onClick={() => {
          console.log("Direct onClick handler");
          togglePause();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            togglePause();
          }
        }}
      >
        {isPaused ? renderPlayIcon() : renderPauseIcon()}
      </div>
    </div>
  );
}

export default PageHero; 