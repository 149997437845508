import React, { useState, useEffect, useRef } from 'react';
import '../styles/components/DiceRollerModal.css';
import { useClickOutside } from '../hooks/useClickOutside';
import { CloseIcon } from '../data/Icons';

// Import dice SVGs
import d4Svg from '../assets/dice/d4.svg';
import d6Svg from '../assets/dice/d6.svg';
import d8Svg from '../assets/dice/d8.svg';
import d10Svg from '../assets/dice/d10.svg';
import d12Svg from '../assets/dice/d12.svg';
import d20Svg from '../assets/dice/d20.svg';
import d100Svg from '../assets/dice/d100.svg';

const DiceRollerModal = ({ isOpen, onClose }) => {
  const [selectedDice, setSelectedDice] = useState([]);
  const [modifier, setModifier] = useState(0);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(null);
  const [isRolling, setIsRolling] = useState(false);
  const [rollHistory, setRollHistory] = useState([]);
  const modalRef = useRef(null);
  const diceContainerRef = useRef(null);

  // Map dice types to their SVG files
  const diceSvgs = {
    'd4': d4Svg,
    'd6': d6Svg,
    'd8': d8Svg,
    'd10': d10Svg,
    'd12': d12Svg,
    'd20': d20Svg,
    'd100': d100Svg,
  };

  // Reset results when modal is opened or closed
  useEffect(() => {
    if (!isOpen) {
      // When modal is closed, prepare for next opening by clearing results
      setResults([]);
      setTotal(null);
      
      // Also clear the dice container if it exists
      if (diceContainerRef.current) {
        diceContainerRef.current.innerHTML = '';
      }
    }
  }, [isOpen]);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Close modal with escape key
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
    }
    
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  const addDie = (diceType) => {
    if (selectedDice.length < 20) { // Limit to 20 dice at once
      setSelectedDice([...selectedDice, diceType]);
    }
  };

  const removeDie = (index) => {
    const newSelectedDice = [...selectedDice];
    newSelectedDice.splice(index, 1);
    setSelectedDice(newSelectedDice);
  };

  const clearDice = () => {
    setSelectedDice([]);
  };

  const rollDice = () => {
    if (isRolling || selectedDice.length === 0) return;
    
    setIsRolling(true);
    const newResults = [];
    let newTotal = 0;
    
    // Clear previous dice
    if (diceContainerRef.current) {
      diceContainerRef.current.innerHTML = '';
    }
    
    // Create and animate dice
    selectedDice.forEach((diceType) => {
      const diceSize = parseInt(diceType.substring(1));
      const result = Math.floor(Math.random() * diceSize) + 1;
      newResults.push({ type: diceType, value: result });
      newTotal += result;
      
      if (diceContainerRef.current) {
        const die = document.createElement('div');
        die.className = `die ${diceType}`;
        
        // Add random rotation and position for animation
        const randomX = Math.random() * 60 - 30;
        const randomY = Math.random() * 60 - 30;
        const randomRotate = Math.random() * 360;
        
        die.style.transform = `translate(${randomX}px, ${randomY}px) rotate(${randomRotate}deg)`;
        
        // Create img element for the die SVG
        const dieImg = document.createElement('img');
        dieImg.src = diceSvgs[diceType];
        dieImg.alt = diceType;
        die.appendChild(dieImg);
        
        // Create span for the result
        const resultSpan = document.createElement('span');
        resultSpan.textContent = result;
        die.appendChild(resultSpan);
        
        diceContainerRef.current.appendChild(die);
        
        // Animate the die to center
        setTimeout(() => {
          die.style.transform = 'translate(0, 0) rotate(0deg)';
        }, 50);
      }
    });
    
    // Add modifier to total
    const modifierValue = parseInt(modifier) || 0;
    newTotal += modifierValue;
    
    // Create dice formula string
    const diceCount = {};
    selectedDice.forEach(die => {
      diceCount[die] = (diceCount[die] || 0) + 1;
    });
    
    const diceFormula = Object.entries(diceCount)
      .map(([die, count]) => `${count}${die}`)
      .join(' + ');
    
    // Update history
    const rollString = diceFormula + (modifierValue >= 0 ? '+' : '') + (modifierValue !== 0 ? modifierValue : '');
    const newRollHistory = [...rollHistory, { 
      formula: rollString, 
      results: newResults, 
      modifier: modifierValue, 
      total: newTotal,
      timestamp: new Date()
    }];
    
    // Limit history to last 10 rolls
    if (newRollHistory.length > 10) {
      newRollHistory.shift();
    }
    
    // Update state after animation
    setTimeout(() => {
      setResults(newResults);
      setTotal(newTotal);
      setRollHistory(newRollHistory);
      setIsRolling(false);
    }, 1000);
  };

  const clearHistory = () => {
    setRollHistory([]);
  };

  const diceOptions = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20', 'd100'];
  
  if (!isOpen) return null;

  return (
    <div className="dice-roller-modal-overlay">
      <div className="dice-roller-modal" ref={modalRef}>
        <div className="dice-roller-header">
          <h2>Dice Roller</h2>
          <button className="close-button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        
        <div className="dice-roller-content">
          <div className="dice-selection-area">
            <div className="dice-icons">
              {diceOptions.map(diceType => (
                <button 
                  key={diceType} 
                  className={`dice-icon-button ${diceType}`}
                  onClick={() => addDie(diceType)}
                  disabled={isRolling}
                  title={`Add ${diceType}`}
                >
                  <div className={`dice-icon ${diceType}`}>
                    <img src={diceSvgs[diceType]} alt={diceType} />
                    <span>{diceType}</span>
                  </div>
                </button>
              ))}
            </div>
            
            <div className="modifier-control">
              <label htmlFor="modifier">Modifier</label>
              <input 
                id="modifier"
                type="number" 
                value={modifier} 
                onChange={(e) => {
                  const value = e.target.value === '' ? 0 : parseInt(e.target.value);
                  setModifier(isNaN(value) ? 0 : value);
                }}
                disabled={isRolling}
              />
            </div>
          </div>
          
          <div className="selected-dice-area">
            <div className="selected-dice-header">
              <h3>Selected Dice</h3>
              {selectedDice.length > 0 && (
                <button 
                  className="clear-dice-button" 
                  onClick={clearDice}
                  disabled={isRolling}
                >
                  Clear All
                </button>
              )}
            </div>
            
            {selectedDice.length === 0 ? (
              <div className="empty-selection">Click on dice icons above to add them to your roll</div>
            ) : (
              <div className="selected-dice-list">
                {selectedDice.map((diceType, index) => (
                  <div key={`${diceType}-${index}`} className="selected-die">
                    <div className={`selected-die-icon ${diceType}`}>
                      <img src={diceSvgs[diceType]} alt={diceType} />
                      <span>{diceType}</span>
                    </div>
                    <button 
                      className="remove-die-button" 
                      onClick={() => removeDie(index)}
                      disabled={isRolling}
                      title="Remove die"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}
            
            <button 
              className="roll-button" 
              onClick={rollDice}
              disabled={isRolling || selectedDice.length === 0}
            >
              {isRolling ? 'Rolling...' : 'Roll Dice'}
            </button>
          </div>
          
          <div className="dice-roller-result">
            <div className="dice-container" ref={diceContainerRef}></div>
            
            {total !== null && (
              <div className="result-display">
                <div className="result-formula">
                  {Object.entries(selectedDice.reduce((acc, die) => {
                    acc[die] = (acc[die] || 0) + 1;
                    return acc;
                  }, {})).map(([die, count]) => `${count}${die}`).join(' + ')}
                  {modifier > 0 && ` + ${modifier}`}
                  {modifier < 0 && ` ${modifier}`}
                </div>
                <div className="result-breakdown">
                  {results.map((result, index) => (
                    <span key={index}>
                      {result.value}
                      {index < results.length - 1 ? ' + ' : ''}
                    </span>
                  ))}
                  {modifier !== 0 && (modifier > 0 ? ` + ${modifier}` : ` - ${Math.abs(modifier)}`)}
                </div>
                <div className="result-total">
                  <span>Total:</span> {total}
                </div>
              </div>
            )}
          </div>
          
          <div className="dice-roller-history">
            <div className="history-header">
              <h3>Roll History</h3>
              <button className="clear-history-button" onClick={clearHistory}>Clear</button>
            </div>
            
            {rollHistory.length === 0 ? (
              <div className="empty-history">No rolls yet</div>
            ) : (
              <div className="history-list">
                {rollHistory.slice().reverse().map((roll, index) => (
                  <div className="history-item" key={index}>
                    <div className="history-formula">{roll.formula}</div>
                    <div className="history-result">
                      <span className="history-breakdown">
                        {roll.results.map((result, idx) => (
                          <span key={idx}>
                            {result.value}
                            {idx < roll.results.length - 1 ? ' + ' : ''}
                          </span>
                        ))}
                        {roll.modifier !== 0 && (roll.modifier > 0 ? ` + ${roll.modifier}` : ` - ${Math.abs(roll.modifier)}`)}
                      </span>
                      <span className="history-total">{roll.total}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiceRollerModal; 